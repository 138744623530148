export enum EmissionValidStepsEnum {
    personal_data = 'personalData',
    manager_data = 'managerData',
    driver_data = 'driverData',
    vehicle = 'vehicle',
    beneficiaries = 'beneficiaries',
    risk_location = 'riskLocation',
    coverages = 'additionalCoverages',
    payment_method = 'payment',
    free_data = 'freeData',
}