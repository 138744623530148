import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { LABELS } from '../constants/labels.enum';
import { MENUS } from '../constants/menus.enum';
import { ERRORS } from '../constants/errors.enum';
import { Subject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class TranslationsService {

  private translations$: Subject<any> | undefined = new Subject<any>();
  constructor(private http : HttpClient) { }

  getPtBr(){
    return this.http.get('/assets/translations/pt-br.language.json').subscribe((data : any) => {
      const allData = JSON.parse(data);
      if(allData && allData['LABELS']){
        Object.entries(allData.LABELS).forEach(([key, value])=> {
          (LABELS as any)[key] = allData.LABELS[key];
        })
        
      }
    })
  }

  getEsMXx(){
    return this.http.get('/assets/translations/es-mx.language.json').subscribe((data : any) => {
      const allData = JSON.parse(data)

      if(allData && allData['LABELS']){
        Object.entries(allData.LABELS).forEach(([key, value])=> {
          (LABELS as any)[key] = allData.LABELS[key];
        })
        // this.translations$?.next({LABELS: this.labels})
      }

      if(allData && allData['MENUS']){
        Object.entries(allData.MENUS).forEach(([key, value])=> {
          (MENUS as any)[key] = allData.MENUS[key];
        })
      }

      if(allData && allData['ERRORS']){
        Object.entries(allData.ERRORS).forEach(([key, value])=> {
          (ERRORS as any)[key] = allData.ERRORS[key];
        })
      }
    })
  }

  getTranslationsObservable() {
    return this.translations$?.asObservable();
  }

  getEsMx(): void {
    this.http.get('/assets/translations/es-mx.language.json', { responseType: 'text' })
      .subscribe(data => {
        const allData = JSON.parse(data);
  
        if (allData && allData['LABELS']) {
          Object.entries(allData.LABELS).forEach(([key, value]) => {
            (LABELS as any)[key] = value;
          });
        }
  
        if (allData && allData['MENUS']) {
          Object.entries(allData.MENUS).forEach(([key, value]) => {
            (MENUS as any)[key] = value;
          });
        }
  
        if (allData && allData['ERRORS']) {
          Object.entries(allData.ERRORS).forEach(([key, value]) => {
            (ERRORS as any)[key] = value;
          });
        }
        this.translations$?.next({LABELS: { ...LABELS }, ERRORS: { ...ERRORS }, MENUS: { ...MENUS }});
      }, error => {
        console.error('Error al cargar las traducciones:', error);
      });
  }
  
}
