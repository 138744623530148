import { Component, Input } from '@angular/core';
import { SideAdvicesModel } from '../models/side-advices.model';
import { LABELS } from 'src/app/main/constants/labels.enum';

@Component({
  selector: 'side-advices',
  templateUrl: './side-advices.component.html',
  styleUrls: ['./side-advices.component.scss']
})
export class SideAdvicesComponent {

  @Input() model? : SideAdvicesModel;

  title : string = LABELS.INSURANCE_ADVANTAGES;
}
