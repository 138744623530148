<div *ngIf="model?.data?.length" class="col-12 card h-100 w-100 d-flex flex-column text-center justify-content-start">
    <div class="h-50 w-100 pt-5">
      <div class="pt-2 pb-4">
        <p class="section-title fw-medium fs-3">{{model?.title ?? 'Descripción de Plan' | uppercase}}</p>
      </div>
  
      <div *ngFor="let dato of model!.data" class="d-flex flex-column justify-content-evenly pb-2 pt-2" style="font-size: 1rem;">
        <p class="my-1 primary-color-text fw-bolder text">{{dato.etiqueta}}</p>
        <p class="my-1 text-secondary">{{dato.valor}}</p>
      </div>
  
      <div class="pt-5 mb-5  d-flex justify-content-center">
        <img alt="Company logo" class="logo-cia" [src]="'/assets/companies/cia' + (model?.ciaId ?? 'aon-logo-small') + '.svg'">
      </div>
    </div>
  </div>
  

<div *ngIf="(model?.data?.length ?? 0) < 0"
    class="col-12 card h-100 w-100 justify-content-center align-items-center text-center">
    Error... No conseguimos el plan cotizado
</div>