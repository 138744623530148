import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { LABELS } from 'src/app/main/constants/labels.enum';

@Component({
  selector: 'app-question-input',
  templateUrl: './question-input.component.html',
  styleUrls: ['./question-input.component.scss']
})
export class QuestionInputComponent implements OnInit {

  @Input() question  = "";
  @Input() yesLabel : string | undefined = undefined;
  @Input() noLabel : string | undefined = undefined;
  @Input() showError  = false;

  @Input() selected : '1' | '0' | undefined | "";
  @Output() answer = new EventEmitter<any>();

  yes : string = LABELS.YES;
  no  : string = LABELS.NO;
  errorMessage : string = LABELS.SELECT_OPTION_ERROR;

  onClick(value : any){
    this.answer.emit(value);
  }

  ngOnInit(): void {
    if (this.selected === "") {
      this.selected = undefined;
    }
  }

}
