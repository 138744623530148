import { Component, Input, OnInit } from '@angular/core';
import { LabelModel } from '../models/label.model';
import { FormBuilder, FormGroup } from '@angular/forms';

@Component({
  selector: 'custom-label',
  templateUrl: './custom-label.component.html',
  styleUrls: ['./custom-label.component.scss']
})
export class CustomLabelComponent {
  @Input()  model : LabelModel = new LabelModel();  

  // para el linting
  genericForm : FormGroup
  constructor(fb : FormBuilder) {    
    this.genericForm = fb.group({});
    
  }
}
