<div class="">    
    <div class="col-12">
        <p class="ps-2 text-white pointer back f-weight-500" (click)="back()" (keydown)="back()" tabindex="0"><i class="fa-solid fa-angle-left"></i> {{back_lbl}}</p>
      </div>

    <div class="d-flex flex-wrap gap-2 justify-content-center">
        <div class="col-12 col-md-4 scroll-y px-3" *ngIf="planDescription">
            <app-issue-plan-description [model]="planDescription"></app-issue-plan-description>
        </div>

        <div class="col-12 col-md-5 px-3 gap-2 d-flex justify-content-center flex-wrap ">
            <div class="col-12 card px-3">
                <div class="">
                    <router-outlet></router-outlet>

                    <div class="row mb-4 mt-2" *ngIf="!hideStepper && showButton">
                        <custom-button [model]="btnReturn"  (customClick)="return()" (keydown)="return()" tabindex="0" tabindex="0" class="col-6 col-sm-6 mt-2"></custom-button>
                        <custom-button [model]="continueBtn" (customClick)="next()"   (keydown)="next()" tabindex="0" class="col-6 col-sm-6 mt-2"></custom-button>
                    </div>                                
                </div>
            </div>
        </div>

        <!-- <div class="col-12 col-sm-2 card px-3 d-none d-md-block">
            <app-issue-steps-aside [steps]="steps" [currentStep]="currentStep" (onStepClick)="changeStep($event)"></app-issue-steps-aside>
        </div> -->
    </div>
</div>

