import { Injectable } from '@angular/core';
import { Observable, Subject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class SpinnerService {
  
  spinnerState$ : Subject<boolean> = new Subject();

  showOrHideSpinner(isShown : boolean){
    this.spinnerState$.next(isShown);
  }

  getState(){    
    return this.spinnerState$.asObservable();
  }

}
