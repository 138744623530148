import { Component, OnDestroy, OnInit } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { ActivatedRoute, NavigationEnd, Router } from '@angular/router';
import { Subscription, filter } from 'rxjs';
import { ButtonStylesEnum, ButtonTypesEnum } from 'src/app/main/constants/buttons.enum';
import { LABELS } from 'src/app/main/constants/labels.enum';
import { DRIVER_LOCATION, ISSUE_BENEFICIARIES, ISSUE_COVERAGES, ISSUE_DOWNLOAD, ISSUE_DRIVER, ISSUE_FREE_DATA, ISSUE_MANAGER, ISSUE_PAYMENT, ISSUE_PERSONAL_DATA, ISSUE_RISK_LOCATION, ISSUE_SUMMARY, ISSUE_VEHICLE } from 'src/app/main/constants/routes.enum';
import { EmissionStepModel } from 'src/app/main/models/emission/emission-step.model';
import { EmissionValidStepsEnum } from 'src/app/main/models/emission/emission-valid-steps.enum';
import { PlanDescriptionModel } from 'src/app/main/models/emission/plan-description.model';
import { EmissionInfoModel } from 'src/app/main/models/emission/emission-info.model';
import { EmissionFormHandlerService } from 'src/app/main/services/issue/emission-form-handler.service';
import { RedirectorService } from 'src/app/main/services/redirector.service';
import { ButtonModel } from 'src/app/shared/models/button.model';
import { ModelCreatorService } from 'src/app/shared/services/model-creator.service';
import { IssueState } from 'src/app/state/issue/issue.state';
import { States } from 'src/app/state/models/states.enum';
import { StateHandlerService } from 'src/app/state/state-handler.service';
import { QuotingInfoModel } from 'src/app/main/models/emission/quoting-info.model';
import { SideAdvicesModel } from 'src/app/shared/models/side-advices.model';
import { LogosEnum } from 'src/app/main/constants/logos.enum';
import { LoginState } from 'src/app/state/login/login.state';
import { PopupOpenerService } from 'src/app/shared/services/popup-opener.service';
import { PopupMessageComponent } from 'src/app/shared/popup-message/popup-message.component';
import { ICONS } from 'src/app/main/constants/icons.enum';
import { PopUpTypesEnum } from 'src/app/shared/enums/popup-types.enum';
import { ERRORS } from 'src/app/main/constants/errors.enum';
import { NewProposalModel } from 'src/app/main/models/issue/new-proposal.model';
import { EmissionPaymentComponent } from 'src/app/features/issue/emission-payment/emission-payment.component';
import { PricedPlanModel } from 'src/app/main/models/issue/priced-plan.enum';
import { PricingResult } from 'src/app/main/models/issue/price.model';
import { Vehicle } from 'src/app/main/models/vehicle.model';
import { ConsultantService } from 'src/app/main/services/consultant.service';

@Component({
    selector: 'app-create-insurance',
    templateUrl: './create-insurance.component.html',
    styleUrls: ['./create-insurance.component.scss']
})
export class CreateInsuranceComponent implements OnDestroy,OnInit {

    steps : EmissionStepModel[] = [];
    currentStep  = 0;
    hideStepper  = false;
    pets : any[] | null = null;
    back_lbl = LABELS.BACK_DASHBOARD;

    continueBtn : ButtonModel;
    btnReturn : ButtonModel;
    showButton=false

    quotingInfo? : QuotingInfoModel;
    quotingInfoSub : Subscription = new Subscription();
    form? : FormGroup;
    initialRequest : any;
    planDescription? : PlanDescriptionModel;

    pricingId? : number;
    idProspecto?:any;
    routeSub : Subscription = new Subscription();
    onCloseSub : Subscription | undefined = new Subscription();

    private subscriptionSteps: Subscription = new Subscription();
    private readonly valid_steps = EmissionValidStepsEnum;

    constructor(private modelCreator: ModelCreatorService,
        private stateHandler: StateHandlerService,
        private router: Router,
        private issue_state: IssueState,
        private formHandler: EmissionFormHandlerService,
        private redirector: RedirectorService,
        private route : ActivatedRoute,
        private login_state : LoginState,
        private popUpOpener : PopupOpenerService,
        private consultantProposal:ConsultantService
    ) {
        this.btnReturn = modelCreator.createButtonModel(LABELS.RETURN_ISSUE, ButtonTypesEnum.Raised, ButtonStylesEnum.without_white_outline);
        this.continueBtn = modelCreator.createButtonModel(LABELS.CONTINUE, ButtonTypesEnum.Common, ButtonStylesEnum.black);
        this.idProspecto=this.issue_state.fullState.prospectusQuoteState.prospectId;
        this.formHandler.cleanForm();
        this.route.queryParams.subscribe({
            next : (params) => {
                if(params){
                    const a = params['pricingId'];
                    if(!this.pricingId)
                        this.pricingId = +a;
                    this.getQuotingInfo(this.pricingId);
                }                
            }
        })

        this.routeSub = router.events.pipe(filter(event => event instanceof NavigationEnd)).subscribe({
            next : (route : any) => {
                const currentStep = this.steps?.find(s => route.url.search(s.route) !== -1)            
                if(currentStep)
                    this.currentStep = this.steps.indexOf(currentStep);
            }
          })
    }
    ngOnInit(): void {
        this.subscriptionSteps.add(
            this.consultantProposal.nextStepCompleted$.subscribe((value) => {
              if (value === '1') {
                this.next();
              }
              if (value === '0') {
                this.return();
              }
            })
          );
    }


    return() {

        if(this.currentStep === 0){
            if(this.quotingInfo?.EsMascota){
                this.redirector.redirectToPETPlansQuoted();
            }else if(this.quotingInfo?.EsVehiculo){
                this.redirector.redirectToProposalPlansVehicle();
            }
            else if(this.quotingInfo?.EsDental){
                this.redirector.redirectToDentalPlansQuoted();
            }
        }
            
        if (this.currentStep > 0){ 
            this.showButton =false;
            this.currentStep--;
            this.router.navigate([this.steps[this.currentStep].route]);
        }
    }

    getQuotingInfo(pricingId : number) {     
        this.quotingInfoSub = this.issue_state.checkState().subscribe({
          next : (state) => {
            const quotingState = state.quotingState;
                
            if(quotingState.state === States.success && quotingState.quotingInfo && !this.planDescription){
              this.quotingInfo = {...quotingState.quotingInfo};  
              this.initialRequest = {...state.pricingState.initialRequest};
              if(!this.steps || this.steps.length === 0) {
                this.setValidSteps(this.quotingInfo);                
              }
              this.pets = state.quotingState.mascotas ?? [];
              this.getPlanDescription(state.pricingState.pricing?.Result, this.quotingInfo.EsMascota ?? false);

              if(state.prospectusQuoteState.quoteInfo){
                try{
                    const prospectData = JSON.parse(state.prospectusQuoteState.quoteInfo.CotDatosMinimos ?? "");
                    this.patchProspectData(prospectData);
                }
                catch{
                    // no patcheamos nada y listo
                }
                
              }
            }
            

            if(state.emissionState.state === States.success && !this.hideStepper && !state.emissionState.paymentInfo){
                if(state.emissionState.emissionResult?.IsSuccess){
                    this.popUpOpener.show({
                        component : PopupMessageComponent,
                        config : {
                            closable : true, closeOnEscape : true
                        },
                        data : {
                            type  : PopUpTypesEnum.success,
                            messages : [LABELS.EMISSION_SUCCES, LABELS.EMISSION_COMPLETE_PAYMENT],
                            button: true,
                            buttonModel: this.modelCreator.createButtonModel(LABELS.GO_TO_PAYMENT,ButtonTypesEnum.Raised,ButtonStylesEnum.black),
                            onAccept: () => {this.popUpOpener.closeAll(), this.onClosePopup()} 
                        },
                      })
                      this.onCloseSuccess();                                                                               
                }
                else{
                  this.popUpOpener.show({
                    component : PopupMessageComponent,
                    config: {closable : true, closeOnEscape : true},
                    data : {
                        type  : PopUpTypesEnum.warning,
                        icon : ICONS.WARNING,
                        messages : [ERRORS.COULD_NOT_EMIT, `<strong>${state.emissionState.emissionResult?.Message ?? ""}</strong>`]
                    }
                  })
                  
                }
                this.continueBtn.disabled = false;
            }
            else if (state.emissionState.state === States.error){
                this.continueBtn.disabled = false;
            }
          }
        })
    }

    setValidSteps(emissionData : QuotingInfoModel | undefined) {
        this.formHandler.createForm(this.quotingInfo?.EsVehiculo, false, true, false,this.quotingInfo?.EsDental);
        this.form = this.formHandler.getForm();
        this.form.patchValue(this.initialRequest);        

        if (emissionData?.EsVehiculo){

            this.steps.push({
                id: 1,
                descripcion: LABELS.VEHICLE_DATA,
                formName: this.valid_steps.vehicle,
                route: ISSUE_VEHICLE
            });  

            this.steps.push({
                id: 7,
                descripcion: LABELS.PERSONAL_DATA_TITLE,
                formName: this.valid_steps.personal_data,
                route: ISSUE_PERSONAL_DATA
            });

            this.steps.push({
                id: 8,
                descripcion: LABELS.DRIVER_DATA_TITLE,
                formName: this.valid_steps.driver_data,
                route: ISSUE_DRIVER
            });
            // let vehicle = {...this.initialRequest.vehiculo ?? this.initialRequest.Vehiculo};
            // vehicle.chasis = vehicle.Chasis;
            // this.formHandler.getFormControl(EmissionValidStepsEnum.vehicle).patchValue(vehicle);
            // if(vehicle.ceroKm)
            //     this.steps.push({
            //         id: 7,
            //         descripcion: LABELS.VEHICLE_DATA,
            //         formName: this.valid_steps.vehicle,
            //         route: ISSUE_VEHICLE
            //     });                
        }



        if (emissionData?.EsDental){

            this.steps.push({
                id: 1,
                descripcion: LABELS.PERSONAL_DATA_TITLE,
                formName: this.valid_steps.personal_data,
                route: ISSUE_PERSONAL_DATA
            });

            this.steps.push({
                id: 7,
                descripcion: LABELS.RISK_LOCATION,
                formName: this.valid_steps.risk_location,
                route: ISSUE_RISK_LOCATION
            });

            this.steps.push({
                id: 8,
                descripcion: LABELS.MANAGER_DATA_TITLE,
                formName: this.valid_steps.manager_data,
                route: ISSUE_MANAGER
            });
        }


        if(emissionData?.EsMascota){
            this.steps.push({
                id: 1,
                descripcion: LABELS.PERSONAL_DATA_TITLE,
                formName: this.valid_steps.personal_data,
                route: ISSUE_PERSONAL_DATA
            });

            this.steps.push({
                id: 7,
                descripcion: LABELS.RISK_LOCATION,
                formName: this.valid_steps.risk_location,
                route: ISSUE_RISK_LOCATION
            });
        }

        /*
        if (this.quotingInfo?.)
            this.steps.push({
                id: 5,
                descripcion: 'Datos Asegurados',
                formName: this.valid_steps.beneficiaries,
                route: ISSUE_BENEFICIARIES
            });
            

        if ((this.quotingInfo?.coberturas?.length ?? 0) > 0)
            this.steps.push({
                id: 7,
                descripcion: 'Coberturas Adicionales',
                formName: this.valid_steps.coverages,
                route: ISSUE_COVERAGES,
            });

        if ((this.quotingInfo?.datosLibres?.length ?? 0) > 0)
            this.steps.push({
                id: 7,
                descripcion: 'Datos Libres',
                formName: this.valid_steps.free_data,
                route: ISSUE_FREE_DATA,
            });

        */
        this.steps.push({
            id: 7,
            descripcion: LABELS.EMIT_SUMMARY,
            formName: '',
            route: ISSUE_SUMMARY,
        });

        this.router.navigate([this.steps[this.currentStep].route]);
    }

    next() {
        this.showButton=false;
        this.continueBtn.text = LABELS.CONTINUE;
        const currentForm: string = this.steps[this.currentStep].formName;
        if (this.form!.controls[currentForm] && this.form!.controls[currentForm].invalid) {
            this.steps[this.currentStep].checked = false;
            //this.showModal([ERRORS.INCOMPLETE_FIELDS], 'warning', undefined, [{textButton: BUTTONS.ACCEPT}],true)
            this.form!.controls[currentForm].markAllAsTouched(); // Forzar que salgan inputs en rojo 
            return;
        }
        this.steps[this.currentStep].checked = true;
        this.getVigency();

        if (this.currentStep + 1 == this.steps.length) {
            this.showButton=true;
            this.continueBtn.text = LABELS.EMIT_INSURANCE;
            if(this.pets?.length){
                this.continueBtn.text = LABELS.EMIT_PLANO;
            }

            this.continueBtn.disabled = true;
            this.emit();
            return;
        }

        this.currentStep++;
        this.router.navigate([this.steps[this.currentStep].route]);

        if (this.currentStep + 1 === this.steps.length) {
            this.continueBtn.text = LABELS.EMIT_INSURANCE;
            if(this.pets?.length){
                this.continueBtn.text = LABELS.EMIT_PLANO;
            }
        }

        
    }

    emit(){
        if(!this.form || this.form.invalid)
            return; //mensajito
        const formValue = this.form!.getRawValue();
        const newProposal : NewProposalModel = {
            IdPlanCotizado : this.pricingId,
            IdProspecto:this.idProspecto,
            Telefono :'52' + '-' +formValue.personalData.telefonoArea + '-' + formValue.personalData.telefono,
            Cliente : {
                Id : this.login_state?.user?.IdCliente as any,
                IdGenero : formValue.personalData.genero?.id,
                IdEstadoCivil: formValue.personalData.estadoCivil?.id,
                FechaNacimiento :formValue.personalData.tipoPersona.id==='1' ? formValue.personalData.fechaNacimiento : formValue.personalData.fechaNacimientoConstitucion,
                Email : formValue.personalData.email ?? '',
                IdGiro:formValue.personalData?.giro?.id,
                IdOcupacion:formValue.personalData?.ocupacion?.id,
                IdRegimenFiscal:formValue.personalData?.regimenFiscal?.id,
                IdRegimenSocietario:formValue.personalData?.regimenSocietario?.id
            },
        }
        if(formValue.vehicle){
            const vehicle = new Vehicle();            
            vehicle.CeroKm =  formValue.vehicle.condicion?.id =='1' ? true : false;
            vehicle.numeroSerie=formValue.vehicle.numeroSerie
            vehicle.motor=formValue.vehicle.motor
            vehicle.placa=formValue.vehicle.placa
            vehicle.repuve=formValue.vehicle.repuve
            vehicle.condicion=formValue.vehicle.condicion.id
            vehicle.precio=formValue.vehicle.precio
            newProposal.Vehiculo = vehicle;
        }

        if(formValue.personalData && formValue.driverData){
            const domicile = {
                Id : formValue.personalData.domicilio?.id !== "-1" ? formValue.personalData.domicilio?.id : null,
                Calle : formValue.personalData.calle,
                Numero : formValue.personalData.numero,
                Complemento : formValue.personalData.numeroExterno+"-"+formValue.personalData.numeroInterno,
                Estado: formValue.personalData.estado,
                IdDistrito : formValue.personalData.colonia?.id ? formValue.personalData.colonia.id : null,
                Distrito : formValue.personalData.colonia?.id ? formValue.personalData.colonia.descripcion : formValue.personalData.colonia,
                Cp : formValue.personalData.codigoPostal,
                IdCiudad: formValue.personalData.municipio?.id ?? formValue.personalData.idCiudad,
                IdRegion : formValue.personalData.estado.id
            };
            
            const asegurado = {
                TipoPersona:formValue.personalData.tipoPersona?.id,
                RfcAsegurado:formValue.personalData.rfc,
                NombresAsegurado:formValue.personalData.tipoPersona.id==='1' ? formValue.personalData.nombres : formValue.personalData.razonSocial,
                ApellidoPaternoAsegurado:formValue.personalData.apellidoPaterno,
                ApellidoMaternoAsegurado:formValue.personalData.apellidoMaterno,

                TipoPersonaConductor:formValue.driverData.tipoPersona?.id,
                RfcConductor:formValue.driverData.rfc,
                NombresConductor:formValue.driverData.nombres,
                ApellidoPaternoConductor:formValue.driverData.apellidoPaterno,
                ApellidoMaternoConductor:formValue.driverData.apellidoMaterno,
                IdOcupacion:formValue.driverData.ocupacion?.id
            };
            newProposal.AseguradoConductor=asegurado
            newProposal.Domicilio = domicile;
        }

        if(this.pets){
            newProposal.Mascotas = this.pets.map(p => {
                return {
                    id : p.plan,
                    descripcion : p.nombre,
                }
            });
        }

        this.issue_state.emit(newProposal);
        
    }


    back() {
        this.redirector.redirectToDashboard();
    }


    ngOnDestroy(): void {
        this.issue_state.clearEmissionState();
        this.quotingInfoSub.unsubscribe();
        this.routeSub.unsubscribe();
        this.onCloseSub?.unsubscribe();
        this.subscriptionSteps.unsubscribe();
    }

    onCloseSuccess() {
        this.onCloseSub = this.popUpOpener.getOnCloseObs()?.subscribe({
            next: (ev) => {   
                if(!this.hideStepper){
                    this.onClosePopup();
                }                
            }

        })
    }

    onClosePopup(){
        this.steps.push({
            id: 10,
            descripcion: LABELS.PAYMENT_DATA_TITLE,
            formName: '',
            route: ''
        });
        this.popUpOpener.hide();
        this.onCloseSub?.unsubscribe();
        this.hideStepper = true;
        this.redirector.redirectToPayment();   
    }
    changeStep(step : any){        
        if(step.id >= this.currentStep || this.hideStepper)
            return;

        this.router.navigate([this.steps[step.id].route]);
    }

    getVigency() : void{        
        const start = this.form?.value.vigencia;
        if(!start)
            return;
        
        const startString = start.getDate() + "/" + (start.getMonth() + 1) + "/" + start.getFullYear();
        const endString = start.getDate() + "/" + (start.getMonth() + 1) + "/" + (start.getFullYear() + 1)

        if(!this.planDescription?.data?.find(d => d.etiqueta === LABELS.VIGENCY))
            this.planDescription?.data?.push({ etiqueta: LABELS.VIGENCY, valor: startString + " - " + endString })
        
    }

    getPlanDescription(pricingState : PricingResult | undefined, isPet : boolean) : void{
        if(isPet){
            const cardData : any = [];
            this.pets?.forEach(p => {                
                cardData.push({etiqueta: p.nombre, valor: p.plan + " - " + p.descripcion});
                cardData.push({etiqueta: LABELS.MONTHLY_QUOTA_DETAIL , valor: pricingState?.Planes.find(pla => pla.IdCotizacionPlan === p.plan)?.Cuota})
            });                    

            this.planDescription = {
                producto : pricingState?.Producto ?? "",
                ciaId : pricingState?.Planes[0].IdCompania,
                title : LABELS.SELECTED_PLAN,
                plan : '',
                vigencia : '',
                cuota : '',
                data : cardData,
            }


        }
        else{
            const currentPlan = pricingState?.Planes.find(p => p.IdCotizacionPlan === this.pricingId);
            if(!currentPlan)
                return undefined;

            this.planDescription = {
                producto : pricingState?.Producto ?? "",
                plan : currentPlan.Plan,
                vigencia : currentPlan.Vigencia ?? '',
                cuota : currentPlan.Cuota,
                ciaId : currentPlan.IdCompania,
                title : LABELS.SELECTED_PLAN,
                data : []
            }                  
            
            const vehiculo = this.initialRequest.Vehiculo.Descripcion;
            if(vehiculo && vehiculo.search("undefined") === -1){ // jeje
                this.planDescription!.data!.push({etiqueta : LABELS.VEHICLE, valor: vehiculo});
            }

            this.planDescription.data?.push({etiqueta: LABELS.PLAN, valor : currentPlan.Plan});
            this.planDescription.data?.push({etiqueta: LABELS.MONTHLY_QUOTA_DETAIL, valor: currentPlan.Cuota});
        }
    }

    patchProspectData(data : any){
        
        const controls = this.form?.controls;
        if(!controls)
            return;

        if(controls[this.valid_steps.vehicle]){
            // Por ahora solo nos viene el chasis
            const values = {
                condicion : '',
                fechaInicioVigencia : '',
                motor : '',
                numeroSerie : data?.Vehiculo?.Chasis,
                placa : '',
            }
            controls[this.valid_steps.vehicle].patchValue(values);
        }
        // Acá podríamos patchear el resto de forms!
            
    }
    

}
