import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import { ButtonStylesEnum, ButtonTypesEnum } from 'src/app/main/constants/buttons.enum';
import { ERRORS } from 'src/app/main/constants/errors.enum';
import { InputTypesEnum } from 'src/app/main/constants/inputs-types.enum';
import { LABELS } from 'src/app/main/constants/labels.enum';
import { CodeValidationModel } from 'src/app/main/models/code-validation.model';
import { CoverCardModel } from 'src/app/main/models/cover-card.model';
import { IRegisterModel } from 'src/app/main/models/auth/register.model';
import { AuthenticatorService } from 'src/app/main/services/authenticator.service';
import { CrypterService } from 'src/app/main/services/crypter.service';
import { NotificatorService } from 'src/app/main/services/notificator.service';
import { RedirectorService } from 'src/app/main/services/redirector.service';
import { SpinnerService } from 'src/app/main/services/spinner.service';
import { TranslationsService } from 'src/app/main/services/translations.service';
import { ButtonModel } from 'src/app/shared/models/button.model';
import { PasswordConfirmationModel } from 'src/app/shared/models/password-confirmation.model';
import { ModelCreatorService } from 'src/app/shared/services/model-creator.service';
import { AppState } from 'src/app/state/app/app.state';
import { StateHandlerService } from 'src/app/state/state-handler.service';

@Component({
  selector: 'app-recover',
  templateUrl: './recover.component.html',
  styleUrls: ['./recover.component.scss']
})
export class RecoverComponent implements OnInit {

  loadedLabels = false;
  title : string = LABELS.RECOVER
  home_message  : string = LABELS.REDIRECT_TO_HOME;
  retry_message : string = LABELS.RESEND_EMAIL;
  error_message : string = ERRORS.WRONG_RECOVER_INFO;  
  success_message : string = LABELS.EMAIL_SUCCESS;
  label_continue: string = LABELS.CONTINUE;

  message  = "";

  coverModel : CoverCardModel = new CoverCardModel();
  passwordModel : PasswordConfirmationModel;
  continueModel : ButtonModel;  
  isErrorShown  = false;
  isMessageShown  = false;

  canContinueError  = true;
  
  queryParams$ : any;
  recoverForm : FormGroup;
  user? : CodeValidationModel;
  enterpriseId : number;

  constructor(private modelCreator : ModelCreatorService,
    private crypter : CrypterService,
    private route : ActivatedRoute,
    private spinner : SpinnerService,
    private auth   : AuthenticatorService,
    private redirector : RedirectorService,
    private notificator : NotificatorService,
    private translations: TranslationsService,
    private appState : AppState,    
    private fb : FormBuilder) {

      this.checkLbls();
      this.spinner.showOrHideSpinner(true);
      this.recoverForm = this.fb.group({
        password : ['', [Validators.required]],
        passwordConfirm : ['', [Validators.required]]
      });
    
      const inputModel = this.modelCreator.createInputModel(LABELS.PASSWORD, InputTypesEnum.password, 'password', this.recoverForm, ERRORS.PASSWORD_UNFILLED);
      this.passwordModel = this.modelCreator.createPasswordModel(inputModel, 'password', this.recoverForm);      

      this.continueModel = this.modelCreator.createButtonModel(this.label_continue, ButtonTypesEnum.Common, ButtonStylesEnum.black);        
      this.enterpriseId = this.appState.getEnterpriseId();
  }

  ngOnInit(): void {

    this.queryParams$ = this.route.queryParams.subscribe((params) => {      
      try{        
        const cleanedParameter = this.cleanParameter(params['token']);
        
        const user = this.crypter.decrypt(cleanedParameter);
        const routingData = JSON.parse(user);
        
        this.user = routingData;
      }
      catch(ex){
        this.showError(false);
      }        
      finally{
        this.spinner.showOrHideSpinner(false);
      }

    });        
  }

  cleanParameter( parameter : string){
    return parameter.replaceAll(' ', '+');
  }

  recoverPassword(){
    if(!this.user){
      this.showError();
      return;
    }


    if(!this.recoverForm.value || this.recoverForm.invalid || this.recoverForm.value.password !== this.recoverForm.value.passwordConfirm){
      return;
    }    

    const recoverData  : CodeValidationModel = {
      Username : this.user.Username,
      Password : this.recoverForm.value.password,
      Code : this.user.Code,
      CodeSended : false,
      IdEmpresa : this.enterpriseId,
      Referencia : this.user.Referencia
    };

    this.auth.changePassword(recoverData).subscribe({
      next : (data) => {
        if(data.IsSuccess){

          this.redirector.redirectToLogin();
          return;
        }

        this.showError();
      },

      error : () => {
        this.showError();

      }


    })
  }

  showError(canContinue  = true){
    this.isErrorShown = true;
    this.canContinueError = canContinue
    this.showMessage(this.error_message);
  }

  showSuccess(){
    this.isErrorShown = false;
    this.canContinueError = false;
    this.showMessage(this.success_message);
    
  }


  showMessage(msg : string){
    this.isMessageShown = true;
    this.message = msg;
  }

  redirectToLogin(){
    this.redirector.redirectToLogin();
  }

  resendEmail(){
    this.notificator.sendResetPasswordEmail(this.user?.Username ?? "").subscribe({
      next : (data) =>{
        if(!data?.IsSuccess){
          this.showError(false);
          return;
        }

        this.showSuccess();
        
      },
      error : () =>{
        this.showError(false);
      }
    });
  }


  checkLbls(){

    this.translations.getTranslationsObservable()?.subscribe(translations => {

      if(translations.LABELS){
        this.title  = LABELS.RECOVER
        this.home_message   = LABELS.REDIRECT_TO_HOME;
        this.retry_message  = LABELS.RESEND_EMAIL;
        this.error_message  = ERRORS.WRONG_RECOVER_INFO;  
        this.success_message  = LABELS.EMAIL_SUCCESS;
        this.label_continue=  LABELS.CONTINUE;

        if(this.passwordModel){
          this.passwordModel!.inputModel!.placeholder = LABELS.PASSWORD        
          this.passwordModel!.inputModel!.customError = ERRORS.PASSWORD_UNFILLED;
        }        

        if(this.isErrorShown){
          this.showError(false);
        }
      }

      this.loadedLabels = true;
    })
  }

}
