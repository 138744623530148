<div class="justify-content-left col-12 col-md-8 m-auto">
    <div class="row pt-3 align-items-start stepper-title-container">
        <p class="fw-medium title mt-4">{{title}}</p>
    </div>
  </div>
  
  <div class="justify-content-center col-12 col-md-8 m-auto">

    <div *ngIf="currentStep===0" >
      <p class="fs-6 text-secondary text-center mt-4" style="color:rgb(172, 172, 172) !important;">{{have_alarm}}</p>
      <div class="card-container d-flex flex-wrap justify-content-center">
          <div class="card" (keypress)="validateAlarm(1)" (click)="validateAlarm(1)" [class.selected]="riskForm!.controls['tieneAlarma']!.value ===1" tabindex="0">
            <div class="card-body" >
              <p class="card-title">Si</p>
            </div>
          </div>
          <div class="card" (keypress)="validateAlarm(0)" (click)="validateAlarm(0)" [class.selected]="riskForm!.controls['tieneAlarma']!.value ===0" tabindex="0">
            <div class="card-body" >
              <p class="card-title">No</p>
            </div>
          </div>
      </div>
  
      <p class="fs-6 text-secondary text-center mt-4" style="color:rgb(172, 172, 172) !important;">{{electric_fence}}</p>
      <div class="card-container d-flex flex-wrap justify-content-center">
          <div class="card" (keypress)="validateElectricFence(1)" (click)="validateElectricFence(1)" [class.selected]="riskForm!.controls['cercaElectronicas']!.value ===1" tabindex="0">
            <div class="card-body" >
              <p class="card-title">Si</p>
            </div>
          </div>
          <div class="card" (keypress)="validateElectricFence(0)" (click)="validateElectricFence(0)" [class.selected]="riskForm!.controls['cercaElectronicas']!.value ===0" tabindex="0">
            <div class="card-body">
              <p class="card-title">No</p>
            </div>
          </div>
      </div>

      <p class="fs-6 text-secondary text-center mt-4" style="color:rgb(172, 172, 172) !important;">{{property_with_railing}}</p>
      <div class="card-container d-flex flex-wrap justify-content-center">
          <div class="card" (keypress)="validatePropertyWithRailing(1)" (click)="validatePropertyWithRailing(1)" [class.selected]="riskForm!.controls['inmuebleConBarandilla']!.value ===1" tabindex="0">
            <div class="card-body" >
              <p class="card-title">Si</p>
            </div>
          </div>
          <div class="card" (keypress)="validatePropertyWithRailing(0)" (click)="validatePropertyWithRailing(0)" [class.selected]="riskForm!.controls['inmuebleConBarandilla']!.value ===0" tabindex="0"> 
            <div class="card-body" >
              <p class="card-title">No</p>
            </div>
          </div>
      </div>

    </div>
  
    <div class="row mb-4 mt-2">
      <custom-button [model]="btnReturn!" (customClick)="return()" class="col-sm-4 mt-2"
        *ngIf="currentStep<=3"></custom-button>
      <custom-button [model]="btnContinue!" (customClick)="next()" class="col-sm-6 offset-sm-2 mt-2"
        *ngIf="true"></custom-button>
    </div>
  </div>