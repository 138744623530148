import { HttpClient } from "@angular/common/http";
import { Subject} from "rxjs"
import { environment } from "src/environments/environment";
import { Injectable, Injector } from "@angular/core";

import { States } from "../models/states.enum";
import { IGenericResponseModel } from "src/app/main/models/generic-response.model";
import { IssueFullState } from "../models/issue-full.state";
import { ClienteProducto } from "src/app/main/models/issue/issue-data.model";
import { IPaymentInfo } from "src/app/main/models/emission/payment-info.model";

@Injectable()
export class IssueState {

    fullState : IssueFullState = new IssueFullState();
    fullState$ : Subject<IssueFullState> = new Subject<IssueFullState>();

    private readonly pre_pricing_url : string = `${environment.quoting_url}/ObtenerDatosCotizacion`
    private readonly quote_url : string = `${environment.quoting_url}/ObtenerCotizaciones`
    private readonly prospectus_quote_url : string = `${environment.quoting_url}/GenerarCotizacionProspecto`
    private readonly pricing_url : string = `${environment.quoting_url}/Cotizar`
    private readonly pre_emission_url : string = `${environment.emission_url}/ObtenerDatosEmision`
    private readonly emission_url : string = `${environment.emission_url}/Emitir`
    private readonly emission_online_url : string = `${environment.emission_url}/EmitirOnline`

    constructor(private http : HttpClient) {}

    // Desde acá vemos en qué estado están las llamadas
    // Puede ser EMPTY (no se llamó nunca), LOADING(estamos llamando) o ERROR(hubo un error en la llamada)
    checkState(){
        setTimeout(() => {
            this.fullState$.next(this.fullState);
        });
        return this.fullState$.asObservable();
    }

    clearQuoteAndPriceState(){
        this.fullState.quotingState= {
            state : States.empty,
            quotingInfo : undefined,
            error : undefined
        }
        this.fullState.pricingState = {
            state: States.empty,
            pricing : undefined,
            error : undefined
        }
        this.fullState.quoteState = {
            state : States.empty,
            quoteId : undefined,
            quoteInfo : undefined,
            quoteType : undefined
        }

        this.fullState.prospectusQuoteState = {
            state : States.empty,
            prospectId : undefined,
            quoteInfo : undefined
        }
    }

    // Esta parte sería la de los "efectos"
    getQuotingInfo(tprId : number,quoteId?:any){
        this.fullState.quotingState = {
            state : States.loading,
            quotingInfo: undefined,
            error : undefined,
        }
        const request={TprId:tprId,CotId:quoteId}
        this.fullState$.next(this.fullState);
        this.http.post<IGenericResponseModel>(this.pre_pricing_url, request)
            .subscribe({
                next : (response) => {
                    if(!this.fullState.quotingState)
                        return;

                    this.fullState.quotingState.state = States.success;
                    this.fullState.quotingState.quotingInfo = response.Result;
                    if(this.fullState.quotingState.quotingInfo)
                        this.fullState.quotingState.quotingInfo.idTipoProducto = tprId;
                },
                error : (err) => {
                    if(!this.fullState.quotingState)
                        return;
                    
                    this.fullState.quotingState.state = States.error;
                    this.fullState.quotingState.error = err;
                },
                complete : () =>{
                    this.fullState$.next(this.fullState);
                }
            })
        return this.fullState$;
    }
    setQuote(quoteId:number,quoteType:number,goToPlans:boolean){
        if(!this.fullState.quoteState)
            return;
        
        this.fullState.quoteState.quoteId = quoteId;
        this.fullState.quoteState.quoteType = quoteType;
        this.fullState.quoteState.goToPlans=goToPlans;
    }

    getQuoteInfo(){
        
        this.fullState.quoteState = {
            state : States.loading,
            quoteInfo: undefined,
            error : undefined,
        }

        this.fullState$.next(this.fullState);
        this.http.post<IGenericResponseModel>(this.quote_url,'')
            .subscribe({
                next : (response) => {
                    if(!this.fullState.quoteState)
                        return;

                    this.fullState.quoteState!.state = States.success;
                    this.fullState.quoteState!.quoteInfo = response.Result;
                    // if(this.fullState.quotingState.quotingInfo)
                    //     this.fullState.quotingState.quotingInfo.idTipoProducto = tprId;
                },
                error : (err) => {
                    this.fullState.quoteState!.state = States.error;
                    this.fullState.quoteState!.error = err;
                },
                complete : () =>{
                    this.fullState$.next(this.fullState);
                }
            })
        return this.fullState$;
    }

    getProspectusQuoteInfo(id:number){
        this.fullState.prospectusQuoteState = {
            state : States.loading,
            quoteInfo: undefined,
            error : undefined,
        }

        this.fullState$.next(this.fullState);
        this.http.post<IGenericResponseModel>(this.prospectus_quote_url,id)
            .subscribe({
                next : (response) => {
                    if(!this.fullState.prospectusQuoteState)
                        return;
                
                    this.fullState.prospectusQuoteState!.state = States.success;
                    this.fullState.prospectusQuoteState!.quoteInfo = response.Result;
                    this.fullState.prospectusQuoteState!.prospectId = id;
                },
                error : (err) => {
                    if(!this.fullState.prospectusQuoteState)
                        return;

                    this.fullState.prospectusQuoteState!.state = States.error;
                    this.fullState.prospectusQuoteState!.error = err;
                },
                complete : () =>{
                    this.fullState$.next(this.fullState);
                }
            })
        return this.fullState$;
    }
    quoteExemple(){
        //llamar a quote para poder simular q tenemos un plan, debería llegar toda esta info desde renovación
        this.fullState.pricingState.state = States.success;
        const response: any = {
            Result: {
                Id: 1,
                Planes: [
                    {
                        IdCotizacionPlan: 16,
                        Plan: "Amplia",
                        IdPlan:800101,
                        Cuota: "$10.000",
                        SumaAsegurada: "$30.000",
                        Coberturas: [
                            { Id: 5, Descripcion: "Coverage E" ,Tipo:"$600"},
                        ],
                        IdCompania: 80,
                        Compania:"GNP",
                        LogoCompania: "",
                        Vigencia: "2024-12-31",
                        selected: false,
                        isSelected: false
                    }
                ],
                Producto: "Software Suite"
            }
        };
        this.fullState.pricingState.pricing = response;
    }

    quote(quotingDetails : any){
        this.fullState.pricingState = {
            state : States.loading,
            pricing : undefined,
            regionId : quotingDetails.idRegion,
            regionDescription : quotingDetails.regionDescription,
            initialRequest : quotingDetails,
            error : undefined
        }
        this.clearPricing()
        this.fullState$.next(this.fullState)
        this.http.post<any>(this.pricing_url, quotingDetails).subscribe({
            next : (response : any) => {
                this.fullState.pricingState.state = States.success;
                this.fullState.pricingState.pricing = response;
            },
            error : (err) => {
                this.fullState.pricingState.state = States.error;
                this.fullState.pricingState.pricing = err;
            },
            complete : () => {
                this.fullState$.next(this.fullState);
            }
        })
    }

    getEmissionInfo(pricingId : number,zipCode : number){
        this.fullState.emissionDataState = {
            state : States.loading,
            EmissionInfo : undefined,
            error : undefined
        }
        const request={
            planId:pricingId,
            codigoPostal:zipCode
        }
        this.http.post<any>(this.pre_emission_url,request).subscribe({
            next : (response ) => {
                this.fullState.emissionDataState.state = States.success;
                this.fullState.emissionDataState.EmissionInfo = response.Result;
            },
            error : (err) => {
                this.fullState.emissionDataState.state = States.error;
                this.fullState.emissionDataState.error = err;
            },
            complete : () => {
                this.fullState$.next(this.fullState);
            }
        })
    }

    emit(newProposal : any){
        this.fullState.emissionState = {
            state : States.loading,
            emission : undefined,
            emissionResult : undefined,
            error : undefined
        }
        this.http.post<any>(this.emission_url, newProposal).subscribe({
            next : (response : any) => {
                this.fullState.emissionState.state = States.success;
                this.fullState.emissionState.emissionResult = response;
            },
            error : (err) => {
                this.fullState.emissionState.state = States.error;
                this.fullState.emissionState.error = err;
            },
            complete : () => {
                this.fullState$.next(this.fullState);
            }
        })
    }

    clearPricing(){
        this.fullState.pricingState = {state : States.empty};
    }

    setQuotingRequest(request : ClienteProducto){
        this.fullState.pricingState.initialRequest = request;
        this.fullState$.next(this.fullState);
    }

    setPaymentInfo(info : IPaymentInfo){
        this.fullState.emissionState.paymentInfo = info;
        this.fullState$.next(this.fullState);
    }

    emitOnline(paymentInfo : IPaymentInfo){
        this.fullState.emissionState = {
            state : States.loading,
            emission : this.fullState.emissionState.emission,
            emissionResult : this.fullState.emissionState.emissionResult,
            paymentInfo : this.fullState.emissionState.paymentInfo,
            confirmationResult : undefined,
            error : undefined
        }
        this.http.post<any>(this.emission_online_url, paymentInfo).subscribe({
            next : (response : any) => {
                this.fullState.emissionState.state = States.success;
                this.fullState.emissionState.confirmationResult = response;
            },
            error : (err) => {
                this.fullState.emissionState.state = States.error;
                this.fullState.emissionState.error = err;
            },
            complete : () => {
                this.fullState$.next(this.fullState);
            }
        })
    }

    clearState(){
        this.fullState = new IssueFullState();
        this.fullState$ = new Subject<IssueFullState>();
    }

    clearEmissionState(){
        this.fullState.emissionState= {
            state : States.empty,
            emissionResult : undefined,
            paymentInfo : undefined,
            confirmationResult : undefined,
            error : undefined
        }
    }

}
