<div class="col-12 text-center">
    <p class="fs-6 text-secondary text-center mt-4" style="color:rgb(172, 172, 172) !important;">{{question}}</p>
    <div class="card-container d-flex flex-wrap justify-content-center">
        <div tabindex="0" class="card" (keypress)="onClick(1)" (click)="onClick(1)" [class.selected]="+(selected ?? -1) === 1">
            <div class="card-body">
                <p class="card-title">{{yesLabel ?? yes}}</p>
            </div>
        </div>
        <div tabindex="0" (keypress)="onClick(0)" class="card" (click)="onClick(0)" [class.selected]="+(selected ?? -1) === 0">
            <div class="card-body">
                <p class="card-title">{{noLabel ?? no}}</p>
            </div>
        </div>
    </div>
    <div class="col-12" *ngIf="showError">
        <p class="text-danger">{{errorMessage}}</p>
    </div>
</div>