import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, Subject } from 'rxjs';
import { IGenericResponseModel } from '../models/generic-response.model';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class NotificatorService {

  private readonly url_notifications = environment.authentication_url;

  constructor(private http : HttpClient) { }

  sendResetPasswordEmail(email : string) : Observable<IGenericResponseModel>{
    return this.http.post<IGenericResponseModel>(this.url_notifications + "/recover",email);
  }
}
