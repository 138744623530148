<div class="card w-100 h-100 common-card enhanced-shadow text-center" *ngIf="!model">
    <div class="card-body rounded" style="border-radius: 20px !important;">
      <div class="row justify-content-center align-items-center">      
        <ng-container *ngFor="let title of titles; let i = index">
          <div class="d-flex text-left col-4 col-sm-{{ smSizes[i] }} col-md-{{ smSizes[i] }} {{getColClasses(i)}}"
          [ngClass]="{'d-block d-sm-none': title === '' }">
            <strong class="basic-font">{{ title }}</strong>
          </div>
        </ng-container>
      </div>
    </div>
</div>
  
  <div *ngIf="model" class="overflow-x-auto pointer" (keypress)="goDetail()" (click)="goDetail()" tabindex="0">
    <div class="card w-100 h-100 common-card enhanced-shadow text-center">
      <div class="card-body rounded d-flex align-items-center">
        <div class="row justify-content-arount align-items-center w-100 h-100">
          <div class="col-12 col-md-1 col-sm-1 d-none d-md-block d-sm-block paddingLeft"><div class="vertical-bar">.</div></div>
          <ng-container *ngFor="let column of columns; let i = index">
            <div class="col-4 col-sm-{{ smSizes[i] }} col-md-{{ smSizes[i] }} {{getColClasses(i)}}">
                <div class="circle" *ngIf="column==='Estado'" [ngStyle]="{'background-color': getCircleColor(model.EstadoId)}"></div>
                <p class="m-0 basic-font" [ngClass]="{'textLeft': column === 'TipoPoliza'}">{{ model[column] }}</p>
                <p class="mb-0 fontSm textLeft" *ngIf="column === 'TipoPoliza' && model.TipoPoliza === 'AUTO'">{{model.TipoPolizaDescripcion}}</p>
            </div>
          </ng-container>
          <div class="col-4 col-md-1"   (keypress)="toggleDetailBox(); $event.stopPropagation();"  (click)="toggleDetailBox(); $event.stopPropagation();" tabindex="0">
            <i class="fas fa-ellipsis-v" (click)="op.toggle($event)" (keypress)="op.toggle($event)" tabindex="0"></i>
            <p-overlayPanel #op>
              <detail-button [title]="textDetail" [showDetailBox]="showDetailBox" [type]="typeDetail" (customClick)="detail($event)"></detail-button>
            </p-overlayPanel>
          </div>
        </div>
      </div>
    </div>
  </div>
  