import { IEnvironment } from "./environment.model";
/* eslint-disable*/
const appVersion =  require('package.json').version;

export const environment : IEnvironment= {
    appVersion: appVersion,
    authentication_url : "https://vm2022aonwd:8093/api/auth",
    notifications_url  : '',
    emission_url       : 'https://vm2022aonwd:8092/api/emit',
    quoting_url        : 'https://vm2022aonwd:8092/api/quota',
    parameters_url     : 'https://vm2022aonwd:8092/api/parameter',
    emission_base_url : 'https://vm2022aonwd:8091/api'
};

