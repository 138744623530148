<div class="p-3 p-sm-2 p-md-2 p-lg-2">
    <div class="row justify-content-center">
        <div class="col-md-5 p-0">
            <app-products-list [fullBoard]="false"></app-products-list>
        </div>
        <div class="col-md-6">
            <div class="row">
                <div class="col-md-12 p-1 h-50" >     
                    <app-insurance-ad></app-insurance-ad>
                </div>
                <div class="col-md-12 p-1 h-50">     
                    <app-insurance-recommendation></app-insurance-recommendation>
                </div>
            </div>
        </div>     
    </div>

</div>


