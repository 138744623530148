import { Component, OnDestroy, OnInit } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { Router } from '@angular/router';
import { Subscription } from 'rxjs';
import { LABELS } from 'src/app/main/constants/labels.enum';
import { QuotingInfoModel } from 'src/app/main/models/emission/quoting-info.model';
import { ClienteProducto, DatosLiberty, IDataDriver, IDataOwner, IInsured, IRenewal, IVehicles, Vehiculo } from 'src/app/main/models/issue/issue-data.model';
import { PriceModel, PricingResult } from 'src/app/main/models/issue/price.model';
import { PricedPlanModel } from 'src/app/main/models/issue/priced-plan.enum';
import { QuoteInfoModel } from 'src/app/main/models/issue/quote-info.model';
import { ISelectItem } from 'src/app/main/models/select-item.model';

import { ConsultantService } from 'src/app/main/services/consultant.service';
import { QuotingFormHandlerService } from 'src/app/main/services/issue/quoting-form-handler.service';
import { RedirectorService } from 'src/app/main/services/redirector.service';
import { PopUpTypesEnum } from 'src/app/shared/enums/popup-types.enum';
import { ButtonModel } from 'src/app/shared/models/button.model';
import { DropdownCustomModel } from 'src/app/shared/models/dropdown.model';
import { PopupMessageComponent } from 'src/app/shared/popup-message/popup-message.component';
import { ModelCreatorService } from 'src/app/shared/services/model-creator.service';
import { PopupOpenerService } from 'src/app/shared/services/popup-opener.service';
import { IssueInsuranceState } from 'src/app/state/issue/issue-insurance.state';
import { IssueState } from 'src/app/state/issue/issue.state';
import { LoginState } from 'src/app/state/login/login.state';
import { States } from 'src/app/state/models/states.enum';

@Component({
  selector: 'issue-insurance-residential',
  templateUrl: './issue-residential.component.html',
  styleUrls: ['./issue-residential.component.scss']
})

export class IssueResidentialComponent implements OnInit,OnDestroy{
  selectedDate: Date | null = null;
  parentGroup: FormGroup |undefined;
  isQuote= false;
  isQuoteSteps= true;
  isLoadPlans= false;


  
  
  readonly title_issue=LABELS.ISSUE_DATA_TITLE;
  readonly title_residential=LABELS.RESIDENTIAL_DATA_TITLE;
  readonly title_protection=LABELS.PROTECTION_DATA_TITLE;
  readonly title_renewal=LABELS.VEHICLE_DATA_RENEWAL;

  readonly back_dashboard=LABELS.BACK_DASHBOARD

  readonly title_banner_residential1 = LABELS.TITLE_BANNER_VEHICLE_1;
  readonly title_banner_residential2 = LABELS.TITLE_BANNER_VEHICLE_2;
  readonly title_banner_residential3 = LABELS.TITLE_BANNER_VEHICLE_3;
  readonly title_banner_residential4 = LABELS.TITLE_BANNER_RESIDENTIAL_4;
  readonly subtitle_banner_residential1 = LABELS.SUBTITLE_BANNER_VEHICLE1;
  readonly body1_banner_residential1 = LABELS.BODY1_BANNER_RESIDENTIAL1;
  readonly subtitle_banner_residential2 = LABELS.SUBTITLE_BANNER_RESIDENTIAL2;
  readonly body1_banner_residential2 = LABELS.BODY1_BANNER_RESIDENTIAL2;
  //readonly body2_banner_residential2 = LABELS.BODY2_BANNER_RESIDENTIAL2;
  readonly subtitle_banner_residential3 = LABELS.SUBTITLE_BANNER_RESIDENTIAL3;
  readonly body1_banner_residential3 = LABELS.BODY1_BANNER_RESIDENTIAL3;
  //readonly body2_banner_residential3 = LABELS.BODY2_BANNER_RESIDENTIAL3;
  readonly subtitle_banner_residential4 = LABELS.SUBTITLE_BANNER_RESIDENTIAL4;
  readonly body1_banner_residential4 = LABELS.BODY1_BANNER_RESIDENTIAL4;
  //readonly body2_banner_residential4 = LABELS.BODY2_BANNER_RESIDENTIAL4;
  

  quotingInfo? : QuotingInfoModel;
  quoteInfo? : QuoteInfoModel[];
  quoteInfoSelected? : QuoteInfoModel;
  quotingInfoSub : Subscription = new Subscription();
  quoteInfoSub : Subscription = new Subscription();

  step =0;
  steps: any[] = [];

  quotes?: DropdownCustomModel;
  selectQuote: ISelectItem[] |undefined;
  prices:any[]=[]
  pricingRequest : any;
  popupData : any;
  constructor(
    private consultantProposal:ConsultantService,    
    private formHandler : QuotingFormHandlerService,    
    private redirector : RedirectorService,
    private issue_state : IssueState,
    private login_state : LoginState,
    private popupSvc : PopupOpenerService){
  }  


  loadQuotingInfo() {    
    this.issue_state.clearPricing();
    this.checkState();

  }

  checkState(){
    this.quotingInfoSub = this.issue_state.checkState().subscribe({
      next :(state) => {      
        if(state.quotingState.state === States.success){
          this.quotingInfo = state.quotingState.quotingInfo;
          if(!this.steps || this.steps.length === 0){
              this.setValidSteps(this.quotingInfo!);
              //this.checkStateQuote(this.quotingInfo!);
          }

        }
        else{
          // mensajito... 
          // En teoría siempre deberíamos tener los datos de cotización
          // peeero... 
        }
        //let pricingState = state.pricingState
        //if(pricingState.state === States.success){
        //  if (pricingState.pricing?.IsSuccess && (pricingState.pricing.Result?.Planes?.length ?? 0) > 0) {
        //    //this.isQuote=true;
        //    this.isLoadPlans=false;            
        //    this.issue_state.setQuotingRequest(this.pricingRequest);
        //  }
        //  else{
        //    // mensajito
        //  }
        //}
      }
    })
    
  }


//   checkStateQuote(quotingInfo:QuotingInfoModel){
//     this.quoteInfoSub = this.issue_state.checkState().subscribe({
//       next :(state) => {      
//         if(state.quoteState.state === States.success){
         
//           this.quoteInfoSelected = state.quoteState.quoteInfo?.find(q=>q.CotId==state.quoteState.quoteId);
//           if(this.quoteInfoSelected!.CotDatosMinimos)
//             this.setForms(this.quoteInfoSelected!.CotDatosMinimos!.toString(),quotingInfo,this.quoteInfoSelected!.PlanesCotizados)
//         }
//         else{
//           // mensajito... 
//           // En teoría siempre deberíamos tener los datos de cotización
//           // peeero... 
//         }
//       }
//     })
//     this.quotingInfoSub.unsubscribe();
//   }


  ngOnInit(): void {
    this.loadQuotingInfo();
  }


  selectMenuItem(item: any) {

    const currentForm : string = this.steps[item].formName;
    if (!this.parentGroup!.controls[currentForm].valid) {
      this.parentGroup!.markAllAsTouched();
      return;
    }
    this.step=item;
  }


  setValidSteps(quotingInfo?: QuotingInfoModel) {
    this.steps = [];
  
    if (quotingInfo !== undefined) {
      this.quotingInfo = quotingInfo;
    }

    if (this.quotingInfo?.EsResidencial) {
      this.steps.push({ id: 0, descripcion: this.title_residential, formName: 'risk', label: 'risk' });
      this.steps.push({ id: 1, descripcion: this.title_protection, formName: 'protectionSystem', label: 'protectionSystem' });
      this.steps.push({ id: 2, descripcion: this.title_renewal, formName: 'renewal', label: 'renewal' });
    }
    this.formHandler.createForm(false,this.quotingInfo?.EsResidencial, false, false, false);
    this.parentGroup = this.formHandler.getForm();
  }
  
  

  next(){
    const currentForm : string = this.steps[this.step].formName;  
    this.steps[this.step].checked =  this.parentGroup!.controls[currentForm]?.valid;

    if (this.parentGroup!.controls[currentForm] && !this.parentGroup!.controls[currentForm].valid) {
      this.parentGroup!.markAllAsTouched();
      return;
    }
    if (this.step + 1 == this.steps.length) {
      this.isQuoteSteps=false;
      this.isLoadPlans=true
      this.consultantProposal.pricesSelect=this.prices;
      //this.pricingRequest = {...clienteProducto};

      //this.issue_state.quote(clienteProducto);      
      
      this.quotingInfoSub.unsubscribe();
      this.quoteInfoSub.unsubscribe();
      this.issue_state.setQuotingRequest(this.pricingRequest);
      this.redirector.redirectToProposalPlansVehicle();
      return;
    }
    else{
    // guardado el paso a paso por ahora no tenemos nada del back  
    //   this.consultantProposal.saveQuote(clienteProducto).subscribe({
    //     next  : (data) => { 
    //     },
    //     error : (err) => {
    //       //this.showError();
    //     }
    //   })
    }
    this.step++;
  }

  backStep(){
    if(this.step === 0){
      this.redirector.redirectToDashboard();
    }
    else{
      this.step --;
    }
  }
  
  back(){
    this.redirector.redirectToDashboard();
  }
  showMessagePopUp(title : string, messages : string[], type : PopUpTypesEnum = PopUpTypesEnum.success){
    this.popupData={
      component:PopupMessageComponent,
      config : {
        title : title,
        hideHeader : true
      },
      
      data : {
        type : type,
        messages : messages,
        button:true
      }
    };
    this.popupSvc.show(this.popupData)
  }

  ngOnDestroy(): void {
    this.quotingInfoSub.unsubscribe();
    this.quoteInfoSub.unsubscribe();
  }
}
