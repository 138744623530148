import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { authenticationGuard } from 'src/app/main/guards/authentication.guard';
import { PlanComparerComponent } from 'src/app/features/issue/plan-comparer/plan-comparer.component';
import { IssueInsuranceVehicleComponent } from './issue-insurance-vehicle.component';

const routes: Routes = [  
  {
    path: '',
    component: IssueInsuranceVehicleComponent,
    canLoad : [authenticationGuard],
    children: [
        // {
        //   path: '',
        //   component:IssueVehicleDataComponent ,
        //   canLoad : []
        // },
        {
          path: 'comparer',
          component: PlanComparerComponent,
          canLoad : []
        },
    ]
  }
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class IssueInsuranceVehicleRoutingModule { }
