import { Component, DoCheck, Input, OnChanges, OnInit, Output, SimpleChanges } from '@angular/core';
import {  Validators} from '@angular/forms';
import { EventEmitter } from '@angular/core';
import { DropdownCustomModel } from '../models/dropdown.model';

@Component({
  selector: 'custom-select',
  templateUrl: './custom-select.component.html',
  styleUrls: ['./custom-select.component.scss'],
})
export class CustomSelectComponent implements OnChanges, DoCheck {

  @Input() model?: DropdownCustomModel;
  @Output() selectBlured = new EventEmitter<string>();
  @Output() selectChanged = new EventEmitter<string>();

  alreadyPatched  = false;

  ngDoCheck(): void {
    const formControl = this.model?.formGroup?.controls[this.model?.formControlName ?? ''];

    if (!formControl)
      return;

    if (this.model?.disabled)
      formControl?.disable();
    else
      formControl.enable();

    if (this.model?.required) {
      formControl?.addValidators(Validators.required);
    }
    else {
      formControl.removeValidators(Validators.required)
    }

      
  
  }

  onSelectChange(){
    
    this.selectChanged.emit(this.model?.formGroup?.controls[this.model.formControlName!].value)    
  }

  ngOnChanges(changes: SimpleChanges): void {
    if(changes['model']?.currentValue){

      const formControl = this.model?.formGroup?.controls[this.model?.formControlName ?? ''];

      if(!formControl)
        return;      

      if(changes['model']?.currentValue.options?.length > 1)
        return;

      
      if(this.model && this.model.options && this.model.notSelectAutomatic){
        const selectedOption = this.model!.options[0];
        this.model!.selected = selectedOption;
        formControl.setValue(selectedOption)
        if(this.model.selected)
          this.selectChanged.emit(this.model.selected.id)
      }            
    }


  }

  onDropdownBlur(){
    this.selectBlured.emit();
  }
}
