<div class="flex" *ngIf="model && model.formGroup">
    <div class="col-12 mp-0 p-0 flex flex-column align-items-center gap-2">
      <form class="w-full" [formGroup]="model.formGroup">
        <div class="flex flex-row align-items-center pb-1">
          <label *ngIf="model.label" class="block pl-1 fs-6 text-dark text-left" for="{{model.formControlName}}">
            {{model.label}}
          </label>
        </div>
  
        
        <p-calendar 
          [showTime]="model.showTime ?? false" 
          hourFormat="24"
          class="custom-calendar"
          [formControlName]="model!.formControlName!"
          [disabled]="model.disabled ?? true"
          [required]="model.required ?? false"
          [maxlength]="12"
          [dateFormat]="'dd/mm/yy'"
          [placeholder]="model.placeholder" 
          [minDate]="model.minDate ?? defaultMinDate"
          (onSelect)="onEnterEvent($event)"
          (onBlur)="onBlurEvent($event)"
          (click)="preventCalendarOpening($event)" 
          (input)="onInputEvent($event)"
        ></p-calendar>
        <div class="col-12">
          <div class="col-12">
              <p *ngIf="model?.required && model.formGroup.controls[model.formControlName!].touched && model.formGroup.controls[model.formControlName!].invalid"
                  class="text-danger ">
                  {{custom_error}}</p>
          </div>
        </div>
      </form>
    </div>
  </div>
  