<div *ngIf="showHeader" class="col-12 row justify-content-center align-items-center text-center small-text pb-5">

    <div class="col-4 pt-3 px-0 px-sm-3 justify-content-start text-center">
        <div class="row col-12">
            <div class="col-12 col-md-6">
                <img alt="aon-logo" src="../../../assets/aon-logo-small.png" style="width: 50%;" />
            </div>
            <div class="col-0 col-md-6"></div>            
        </div>
        <div class="row col-12 col-md-6 text-center">
            <p> Powered by i-SOL®</p>
        </div>
    </div>

    <div class="col-4 row justify-content-evenly align-items-center text-center">
        <div tabindex="0" class="col-12 active justify-content-center header-link" (keypress)="navigateTo('menu')" (click)="navigateTo('/home')">
            <p class="text-center">Inicio</p>
        </div>
    </div>
    <div class="col-4 col-sm-4 justify-content-end text-end pr-sm-3">
        <custom-button [model]="btnLogin" (customClick)="navigateTo('login')"/>
    </div>

</div>