export enum QuotingVehicleValidStepsEnum {
    owner_data = 'owner',
    vehicle = 'vehicle',
    insured_data='insured',
    driver_data='driver',
    renewal_data='renewal',

    vehicleMexico = 'vehicleMex',
    insured_data_mex='insuredMex',
    payment_data='payment',
}