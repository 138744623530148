
<div class="poliza-container">

  <div class="filter-section">
    <div class="col-3 col-sm-3">
      <h2 class="title mt-2">{{title}}</h2>
    </div>
    <!-- <div class="filter-buttons"  *ngIf="!isRenewal">
      <button (click)="setFilter('todas')" [ngClass]="{'active-filter': activeFilter === 'todas'}">Todas</button>
      <button (click)="setFilter('pendientes')" [ngClass]="{'active-filter': activeFilter === 'pendientes'}">Pendientes</button>
      <button (click)="setFilter('emitidas')" [ngClass]="{'active-filter': activeFilter === 'emitidas'}">Emitidas</button>
    </div> -->

    <div class="col-9 col-sm-9 filter-inputs" [formGroup]="parentGroup!">
      <span class="filter-label col-12 col-sm-1">{{search_table}}</span>
      <input type="text" placeholder="Nombre y apellidos" formControlName="personal" class="search-input name-input " (blur)="search()" />
      <input *ngIf="isRenewal" type="text" placeholder="Código postal" formControlName="zipCode" class="search-input" (blur)="search()" />
      <input *ngIf="isRenewal" type="text" placeholder="Vin" formControlName="vehicle" class="search-input" (blur)="search()" />
      <input *ngIf="!isRenewal" type="text" placeholder="RFC" formControlName="rfc" class="search-input" (blur)="search()" />   
      <button class="search-button" (keypress)="search()" (click)="search()" tabindex="0">
        <i class="fa fa-search"></i>
      </button>
    </div>
  </div>



  <table class="poliza-table">
    <thead>
      <tr>
        <th *ngFor="let column of columns">
          <div class="header-container">
            <span>{{ column.title }}</span>
            <div class="sort-icons" *ngIf="!(column.title === 'Póliza' && !isRenewal)">
              <i
                class="fa fa-caret-up"
                [ngClass]="{ 'active-sort': sortField === column.field && sortDirection === 'asc' }"
                (keypress)="sortDataByField(column.field, 'asc')"
                (click)="sortDataByField(column.field, 'asc')"
                tabindex="0"
              ></i>
              <i
                class="fa fa-caret-down"
                [ngClass]="{ 'active-sort': sortField === column.field && sortDirection === 'desc' }"
                (keypress)="sortDataByField(column.field, 'desc')"
                (click)="sortDataByField(column.field, 'desc')"
                tabindex="0"
              ></i>
            </div>
          </div>
        </th>
      </tr>
    </thead>
    <tbody *ngIf="isRenewal">
      <tr *ngFor="let row of dataFilter; let i = index">
        <td *ngFor="let column of columns" [ngClass]="column.styleClass ?? '' " style="white-space: break-spaces;">
          <ng-container *ngIf="column.field; else optionsColumn">
            <span 
              [pTooltip]="row[column.field]?.length > 20 ? row[column.field] : null" 
              [tooltipPosition]="'top'"
              [tooltipStyleClass]="'tooltipSide'">
              {{ row[column.field]?.length > 20 && column.field === 'Modelo' ? (row[column.field] | slice:0:20) + '...' : row[column.field] }}
            </span>
          </ng-container>
          <ng-template #optionsColumn>
            <div class="button-container justify-content-center" style="display: flex; align-items: center; min-height: 1rem;">
              <ng-container *ngIf="row['IdEstado']===1; else statusTemplate">
                <button class="quote-button cursor-pointer  px-2 py-1" 
                (keypress)="detail(0,row)"
                (click)="detail(0,row)">
                  {{quote}}
                </button>
              </ng-container>
              
              <ng-template #statusTemplate>
                <div style="display: flex; align-items: center;">
                  <span *ngIf="row['IdEstado']===2" style="color:#5D933D; margin-right: 10px;">
                    {{renewed_policy}}
                  </span>
                  <span *ngIf="row['IdEstado']===3" style="color:gray; margin-right: 10px;">
                    {{not_interested}}
                  </span>
                  <div *ngIf="row['IdEstado']===2">
                    <button class="options-button" 
                      (keypress)="toggleDetailBox(); $event.stopPropagation();"
                      (click)="toggleDetailBox(); $event.stopPropagation();" style="margin-left: 10px;" tabindex="0">
                      <i class="fa fa-ellipsis-v" (click)="op.toggle($event)" (keypress)="op.toggle($event)" tabindex="0"></i>
                    </button>
                    <p-overlayPanel #op>
                      <detail-button [title]="textTitle" [showDetailBox]="showDetailBox" [type]="typeDetail" [isRenewal]="true" (customClick)="detail($event,row)"></detail-button>
                    </p-overlayPanel>
                  </div>
                </div>
              </ng-template>
            </div>
          </ng-template>
        </td>
      </tr>
    </tbody>
    <tbody *ngIf="!isRenewal">
      <tr *ngFor="let row of dataFilter; let i = index">
        <td *ngFor="let column of columns" [ngClass]="column.styleClass ?? '' " style="white-space: break-spaces;" 
        (click)="detail(1,row)"
        (keypress)="detail(1,row)"
        tabindex="0"
        >
          <ng-container *ngIf="column.field; else optionsColumn">
            <span 
            *ngIf="column.field === 'Estado'; else defaultField"
            [style.color]="colorMap[row.EstadoId]">
            {{ row[column.field] }}
            </span>
            <ng-template #defaultField>
              <span 
                [pTooltip]="row[column.field]?.length > 20 ? row[column.field] : null" 
                [tooltipPosition]="'top'"
                [tooltipStyleClass]="'tooltipSide'">
                {{ row[column.field]?.length > 20 && column.field === 'Modelo' ? (row[column.field] | slice:0:20) + '...' : row[column.field] }}
              </span>
            </ng-template>           
          </ng-container>
          <ng-template #optionsColumn>
            <div class="button-container justify-content-center" style="display: flex; align-items: center; min-height: 1rem;">
              <ng-container>
                <button class="options-button" (keypress)="toggleDetailBox(); $event.stopPropagation()" (click)="toggleDetailBox(); $event.stopPropagation();" style="margin-left: 10px;" tabindex="0">
                  <i class="fa fa-ellipsis-v" (click)="op.toggle($event)" (keypress)="op.toggle($event)" tabindex="0"></i>
                </button>
                <p-overlayPanel #op>
                  <detail-button [title]="textTitle" [showDetailBox]="showDetailBox" [type]="typeDetail" [isRenewal]="true" (customClick)="detail($event,row)"></detail-button>
                </p-overlayPanel>
              </ng-container>
              
            </div>
          </ng-template>
        </td>
      </tr>
    </tbody>
  </table>
  
  
<div *ngIf="showDetailCardBox">
  <detail-prospect (closeDetail)="closeDetailCard()" (goQuote)="goQuote($event)" [policy]="selectedPolicy" ></detail-prospect>
</div>

<div class="pagination-container">
  <div class="pagination-left">
    <span>{{ (paginaActual - 1) * registrosPorPagina + 1 }} - {{ getDisplayedItemsCount() }} de {{ totalRegistros }} ítems</span>
  </div>
  <div class="pagination-right">
    <button class="pagination-button" (keypress)="goToPage(1)" (click)="goToPage(1)" [disabled]="paginaActual === 1" tabindex="0">
      <mat-icon>first_page</mat-icon>
    </button>
    <button class="pagination-button" (keypress)="goToPage(paginaActual - 1)" (click)="goToPage(paginaActual - 1)" [disabled]="paginaActual === 1" tabindex="0">
      <mat-icon>chevron_left</mat-icon>
    </button>
    <span class="current-page">{{ paginaActual }}</span>
    <button class="pagination-button" (keypress)="goToPage(paginaActual - 1)" (click)="goToPage(paginaActual + 1)" [disabled]="paginaActual === totalPaginas" tabindex="0">
      <mat-icon>chevron_right</mat-icon>
    </button>
    <button class="pagination-button" (keypress)="goToPage(totalPaginas)" (click)="goToPage(totalPaginas)" [disabled]="paginaActual === totalPaginas" tabindex="0">
      <mat-icon>last_page</mat-icon>
    </button>
  </div>
</div>

</div>


