<div class="w-100 h-100 bg-secondary bg-opacity-25">
    <div class="full-cover-card p-0 rounded col-12 d-flex justify-content-center">
        <app-brands-banner class="d-block d-lg-none " [enterpriseId]="enterpriseId"></app-brands-banner>
        <div class="h-100 bg-white p-0 scroll-login pb-4 custom-rounded-border-start max-width-50 ">
                <div class="back-button" *ngIf="model.showBackButton">
                    <custom-button [model]="btnBack" (customClick)="navigateBack()" class="d-md-flex"/>
                </div>
                
                <div class=" d-flex justify-content-center">
                    <div class="col-12 max-height-100">
                        <ng-content></ng-content>    
                    </div>                    
                        
                </div>
                    
        </div>
        

        <div class="h-100 p-0 custom-rounded-border-end d-none d-lg-flex">
            <app-brands-banner [enterpriseId]="enterpriseId"></app-brands-banner>
            <img alt="ad for login" src="../../../assets/ads/loginad.webp" class="custom-rounded-border-end no-border-radius-start" width="auto" height="100%">
        </div>                    
    
    </div>        
</div>
