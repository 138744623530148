
  
  <div class="justify-content-center col-12 col-md-11 m-auto">
    <p class="title-quote title">{{title}}</p>
  </div>
  
  <div class="justify-content-center col-12 col-md-11 m-auto"> 

    <div class="row pt-3">
      <div>
        <custom-select [model]="periodicityModel!"></custom-select>
        <p class="mt-1 mb-1" style="font-size: 0.9rem; color: rgb(67, 67, 67);">• Depósito referenciado solo habilitado para periocidad semestral y anual</p>
      </div>

    </div>
    <div class="row mb-4 mt-4">
      <custom-button [model]="btnReturn!" (customClick)="return()" class="col-6 col-sm-4 mt-2"
        *ngIf="currentStep<=3"></custom-button>
      <custom-button [model]="btnContinue!" (customClick)="next()" class="col-6 col-sm-6 offset-sm-2 mt-2"
        *ngIf="true"></custom-button>
    </div>
</div>