
<div class="container w-100">    
    <p class="fw-medium fs-5 title">{{title}}</p>

    <div class="col-12">
        <custom-select [model]="periodicityModel!"></custom-select>
    </div>

    <div class="col-12 mt-4">
        <custom-select [model]="paymentInstrumentModel!"></custom-select>
    </div>


    <p class="text-muted mt-2">
        • Depósito referenciado solo habilitado para periodicidad semestral y anual
    </p>
    <div class="mt-4">
        <p class="fw-semibold fs-6">Plan de pagos</p>
        <ul class="list-unstyled">
            <li>#1 - 26/03/2025 <span class="float-end">$1,650.00</span></li>
            <li>#2 - 26/06/2025 <span class="float-end">$1,350.00</span></li>
            <li>#3 - 26/09/2025 <span class="float-end">$1,350.00</span></li>
            <li>#4 - 26/12/2025 <span class="float-end">$1,350.00</span></li>
        </ul>
    </div>


    <div class="border-top mt-3 pt-2">
        <p class="fw-semibold fs-6">Prima total <span class="float-end">$5,700.00</span></p>
    </div>

    <div class="row mb-4 mt-2" *ngIf="showButtons">
        <custom-button [model]="btnReturn!" (customClick)="return()" class="col-6 col-sm-6 mt-2"></custom-button>
        <custom-button [model]="continueBtn!" (customClick)="next()" class="col-6 col-sm-6 mt-2"></custom-button>
    </div> 
</div>


