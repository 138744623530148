import { Component, EventEmitter, HostListener, Input, Output } from '@angular/core';
import { ModelCreatorService } from '../services/model-creator.service';
import { LABELS } from 'src/app/main/constants/labels.enum';
import { ButtonModel } from '../models/button.model';
import { RedirectorService } from 'src/app/main/services/redirector.service';
import { ButtonTypesEnum } from '../enums/buttons.enum';
import { ButtonStylesEnum } from 'src/app/main/constants/buttons.enum';
import { PricesFilterModel, PricingListConfigModel } from '../models/pricing-list-config.model';
import { PricedPlanModel } from 'src/app/main/models/issue/priced-plan.enum';
import { IssueState } from 'src/app/state/issue/issue.state';
import { PopupOpenerService } from '../services/popup-opener.service';
import { PopupMessageComponent } from '../popup-message/popup-message.component';
import { PopUpTypesEnum } from '../enums/popup-types.enum';
import { Subscription } from 'rxjs';
import { States } from 'src/app/state/models/states.enum';
import { DropdownCustomModel } from '../models/dropdown.model';
import { ISelectItem } from 'src/app/main/models/select-item.model';
import { FormBuilder, FormGroup } from '@angular/forms';

@Component({
  selector: 'app-pricing-list',
  templateUrl: './pricing-list.component.html',
  styleUrls: ['./pricing-list.component.scss']
})
export class PricingListComponent {

  @Input() prices: PricedPlanModel[] = [];
  @Input() config : PricingListConfigModel = new PricingListConfigModel();
  @Input() pricingId? : number;

  @Output() planChecked : EventEmitter<any> = new EventEmitter<any>()
  @Output() optionClicked : EventEmitter<any> = new EventEmitter<any>()
  @Output() continueClicked : EventEmitter<any> = new EventEmitter<any>()

  quotingState1$ : Subscription = new Subscription();

  selectedPrices: any[] = [];
  filteredPrices : any[] = [];  
  btnContinue: ButtonModel | undefined;
  btnComparePlans: ButtonModel | undefined;
  btnBack: ButtonModel | undefined;

  isComparer = false;
  showFiltered  = false;
  showMessageComparer  = false;
  popupData : any;

  filterModel? : DropdownCustomModel;
  filterForm: FormGroup;
  selectFilter: ISelectItem[] | undefined;
  screenWidth: number;

  readonly filter_plans   = LABELS.FILTERS_PLANS;
  readonly filter_company = LABELS.FILTERS_COMPANY;
  readonly back_dashboard = LABELS.BACK_DASHBOARD;
  readonly title_issue_plans = LABELS.TITLE_ISSUE_PLANS;  
  readonly filter_types_plans = LABELS.FILTERS_TYPES_PLANS;
  readonly back_quoting = LABELS.BACK_TO_QUOTING;
  readonly select_multiple_plans =LABELS.SELECT_MULTIPLE_PLANS
  readonly vehicle = '';

  constructor(private modelCreator: ModelCreatorService,
    private redirector: RedirectorService,
    private issueState: IssueState,
    private redirectorService: RedirectorService,
    private popupSvc : PopupOpenerService,
    private fb:FormBuilder
  ) {  
    this.screenWidth = window.innerWidth;
 
    this.filterForm = fb.group({
      filter : ['']
    })
   
    this.selectFilter =     [
      { id: '1', descripcion: LABELS.LOWEST_PRICE },
      { id: '2', descripcion: LABELS.HIGHEST_PRICE },
    ]

     this.vehicle = this.issueState.fullState.pricingState.initialRequest.Vehiculo.Descripcion;


  }
  ngOnInit(): void {
    this.filterModel = this.modelCreator.createSelectModel('filter',LABELS.ORDER, this.selectFilter ?? [], this.filterForm, undefined, 'padding: 0px');
    this.btnComparePlans = this.modelCreator.createButtonModel(LABELS.COMPARE_PLANS_ISSUE, ButtonTypesEnum.Raised, ButtonStylesEnum.grey_white);
    this.btnContinue = this.modelCreator.createButtonModel(LABELS.CONTINUE_ISSUE, ButtonTypesEnum.Raised, ButtonStylesEnum.black);
    this.btnBack = this.modelCreator.createButtonModel(LABELS.RETURN_ISSUE, ButtonTypesEnum.Raised, ButtonStylesEnum.without_white_outline);
    
    if(this.prices.length==1){
      this.prices[0].isSelected=true;
      this.selectedPrices = this.prices.filter(p => p.isSelected);
    }
  }

  @HostListener('window:resize', ['$event'])
  onResize(event: any) {
    this.screenWidth = window.innerWidth; // Actualiza el ancho de la pantalla al redimensionar
  }
  
  isLargeScreen(): boolean {
    return this.screenWidth >= 992; // Cambia este valor si necesitas ajustar el punto de ruptura
  }
  
  onOrder(filter: any) {
    if (filter.id === '1') {
      // Ordenar de mayor a menor precio
      this.prices.sort((a, b) => this.getPrice(b.Cuota) - this.getPrice(a.Cuota));
    } else if (filter.id === '2') {
      // Ordenar de menor a mayor precio
      this.prices.sort((a, b) => this.getPrice(a.Cuota) - this.getPrice(b.Cuota));
    }
  }
  
  getPrice(cuota: string | null): number {
    if (!cuota) {
      return 0;  // Valor predeterminado si la cadena está vacía o es nula
    }
  
    const sanitizedString = cuota.replace(/[^0-9,.]/g, '').trim();
    const normalizedString = sanitizedString.replace(',', '.');
    const price = parseFloat(normalizedString);
  
    return isNaN(price) ? 0 : price;
  }

  checkPricesSelected(plan: any, value: any) {
    const selPrices = this.prices.find(p => p.IdProducto === plan.IdProducto);
    if (selPrices) { selPrices.isSelected = value;}

    this.selectedPrices = this.prices.filter(p => p.isSelected);

    this.emitPlanChecked(selPrices, selPrices?.isSelected ?? false);
  }

  comparePlans() {
    this.showMessageComparer=false;
    this.selectedPrices = this.prices.filter(p => p.isSelected);
    if (this.selectedPrices.length > 1 && this.selectedPrices.length < 4)
      this.isComparer = true;
    else
      this.showMessageComparer=true;

      
  }

  onFilterChange(filter : PricesFilterModel){
    this.filteredPrices = [...this.prices];    
    this.showFiltered = false;

    this.config?.filters?.forEach(currentFilter => {
      const selectedChecks = currentFilter.opciones.filter(o => o.value);
  
      if (selectedChecks.length > 0) {
        this.showFiltered = true;
        let tempFilteredPrices: any[] = [];
  
        selectedChecks.forEach(selectedCheck => {
          const filteredByCurrentCheck = this.filteredPrices.filter(p => p[currentFilter.field] == selectedCheck.id);
          tempFilteredPrices = tempFilteredPrices.concat(filteredByCurrentCheck);
        });
        if (this.filteredPrices.length === this.prices.length) {
          this.filteredPrices = tempFilteredPrices;
        } else {
          this.filteredPrices = this.filteredPrices.filter(p => tempFilteredPrices.includes(p));
        }
      }
    });

  }

  beginEmission() {
    this.continueClicked.emit()
  }
  back() {
    this.isComparer = false;
  }

  subscribeToQuotingState(quoteType:number){
    this.quotingState1$ = this.issueState.checkState().subscribe({
      next : ({quotingState}) => {
        if(quotingState.state === States.loading)
          return;

        // if(quotingState.state === States.empty || (quotingState.state === States.success && quotingState.quotingInfo?.idTipoProducto !== quoteType && quoteType !== -1)){
          this.issueState.getQuotingInfo(quoteType);
        //   return;
        // }

        if(quotingState.state === States.success){
          if(quotingState.quotingInfo?.EsMascota){
            this.redirectorService.redirectToPetPricing();
          }            
          else if(quotingState.quotingInfo?.EsVehiculo){
            this.issueState.fullState.quoteState.goToPlans=false;
            this.redirector.redirectToProposalVehicle();
          }

          else{
            //this.showMessagePopUp(LABELS.COMING_SOON, [LABELS.WORKING_FOR_YOUR_EXPERIENCE]);
          }          
        }
        this.quotingState1$.unsubscribe();
      }
    })      
  }

  backQuote(){
    const typeProduct=this.issueState.fullState.quotingState.quotingInfo?.idTipoProducto;
    this.subscribeToQuotingState(typeProduct!);
  }
  
  backDasboard() {
    this.redirector.redirectToDashboard()
  }

  emitOptionClick(option : any){
    this.optionClicked.emit(option)
  }

  onComparationSelected(ev : any){
    this.back();
    this.emitPlanChecked(ev, true); 
    this.selectedPrices.forEach(p => {
      p.isSelected = false;
    })
    const index = ev;
    this.checkPricesSelected(index, true);
  }

  emitPlanChecked(plan : any, value : boolean){
    this.planChecked.emit({plan : plan, value : value});
  }
  showMessagePopUp(title : string, messages : string[], type : PopUpTypesEnum = PopUpTypesEnum.success){
    this.popupData={
      component:PopupMessageComponent,
      config : {
        title : title,
        hideHeader : true
      },
      
      data : {
        type : type,
        messages : messages
      }
    };
    this.popupSvc.show(this.popupData)
  }

  goToLastStep(){
    // ESTO NO SE HACE, NO HAY QUE USAR EL FULLSTATE DIRECTAMENTE
    this.issueState.fullState.pricingState.backedFromPlans = true;
    this.backQuote();
  }
}
