<div class="w-100 h-100">
    <ul class="nav flex-column" *ngIf="steps">
        <li class="nav-item" *ngFor="let step of steps; index as i" 
            tabindex="0"
            (keypress)="emitStepClick(i, step)"
            (click)="emitStepClick(i, step)">
            <a class="nav-link" 
                [ngClass]="{'actived': i===+(currentStep ?? -1),'activee': checkSelected(i)}">
                {{step.descripcion}}
            </a>
        </li>
    </ul>
</div>