import { Component } from '@angular/core';
import { LABELS } from 'src/app/main/constants/labels.enum';

@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.scss']
})
export class FooterComponent {

  disclaimer = LABELS.LEGAL_DISCLAIMER;
  link1 = LABELS.PRIVACY_POLCIES;
  link2 = LABELS.COOKIES_WARNING;

}
