
<div class="h-100 w-100">
    <div class="">
        <p class="fw-medium fs-5">{{title}}</p>
    </div>

    <div class="py-3 ">
        <app-new-home [form]="form" [regions]="regions ?? []"></app-new-home>
    </div>
</div>


