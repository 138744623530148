import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from "@angular/core";
import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import { Subscription } from "rxjs";
import { ButtonStylesEnum, ButtonTypesEnum } from "src/app/main/constants/buttons.enum";
import { ERRORS } from "src/app/main/constants/errors.enum";
import { InputTypesEnum } from "src/app/main/constants/inputs-types.enum";
import { LABELS } from "src/app/main/constants/labels.enum";
import { QuotingInfoModel } from "src/app/main/models/emission/quoting-info.model";
import { QuotingResidentialValidStepsEnum } from "src/app/main/models/issue/quoting-residential-steps.enum";
import { ISelectItem } from "src/app/main/models/select-item.model";
import { ConsultantService } from "src/app/main/services/consultant.service";
import { QuotingFormHandlerService } from "src/app/main/services/issue/quoting-form-handler.service";
import { InputDateTimeModel } from "src/app/shared/input-datetime-custom/model/input-datetime-model";
import { ButtonModel } from "src/app/shared/models/button.model";
import { DropdownCustomModel } from "src/app/shared/models/dropdown.model";
import { InputModel } from "src/app/shared/models/input.model";
import { ModelCreatorService } from "src/app/shared/services/model-creator.service";
import { IssueState } from "src/app/state/issue/issue.state";
import { States } from "src/app/state/models/states.enum";

@Component({
    selector: 'issue-renewal-residential',
    templateUrl: './issue-renewal-residential.component.html',
    styleUrls: ['./issue-renewal-residential.component.scss']
  })
  export class IssueRenewalResidentialComponent implements OnDestroy{
    @Input() parentGroup: FormGroup |undefined;
    @Output() completedClicked = new EventEmitter<any>();
    @Output() backClicked = new EventEmitter<any>();

    btnContinue : ButtonModel|undefined;
    btnReturn : ButtonModel|undefined;

    budgetSelected:string|undefined

    renewalForm: FormGroup |undefined;

    claimSelected: string | undefined;
    susepCodeInput: InputModel | undefined;
    renewalPolicyNumberInput: InputModel | undefined;
    SupepCodeSelected: string | undefined;
    susepCodeSelect?: DropdownCustomModel;
    SusepCodeList: ISelectItem[] | undefined;
    title=LABELS.RENEWAL_DATA;
    steps: number[] = [0, 1,2];
    currentStep = 0;

    quotingInfo? : QuotingInfoModel;
    quotingInfoSub : Subscription = new Subscription();

    readonly renewal_quote=LABELS.RENEWAL_QUOTE
    readonly claim_occurred=LABELS.CLAIM_OCCURRED

    readonly proposal_question_isnew_no = LABELS.QUESTION_ISNEW_NO;
    readonly proposal_question_isnew_yes = LABELS.QUESTION_ISNEW_YES;
    readonly question_suped_code=LABELS.QUESTION_SUSEP_CODE
    constructor(private fb : FormBuilder,private modelCreator : ModelCreatorService,private consultantProposal:ConsultantService, 
      private issue_state : IssueState,
      private formHandler : QuotingFormHandlerService){
      this.renewalForm = this.formHandler.getFormControlResident(QuotingResidentialValidStepsEnum.renewal) as FormGroup;
      this.loadDataBack();
      this.loadQuotingInfo();
      
    }


    loadData( quotingInfo : QuotingInfoModel){{
      this.SusepCodeList = quotingInfo.CodigosSupep!.map((gender: any) => ({
        id: gender.Id,
        descripcion: gender.Descripcion,
      }));

      
      this.loadInputs();
    }}
    loadInputs(){
      this.susepCodeSelect = this.modelCreator.createSelectModel('codigoSupep', LABELS.SELECT, this.SusepCodeList ?? [], this.renewalForm!, LABELS.TRACKER, '', false,false,false,LABELS.SELECT_ERROR); 
      this.renewalPolicyNumberInput = this.modelCreator.createInputModel(LABELS.QUESTION_RENEWAL_POLICY_NUMBER, InputTypesEnum.common, 'numeroPoliza', this.renewalForm!, ERRORS.INPUT_INVALID, undefined, false, 50, true);
      this.btnContinue = this.modelCreator.createButtonModel(LABELS.QUOTE_ISSUE, ButtonTypesEnum.Raised, ButtonStylesEnum.black);
      this.btnReturn = this.modelCreator.createButtonModel(LABELS.RETURN_ISSUE, ButtonTypesEnum.Raised, ButtonStylesEnum.without_white_outline);
    }
    loadDataBack(){
        this.claimSelected=this.renewalForm!.get('siniestro')?.value.toString();
        this.budgetSelected=this.renewalForm!.get('esRenovacion')?.value.toString();
    }
    loadQuotingInfo() {
      this.quotingInfoSub = this.issue_state.checkState().subscribe({
        next :(state) => {
          const quotingState = state.quotingState;
          if(quotingState.state === States.success){
            this.quotingInfo = quotingState.quotingInfo;
            this.loadData(this.quotingInfo!);
            
          }
          else{
            // de nuevo, no debería pasar nunca
          }
        }      
      })
    }   
    navigateStep(step:any){
        this.currentStep=step;
    }

  validateBudget(value: any) {
    this.renewalForm!.get('esRenovacion')?.setValue(value);
    this.budgetSelected=value.toString();
    if(value==1){
        this.susepCodeSelect!.required=true
        this.renewalPolicyNumberInput!.required=true;
    }else{
        const susepControl = this.renewalForm!.get('codigoSupep');
        const susep = susepControl!.value;
        const errorSusep  = susepControl!.errors;
  
        const proposalNumberControl = this.renewalForm!.get('numeroPoliza');
        const proposalNumberError = proposalNumberControl?.errors;
  
        if(this.currentStep==0){
          if(this.budgetSelected=="0")
          {
            if(errorSusep){
              susepControl?.setErrors(null);
            }
  
            if(proposalNumberError){
                proposalNumberControl?.setErrors(null);
            }
          }
  
          return susep=="" || this.budgetSelected==undefined || this.claimSelected==undefined ?  this.renewalForm!.markAllAsTouched() :this.completedClicked.emit();
        }
        this.susepCodeSelect!.required=false
        this.renewalPolicyNumberInput!.required=false;
    }
  }

  validateClaim(value: any) {
    this.renewalForm!.get('siniestro')?.setValue(value);
    this.claimSelected = value.toString();
  }

  validateSupepCode(){
    const susepCodeControl = this.renewalForm!.get('codigoSupep');
    const susepCode = susepCodeControl!.value;
    this.SupepCodeSelected = susepCode.id;
  }
   
  next(){
    if(this.currentStep === 0){
      if(this.budgetSelected=="0"){
          this.completedClicked.emit();
      }else{
          const numberProposal = this.renewalForm!.get('numeroPoliza')?.value;
          this.claimSelected=="" || this.SupepCodeSelected=="" || numberProposal=="" ? this.renewalForm?.markAllAsTouched() : this.completedClicked.emit()
      }
    }
   
    }

    return(){
        if(this.currentStep === 0){
          this.backClicked.emit();
        }
  
        if(this.currentStep >0){
          this.currentStep--;
        }
    }

    ngOnDestroy(): void {
      this.quotingInfoSub.unsubscribe();
    }
  }
