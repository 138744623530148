<div class="pb-4 ">
    <div class="d-flex flex-wrap"></div>
    <div class="col-12">
      <p class="ps-2 text-white pointer back" tabindex="0" (keypress)="back()" (click)="back()"> < {{back_dashboard}}</p>
    </div>
    <div class="col-12 gap-2 d-flex  flex-wrap-reverse justify-content-center " style="margin-top: 5px;font-size: 1rem !important;">
        <div class="col-12 col-md-4 card scroll-y px-3" *ngIf="isQuoteSteps" >
          <div class="insurance-benefits">
            <h4 class="section-title" style="margin-top: 20px !important;"><span class="bold-text">{{title_banner_residential1}}</span></h4>
            <h4 class="section-title"><span class="bold-text">{{title_banner_residential2}}</span></h4>
            <h4 class="section-title fontSize"><span class="bold-text">{{title_banner_residential3}}</span> {{title_banner_residential4}}</h4>
            <div class="benefits-grid">
                <div class="benefit-item">
                  <img alt="Financial-Advantage" src="../../../../assets/banners/Financiera.svg"/>
                    <h5 class="bold-text" style="color:#5a5959;  font-size: 0.8rem  !important">{{subtitle_banner_residential1}}</h5>
                    <p>{{body1_banner_residential1}}</p>
                </div>
                <div class="benefit-item">
                  <img alt="Flex-Coverage" src="../../../../assets/banners/CoberturaFlexible.svg"/>
                    <h5 class="bold-text" style="color:#5a5959;font-size: 0.8rem  !important">{{subtitle_banner_residential2}}</h5>
                    <p>{{body1_banner_residential2}}</p>
                </div>
                <div class="benefit-item">
                  <img alt="Complementary-Assistance" src="../../../../assets/banners/AsistenciaComplementaria.svg"/>
                    <h5 class="bold-text" style="color:#5a5959; font-size: 0.8rem  !important">{{subtitle_banner_residential3}}</h5>
                    <p>{{body1_banner_residential3}}</p>
                </div>
                <div class="benefit-item">
                  <img alt="Responsibility-Coverage" src="../../../../assets/banners/ResponsabilidadCubierta.svg"/>
                    <h5 class="bold-text" style="color:#5a5959; font-size: 0.8rem  !important">{{subtitle_banner_residential4}}</h5>
                    <p>{{body1_banner_residential4}}</p>
                </div>
            </div>
          </div>
        </div>        
        <div class="col-12 col-md-5 card px-3" *ngIf="isQuoteSteps && steps[step]!==undefined">
          <issue-risk  *ngIf="steps[step].id === 0" (backClicked)="backStep()" (completedClicked)="next()"></issue-risk>
          <issue-protections-system  *ngIf="steps[step].id === 1" (backClicked)="backStep()" (completedClicked)="next()"></issue-protections-system>
          <issue-renewal-residential  *ngIf="steps[step].id === 2" (backClicked)="backStep()" (completedClicked)="next()"></issue-renewal-residential>
        </div>
  
        <div class="col-12 col-md-2 card px-3 d-none d-md-block" *ngIf="isQuoteSteps">
          <app-issue-steps-aside [steps]="steps" [currentStep]="step" (stepClicked)="selectMenuItem($event.id)"></app-issue-steps-aside>
        </div>
          
    </div>
  
  </div>
  
  
  
  