import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class LocatorService {

  private readonly url = environment.parameters_url;
  constructor(private http : HttpClient) { }

  getRegions(){
    return this.http.post(this.url + '/Items', "Regiones") // deberíamos usar otro servicio... 
  }

  getCities(regionId : number){
    return this.http.post(this.url + '/ListarCiudades', {Id : regionId})
  }

  getDomicileByPostalCode(postalCode : string, companyId : string, productId : string){
    return this.http.post(this.url + '/ListarCallePorCP', { Id: postalCode, Descripcion: productId, Tipo: companyId })
  }
}
