export class LABELS {
  static YES = 'Sim';
  static NO = 'Não';
  static OR = 'ou';
  static CONTINUE = 'Continuar';
  static CONTINUE_GOOGLE = 'Google';
  static CONTINUE_MICROSOFT = 'Microsoft';
  static SEND_LINK = 'Enviar Link';
  static BACK_TO_LOGIN = 'Volver al login';
  static NO_ACCOUNT = 'Ainda não tem uma conta?';
  static REGISTER = 'Cadastrar';
  static LOGIN = 'Iniciar sessão';
  static EMAIL = 'E-mail';
  static NAME = 'Nome';
  static NAME_MOTHER = 'Nome completo de sua mãe';
  static LASTNAME = 'Apellido';
  static MATERNAL_LASTNAME = 'Apellido Materno';
  static GENDER = 'Género';
  static PERIODICITY = 'Periodicidad de pago';
  static PAYMENT_INSTRUMENT = 'Instrumento de pago';
  static GENDER_DRIVER = 'Género del conductor';
  static PASSWORD = 'Senha';
  static PASSWORD_CONFIRM = 'Confirme a senha';
  static REGISTER_MYSELF = 'Cadastrar';
  static USE_TERMS = 'Termos de uso';
  static PRIVACY_POLCIES = 'Política de Privacidade';
  static SEE_PRIVACY_POLICY= 'Ver Política de Privacidade';
  static RESEND_CODE = 'Reenviar código';
  static RECOVER = 'Recuperar senha';
  static RETRY = 'Tentar novamente';
  static RESEND_EMAIL = 'Reenviar E-mail';
  static REDIRECT_TO_HOME = 'Volver al inicio';
  static WELCOME = 'Bienvenido';
  static PHONE = 'Telefone';
  static EMAIL_SUCCESS = 'O e-mail foi enviado com sucesso! Verifique sua caixa de entrada. para continuar. Se não o encontrar, verifique a sua caixa de lixo eletrônico/spam.';
  static PRIVACY_WARN = 'Ao se cadastrar você declara aceitar nossa';
  static EMAIL_CONFIRMED = 'O e-mail foi confirmado com sucesso! Aguarde um momento...';
  static LOGIN_TO_YOUR_ACCOUNT = 'Faça login em sua conta';
  static CODE_SENDED = 'Para completar o seu cadastro; enviamos um código para o e-mail informado.';
  static PLEASE_ENTER_CODE = 'Por favor; digite o código abaixo:';
  static RESEND_CODE_FAILED = 'Não foi possível reenviar a mensagem... Por favor; tente novamente mais tarde';
  static CONTINUE_WITH = 'Continuar com...';
  static FORGOT_PASSWORD = 'Esqueci minha senha';
  static PRODUCTS_TITLE = 'Que seguro desea cotizar?';
  static INSURANCE_AD_TITLE = 'A melhor proteção para sua casa';
  static INSURANCE_DATA = 'Datos de la aseguradora';
  static INSURANCE_AD_BODY = 'Sabemos o quão importante é a sua casa para você; é por isso que oferecemos diferentes alternativas de proteção com múltiplos benefícios.';
  static INSURANCE_AD_BUTTON = 'Eu quero um seguro';
  static INSURANCE_RECOMMENDATION_TITLE = 'A melhor proteção para sua casa';
  static INSURANCE_RECOMMENDATION_BODY = 'Sabemos o quão importante é a sua casa para você; é por isso que oferecemos diferentes alternativas de proteção com múltiplos benefícios.';
  static INSURANCE_QUESTIONS_PROPOSAL = 'Como faço para contratar um seguro?';
  static INSURANCE_QUESTIONS_PROPOSAL1 = 'Como faço para cotar um seguro?';
  static INSURANCE_QUESTIONS_PROPOSAL2 = 'Como faço para pagar o seguro?';
  static INSURANCE_QUESTIONS_FREQUENT = 'Perguntas frequentes';
  static INSURANCE_RECOMMENDATION_TITLE_ONE = 'A melhor proteção para seu carro';
  static INSURANCE_RECOMMENDATION_BODY_ONE = 'Sabemos o quão importante é a seu carro para você; é por isso que oferecemos diferentes alternativas de proteção com múltiplos benefícios.';
  static INSURANCE_RECOMMENDATION_TITLE_TWO = 'A melhor proteção para sua casa';
  static INSURANCE_RECOMMENDATION_BODY_TWO = 'Sabemos o quão importante é a sua casa para você; é por isso que oferecemos diferentes alternativas de proteção com múltiplos benefícios.';
  static INSURANCE_RECOMMENDATION_TITLE_THREE = 'A melhor proteção para seu cachorro';
  static INSURANCE_RECOMMENDATION_BODY_THREE = 'Sabemos o quão importante é a seu cachorro para você; é por isso que oferecemos diferentes alternativas de proteção com múltiplos benefícios.';
  static PROPOSAL_LIST_MONTHLY_PAYMENT = 'PAGAMENTO MENSAL';
  static PROPOSAL_LIST_NUMBER = 'NÚMERO';
  static PROPOSAL_LIST_INSURANCE_COMPANY = 'SEGURADORA';
  static PROPOSAL_LIST_TYPE = 'TIPO DE SERVIÇO'; //ACA ANTES DECIA TIPO DE APÓLICE
  static PROPOSAL_LIST_STATUS = 'STATUS';
  static SEE_PROPOSAL_DETAIL = 'Ver detalhes';
  static HIDE_PROPOSAL_DETAIL = 'Ocultar detalhes';
  static PROPOSAL_DETAIL = 'Detalhes da apólice';
  static MY_PROPOSALS = 'Meus seguros';
  static MY_QUOTES_LIST = 'Minhas Cotações de Automóvel';
  static MY_QUOTES = 'Cotações';
  static MY_RE_QUOTES = 'Continuar Cotação';
  static GO_TO_PLANS='Ir para os planos cotados';
  static MONTHLY_QUOTA_DETAIL = 'Pagamento mensal';
  static VIEW_COVERAGES = 'Ver cobertura do plano';
  static REGRET_BUTTON = 'Botão de arrependimento';
  static CANCEL_BUTTON = 'Cancelar seguro';

  static TITLE_DENTAL='Cotação do Plano Odonto';
  static SUBTITLE_DENTAL='Obtenha a melhol cobertura odontológica para você:';
  static TITLE_BANNER_DENTAL_1 = 'As principais vantagens';
  static TITLE_BANNER_DENTAL_2 = 'de contar con um ';
  static TITLE_BANNER_DENTAL_3 = 'Plano Odonto:';

  static SUBTITLE_BANNER_DENTAL1 = 'Atendimiento Integral';
  static BODY1_BANNER_DENTAL1 = 'Amplo pacote de serviços:';
  static BODY2_BANNER_DENTAL1 = ' de consultas e diagnósticos a emergências e cirurgias,';
  static SUBTITLE_BANNER_DENTAL2 = 'Rede de Profissionais Confiáveis';
  static BODY1_BANNER_DENTAL2 = 'Ampla rede disponível, com equipe altamente qualificada,';
  static BODY2_BANNER_DENTAL2 = ' cobrindo todo o país.';
  static SUBTITLE_BANNER_DENTAL3 = 'Assitência 24 horas';
  static BODY1_BANNER_DENTAL3 = 'Disponível todos os dias,para quando precisar.';
  static BODY2_BANNER_DENTAL3 = '';
  static SUBTITLE_BANNER_DENTAL4 = 'Proteção para toda a Família';
  static BODY1_BANNER_DENTAL4 = '';
  static BODY2_BANNER_DENTAL4 = 'Seus entes querido com um sorriso saudável.';

  static TITLE_BANNER_VEHICLE_1 = 'Las principales ventajas';
  static TITLE_BANNER_VEHICLE_2 = 'de';
  static TITLE_BANNER_VEHICLE_3 = 'un';
  static TITLE_BANNER_VEHICLE_4 = 'seguro para auto';

  static TITLE_BANNER_RESIDENTIAL_4 = 'seguro para seu casa:';


  static SUBTITLE_BANNER_VEHICLE1 = 'Tranquilidad Financiera';
  static BODY1_BANNER_VEHICLE1 = 'Cubre gastos imprevistos';
  static BODY2_BANNER_VEHICLE1 = ' en caso de accidentes, daños o robo.';
  static SUBTITLE_BANNER_VEHICLE2 = 'Asistencia y acompañamiento';
  static BODY1_BANNER_VEHICLE2 = 'Ofrece asistencia rápida';
  static BODY2_BANNER_VEHICLE2 = ' y técnica en caso de emergencias en carretera.';
  static SUBTITLE_BANNER_VEHICLE3 = 'Apoyo legal';
  static BODY1_BANNER_VEHICLE3 = 'Responsabilidad civil obligatoria';
  static BODY2_BANNER_VEHICLE3 = ' garantiza indemnización a todas las víctimas de accidentes de tránsito.';
  static SUBTITLE_BANNER_VEHICLE4 = 'Soporte integral';
  static BODY1_BANNER_VEHICLE4 = 'Soporte jurídico, médico y financiero';
  static BODY2_BANNER_VEHICLE4 = ' para ti y para terceros.';
  static SUBTITLE_BANNER_RESIDENTIAL1 = 'Tranquilidade Financeira';
  static BODY1_BANNER_RESIDENTIAL1 = "Cobre despesas com roubos, incêndios e outros imprevistos que possam afetar sua casa.";
  static SUBTITLE_BANNER_RESIDENTIAL2 = 'Cobertura flexível';
  static BODY1_BANNER_RESIDENTIAL2 = 'Pode optar por uma cobertura focada na estrutura da sua casa, nos seus bens no interior ou na combinação de ambos.';
  static SUBTITLE_BANNER_RESIDENTIAL3 = 'Assistência complementar';
  static BODY1_BANNER_RESIDENTIAL3 = 'Oferece assistência para imprevistos no lar, desde serviços de encanamento e eletricidade até chaveiro.';
  static SUBTITLE_BANNER_RESIDENTIAL4 = 'Responsabilidade Coberta';
  static BODY1_BANNER_RESIDENTIAL4 = 'Cobre os danos acidentais que os problemas na sua residência possam causar a terceiros, como infiltrações de água ou incidentes com gás.';
  static TITLE_COVERAGES = 'COBERTURAS';
  static DESCRIPTION_COVERAGE = 'DESCRIÇÃO';
  static VALUE_COVERAGE = 'VALOR';
  static TITLE_COVERAGES_DETAIL = 'Detalhe Coberturas';
  static LOAD_VEHICLES = 'Buscar veículo';
  static CONTINUE_ISSUE = 'Continuar';
  static MESSAGE_CONFIRMATION = 'Tem certeza que deseja cancelar sua apólice?';
  static MESSAGE_CANCELED_POLICY= 'SUA APÓLICE FOI CANCELADA';
  static QUOTE_ISSUE = 'Cotizar';
  static RENEWED_POLICY = 'Póliza renovada';
  static NOT_INTERESTED = 'No interesado';
  static CONTINUE_ISSUE_WITH_PATENT = 'Sim; continuar';
  static RETURN_ISSUE = 'Volver';
  static VEHICLE_DATA = 'Datos del vehículo';
  static VEHICLE_PATENT_QUESTION = 'Qual a placa do seu veículo?';
  static VEHICLE_CHASSIS_QUESTION='Qual é o chassi do seu veículo?';
  static VEHICLE_QUESTION = 'Esse é o seu veículo?';
  static RECOMMENDATION_VEHICLE_PATENT = 'Com a placa poderemos encontrar rapidamente o veículo que você deseja assegurar.';
  static PROPOSAL_WITHOUT_PATENTE = 'Cotar sem placa';
  static QUESTION_VEHICLE_YEAR = 'Cúal es el año de modelo de su auto?';
  static QUESTION_ISNEW = 'Tu vehículo es nuevo?';
  static QUESTION_ISNEW_PATENT = 'Sem Placa';
  static QUESTION_ISNEW_YES = 'Si';
  static QUESTION_ISNEW_NO = 'No';
  static PROPOSAL_SUGGESTION = 'Sugestões';
  static VEHICLE_MODEL_QUESTION = 'Submarca';
  static VEHICLE_VERSION_QUESTION = 'Descripción(versión)';
  static VEHICLE_MAKE_QUESTION = 'Marca';
  static QUESTION_TRACKER = 'O veículo tem um rastreador via satélite?';
  static QUESTION_ARMORED = 'Seu veículo é blindado?';
  static SUM_SECURED_ARMORED = 'Insira o valor da blindado ($)';
  static DECLARE_ARMORED = 'Será necessário apresentar a declaração de blindagem para contratar o seguro e a nota fiscal caso o seguro seja acionado.'
  static QUESTION_ZIPCODE = 'Digite seu CEP';
  static QUESTION_INSURED_ZIPCODE = 'Código postal';
  static QUESTION_DRIVER_ZIPCODE = 'Código postal del conductor';
  static QUESTION_TYPE_PERSON = 'Tipo persona';
  static QUESTION_DRIVER_INSURED = 'El contratante es el conductor?';
  static QUESTION_MODEL_TRACKER = 'Digite o modelo do rastreador';
  static QUESTION_EXEMPTION_REASON = 'Motivo isenção fiscal';
  static QUESTION_TAX_BENEFIT_START_DATE = 'Data de início do benefício fiscal';
  static QUESTION_HAVE_TAX_EXEMPTION = 'Possui isenção fiscal?';
  static TYPE_USE_VEHICLE='Particular';


  static TYPE_PROPERTY='Tipo de Propriedade';
  static TYPE_USE_RESIDENTIAL='Tipo de utilização';
  static TYPE_CONSTRUCTION='Tipo de construção';
  static TYPE_ACTIVITY='Tipo de atividade';

  
  static OWNER_INSURED='O proprietário está segurado?';
  static PUBLIC_ASSETS='A habitação é propriedade pública?';
  static VACANT_PROPERTY='É uma propriedade desocupada?';

  static WOOD_WALLS='Tem madeira nas paredes externas?';
  static HAVE_ALARM='Tem um alarme?';

  static ELECTRICFENCE='Tem cercas eletrônicas?';
  static PROPERTY_WITH_RAILING='Tem propriedades com grades?';
  static QUESTION_RENEWAL_POLICY_NUMBER = 'Digite o número da apólice a renovar';
  static QUESTION_RENEWAL_POLICYID_NUMBER = 'Digite número de identificação da apólice';
  static QUESTION_SUSEP_CODE = 'Nome da Companhia do seguro atual';
  static QUESTION_FINANCER = 'Digite o financista';
  static QUESTION_BONUS = 'Classe de bônus do segurado';
  static QUESTION_USE_VEHICLE = 'O veículo é usado 2 ou mais dias para prestar serviços e/ou visitar clientes e/ou produtores?';
  static RENEWAL_QUOTE ='Sua cotação é uma renovação?';
  static CLAIM_OCCURRED ='Houve algum sinistro durante o período contratado?';
  static REPLACEMENT_RENEWAL='A renovação é com substituição do veículo?'
  static VEHICLE_DATA_TITLE = 'Datos vehiculares';
  static RESIDENTIAL_DATA_TITLE = 'Residência de risco';
  static PROTECTION_DATA_TITLE = 'Sistema de proteção';
  static VEHICLE_DATA_INSURED = 'Datos personales';
  static DRIVER_QUOTING_DATA_TITLE='Datos del conductor';
  static VEHICLE_DATA_RENEWAL = 'Dados da renovação';
  static BACK = 'Volver';
  static COMPARER_COVERAGES_TITLE = 'COMPARAÇÃO DE COBERTURAS';
  static COMPARER_COVERAGES_SUBTITLED = 'Cobertura para todas as necessidades';
  static SELECT_SINGLE_PLAN='Deve selecionar apenas um plano para continuar';
  static SELECT_AT_LEAST_PLAN = 'Deve selecionar um plano para continuar';
  static SELECT_MULTIPLE_PLANS = 'Deve selecionar mais de um plano(máximo três) para comparar';
  static SELECT_SINGLE_QUOTE='Deve selecionar apenas uma cotação para continuar';
  static PROPOSALS_LIST_ERROR1 = 'ATUALMENTE NÃO POSSUI SEGUROS ATIVOS';
  static PROPOSALS_LIST_ERROR2 = 'Cote e contrate seu seguro de forma fácil e rápida';
  static GO_QUOTE = 'Cotizar seguro';
  static GO_RENEWAL = 'Volver a intentar';
  static QUOTE_ERROR1 = 'No momento nenhuma segurado retornou uma cotação para seu veículo';
  static QUOTE_ERROR2 = 'Não conseguimos carregar nenhum plano de seguro neste momento. Isso pode ter ocorrido devido a um problema temporário ou a um dado incorreto nas informações que você inseriu.';
  static QUOTE_ERROR_HELP1 = 'Isso pode ter ocorrido devido a um problema temporário na seguradora.';
  static QUOTE_ERROR_HELP2 = 'Você pode retomar sua cotação ou, tentar mais tarde';
  static RETRY_QUOTE= 'Tentar novamente';
  static SELECT_PLAN = 'Selecionar plano';
  static PRICE_PER_MONTH = '/por mês';
  static LOWEST_PRICE = 'Preço mais baixo';
  static HIGHEST_PRICE = 'Preço mais alto';
  static ISSUE_DATA_TITLE = 'Endereços';
  static PERSONAL_DATA_TITLE = 'Datos contratante';
  static PERIODICITY_DATA_TITLE = 'Medio de pago';
  static BENEFICIARY_DATA_TITLE = 'Dados do beneficiário';
  static RESPONSIBLE_DATA_TITLE = 'Proprietário';
  static DRIVER_DATA_TITLE = 'Datos condutor';
  static LOAD_PLANS = 'Estamos procurando os melhores planos para o seu carro';
  static LOAD_PLANS_PET = 'Estamos procurando os melhores planos para o seu pet';
  static NO_PLANS = 'Nenhum plano encontrado';
  static COMPARE_PLANS_ISSUE = 'Comparar planos';
  static TITLE_ISSUE_PLANS = 'Consulta las compañías de seguros que cotizaron tu vehículo y selecciona el plan ideal';
  static FILTERS_PLANS = 'Filtrar por:';
  static FILTERS_COMPANY = 'Seguradoras';
  static FILTERS_TYPES_PLANS = 'Tipos de planos';
  static QUOTA_PLAN = 'Prima total';
  static SUM_INSURED_PLAN = 'Valor segurado';
  static PLATE = 'Placa';
  static USE = 'Uso';
  static CHASIS = 'Chassis';
  static MODEL = 'Modelo';
  static ENGINE = 'Motor';
  static PUT_VEHICLE_DATA = 'Introduce los datos del vehículo';
  static CONDITION = 'Condición del vehículo';
  static PATENT = 'Placa';
  static SERIAL_NUMBER = 'Número de serie';
  static REPUVE = 'REPUVE';
  static VEHICLE_PRICE = 'Precio del vehículo';
  static TYPE_PERSON = 'Tipo de persona';
  static COMPANY_TYPE = 'Tipo de sociedad';
  static CORPORATE_REGIME = 'Régimen societario';
  static TAX_REGIME = 'Régimen fiscal';
  static OCCUPATION = 'Ocupación';
  static TURN = 'Giro';
  static COMPANY_NAME = 'Razón Social';
  static DATE_OF_BIRTH_CONSTITUTION  = 'Fecha de nacimiento constitución';
  static EMIT_INSURANCE = 'Emitir meu seguro';
  static EMIT_PLANO = 'Emitir meu plano';
  static EMIT_SUMMARY = 'Revisão de dados';
  static DOMICILE = 'Endereço';
  static FULL_NAME = 'Nombres';
  static INVOICE = 'Número de Factura';
  static INVOICE_DATE = 'Fecha de Factura';
  static INVOICE_AMOUNT= 'Importe de Factura';
  static SURNAME_PATERNAL = 'Apellido paterno';
  static SURNAME_MATERNAL = 'Apellido materno';
  static EXTERNAL_NUMBER = 'Número externo';
  static INTERNAL_NUMBER = 'Número interno';
  static DOCUMENT_ID = 'RFC';
  static DOCUMENT_TYPE = 'Tipo de documento';
  static PERSON_TYPE = 'Tipo de pessoa';
  static BIRTHDATE = 'Fecha nacimiento';
  static BIRTHDATE_DRIVER = 'Fecha de nacimiento del conductor';
  static DATA_ERROR = 'Digite a data';
  static RELATIONSHIP = 'Parentesco/Relacionamento';
  static PORCENTAGE = 'Porcentagem';
  static RISK_LOCATION = 'Datos de residencia';
  static MANAGER_DATA_TITLE = 'Datos conductor';
  static PAYMENT_DATA_TITLE = 'Datos de pago';
  static RELATIONSHIP_WITH_OWNER = 'Relação com o titular do plano';
  static SELECT = 'Selecione';
  static ORDER = 'Ordenar por';
  static SELECT_ERROR = 'Selecione uma opção';
  static SELECT_QUOTES = 'Selecione uma cotação anterior';
  static VERSION = 'Versão';
  static TRACKER = 'Rastreador';
  static RELATIONSHIP_GRADE = 'Grau de relacionamento entre o funcionário e o proprietário';
  static VEHICLE_USE_TYPE = 'Tipo de uso do veículo';
  static DEALER_DEPARTURE_DATE = 'dd/mm/aaaa hh:mm';
  static BITHDATE_DATE = 'dd/mm/aaaa';
  static DEALER_DEPARTURE_DATE_INPUT = 'Insira a data e a hora de saída do revendedor';
  static POLICY_END_DATE = 'Ingrese la policy end date';     
  static POLICY_START_DATE = 'Ingrese la policy start date'; 
  static SELECT_GENDER = 'Selecione o gênero';
  static SELECT_GENDER_RESIDENTS = 'Selecione o gênero dos residentes';
  static INSURED_VEHICLE_OWNER = 'O proprietário do veículo é o segurado?';
  static VEHICLE_SALVAGE = '¿Desea asegurar un auto de salvamento?';
  static RELATIONSHIP_INSURED_OWNER = 'Selecione o relacionamento com o segurado';
  static RELATIONSHIP_EMPLOYEE_OWNER = 'Grau de relacionamento entre o funcionário e o proprietário';
  static CPF_OWNER = 'CPF';
  static NAME_OWNER = 'Nome do proprietário';
  static SURNAME_OWNER = 'Apellido do proprietário';
  static CODE_AREA = 'Área';
  static CODE_COUNTRY = 'País';
  static CPF_FORMAT = '0000000000000';
  static ZIPCODE_FORMAT = '00000';
  static PHONE_INSURED_PLACEHOLDER = 'Número de telefone';
  static RISK_DATA = 'Dados do residência de risco';
  static PROTECTION_SYSTEM_DATA = 'Dados do sistema de proteção';
  static TYPE_USE = 'Tipo de uso do veículo';
  static DRIVER_DAT_OF_BIRTH = 'Data de nascimento do condutor';
  static OWNER_DATA = 'Dados do proprietário';
  static RENEWAL_DATA = 'Dados da renovação';
  static RENEWAL_PROPOSALS = 'Pólizas a Renovar';
  static EMISSION_LIST = 'Consulta de emisiones';
  static SEARCH_TABLE = 'Buscar por:';
  static DRIVE_WEEKLY = 'A pessoa dirige o veículo semanalmente?';
  static PHYSICAL_OR_LEGAL = 'É físico ou jurídico?';
  static CIVIL_STATUS = 'Estado civil';
  static DRIVER_CIVIL_STATUS = 'Estado civil del conductor';
  static GENDER_RESIDENT_STATUS = 'Gênero dos residentes';
  static TYPE_DRIVER = 'Tipo de condutor';
  static NAME_DRIVER = 'Nome do condutor';
  static CPF_DRIVER = 'CPF';
  static RELATIONSHIP_TYPE = 'Tipo de relacionamento com o segurado';
  static INSURED_DRIVER = 'O condutor é a pessoa segurada?';
  static OWNER_DRIVER = 'O proprietário é condutor?';
  static CPF_INSURED = 'CPF do segurado';
  static CPF_PHONE = 'Telefone do segurado';
  static NAME_INSURED = 'Nome do segurado';
  static SURNAME_INSURED = 'Apellido do segurado';
  static SURNAME_DRIVER = 'Apellido do condutor';
  static BITHDATE = 'Insira a data de nascimento';
  static QUESTIONS_COVERAGES_RESIDENT = 'Deseja estender a cobertura do seguro para residentes com idade entre 18 e 24 anos?';
  static FREE_DATA = 'Dado Livre';
  static QUESTION_LIVE_PERSON = 'Alguém com idade entre 18 e 24 anos mora com o condutor?';
  static ACCOUNT_ADMINISTRATION = 'Dados pessoais';
  static MODIFY_YOUR_INFO = 'Alteração de informações pessoais';
  static MY_ACCOUNT = 'Meu Perfil';
  static SAVE = 'Salvar';
  static DELETE = 'Eliminar os meus dados pessoais';
  static SAVE_SUCCESS = 'Salvamento correto';
  static CLIENT_UPDATE_SUCCESS = 'Seus dados foram atualizados com sucesso!';
  static COUNTRY = 'País';
  static PET_NAME = 'Nome de seu Pet';
  static REGION = 'Estado';
  static SELECT_REGION = 'Selecione sua região';
  static PET_TITLE = 'Dados do pet';
  static PET_SUBTITLE = 'Faça um seguro para seus companheiros peludos preenchendo os dados abaixo.';
  static INPUT_PET_SUBTITLE = 'Você pode adicionar vários animais de estimação a essa cotação';
  static PET_ADD = 'Você pode incluir vários PETs a esse orçamento';
  static PET_COMPLETE_NAMES = 'Nomes de seus animais de estimação';
  static ENTER_PET_PLAN = 'Plano do seu animal de estimação';
  static ENTER_SECOND_PET_PLAN = 'Digite o plano do seu segundo animal de estimação';
  static ENTER_THIRD_PET_PLAN = 'Digite o plano do seu terceiro animal de estimação';
  static PET_PLAN_CHOOSE = 'Escolha o plano para seu animal de estimação';
  static PET_SELECTION_SUBTITLE = 'Selecione um animal de estimação para eleger um plano de saúde.'
  static PET_SELECTION_SUBTITLE2 = 'Você pode alternar entre seus pets e escolher o melhor plano para cada um:'
  static INSURED_AMOUNT = 'Soma Franquia';
  static MONEY_SYMBOL = '$';
  static LIMIT_COMPARER = 'Limites';
  static COVERAGES_COMPARER = 'Coberturas';
  static RESTRICTIONS = 'Restrições';
  static GENERAL_CONDITIONS = 'Condições gerais';
  static PLAN = 'Plano';
  static DOWNLOAD_DOCUMENTACION='Download de documentos';
  static DOWNLOAD='Download';
  static DOWNLOAD_POLICY='Apólice Digital';
  static DOWNLOAD_PROPOSAL='Proposta Digital';
  static DOWNLOAD_PRODUCT_MANUAL='Manual do Produto';
  static CANCEL_QUESTION='REALMENTE DESEJA CANCELAR SUA APÓLICE?';
  static CANCELLATION_WARNING1='Se decidir cancelar, você perderá esses benefícios.';
  static CANCELLATION_WARNING2='Seu seguro oferece proteção e tranquilidade.';
  static NUMBER_PROPOSAL_CANCEL='NRO DA APÓLICE:';
  static CANCELLATION_REQUEST='O seu pedido de cancelamento foi registado com sucesso';
  static CANCELLATION_ERROR='DEVE SELECIONAR UM MOTIVO';
  static DOCUMENT_DRIVER_DUPLICATION_ERROR='CPF do principal conductor e segurado são iguais. Favor verificar.';
  static DOCUMENT_OWNER_DUPLICATION_ERROR='CPF do proprietário e segurado são iguais. Favor verificar.';
  static DOCUMENT_OWNER_DRIVER_DUPLICATION_ERROR='CPF do principal conductor e proprietário são iguais. Favor verificar.';
  static SELECT_REASON='SELECIONE O MOTIVO DO CANCELAMENTO:';
  static CARRIER_DATA='Dados da seguradora'
  static QUOTE_OTHER_COMPANIES='Cotizar con otras compañías';
  static ACCEPT_RENEWAL='Aceptar renovación con la compañía actual';
  static CONFIRM_PROPOSAL_INFO = 'Confirme os detalhes de sua apólice';
  static YOUR_PETS = 'Seus animais de estimação';
  static PET_NUMBER = 'N° do animal de estimação';
  static PERSONAL_INFO = 'Atualize suas informações pessoais';
  static UPDATE_REQUIRED = 'Você precisa atualizar seus detalhes para continuar';
  static FUNCTION_NOT_AVAILABLE = 'Essa funcionalidade está em construção.';
  static MESSAGE_CONFIRMATION1 = 'Sua apólice oferece proteção e tranquilidade.';
  static MESSAGE_CONFIRMATION2 = 'Se decidir cancelar,você perderá esses benefícios';
  static CONFIRM = 'Confirmar';
  static ACCEPT = 'Aceitar';
  static DOCUMENTS_DOWNLOADS = 'Que documentos deseja fazer o download?';
  static TITLE_DOWNLOADS = '"QUE DOCUMENTOS DESEJA DESCARREGAR?"';
  static WORKING_FOR_YOUR_EXPERIENCE = 'Estamos trabalhando para melhorar sua experiência';
  static SORRY = 'Pedimos desculpas!';
  static ATTENTION = 'Atenção!';
  static YOUR_REGION = 'Sua região ';
  static HIRE = 'Contratar';
  static CHANGE_REGION = 'Toque aqui para alterar sua região';
  static INTENTION_PARTNERSHIP = 'Acreditamos que uma empresa é tão forte e poderosa quanto cada uma das pessoas que lhe dão vida. Essa parceria é uma forma de cuidar dessas pessoas e retribuir parte da sua dedicação.';
  static ABOUT_PARTNERSHIP = 'Sobre a Parceria';
  static P1_PARTNERSHIP = 'Sua empresa e a Aon formaram uma parceria para fornecer serviços valiosos aos seus colaboradores. Essa parceria é reflexo do trabalho que a sua empresa faz todos os dias para devolver valor a cada um dos colaboradores.';
  static P2_QUESTION = 'Quem é a Aon? ';
  static P2_PARTNERSHIP = 'Tecnicamente, a Aon plc (NYSE: AON) é uma empresa líder global com mais de três décadas de experiência focada no fornecimento de serviços profissionais e soluções em riscos, aposentadoria e seguros de saúde para empresas e pessoas. Na prática, a Aon está no negócio das melhores Decisões. A missão é promover tomadas de decisão positivas para seus clientes, buscando proteger e enriquecer a vida das pessoas. O foco dos mais de 50 mil colegas em 120 países é construir excelentes resultados. É fornecer informações, conselhos e soluções claras para que os clientes possam tomar melhores decisões. A abordagem colaborativa e a utilização das capacidades globais do escritório garantem uma abordagem eficiente e diferenciada às necessidades dos nossos clientes.';
  static P3_QUESTION = 'Quais serviços serão acrescentados na parceria?';
  static P3_PARTNERSHIP = 'Um portfólio abrangente de soluções de seguros que vão desde seguros patrimoniais até produtos de saúde e vida. A presença global da Aon, o valor da sua presença no mercado e os seus profundos laços com as principais operadoras locais e internacionais, permitem que este portfólio não seja só abrangente, mas tenha a melhor cobertura do mercado, a um ótimo preço.';
  static ADV_TITLE_PARTNERSHIP = 'Vantagens';
  static ADV_P1_TITLE = 'Portfólio Diferenciado';
  static ADV_P1_PARTNERSHIP = 'A parceria permite ao colaborador da empresa acesso a soluções de seguros diferenciadas no mercado: disponibilização de serviços chave associados a coberturas, a um preço altamente competitivo – a melhor oferta disponível para esse combo no mercado.';
  static ADV_P2_TITLE = 'Experiência digital';
  static ADV_P2_PARTNERSHIP = 'Solução de ponta a ponta: da escolha, comparação, compra e gestão de produtos de seguros online para facilitar não só o processo de aquisição, mas também o processo de serviço.';
  static ADV_P3_TITLE = 'Suporte imersivo';
  static ADV_P3_PARTNERSHIP = 'Acompanhamento do processo de compra e gestão do serviço através de múltiplos canais e ferramentas (bot, FAQ, central de atendimento, dicas, etc.) para que a escolha e utilização do seguro seja baseada na informação. O cliente, pode tomar as melhores decisões.';
  static ADV_P4_TITLE = 'Site nativo';
  static ADV_P4_PARTNERSHIP = 'Plataforma de compra e gestão de seguros desenvolvida especialmente para este tipo de produto, possibilitando fácil navegação, conteúdo adequado, funções claras. Em outras palavras, um caminho “amigável” para uma linha de produtos que ainda não é amigável.';
  static BACK_DASHBOARD = 'Volver al inicio';
  static BACK_TIPS = 'Volver a dudas';
  static PET_INSURANCE = 'plano de saúde para seu pet: ';
  static INSURANCE_ADVANTAGES = 'Las principales ventajas de un ';
  static PET_PROTECTION = 'Proteção integral';
  static PET_ASSISTANCE = 'Assistência Complementar';
  static PET_NETWORK = 'Rede de veterinários de confiança';
  static PET_PEACE = 'Paz de espírito';
  static PET_COVERAGE_BOLD = 'Atende a todas as necessidades de seu cão ou gato; ';
  static PET_COVERAGE = 'desde check-ups de rotina até emergências. Para que os custos não sejam a preocupação';
  static PET_DISCOUNT_BOLD = 'Oferece descontos em serviços ';
  static PET_DISCOUNT = 'produtos de beleza e higiene ';
  static PET_MEDKIT_BOLD = 'Dá acesso a uma rede de profissionais de excelência';
  static PET_OBLIGATION_BOLD = 'Cobre a obrigação financeira ';
  static PET_OBLIGATION = 'a responsabilidade do proprietário por danos causados por seu animal de estimação a terceiros';
  static USER_DATA = 'Dados do usuário';
  static PRODUCTS = 'Produtos';
  static ADDRESSES = 'Endereços';
  static LOGIN_AND_SECURITY = 'Login e segurança';
  static LOGOUT = 'Logout';
  static USER_CALL_VALIDATION = 'Validação do usuário atendido';
  static USER_CALL_EMAIL_ENTER = 'Digite o endereço de e-mail do usuário com quem está lidando.';
  static COMING_SOON = 'Em breve';
  static LEGAL_DISCLAIMER = 'Os seguros e serviços ofertados por esse canal digital têm a corretagem da Aon Holdings Corretores de Seguros Ltda. | CNPJ 48.102.552.0001-37 | Código SUSEP: 202039375';
  static COOKIES_WARNING = 'Aviso de Cookies';
  // static LEGAL_DATA_DISCLAIMER =  '“Eu autorizo e concordo que a Aon efetuará o tratamento dos meus dados pessoais para a finalidade específica de prestação de serviços de corretagem de seguros; em conformidade com a Lei nº 13.709\\2018 Lei Geral de Proteção de Dados Pessoais (LGPD). E declaro que conheço; estou de acordo e aceito a Política de Privacidade da Aon.”';
  static LEGAL_DATA_DISCLAIMER =  'Eu autorizo e concordo que a Aon efetuará o tratamento dos meus dados pessoais para a finalidade específica de prestação de serviços de corretagem de seguros; em conformidade com a Lei nº 13.709\\2018 Lei Geral de Proteção de Dados Pessoais (LGPD). E declaro que conheço; estou de acordo e aceito a <a href="https://www.aon.com/brasil/politica-de-privacidade.jsp" target="_blank">Política de Privacidade da Aon</a>.';
  // static LEGAL_DATA_DISCLAIMER_OWNER = '“Eu autorizo e concordo, assim como meus dependentes, que a Aon efetuará o tratamento dos meus dados pessoais para a finalidade específica de prestação de serviços de corretagem de seguros, em conformidade com a Lei nº 13.709\\2018 Lei Geral de Proteção de Dados Pessoais (LGPD). E declaro que conheço, estou de acordo e aceito a Política de Privacidade da Aon.”';
  static LEGAL_DATA_DISCLAIMER_OWNER = '“Eu autorizo e concordo, assim como meus dependentes, que a Aon efetuará o tratamento dos meus dados pessoais para a finalidade específica de prestação de serviços de corretagem de seguros, em conformidade com a Lei nº 13.709\\2018 Lei Geral de Proteção de Dados Pessoais (LGPD). E declaro que conheço, estou de acordo e aceito a <a href="https://www.aon.com/brasil/politica-de-privacidade.jsp" target="_blank">Política de Privacidade da Aon</a>.”';
  static ADD_DOMICILE = 'Adicionar endereço';
  static CHANGE_PASSWORD = 'Alterar senha'
  static CHANGE_PASSWORD_SUBTITLE = 'Insira seu e-mail para enviarmos as instruções de redefinição de sua senha.'
  static SELECTED_PLAN = 'Plano Selecionado';
  static PRODUCT = 'Produto'  ;
  static CITY = 'Municipio';
  static STREET = 'Calle';
  static NUMBER = 'Número';
  static ZIPCODE = 'Código postal';
  static DISTRICT = 'Colonia'
  static VIGENCY = 'Vigência';
  static REDIRECT_TO_PAYMENT = 'O seu contrato está agora registado! Será redireccionado para o pagamento';
  static GO_TO_PAYMENT = 'Continuar a pasarela de pago';
  static EMISSION_SUCCES = 'Seu contrato foi registrado com sucesso!';
  static EMISSION_COMPLETE_PAYMENT = 'Por favor, complete o pagamento para concluir o processo.';
  static MUST_PSW_CONTAIN = 'Deve incluir';
  static MUST_PSW_LENGTH = 'Mais de 8 caracteres';
  static MUST_PSW_LETTER = 'Pelo menos una letra';
  static MUST_PSW_NUMBER = 'Pelo menos una número';
  static MUST_PSW_MAYUS = 'Pelo menos uma letra maiúscula';
  static DATE_FISCAL_NOTE = 'Data da nota fiscal';
  static VALUE_FISCAL_NOTE = 'Valor da nota fiscal';
  static DEALERSHIP = 'Concessionária';
  static FISCAL_NOTE = 'Nota fiscal';
  static FINANCING = 'Financiadora';
  static FIDUCIARY_ALINEATION = 'Alienação fiduciária?';
  static PAYMENT_SUCCESS = 'Pagamento efectuado com sucesso';
  static CLOSE_TO_VIEW_PROPOSALS = 'Feche esta janela para ver as suas apólices';
  static YEAR = 'Ano';
  static BILL = 'Fatura';
  static QUESTION_ALINEATION = 'O veículo esta Alienado?';
  static SELECT_PET_PLAN = 'Escolha o plano para os seus pets';
  static SELECT_DENTAL_PLAN = 'Escolha o plano que melhor se adapte as suas necessidades:'
  static P_PROVIDERS_FOOTER11 = 'YLM Seguros S/A. | CNPJ: 61.550.141/0001-72';
  static P_PROVIDERS_FOOTER12 = 'End. Rua Dr. Geraldo Campos Moreira, 110 - Brooklin Novo - São Paulo/SP - CEP: 04.571-020';
  static P_PROVIDERS_FOOTER13 = 'Código SUSEP Seguradora: 518-5 | Processos SUSEP Produtos: 15414.100331/2004-96 e 15414.901089/2015-23';
  static P_PROVIDERS_FOOTER14 = 'Consulte as condições gerais do produto antes de contratar';
  static P_PROVIDERS_FOOTER15 = 'Canais de Contato: 4004-5423 Capital e Região Metropolitana e 0800-709-5423 Demais Localidades';
  static P_PROVIDERS_FOOTER16 = 'Canais de Contato (Aliro): 3003-2127 Capital e Região Metropolitana e 0800-220-2127 Demais Localidades';
  
  static P_PROVIDERS_FOOTER21 = 'HDI Seguros S/A. | CNPJ: 29.980.158/0001-57';
  static P_PROVIDERS_FOOTER22 = 'End. Av. das Nações Unidas, 14261 Conj. 2101B - Brooklin, São Paulo/SP - CEP: 04578-000';
  static P_PROVIDERS_FOOTER23 = 'Código SUSEP Seguradora: 657-2 | Processos SUSEP Produtos: 15414.001197/2004-41 e 15414.900886/2016-74';
  static P_PROVIDERS_FOOTER24 = 'Consulte as condições gerais do produto antes de contratar';
  static P_PROVIDERS_FOOTER25 = 'Canais de Contato: 3003-5390 Capital e Regiões Metropolitanas e 0800-434-4340 Demais Localidades';

  static P_PROVIDERS_FOOTER31 = 'PETSUPERMARKET COMERCIO DE PRODUTOS PARA ANIMAIS LTDA. | CNPJ: 10.864.846/0033-00';
  static P_PROVIDERS_FOOTER32 = 'End.: Av. das Nações Unidas nº 12.901 - Brooklin Paulista - São Paulo/SP - CEP 04578-910';
  static P_PROVIDERS_FOOTER33 = 'Inscrição Municipal: 1.284.008-4';
  static P_PROVIDERS_FOOTER34 = 'Fale com a Petlove pelo telefone: 011 3003-7190 ou pelo chat';

  static P_PROVIDERS_FOOTER41 = 'YLM Seguros S/A. | CNPJ: 61.550.141/0001-72';
  static P_PROVIDERS_FOOTER42 = 'End. Rua Dr. Geraldo Campos Moreira, 110 - Brooklin Novo - São Paulo/SP - CEP: 04.571-020';
  static P_PROVIDERS_FOOTER43 = 'Código SUSEP Seguradora: 518-5 | Processos SUSEP Produtos: 15414.004633/2004-34';
  static P_PROVIDERS_FOOTER44 = 'Canais de Contato: 4004-5423 Capital e Região Metropolitana e 0800-709-5423 Demais Localidades';

  static P_PROVIDERS_FOOTER4 = 'Processos SUSEP: 15414.100331/2004-96';
  static P_PROVIDERS_FOOTER5 = 'Metlife Planos Odontológicos LTDA.| CNPJ: 03.273.825/0001-78';
  static P_PROVIDERS_FOOTER6 = 'Registro ANS: 490.277/21-7, 490.421/21-4 e 479.622/17-5';
  static P_PROVIDERS_FOOTER7 = 'PETSUPERMARKET COMERCIO DE PRODUTOS PARA ANIMAIS LTDA. ';
  static P_PROVIDERS_FOOTER8 = 'CNPJ: 10.864.846/0033-00 | End.: Av. das Nações Unidas nº 12.901 - Brooklin Paulista - São Paulo/SP - CEP 04578-910 | Inscrição Municipal: 1.284.008-4 | Fale com a Petlove pelo telefone: 011 3003-7190 ou pelo chat';
  static CLICK_HERE = 'clicando aquí';
  static P_PROVIDERS1 = 'Conheça nossos Provedores';
  static P_PROVIDERS2 = 'Além de nos unirmos com sua empresa, a Aon busca no mercado as melhores seguradoras e empresas de assistências e serviços para lhe oferecer as melhores soluções:'
  static P_PROVIDERS3 = 'YLM Seguros S/A (Liberty) | CNPJ: 61.550.141/0001-72'
  static P_PROVIDERS4 = 'Processos SUSEP: 15414.100331/2004-96 e 15414.901089/2015-23'
  static P_PROVIDERS5 = 'Canais de Contato: 4004 5423 Capital e Região Metropolitana | 0800 709 5423 Demais Localidades'
  static P_PROVIDERS6 = 'YLM Seguros S/A (Aliro) | CNPJ: 61.550.141/0001-72'
  static P_PROVIDERS7 ='HDI Seguros';
  static P_PROVIDERS8  = 'Blvd. San Juan Bosco #5003, Colonia Rancho Seco, C.P. 37669, León, Guanajuato, México | Contacto: 477 740 2827 | Atención al Cliente: 800 019 6000.'
  static P_PROVIDERS9 = 'HDI Seguros S/A | CNPJ: 29.980.158/0001-57'
  static P_PROVIDERS10 = 'Processos SUSEP: 15414.001197/2004-41 e 15414.900886/2016-74'
  static P_PROVIDERS11 = 'Canais de Contato: 3003 5390 Capital e Regiões Metropolitanas | 0800 434 4340 Demais Localidades'
  static P_PROVIDERS12 = 'Metlife Planos Odontológicos LTDA.| CNPJ: 03.273.825/0001-78'
  static P_PROVIDERS13 = 'Registro ANS: 490.277/21-7, 490.421/21-4 e 479.622/17-5'
  static P_PROVIDERS14 = 'Canais de Contato: 3003-3422 Capital e Regiões Metropolitanas | 0800 746 3422 Demais Localidades'
  static P_PROVIDERS15 = 'PETSUPERMARKET Comércio de Produtos para Animais LTDA.| CNPJ: 10.864.846/0033-00'
  static P_PROVIDERS16 = 'Canais de Contato: 011 3003-7190'
  static SOCIAL_NAME = 'Nome Social';
  static JOB = 'Profissão';
  static SALARY_RANGE = 'Faixa salarial';
  static ARE_YOU_POLITCALLY_EXPOSED = 'É uma pessoa politicamente exposta?';
  static COMPLEMENTARY = 'Complemento'
  static ERROR_EMISSION_PROPOSAL = 'Erro ao registar a sua apólice'  
  static EDIT = 'Editar'
  static EMISSION_SUCCESS_SUBTITLE = 'Seu pedido está em análise com a seguradora.';
  // static CHECK_YOUR_POLICIES = 'Você pode consultar o status no menu “Meus Seguros”.'
  static CHECK_YOUR_POLICIES = 'Você pode consultar o status no menu <b>“Meus Seguros”</b>.';
  static GO_TO_MY_POLICIES = 'Acessar Meus Seguros';
  static LEGAL_CONSUMER_DEFENSE = 'Atendendo os termos do art. 49 do Código de Defesa do Consumidor, o exercício de direito de arrependimento poderá ser realizado nesta plataforma no prazo de até 07 (sete) dias corridos contados da contratação. A rescisão sem ônus está condicionada à não utilização do plano, e em caso de uso, a operadora poderá cobrar o custeio dos procedimentos efetuados. O exercício do arrependimento implica na rescisão desse contrato.';
  static BACK_TO_QUOTING = 'Volver a cotizar';
  static CHECK_SPAM_MAILBOX = 'Verifique sua pasta de Spam / Lixo Eletrônico';
  static CHECK_SPAM_MAILBOX_EMISSION = 'Verifique sua caixa de lixo eletrônico ou SPAM.';
  static LABEL_MY_QUOTE = 'Gerencie suas cotações facilmente. Escolha uma para ver os detalhes.';

  static LOADING_PROPOSAL_DATA = 'Só um momento... Estamos a terminar o registo dos dados da sua apólice.'
  static LOADING_PAYMENT = 'Carregando meio de pagamento da seguradora. Fique atento: Seus dados podem ser registrados agora e cobrados posteriormente.';
  static LOADING_DOWNLOAD = 'Al hacer clic en el siguiente botón, serás redirigido a una pasarela de pago en una pestaña nueva. Una vez completado el pago, regresa a esta pantalla para finalizar la compra.';
  static LOADING_DOWNLOAD_EXTERNAL = 'Al hacer clic en el siguiente botón, serás redirigido a una pasarela de pago en una pestaña nueva.';
  static LOADING_DOWNLOAD1 = 'Luego de realizar el pago exitosamente, podrá consultar los detalles de la póliza adquirida.';
  static POLICY_MESSAGE = 'Una vez realizado el pago, deberás ingresar el número de póliza:';
  static ARE_YOU_SURE_DELETE = 'Tem certeza que deseja excluir seus dados?'
  static DATA_DELETED = 'Os seus dados foram excluídos com susseso'
  
  static SELECT_OPTION_ERROR = 'Selecione uma opção';
  static HINTS = 'Dicas';
  static KNOW_MORE = 'Saiba mais'
  static VEHICLE = 'Vehículo'
  static STARTING_DATE_VIGENCY = 'Fecha de Inicio de Vigencia'
  static ASOCIATE_NUMBER = 'Número de asociado'
}
