export const ISSUE_BASE = 'my/emission';
export const RENEWAL_BASE = 'my/renewals';
export const PERSONAL_DATA = 'personal_data'
export const VEHICLE = 'vehicle';
export const RISK_LOCATION = 'risk_location';
export const MANAGER_LOCATION = 'manager';
export const DRIVER_LOCATION = 'driver';
export const BENEFICIARIES = 'beneficiaries'
export const COVERAGES = 'coverages'
export const FREE_DATA = 'free_data'
export const SUMMARY = 'summary'
export const PAYMENT = 'payment'
export const PERIODICITY = 'periodicity'
export const DOWNLOAD = 'download'
export const CONFIRMATION = 'confirmation'

export const ISSUE_PERSONAL_DATA = `${ISSUE_BASE}/${PERSONAL_DATA}`;
export const ISSUE_VEHICLE = `${ISSUE_BASE}/${VEHICLE}`;
export const ISSUE_RENEWAL_PERIODICITY = `${RENEWAL_BASE}/${PERIODICITY}`;
export const ISSUE_BENEFICIARIES = `${ISSUE_BASE}/${BENEFICIARIES}`;
export const ISSUE_RISK_LOCATION = `${ISSUE_BASE}/${RISK_LOCATION}`;
export const ISSUE_MANAGER = `${ISSUE_BASE}/${MANAGER_LOCATION}`;
export const ISSUE_DRIVER = `${ISSUE_BASE}/${DRIVER_LOCATION}`;
export const ISSUE_COVERAGES = `${ISSUE_BASE}/${COVERAGES}`;
export const ISSUE_FREE_DATA = `${ISSUE_BASE}/${FREE_DATA}`;
export const ISSUE_SUMMARY   = `${ISSUE_BASE}/${SUMMARY}`;
export const ISSUE_PAYMENT   = `${ISSUE_BASE}/${PAYMENT}`;
export const ISSUE_DOWNLOAD  = `${ISSUE_BASE}/${DOWNLOAD}`;
export const ISSUE_CONFIRMATION  = `${ISSUE_BASE}/${CONFIRMATION}`;