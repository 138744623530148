import { Component } from '@angular/core';
import { SpinnerService } from '../services/spinner.service';

@Component({
  selector: 'spinner',
  templateUrl: './spinner.component.html',
  styleUrls: ['./spinner.component.scss']
})
export class SpinnerComponent {

  isShown  = false;

  constructor(private service : SpinnerService) {
    this.service.getState().subscribe({
      next: (isShown) => {
        
        this.isShown = isShown;
      }
    })
  }

}
