import { Component, OnDestroy } from '@angular/core';
import { Subscription } from 'rxjs';
import { ButtonStylesEnum } from 'src/app/main/constants/buttons.enum';
import { LABELS } from 'src/app/main/constants/labels.enum';
import { IPaymentInfo } from 'src/app/main/models/emission/payment-info.model';
import { RedirectorService } from 'src/app/main/services/redirector.service';
import { ButtonTypesEnum } from 'src/app/shared/enums/buttons.enum';
import { ButtonModel } from 'src/app/shared/models/button.model';
import { ModelCreatorService } from 'src/app/shared/services/model-creator.service';
import { IssueState } from 'src/app/state/issue/issue.state';
import { States } from 'src/app/state/models/states.enum';

@Component({
  selector: 'app-emission-confirmation',
  templateUrl: './emission-confirmation.component.html',
  styleUrls: ['./emission-confirmation.component.scss']
})
export class EmissionConfirmationComponent implements OnDestroy {

  issue$ : Subscription = new Subscription();

  isLoading  = true;
  isSuccess  = false;
  succesBtn : ButtonModel = new ButtonModel();

  readonly error_lbl = LABELS.ERROR_EMISSION_PROPOSAL;
  readonly success_lbl = LABELS.EMISSION_SUCCES;
  readonly success_msg = LABELS.EMISSION_SUCCESS_SUBTITLE;
  readonly success_msg_2 = LABELS.CHECK_YOUR_POLICIES;
  readonly legal_text = LABELS.LEGAL_CONSUMER_DEFENSE;
  readonly check_spam = LABELS.CHECK_SPAM_MAILBOX_EMISSION;
  readonly loading_message = LABELS.LOADING_PROPOSAL_DATA;

  constructor(private issue_state : IssueState,
    private redirector : RedirectorService,
    private modelCreator : ModelCreatorService

  ) {
    //this.checkState();
    //forzar para que muestre la pantalla de confirmación
    this.isSuccess = true;
    this.isLoading=false;
    this.succesBtn = modelCreator.createButtonModel(LABELS.GO_TO_MY_POLICIES, ButtonTypesEnum.Common, ButtonStylesEnum.secondary_button, false);    
  }

  // checkState(){
  //   this.issue$ = 
  //     this.issue_state.checkState().subscribe({
  //       next : ({emissionState}) => {
          
  //         if(emissionState.state !== States.success)
  //           return; //mensajito

  //         if(!emissionState.confirmationResult && emissionState.paymentInfo){
  //           //this.callOnline(emissionState.paymentInfo);
  //           return;
  //         }

  //         this.isLoading = false;

  //         if(emissionState.confirmationResult?.IsSuccess){
  //           this.isSuccess = true;
  //           //this.redirector.redirectToProposalList();
  //           // redirect a algun lado? 
  //         }
  //         else{
  //           this.isSuccess = false;
  //         }

  //       }


        
  //     })
  // }

  // callOnline(paymentInfo : IPaymentInfo){
  //   this.issue_state.emitOnline(paymentInfo)
  // }

  ngOnDestroy(): void {
    this.issue$.unsubscribe();
  }

  redirectToProposalList(){
    this.redirector.redirectToProposalList();
  }
}
