<div class="w-100 h-100 payment-height">
    <div *ngIf="isLoading && !externalGangway" class="text-center text-secondary">
        <p>{{loading_payment}}</p>
    </div>
    <iframe  *ngIf="safeUrl && !externalGangway"
        id="payment_frame"
        class="w-100 m-auto"
        [src]="safeUrl"
        (load)="onIframeLoad($event)"
    ></iframe>
    <div class="w-100 h-100 my-4" *ngIf="externalGangway">
        <div class="payment-section text-center" *ngIf="isGnp">
            <div class="payment-icon">
                <img alt="Financial Advantage" src="/assets/banners/Financiera.svg" width="20%" height="auto">
            </div>
        
            <p *ngIf="!isDownload">{{loading_download}}</p>
            <a [href]="urlGangway" target="_blank" rel="noopener noreferrer" *ngIf="!isDownload">
                <custom-button [model]="btnExternalGangway" class="btn-custom-width" ></custom-button>
            </a>
        
            <p class="mt-4" *ngIf="!isDownload">{{policy_message}}</p>
        
            <div *ngIf="!isDownload">
                <custom-input [model]="numberPolicy!"></custom-input>
            </div>
        
            <div *ngIf="!isDownload" class="mt-4">
                <custom-button [model]="btnContinue" class="btn-custom-width mt-4" (customClick)="updatePolicy()"></custom-button>
            </div>
            
            <div *ngIf="isDownload" class="mt-4">
                <custom-button [model]="btnDownload" class="btn-custom-width mt-4" (customClick)="requestDocument()"></custom-button>
            </div>
            
        </div>
        <div class="payment-section text-center" *ngIf="!isGnp">
            <div class="payment-icon">
                <img alt="Second Financial Advantage" src="/assets/banners/Financiera.svg" width="20%" height="auto">
            </div>
        
            <p>{{loading_download_external}}</p>
            <a [href]="urlGangway" target="_blank" rel="noopener noreferrer">
                <custom-button [model]="btnExternalGangway" style="width: 10%;" class="btn-custom-width"></custom-button>
            </a>
        
            <p class="mt-4">{{loading_download1}}</p>

            <div class="mt-4">
                <custom-button [model]="btnDownload" class="btn-custom-width mt-4" (customClick)="requestDocument()"></custom-button>
            </div>
            
        </div>


    </div>
    

</div>

