import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from "@angular/core";
import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import { Subscription } from "rxjs";
import { ButtonStylesEnum, ButtonTypesEnum } from "src/app/main/constants/buttons.enum";
import { ERRORS } from "src/app/main/constants/errors.enum";
import { InputTypesEnum } from "src/app/main/constants/inputs-types.enum";
import { LABELS } from "src/app/main/constants/labels.enum";
import { QuotingInfoModel } from "src/app/main/models/emission/quoting-info.model";
import { QuotingVehicleValidStepsEnum } from "src/app/main/models/emission/quoting-vehicle-steps.enum";
import { ISelectItem } from "src/app/main/models/select-item.model";
import { ConsultantService } from "src/app/main/services/consultant.service";
import { QuotingFormHandlerService } from "src/app/main/services/issue/quoting-form-handler.service";
import { Masks } from "src/app/shared/enums/masks.enum";
import { InputDateTimeModel } from "src/app/shared/input-datetime-custom/model/input-datetime-model";
import { ButtonModel } from "src/app/shared/models/button.model";
import { DropdownCustomModel } from "src/app/shared/models/dropdown.model";
import { InputModel } from "src/app/shared/models/input.model";
import { ModelCreatorService } from "src/app/shared/services/model-creator.service";
import { IssueState } from "src/app/state/issue/issue.state";
import { LoginState } from "src/app/state/login/login.state";
import { States } from "src/app/state/models/states.enum";

@Component({
    selector: 'issue-insured-data',
    templateUrl: './issue-insured-data.component.html',
    styleUrls: ['./issue-insured-data.component.scss']
  })
  export class IssueInsuredDataComponent implements OnDestroy{
    @Input() parentGroup: FormGroup |undefined;
    @Output() completedClicked = new EventEmitter<any>();
    @Output() backClicked = new EventEmitter<any>();

    driverBirthdateModel: InputDateTimeModel|undefined;

    zipCodeInput: InputModel | undefined;
    zipCodeInputDriver: InputModel | undefined;
 
    btnContinue : ButtonModel|undefined;
    btnReturn : ButtonModel|undefined;

    isDriver:string|undefined;
    insuredrForm: FormGroup |undefined;

    genderSelected:string|undefined;
    selectGender: ISelectItem[] |undefined;
    genderSelect? : DropdownCustomModel;

    driverGenderSelected:string|undefined;
    driverGenderSelect? : DropdownCustomModel;

    // typePersonSelected:string|undefined;
    // selectTypePerson: ISelectItem[] |undefined;
    // typePersonSelect? : DropdownCustomModel;

    civilStatusSelected:string|undefined;
    selectCivilStatus: ISelectItem[] |undefined;
    civilStatusSelect? : DropdownCustomModel;

    driverCivilStatusSelected:string|undefined;
    driverSelectCivilStatus: ISelectItem[] |undefined;
    driverCivilStatusSelect? : DropdownCustomModel;

    title=LABELS.VEHICLE_DATA_INSURED;
    steps: number[] = [0, 1,2];
    currentStep = 0;

    quotingInfo? : QuotingInfoModel;
    quotingInfoSub : Subscription = new Subscription();

    readonly type_use = LABELS.TYPE_USE
    readonly yes=LABELS.QUESTION_ISNEW_YES
    readonly no=LABELS.QUESTION_ISNEW_NO
    birthdate_label=LABELS.BIRTHDATE
    readonly driver_civil_status = LABELS.DRIVER_CIVIL_STATUS;
    readonly civil_status=LABELS.CIVIL_STATUS
    gender = LABELS.GENDER
    readonly data_insured=LABELS.VEHICLE_DATA_INSURED
    readonly type_person=LABELS.QUESTION_TYPE_PERSON
    readonly question_driver_insured=LABELS.QUESTION_DRIVER_INSURED
    chk_lbl : string = LABELS.LEGAL_DATA_DISCLAIMER;
    
    constructor(private fb : FormBuilder,private modelCreator : ModelCreatorService,private consultantProposal:ConsultantService, 
      private issue_state : IssueState,
      private formHandler : QuotingFormHandlerService,
      private loginState:LoginState){
      this.insuredrForm = this.formHandler.getFormControl(QuotingVehicleValidStepsEnum.insured_data_mex) as FormGroup;

      this.loadQuotingInfo();
      this.loadDataBack();
    }

    loadData( quotingInfo : QuotingInfoModel){{
      // this.selectTypePerson = quotingInfo.TipoPersonas.map(status => ({
      //   id: status.Id,
      //   descripcion: status.Descripcion,
      // }));

      this.selectGender = quotingInfo.Generos.map(gender => ({
        id: gender.Id,
        descripcion: gender.Descripcion,
      }));

      this.selectCivilStatus = quotingInfo.EstadosCiviles.map(status => ({
        id: status.Id,
        descripcion: status.Descripcion,
      }));
      this.loadInputs();
    }}

    loadInputs(){  
      this.zipCodeInput = this.modelCreator.createInputModel(LABELS.QUESTION_INSURED_ZIPCODE, InputTypesEnum.zipCode, 'zipCode', this.insuredrForm!, ERRORS.ZIPCODE_INVALID, undefined, true, 5, true);
      this.zipCodeInputDriver = this.modelCreator.createInputModel(LABELS.QUESTION_DRIVER_ZIPCODE, InputTypesEnum.zipCode, 'zipCodeDriver', this.insuredrForm!, ERRORS.ZIPCODE_INVALID, undefined, false, 5, true);
      this.driverBirthdateModel  = this.modelCreator.createCalendarModel('birthdate', this.insuredrForm!, true,  LABELS.BIRTHDATE, false,this.birthdate_label);    
      // this.typePersonSelect = this.modelCreator.createSelectModel('typePerson', LABELS.SELECT,this.selectTypePerson!, this.insuredrForm!,'','',true,false,false,LABELS.SELECT_ERROR);
      // this.typePersonSelect.outsideLabel=this.type_person
      this.driverGenderSelect = this.modelCreator.createSelectModel('driverGender',  LABELS.SELECT, this.selectGender!, this.insuredrForm!,'','',true,false,false,LABELS.SELECT_ERROR);
      this.driverGenderSelect.outsideLabel=LABELS.GENDER_DRIVER
      this.genderSelect = this.modelCreator.createSelectModel('gender',  LABELS.SELECT, this.selectGender!, this.insuredrForm!,'','',true,false,false,LABELS.SELECT_ERROR);
      this.civilStatusSelect = this.modelCreator.createSelectModel('civilStatus', LABELS.SELECT, this.selectCivilStatus!, this.insuredrForm!,'', '', true,false,false,LABELS.SELECT_ERROR);
      this.driverCivilStatusSelect = this.modelCreator.createSelectModel('driverCivilStatus', LABELS.SELECT, this.selectCivilStatus!, this.insuredrForm!,'', '', false,false,false,LABELS.SELECT_ERROR);
      this.driverCivilStatusSelect.outsideLabel=LABELS.DRIVER_CIVIL_STATUS
      this.btnContinue = this.modelCreator.createButtonModel(LABELS.CONTINUE_ISSUE, ButtonTypesEnum.Raised, ButtonStylesEnum.black);
      this.btnReturn = this.modelCreator.createButtonModel(LABELS.RETURN_ISSUE, ButtonTypesEnum.Raised, ButtonStylesEnum.without_white_outline);

      this.driverBirthdateModel!.label=LABELS.BIRTHDATE
      this.genderSelect!.outsideLabel=LABELS.GENDER
      this.civilStatusSelect!.outsideLabel=LABELS.CIVIL_STATUS
       
    }

    loadDataBack(){
      this.isDriver=this.insuredrForm!.get('isDriver')!.value;
      
      this.genderSelected=this.insuredrForm!.get('gender')!.value?.id;      
      // this.typePersonSelected=this.insuredrForm!.get('typePerson')!.value?.id;
      this.civilStatusSelected = this.insuredrForm!.get('civilStatus')!.value?.id;
    }

    loadQuotingInfo() {
      this.quotingInfoSub = this.issue_state.checkState().subscribe({
        next :(state) => {
          const quotingState = state.quotingState;
          if(quotingState.state === States.success){
            this.quotingInfo = quotingState.quotingInfo;
            this.loadData(this.quotingInfo!);
            
          }
          else{
            // de nuevo, no debería pasar nunca
          }
        }      
      })
    }
    
    navigateStep(step:any){
      this.currentStep=step;
    }

    validateGender(){   
      const genderControl = this.insuredrForm!.get('gender');
      const genderStatus =genderControl!.value;
      if(genderStatus!="")
          this.genderSelected= genderStatus.descripcion;

      const birthdate = this.insuredrForm!.get('birthdate')!.value;
      if( birthdate===undefined || this.genderSelected===""){
        this.insuredrForm!.markAllAsTouched()
        return
      }
    }

    validateDriverGender(){   
      const genderControl = this.insuredrForm!.get('driverGender');
      const genderStatus =genderControl!.value;
      if(genderStatus!="")
          this.driverGenderSelected= genderStatus.descripcion;
      const birthdate = this.insuredrForm!.get('birthdate')!.value;
      if( birthdate===undefined || this.genderSelected===""){
        this.insuredrForm!.markAllAsTouched()
        return
      }
    }

  validateCivilStatus(){   
      
    const civilStatusControl = this.insuredrForm!.get('civilStatus');
    const civilStatus =civilStatusControl!.value;
    if(civilStatus!="")
        this.civilStatusSelected= civilStatus.descripcion;

    const birthdate = this.insuredrForm!.get('birthdate')!.value;
    //|| this.civilStatusSelected===""
    if( birthdate===undefined || this.genderSelected==="" ){
      //this.insuredrForm!.markAllAsTouched()
      return
    }
  }

  validateDriverCivilStatus(){   
    const civilStatusControl = this.insuredrForm!.get('driverCivilStatus');
    const civilStatus =civilStatusControl!.value;
    if(civilStatus!="")
        this.driverCivilStatusSelected= civilStatus.descripcion;
  }

  // validateTypePerson(){
  //   const typePersonControl = this.insuredrForm!.get('typePerson');
  //   const typePerson =typePersonControl!.value;
  //   if(typePerson!="")
  //       this.typePersonSelected= typePerson.descripcion;
  //   if(typePerson.id=='2'){
  //     this.driverBirthdateModel!.label=LABELS.BIRTHDATE_DRIVER
  //     this.genderSelect!.outsideLabel=LABELS.GENDER_DRIVER
  //     this.civilStatusSelect!.outsideLabel=LABELS.DRIVER_CIVIL_STATUS
  //   }else{
  //     this.driverBirthdateModel!.label=LABELS.BIRTHDATE
  //     this.genderSelect!.outsideLabel=LABELS.GENDER
  //     this.civilStatusSelect!.outsideLabel=LABELS.CIVIL_STATUS
  //   }  
  // }
    
  validateStep0(){
    let hasError = false;  
    this.isDriver='1';
    this.insuredrForm!.get('isDriver')?.setValue(this.isDriver);
        this.gender=LABELS.GENDER;
        this.genderSelect!.outsideLabel=this.gender
        this.btnContinue!.text=LABELS.QUOTE_ISSUE;
        this.zipCodeInputDriver!.required=false;

        const birthdate = this.insuredrForm!.get('birthdate')!.value;
        const zipCodeInput = this.insuredrForm!.get('zipCode')!.value;
        if (!zipCodeInput || !birthdate || !this.genderSelected || !this.civilStatusSelected ) {
          // Tocar solo los campos específicos
          if (!birthdate)
            this.insuredrForm!.get('birthdate')!.markAsTouched();
          if (!zipCodeInput)
            this.insuredrForm!.get('zipCode')!.markAsTouched();
          if (!this.genderSelected)
            this.insuredrForm!.get('gender')!.markAsTouched();
          if (!this.civilStatusSelected) 
            this.insuredrForm!.get('civilStatus')!.markAsTouched();
          // if (!this.typePersonSelected) 
          //   this.insuredrForm!.get('typePerson')!.markAsTouched();        
          hasError = true;
        } 
        else {
            this.completedClicked.emit();
        }     
    }

    validateIsOwner(value:any){
      this.isDriver=value.toString();
      this.insuredrForm!.get('isDriver')?.setValue(this.isDriver);
    }

    next(){     
      if(this.currentStep==0){
        this.validateStep0()
        return
      }
    }

    return(){
      if(this.currentStep === 0){
        this.backClicked.emit();
      }
      if(this.currentStep >0){
        this.currentStep--;
        this.title=LABELS.VEHICLE_DATA_INSURED;
      }
    }

    ngOnDestroy(): void {
      this.quotingInfoSub.unsubscribe();
    }
  }
