<div class="h-100 w-100" *ngIf="showInputs">

    <div class="row">
        <div class="col-md-4 col-sm-4">
            <custom-input (blurred)="checkPostalCode()" [model]="zipCodeModel!"></custom-input>
        </div>
        <div class="col-md-8 col-sm-8">
        </div>
    </div>

<!--    <div *ngIf="showError" class="text-danger">
        <p>{{msgError}}</p>
    </div>-->
    <div class="row">
        <div class="col-md-6 col-sm-6">
            <custom-input [model]="externalNumberModel!"></custom-input>
        </div>
        <div class="col-md-6 col-sm-6">
            <custom-input [model]="internalNumberModel!"></custom-input>
        </div>

    </div>


    <div class="row">
        <!-- <div class="col-md-7 col-sm-7">
            <custom-input [model]="floorModel!" *ngIf="false"></custom-input>
        </div> -->
        <div class="col-md-7 col-sm-7">
            <custom-input [model]="streetModel!"></custom-input>
        </div>        
        <div class="col-md-5 col-sm-5" *ngIf="true">
            <custom-select [model]="districtsModel!"></custom-select>
        </div>

    </div>



    <div class="row">
        <div class="col-md-7 col-sm-7">
            <div class="py-2">
                <custom-select [model]="cityModel"></custom-select>
            </div>
        </div>
        <div class="col-md-5 col-sm-5">
            <div class="py-2"> 
                <custom-select [model]="regionModel" (selectChanged)="getCities()"></custom-select>
            </div>

        </div>

    </div>


    

    

    <!--<div class="d-flex pt-3 col-12 justify-content-end">
        <div class="col-4">
            <custom-button (customClick)="onSaveClick()" [model]="btnSave"></custom-button>
        </div>
        
    </div>-->
</div>
