import { Component } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { ButtonStylesEnum, ButtonTypesEnum } from 'src/app/main/constants/buttons.enum';
import { ERRORS } from 'src/app/main/constants/errors.enum';
import { InputTypesEnum } from 'src/app/main/constants/inputs-types.enum';
import { LABELS } from 'src/app/main/constants/labels.enum';
import { IRegisterModel } from 'src/app/main/models/auth/register.model';
import { NotificatorService } from 'src/app/main/services/notificator.service';
import { RedirectorService } from 'src/app/main/services/redirector.service';
import { TranslationsService } from 'src/app/main/services/translations.service';
import { ButtonModel } from 'src/app/shared/models/button.model';
import { InputModel } from 'src/app/shared/models/input.model';
import { ModelCreatorService } from 'src/app/shared/services/model-creator.service';

@Component({
  selector: 'app-reset-password',
  templateUrl: './reset-password.component.html',
  styleUrls: ['./reset-password.component.scss']
})
export class ResetPasswordComponent {
  emailInput : InputModel;
  formGroup : FormGroup;

  btnSend : ButtonModel;
  btnBackToLogin : ButtonModel;

  emailSended  = false;
  emailStatus  = "";

  title : string = LABELS.CHANGE_PASSWORD
  subtitle : string = LABELS.CHANGE_PASSWORD_SUBTITLE;


  constructor(private modelCreator : ModelCreatorService,
    private notificator : NotificatorService,
    private translations : TranslationsService,
    private router : Router,
    private fb : FormBuilder,
    private redirector : RedirectorService) {

      this.checkLbls();

      this.formGroup = this.fb.group({
        email : ['', [Validators.required]]
      });

    this.emailInput = modelCreator.createInputModel(LABELS.EMAIL, InputTypesEnum.email, 'email', this.formGroup, ERRORS.EMAIL_INVALID);
    
    this.btnSend = this.modelCreator.createButtonModel(LABELS.SEND_LINK, ButtonTypesEnum.Raised, ButtonStylesEnum.black)
    this.btnBackToLogin = modelCreator.createButtonModel(LABELS.BACK_TO_LOGIN, ButtonTypesEnum.Raised, ButtonStylesEnum.white_outline)
  }

  submit(){
    if(this.formGroup.invalid)
      return;

    this.notificator.sendResetPasswordEmail(this.formGroup.value.email).subscribe({
      next: (data) => {

        this.emailSended = true;
        if(data && data.IsSuccess){
          this.emailStatus = LABELS.EMAIL_SUCCESS;
          return;
        }        
        
        this.emailStatus = ERRORS.COULD_NOT_RESET_MAIL;
      },
      error: () => {
        this.emailSended = true;
        this.emailStatus = ERRORS.COULD_NOT_RESET_MAIL;
      }
    });    
  }

  checkLbls(){
    this.translations.getTranslationsObservable()?.subscribe(translations => {
      if (translations.LABELS) {
        this.title = LABELS.CHANGE_PASSWORD
        this.subtitle = LABELS.CHANGE_PASSWORD_SUBTITLE;
        this.emailInput.customError = ERRORS.EMAIL_INVALID;
      }
    });
  }

  navigateToLogin(){
    this.redirector.redirectToLogin();
  }
}
