import { Component, Input, Output, EventEmitter } from '@angular/core';

@Component({
  selector: 'modal-popup',
  templateUrl: 'modal-popup.component.html',
  styleUrls: ['modal-popup.component.scss']
})
export class ModalPopUpComponent {

    @Output() cancel: EventEmitter<void> =  new EventEmitter<void>();
    /* eslint-disable*/
    @Input('display') set displatyEnable(value:boolean) {
        this.visible = value as boolean;
        setTimeout(() => this.visibleAnimate = true, 100);
    }
    @Input() hideHeader:boolean|undefined;
    @Input() hideButtons:boolean|undefined;
    @Input() maxSize:boolean|undefined;

    public visible = false;
    public visibleAnimate = false;

    public hide(): void {
        this.visibleAnimate = false;
        setTimeout(() => this.visible = false, 300);
        this.cancel.emit();
    }

    public onContainerClicked(event: MouseEvent): void {
        if ((<HTMLElement>event.target).classList.contains('modal')) {
            this.hide();
            this.cancel.emit();
        }
    }

    keyPressed(){
        this.hide();
        this.cancel.emit()
    }
    handleDismiss(){
        this.cancel.emit();
    }

}
