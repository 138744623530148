import { Component, Input, OnDestroy } from '@angular/core';
import { FormArray, FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Subscription } from 'rxjs';
import { ButtonStylesEnum, ButtonTypesEnum } from 'src/app/main/constants/buttons.enum';
import { ERRORS } from 'src/app/main/constants/errors.enum';
import { ICONS } from 'src/app/main/constants/icons.enum';
import { InputTypesEnum } from 'src/app/main/constants/inputs-types.enum';
import { LABELS } from 'src/app/main/constants/labels.enum';
import { LogosEnum } from 'src/app/main/constants/logos.enum';
import { ISelectItem } from 'src/app/main/models/select-item.model';
import { RedirectorService } from 'src/app/main/services/redirector.service';
import { PopUpTypesEnum } from 'src/app/shared/enums/popup-types.enum';
import { ButtonModel } from 'src/app/shared/models/button.model';
import { DropdownCustomModel } from 'src/app/shared/models/dropdown.model';
import { InputModel } from 'src/app/shared/models/input.model';
import { SideAdvicesModel } from 'src/app/shared/models/side-advices.model';
import { PopupMessageComponent } from 'src/app/shared/popup-message/popup-message.component';
import { ModelCreatorService } from 'src/app/shared/services/model-creator.service';
import { SideAdvicesComponent } from 'src/app/shared/side-advices/side-advices.component';
import { IssueState } from 'src/app/state/issue/issue.state';
import { LoginState } from 'src/app/state/login/login.state';
import { States } from 'src/app/state/models/states.enum';

@Component({
  selector: 'issue-pet',
  templateUrl: './issue-pet.component.html',
  styleUrls: ['./issue-pet.component.scss']
})
export class IssuePetComponent implements OnDestroy {
  readonly back_dashboard=LABELS.BACK_DASHBOARD
  form : FormGroup;

  regions : ISelectItem[] = [];
  regionSelect? : DropdownCustomModel;

  petInput? : InputModel;
  btnNext : ButtonModel;

  title : string = LABELS.PET_TITLE;
  subtitle : string = LABELS.PET_ADD;
  regions_title : string = LABELS.SELECT_REGION;
  componentData : any;
  isPopupShowing  = false;
  maxPetQuantity  = 1
  quotingId : number | undefined;
  
  quotingInfo$ : Subscription = new Subscription();
  pricing$ : Subscription = new Subscription();

  advices : SideAdvicesModel = {
    title : LABELS.PET_INSURANCE,
    advices : [
      {logo : LogosEnum.PET_PAW, title : LABELS.PET_PROTECTION        , boldText: LABELS.PET_COVERAGE_BOLD,     text: LABELS.PET_COVERAGE},
      {logo : LogosEnum.YOGA, title : LABELS.PET_PEACE                , boldText: LABELS.PET_OBLIGATION_BOLD,   text: LABELS.PET_OBLIGATION},
      {logo : LogosEnum.MEDKIT, title : LABELS.PET_NETWORK            , boldText: ''  ,     text: LABELS.PET_MEDKIT_BOLD},
      {logo : LogosEnum.ARROW_TRIANGLE, title : LABELS.PET_ASSISTANCE , boldText: LABELS.PET_DISCOUNT_BOLD,     text: LABELS.PET_DISCOUNT}
    ]
  }


  constructor(private modelCreator : ModelCreatorService,
    private fb : FormBuilder,
    private issueState : IssueState,
    private redirector : RedirectorService,
    private loginState : LoginState,
  ) {
    this.form = this.fb.group({
      region : [''],
      idTipoProducto : [''],
      idCliente : [''],
      nombre : [''],
      mascotas : [''],
    });    
    
    this.btnNext = modelCreator.createButtonModel(LABELS.CONTINUE, ButtonTypesEnum.Common, ButtonStylesEnum.base_button);

    this.form.controls['idCliente'].setValue(loginState.user?.IdCliente ?? 1);    

    this.checkIssueState();    
  }

  checkIssueState(){
    this.quotingInfo$ = this.issueState.checkState().subscribe({
      next : ({quotingState}) => {
        if(quotingState.state !== States.success)
          return;
        
        this.form.controls['idTipoProducto'].setValue(quotingState.quotingInfo?.idTipoProducto);
        
        this.regions = quotingState.quotingInfo?.Regiones?.map((r: any) => {
          r.id = r.Id;
          r.descripcion = r.Descripcion
          return r;
        }) ?? [];

        this.quotingId = quotingState.quotingInfo?.CotId;

        this.maxPetQuantity = quotingState.quotingInfo?.CantidadMascotas ?? 1;
        this.createInputModels();
        
      }
    })
  }  

  createInputModels(){        
    this.regionSelect = this.modelCreator.createSelectModel('region', LABELS.SELECT_REGION, this.regions, this.form, '', '', true);
    this.petInput = this.modelCreator.createInputModel(LABELS.PET_NAME, InputTypesEnum.common, 'nombre', this.form,undefined,undefined,undefined,undefined,undefined,undefined,undefined,LABELS.INPUT_PET_SUBTITLE);
  }

  showPopup(popupType : PopUpTypesEnum, title : string, messages : string[]){
    this.componentData = {
      component : PopupMessageComponent,
      type : popupType,
      title : title,
      messages : messages
    }
    this.isPopupShowing = true;
  }

  closePopup(){
    this.isPopupShowing = false;
  }  

  price(){
    
    if(this.form.invalid || !this.form.value.region || (this.form.value.mascotas.length ?? 0) === 0)
      return; //mensajito

    this.issueState.quote({
       idRegion : +this.form.value.region.id,
       regionDescription : this.form.value.region.descripcion,
       idTipoProducto: +this.form.value.idTipoProducto,
       idCliente : this.loginState.user?.IdCliente,
       cotId : this.quotingId});
    
    this.pricing$ = 
      this.issueState.checkState().subscribe({
      next : ({quotingState}) => {
        if(quotingState.state === States.success){
          if(quotingState.quotingInfo?.EsMascota){
            quotingState!.mascotas = this.form.value.mascotas;
            this.redirector.redirectToPETPlansQuoted();               
            return;
          }                    
        }        
      }
    })
    
  }

  addPet(){
    
    if(!this.form.value.nombre){
      this.form.markAllAsTouched();
      return;
    }    

    const formControl = this.form.controls['mascotas'];
    const pets = [...formControl.value]

    if((pets?.length ?? 0) >= this.maxPetQuantity){
      // mensajito
      return;
    }
      
    if(pets.find(p => p.nombre === this.form.value.nombre)){
      return;
    }

    const petArray = {
      nombre: this.form.value.nombre,
      plan: undefined,
      descripcion: undefined,
    };

    pets.push(petArray);

    this.form.controls['mascotas'].setValue(pets);
    
    this.form.controls['nombre'].reset();
  }

  backDasboard() {
    this.redirector.redirectToDashboard()
  }

  ngOnDestroy(): void {
    this.pricing$.unsubscribe();
    this.quotingInfo$.unsubscribe();
  }

}
