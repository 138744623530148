import { IRegisterModel } from "./auth/register.model";

export class CodeValidationModel implements IRegisterModel{
    Username = "";
    Password = "";
    Referencia: string;
    IdEmpresa = -1;
    Code? : string;
    CodeSended  = false;


    constructor(obj : IRegisterModel, codeSended : boolean) {
        this.Username = obj.Username;
        this.Password = obj.Password;
        this.IdEmpresa = obj.IdEmpresa;        
        this.CodeSended = codeSended;
        this.Referencia = obj.Referencia;
    }
}