<div class="rounded common-card mt-1 fixed-card">
  <div class="col-lg-9 pt-5 pointer" (keypress)="redirectToTips()" (click)="redirectToTips()" tabindex="0">
      <p class="fs-5 ps-4">{{ title }}</p>
  </div>

  <div class="col-lg-9 pt-2 ps-4 pointer" (keypress)="redirectToTips()" (click)="redirectToTips()" tabindex="0">
      <p class="fs-6">{{ body }}</p>
  </div>

  <div class="centered-container">
      <div class="dot-container">
          <span class="dot" [class.active]="currentIndex === 0" (keypress)="change(0)" (click)="change(0)" tabindex="0"></span>
          <span class="dot" [class.active]="currentIndex === 1" (keypress)="change(1)" (click)="change(1)" tabindex="0"></span>
          <span class="dot" [class.active]="currentIndex === 2" (keypress)="change(2)" (click)="change(2)" tabindex="0"></span>
      </div>
  </div>

  <div class="arrow-container">
      <i class="arrow" [ngClass]="icon" (click)="change()" (keypress)="change()" tabindex="0"></i>
  </div>
</div>
