import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

@Component({
  selector: 'app-custom-stepper',
  templateUrl: './custom-stepper.component.html',
  styleUrls: ['./custom-stepper.component.scss']
})
export class CustomStepperComponent {

  @Input() steps : any[] | undefined;
  @Input() currentStep : number | undefined;

  @Output() stepClicked = new EventEmitter<number>();

  emitStepClick(clicked : number){
    this.stepClicked.emit(clicked)
  }

}
