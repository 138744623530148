<div *ngIf="model?.advices" 
    class="h-100 w-100 d-flex justify-content-center align-items-start text-center flex-wrap fw-light">    
    <p class="col-12 advice-title fs-4 fw-bold">{{title}}<span class="fw-light">{{model?.title}}</span></p>
    <div *ngFor="let advice of model!.advices" class="col-6">
        <img [alt]="'Aviso'" class="side-advice-img"
            [src]="'/assets/banners/' + advice.logo">
        
        <p class="fw-semibold small-text">{{advice.title}}</p>
        <p class="small-text" ><span class="fw-semibold ">{{advice.boldText}}</span>{{advice.text}}</p>
    </div>
</div>