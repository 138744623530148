 


<div class="col-12 py-2" *ngIf="model && model.parentGroup && model.formControlName">
    <form [formGroup]="model.parentGroup">

        <div class="col-12 m-auto">

            <span class="w-100">
                <label class="col-12" [htmlFor]="model.formControlName">{{model.placeholder}}</label>
                <label *ngIf="model.subtitle" class="col-12 subtitle-label" [htmlFor]="model.formControlName">{{model.subtitle}}</label>
                <input *ngIf="!model.type || model.type === common_type || model.type === mail_type || model.type === patent_type || model.type === repuve_type"
               
                 pInputText
                 class="col-12 input"
                 [ngClass]="{'text-danger ng-invalid ng-dirty': (model.parentGroup.controls[model.formControlName].touched && model.parentGroup.controls[model.formControlName].invalid)}"
                 [id]="model.formControlName"
                 [formControlName]="model.formControlName!"          
                 [max]="model.max"
                 [maxlength]="+(model.maxLength ?? 255)"
                 (focus)="emitOnFocus()" 
                 (focusout)="emitOnFocusOut($event)" 
                 (keypress)="emitOnKeyPress($event)"
                 (keyup)="emitOnKeyUp()" (keydown)="emitOnKeyDown()"
                 (blur)="emitOnBlur()"
                 > 
                 <input *ngIf="model.type === zipCode.toString()"
                 pInputText
                 class="col-12 input"
                 [ngClass]="{'text-danger ng-invalid ng-dirty': (model.parentGroup.controls[model.formControlName].touched && model.parentGroup.controls[model.formControlName].invalid)}"
                 [id]="model.formControlName"
                 [formControlName]="model.formControlName!"
                 [disabled]="model.disabled ?? false"  
                 [max]="model.max"
                 [maxlength]="+(model.maxLength ?? 255)"
                 (focus)="emitOnFocus()" 
                 (focusout)="emitOnFocusOut($event)" 
                 (keypress)="emitOnKeyPress($event)"
                 (input)="onInputZipCode($event)"
                 placeholder="{{zipCodeInput}}"
                 (keyup)="emitOnKeyUp()" (keydown)="emitOnKeyDown()"
                 (blur)="emitOnBlur()"
                 >

                 <input *ngIf="model.type === serial_number_type.toString()"
                 pInputText
                 class="col-12 input"
                 [ngClass]="{'text-danger ng-invalid ng-dirty': (model.parentGroup.controls[model.formControlName].touched && model.parentGroup.controls[model.formControlName].invalid)}"
                 [id]="model.formControlName"
                 [formControlName]="model.formControlName!"
                 [disabled]="model.disabled ?? false"  
                 [max]="model.max"
                 [maxlength]="+(model.maxLength ?? 255)"
                 (focus)="emitOnFocus()" 
                 (focusout)="emitOnFocusOut($event)" 
                 (keypress)="emitOnKeyPress($event)"
                 (input)="onInputSerialNumber($event)"
                 placeholder=""
                 (keyup)="emitOnKeyUp()" (keydown)="emitOnKeyDown()"
                 (blur)="emitOnBlur()"
                 >
                 
                 <input *ngIf="model.type === cpf_type.toString()"
                 pInputText
                 class="col-12 input"
                 [ngClass]="{'text-danger ng-invalid ng-dirty': (model.parentGroup.controls[model.formControlName].touched && model.parentGroup.controls[model.formControlName].invalid)}"
                 [id]="model.formControlName"
                 [formControlName]="model.formControlName!"
                 [disabled]="model.disabled ?? false"  
                 [max]="model.max"
                 [maxlength]="+(model.maxLength ?? 255)"
                 placeholder="{{cpfInput}}"
                 (focus)="emitOnFocus()" 
                 (focusout)="emitOnFocusOut($event)" 
                 (keypress)="emitOnKeyPress($event)"
                 (keyup)="emitOnKeyUp()" (keydown)="emitOnKeyDown()"
                 (blur)="emitOnBlur()"
                 >

                 <p-inputNumber *ngIf="model.type === number_type.toString()"
                    [id]="model.formControlName"
                    formControlName={{model.formControlName!}}
                    [disabled]="model.disabled ?? false"
                    [min]="model.min ?? 0"
                    [max]="model.max ?? 0"
                    mode="decimal"
                    [minFractionDigits]="2"
                    [maxFractionDigits]="2"
                    (focus)="emitOnFocus()" 
                    (focusout)="emitOnFocusOut($event)" 
                    (keypress)="emitOnKeyPress($event)"
                    (keyup)="emitOnKeyUp()" 
                    (keydown)="emitOnKeyDown()"
                    (blur)="emitOnBlur()">
                </p-inputNumber>
   
                 <p-password *ngIf="model.type === password_type.toString()" 
                 class="password-input"
                    [id]="model.formControlName"
                    [formControlName]="model.formControlName!"
                    [disabled]="model.disabled ?? false"  
                    [feedback]="false"
                    [toggleMask]="true"
                    (focus)="emitOnFocus()" 
                    (focusout)="emitOnFocusOut($event)" 
                    (keypress)="emitOnKeyPress($event)"
                    (keyup)="emitOnKeyUp()" (keydown)="emitOnKeyDown()"
                    (blur)="emitOnBlur()"
                    >
                </p-password>
                <div class="gap-1 d-md-flex" *ngIf="model.type === phone_type.toString()">
                    <!--<input
                    pInputText
                    class="col-2 col-sm-3 col-md-1 col-lg-1 input"
                    [ngClass]="{'text-danger ng-invalid ng-dirty': (model.parentGroup.controls[model.formControlName + 'Pais'].touched && model.parentGroup.controls[model.formControlName + 'Pais'].invalid)}"
                    [id]="model.formControlName + 'Pais'"
                    [formControlName]="model.formControlName + 'Pais'"
                    [disabled]="model.disabled ?? false"
                    [maxlength]="2"
                    placeholder="{{codeCountry}}"
                    (focus)="emitOnFocus()"
                    (focusout)="emitOnFocusOut($event)"
                    (keypress)="emitOnKeyPress($event)"
                    (keyup)="emitOnKeyUp()"
                    (keydown)="emitOnKeyDown()"
                    (blur)="emitOnBlur()"
                    [value]="52"
                >-->
                    <input
                        pInputText
                        class="col-9 col-sm-3 col-md-3 col-lg-3 ms-sm-0 ms-md-0 ms-lg-0 ms-3 input"
                        [ngClass]="{'text-danger ng-invalid ng-dirty': (model.parentGroup.controls[model.formControlName + 'Area'].touched && model.parentGroup.controls[model.formControlName + 'Area'].invalid)}"
                        [id]="model.formControlName + 'Area'"
                        [formControlName]="model.formControlName + 'Area'"
                        [disabled]="model.disabled ?? false"
                        [maxlength]="model.parentGroup.controls[model.formControlName].value && model.parentGroup.controls[model.formControlName].value.length >= 8 ? 2 : 3"
                        [minlength]="model.parentGroup.controls[model.formControlName].value && model.parentGroup.controls[model.formControlName].value.length >= 8 ? 2 : 3"
                        placeholder="{{codeArea}}"
                        (focus)="emitOnFocus()"
                        (focusout)="emitOnFocusOut($event)"
                        (keypress)="emitOnKeyPress($event)"
                        (keyup)="emitOnKeyUp()"
                        (keydown)="emitOnKeyDown()"
                        (blur)="emitOnBlur()"
                        [value]=""
                    >
                    <input
                        pInputText
                        class="col-12 col-sm-6 col-md-8 col-lg-8 input mt-2 mt-sm-0 mt-md-0 mt-lg-0"
                        [ngClass]="{'text-danger ng-invalid ng-dirty': (model.parentGroup.controls[model.formControlName].touched && model.parentGroup.controls[model.formControlName].invalid)}"
                        [id]="model.formControlName"
                        [formControlName]="model.formControlName"
                        [disabled]="model.disabled ?? false"
                        [max]="model.max"
                        [maxlength]="model.parentGroup.controls[model.formControlName + 'Area'].value && model.parentGroup.controls[model.formControlName + 'Area'].value.length >= 3 ? 7 : 8"
                        [minlength]="model.parentGroup.controls[model.formControlName + 'Area'].value && model.parentGroup.controls[model.formControlName + 'Area'].value.length >= 3 ? 7 : 8"
                        placeholder="{{phone}}"
                        (focus)="emitOnFocus()"
                        (focusout)="emitOnFocusOut($event)"
                        (keypress)="emitOnKeyPress($event)"
                        (keyup)="emitOnKeyUp()"
                        (keydown)="emitOnKeyDown()"
                        (blur)="emitOnBlur()"
                    >
                </div>

                <div class="d-flex" *ngIf="model.type === mask_type.toString()">
                    <p-inputMask
                        pInputText
                        class="password-input"
                        [ngClass]="{'text-danger ng-invalid ng-dirty': (model.parentGroup.controls[model.formControlName].touched && model.parentGroup.controls[model.formControlName].invalid)}"
                        [id]="model.formControlName"
                        [formControlName]="model.formControlName"
                        [mask]="model.mask"
                        [disabled]="model.disabled ?? false"
                        [autoClear]="true"
                        [slotChar]="''"
                        [unmask]="true"
                        [placeholder]="model.mask ?? ''"

                        (onInput)="emitOnKeyUp()"
                        (onKeydown)="emitOnKeyDown()"
                        (onBlur)="emitOnBlur()"
                    >


                    </p-inputMask>
                </div>
            </span>
            
            
        </div>
        <div class="col-12">
            <div class="col-12">
                <p *ngIf="model.customError && model.parentGroup.controls[model.formControlName].touched && model.parentGroup.controls[model.formControlName].invalid"
                    class="text-danger ">
                    {{model.customError}}</p>
            </div>
        </div>
    </form>

</div>