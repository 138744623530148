import { Component } from '@angular/core';
import { UserInfoComponent } from 'src/app/features/user/user-info/user-info.component';
import { LABELS } from 'src/app/main/constants/labels.enum';
import { UserService } from 'src/app/main/services/user/user.service';
import { PopUpTypesEnum } from 'src/app/shared/enums/popup-types.enum';
import { PopupMessageComponent } from 'src/app/shared/popup-message/popup-message.component';
import { PopupOpenerService } from 'src/app/shared/services/popup-opener.service';
import { LoginState } from 'src/app/state/login/login.state';

@Component({
  selector: 'app-dashboard',
  templateUrl: './dashboard.component.html',
  styleUrls: ['./dashboard.component.scss']
})
export class DashboardComponent {
  //aca se debe llenar con un llamando a un servicio que resuelva que preguntas mostrar..
  questions:string[]=[LABELS.INSURANCE_QUESTIONS_PROPOSAL,LABELS.INSURANCE_QUESTIONS_PROPOSAL1,LABELS.INSURANCE_QUESTIONS_PROPOSAL2];
  needsToUpdateClient  = false;

  updateClientComponent : any ={
    component:UserInfoComponent,
    config : {
      closeOnEscape : false,
      closable : false,
      width: '80%',
    },
    data :{ 
      showFullInfo : false,
      onSave : () => {        
        this.needsToUpdateClient = false;
        this.showMessagePopUp(LABELS.SAVE_SUCCESS, [LABELS.CLIENT_UPDATE_SUCCESS])}   
      } 
  };

  constructor(private loginState : LoginState,
    private dialog : PopupOpenerService,
  ) {
    //this.checkLoginState();
  }

  checkLoginState(){
    if(!this.loginState.isLogged)
      return;
    
    if(!this.loginState.user?.esCliente){
      this.needsToUpdateClient = true;
      this.dialog.show(
        this.updateClientComponent
      );
    }
    else{
      this.loginState.getUserInfo();
    }
  }

  onCancel(){
    this.showMessagePopUp(LABELS.PERSONAL_INFO, [LABELS.UPDATE_REQUIRED], PopUpTypesEnum.warning);
  }

  showMessagePopUp(title : string, messages : string[], type : PopUpTypesEnum = PopUpTypesEnum.success){
    this.updateClientComponent ={
      component:PopupMessageComponent,
      config : {
        closable : true
      },
      data : {
        type : type,
        title : title,
        messages : messages
      }      
    };
    this.needsToUpdateClient=true;
    this.dialog.hide();
    this.dialog.show(this.updateClientComponent)
  }

}
