
<div class="detail-box">

  <div class="title-container">
    <div tabindex="0" class="item" (keypress)="emitClick(1)" (click)="emitClick(1)">
      <i class="fa-solid fa-file-lines"></i>
      <p class="font-family">{{titleText}}</p>
    </div>
    <div tabindex="0" class="item" (keypress)="emitClick(2)" (click)="emitClick(2)">
      <i class="fa fa-download"></i>
      <p class="font-family">{{download_documentation}}</p>
    </div>
    <div tabindex="0" class="item" (keypress)="emitClick(3)"  (click)="emitClick(3)">
      <i class="fa fa-info-circle"></i>
      <p class="font-family">{{carrier_data}}</p>
    </div>
  </div>

</div>
