import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { MatIconRegistry } from '@angular/material/icon';
import { DomSanitizer } from '@angular/platform-browser';

@Injectable({
  providedIn: 'root'
})
export class MaterialHelperService {

  private readonly icon_path = "../../../assets/icons/";


  constructor(private iconRegister : MatIconRegistry,
    private domSanitizer: DomSanitizer) {   
      this.registerIcon('google-logo', 'google-logo.svg');
      this.registerIcon('microsoft-logo', 'microsoft-logo.svg');      
      this.registerIcon('google-logo-color', 'google-logo-color.svg');
      this.registerIcon('microsoft-logo-color', 'microsoft-logo-color.svg');      
      this.registerIcon('email-unread', 'email-unread.svg')

  }


  registerIcon(name : string, fileName : string){ 
    this.iconRegister.addSvgIcon(name, this.domSanitizer.bypassSecurityTrustResourceUrl( this.icon_path + fileName) )
  }
}
