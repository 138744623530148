<full-cover-card [model]="coverCardModel" (backPressed)="navigateBack()" >
    <!--<div class=" pt-0 ps-4 h-100 m-auto text-start">-->                       
        <div class="col-12">

            <div class=" text-center">
                <p class="fs-3">{{title}}</p>
            </div>

        
            <div class=" col-12 pt-3 pb-1">
                <div class="col-8 col-md-6 justify-content-center m-auto">
                    <custom-input [model]="userInput" (inputFocusedOut)="onUserFocusOut()" (inputFocused)="hideErrors()"/>
                </div>

                <div *ngIf="showPasswordControl" 
                    class="col-8 col-md-6 d-flex flex-column  justify-content-center m-auto
                    password-animation">

                    <custom-input [model]="passwordInput" (keyPressed)="onKeyPress($event)" (iconClicked)="showOrHidePassword()" (inputFocused)="hideErrors()" />
                    <div class="col-12 text-center pb-3"><a tabindex="0" (keypress)="navigateTo('reset')"  (click)="navigateTo('reset')">{{forgot_password}}</a></div>
                    <div *ngIf="isCapsLockActive" class="text-red d-flex flex-column  justify-content-center m-auto">
                        {{capitals}}
                     </div>
                </div>

            </div>

            <div class=" col-12 text-center pt-2"> 
                <div *ngIf="couldNotLog" class="col-12 text-center col-md-6 m-auto">
                    <p class="text-danger">{{loginError}}</p>
                </div>

                <div class="col-8 col-md-6 m-auto" [ngClass]="{'password-animation':showPasswordControl}">
                    <custom-button [model]="btnBegin" (customClick)="continue()"/>
                </div>
            </div>

            <div *ngIf="!showPasswordControl"  class=" col-12 pt-4" >
                <div class="col-12 align-items-center"><p class="text-center between-lines-text">{{OR}}</p></div>
            <!--    <div class="col-12 text-center"><p>{{continue_with}}</p></div>-->
            </div>

            <!--<div *ngIf="!showPasswordControl" class="d-flex align-items-center text-center justify-content-center " >
                <div class="col-4 me-1 mb-2">
                    <custom-button [model]="btnLoginGoogle" (mouseenter)="onMouseInOut(false,true)" (mouseleave)="onMouseInOut(false, false)" (customClick)="loginWithProvider('google')"/>
                </div>                
                <div class="col-4 ms-1 mb-2 ">
                    <custom-button [model]="btnLoginMicrosoft" (mouseenter)="onMouseInOut(true ,true)" (mouseleave)="onMouseInOut(true, false)" (customClick)="loginWithProvider('microsoft')"/>
                </div>
            </div>-->

            <div *ngIf="!showPasswordControl" class=" pb-0" > 
                <p class="text-center pb-0">{{no_account}}</p>                
            </div>
            <div *ngIf="!showPasswordControl" class=" pt-0" > 
                <p class="text-center register-label pt-0"> <b tabindex="2" (keypress)="navigateTo('register')" (click)="navigateTo('register')">{{register}}</b></p>
            </div>
            

        </div>
    <!--</div>      -->


</full-cover-card>

<div style="position: absolute; bottom: 0; right: 1%; font-size: 0.35rem;">
    <p class="text-secondary">{{VERSION}}</p>
</div>