import { Component, OnDestroy, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { Subject, Subscription } from 'rxjs';
import { ButtonStylesEnum, ButtonTypesEnum } from 'src/app/main/constants/buttons.enum';
import { ERRORS } from 'src/app/main/constants/errors.enum';
import { ICONS } from 'src/app/main/constants/icons.enum';
import { InputTypesEnum } from 'src/app/main/constants/inputs-types.enum';
import { LABELS } from 'src/app/main/constants/labels.enum';
import { CodeValidationModel } from 'src/app/main/models/code-validation.model';
import { CoverCardModel } from 'src/app/main/models/cover-card.model';
import { IRegisterModel } from 'src/app/main/models/auth/register.model';
import { AuthenticatorService } from 'src/app/main/services/authenticator.service';
import { CrypterService } from 'src/app/main/services/crypter.service';
import { RedirectorService } from 'src/app/main/services/redirector.service';
import { ButtonModel } from 'src/app/shared/models/button.model';
import { DividedInputModel } from 'src/app/shared/models/divided-input.model';
import { ModelCreatorService } from 'src/app/shared/services/model-creator.service';

@Component({
  selector: 'app-validate',
  templateUrl: './validate.component.html',
  styleUrls: ['./validate.component.scss']
})
export class ValidateComponent implements OnInit, OnDestroy {
  
  
  readonly enter_code      = LABELS.PLEASE_ENTER_CODE;
  readonly code_sended     = LABELS.CODE_SENDED   
  readonly resend_code     = LABELS.RESEND_CODE;    
  readonly code_invalid    = ERRORS.CODE_INVALID;  
  readonly email_sended    = LABELS.EMAIL_SUCCESS  
  readonly code_unfilled   = ERRORS.CODE_UNFILLED;    
  readonly resend_failed   = LABELS.RESEND_CODE_FAILED;
  readonly success_message = LABELS.EMAIL_CONFIRMED;  
  readonly spam_text       = LABELS.CHECK_SPAM_MAILBOX;  

  user : CodeValidationModel | undefined;
  dividedInputModel? : DividedInputModel;
  btnNext : ButtonModel = new ButtonModel();
  btnBack : ButtonModel;
  validationForm : FormGroup;

  showMessage  = false;  
  message  = "";
  
  coverCardModel : CoverCardModel = {showBackButton : false, useDefaultBack : false };  

  queryParams$ : Subscription = new Subscription();
  
  interval : any;
  minutesLeft : number | undefined = 5;
  secondsLeft : number | undefined = 0;
  
  showTimer  = false;
  canSendCode  = false;

  constructor(
    private route : ActivatedRoute,
    private modelCreator : ModelCreatorService,
    private fb : FormBuilder,
    private auth : AuthenticatorService,
    private router  : Router,
    private crypter : CrypterService,
    private redirectorService: RedirectorService) {      

    this.validationForm = this.fb.group({
      code : this.fb.array([])
    })  

    this.btnBack =  this.modelCreator.createIconButtonModel('', ICONS.ARROW_BACK, false, 'rounded fs-6 flat-button text-dark p-0 pb-4 justify-content-start')      

  }

  ngOnInit(): void {

    this.dividedInputModel = this.modelCreator.createDividedInputModel('',InputTypesEnum.common, 'code', this.validationForm, this.code_unfilled, 5, 1)
    this.btnNext = this.modelCreator.createButtonModel(LABELS.CONTINUE, ButtonTypesEnum.Raised, ButtonStylesEnum.grey)

    this.setQueryParamsObs();
  }

  next(){    
    if(!this.user)
      return;

    this.dividedInputModel!.customError = this.code_unfilled;
    if(this.validationForm.invalid || !this.user)
      return;
    
    // Viene en formato [{value: '1'}]
    // Tengo que pasarlo a un formato "plano"
    let code = "" ;
    this.validationForm.value.code.forEach((input : any) => {
      code += input.value;
    });

    const form : CodeValidationModel = new CodeValidationModel(this.user, false);
    form.Code = code;

    this.auth.isCodeValid(form).subscribe({
      next : (data) => {                
        if(data && data.IsSuccess){
          const route = 'login';
          this.showErrorOrSuccessMessage(this.success_message, true, route);
          return;
        }      

        this.showErrorOrSuccessMessage(this.code_invalid, false);
        this.validationForm.reset();
      }
    
    , error : (err) => {
      this.showErrorOrSuccessMessage(this.code_invalid, false);      
    }});
  }

  showErrorOrSuccessMessage(message : string, hasToRedirect : boolean, route? : string){
    this.message = message;

    this.showMessage = true;
    setTimeout(() => {
      this.showMessage = false;
      if(hasToRedirect)
        this.router.navigate([route]);
    }, 3000);
  }

  setQueryParamsObs(){
    this.queryParams$ = this.route.queryParams.subscribe((params) => {      
      try{
        const routingData = JSON.parse(this.crypter.decrypt(params['data']));        
        this.user = routingData.user;
        this.showTimer = true;
        this.startTimer();

        this.canSendCode = !routingData.emailSended;
      }
      catch(ex){
        console.log(ex);
        this.user = undefined;
      }        
    });    
  }

  resendCode(){
    this.showTimer = true;
    if(!this.user?.Username || !this.canSendCode)
      return;

    this.auth.resendCode(this.user?.Username).subscribe({
      next : (data) => {
        if(data.IsSuccess)
          this.showErrorOrSuccessMessage(this.email_sended, false)
        else
          this.showErrorOrSuccessMessage(this.resend_failed, false)


        this.validationForm.reset();
        
      },
      error : () => {
        this.showErrorOrSuccessMessage(this.resend_failed, false);
        this.validationForm.reset();
      } ,
      complete : () => {
        this.resetTimer();
        this.showTimer = true;
        this.canSendCode = false;
        this.startTimer();        
      }   
    })  
  }

  resetTimer(){
    clearInterval(this.interval);
    this.minutesLeft = 4;
    this.secondsLeft = 59;
  }

  startTimer() {
    if(!this.secondsLeft)
      this.secondsLeft = 0;

    this.interval = setInterval(() => {
      if(this.secondsLeft! > 0) {
        this.secondsLeft!--;
      } else {
        this.minutesLeft!--;        
        this.secondsLeft = 59;
      }

      if(this.minutesLeft! <= 0 && this.secondsLeft == 0){
        this.canSendCode = true;   
      }

    },1000)
  }

  navigateBack(){
    this.redirectorService.redirectToLogin();  
  }

  ngOnDestroy(): void {
    this.queryParams$.unsubscribe();
  }

}
