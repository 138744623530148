import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { authenticationGuard } from 'src/app/main/guards/authentication.guard';
import { EmissionPersonalInfoComponent } from 'src/app/features/issue/emission-personal-info/emission-personal-info.component';
import { EmissionVehicleInfoComponent } from 'src/app/features/issue/emission-vehicle-info/emission-vehicle-info.component';
import { EmissionBeneficiariesComponent } from 'src/app/features/issue/emission-insured/emission-insured.component';
import { EmissionRiskLocationComponent } from 'src/app/features/issue/emission-risk-location/emission-risk-location.component';
import { EmissionCoveragesComponent } from 'src/app/features/issue/emission-coverages/emission-coverages.component';
import { EmissionSummaryComponent } from 'src/app/features/issue/emission-summary/emission-summary.component';
import { EmissionPaymentComponent } from 'src/app/features/issue/emission-payment/emission-payment.component';
import { EmissionFreeDataComponent } from 'src/app/features/issue/emission-free-data/emission-free-data.component';
import { BENEFICIARIES, CONFIRMATION, COVERAGES, DRIVER_LOCATION, FREE_DATA, ISSUE_BENEFICIARIES, ISSUE_COVERAGES, ISSUE_FREE_DATA, ISSUE_PAYMENT, ISSUE_RISK_LOCATION, ISSUE_SUMMARY, ISSUE_VEHICLE, MANAGER_LOCATION, PAYMENT, PERIODICITY, PERSONAL_DATA, RISK_LOCATION, SUMMARY, VEHICLE } from 'src/app/main/constants/routes.enum';
import { EmissionConfirmationComponent } from 'src/app/features/issue/emission-confirmation/emission-confirmation.component';
import { EmissionManagerInfoComponent } from 'src/app/features/issue/emission-manager/emission-manager.component';
import { EmissionDriverInfoComponent } from 'src/app/features/issue/emission-driver/emission-driver.component';
import { CreateRenewalComponent } from './create-renewal.component';
import { EmissionPeriodicityInfoComponent } from 'src/app/features/issue/emission-periodicity/emission-periodicity-info.component';




const routes: Routes = [  
  {
    path: '',
    component: CreateRenewalComponent,
    canLoad : [authenticationGuard],
    children: [
        {
          path: PERSONAL_DATA,
          component: EmissionPersonalInfoComponent,
          canLoad : []
        },
        {
          path: VEHICLE,
          component: EmissionVehicleInfoComponent,
          canLoad : []
        },
        {
          path: BENEFICIARIES,
          component: EmissionBeneficiariesComponent,
          canLoad : []
        },
        {
          path: RISK_LOCATION,
          component: EmissionRiskLocationComponent,
          canLoad : []
        },
        {
          path: MANAGER_LOCATION,
          component: EmissionManagerInfoComponent,
          canLoad : []
        },
        {
          path: DRIVER_LOCATION,
          component: EmissionDriverInfoComponent,
          canLoad : []
        },
        {
          path: COVERAGES,
          component: EmissionCoveragesComponent,
          canLoad : []
        },
        {
          path: SUMMARY,
          component: EmissionSummaryComponent,
          canLoad : []
        },
        {
          path: PAYMENT,
          component: EmissionPaymentComponent,
          canLoad : []
        },  
        {
            path: PERIODICITY,
            component: EmissionPeriodicityInfoComponent,
            canLoad : []
        },        
        {
          path: FREE_DATA,
          component: EmissionFreeDataComponent,
          canLoad : []
        },
        {
          path: CONFIRMATION,
          component: EmissionConfirmationComponent,
          canLoad : []
        }
    ]
  }
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class CreateRenewalRoutingModule { }
