import { Injectable } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { EmissionValidStepsEnum } from '../../models/emission/emission-valid-steps.enum';
import { QuotingVehicleValidStepsEnum } from '../../models/emission/quoting-vehicle-steps.enum';
import { QuotingResidentialValidStepsEnum } from '../../models/issue/quoting-residential-steps.enum';

@Injectable({
  providedIn: 'root'
})
export class QuotingFormHandlerService {

  form : FormGroup;
  private readonly valid_steps_vehicle = QuotingVehicleValidStepsEnum;
  private readonly valid_steps_residential = QuotingResidentialValidStepsEnum;
  constructor(private fb : FormBuilder) {
    this.form = fb.group({


    });
  }

  getForm(){
    return this.form;
  }

  getFormControl(step : QuotingVehicleValidStepsEnum){
    return this.form.controls[step];
  }

  getFormControlResident(step : QuotingResidentialValidStepsEnum){
    return this.form.controls[step];
  }

  createForm(hasVehicle? : boolean, hasResidential? : boolean,hasBenef? : boolean, hasHome? : boolean, hasCoverages? : boolean){
    //this.form.addControl(this.valid_steps.vehi, this.createPersonalDataForm());
    
    if(hasVehicle){
      this.form.addControl(this.valid_steps_vehicle.vehicleMexico, this.createVehicleMexForm());
      this.form.addControl(this.valid_steps_vehicle.insured_data_mex, this.createInsuredMexForm());
      this.form.addControl(this.valid_steps_vehicle.payment_data, this.createPaymentForm());
    }
    if(hasResidential){
      this.form.addControl(this.valid_steps_residential.risk_residence, this.createRiskForm());
      this.form.addControl(this.valid_steps_residential.protection_system, this.createProtectionSystemForm());
      this.form.addControl(this.valid_steps_residential.renewal, this.createRenewalResidentialForm());
    }

    // if(hasBenef)
    //   this.form.addControl(this.valid_steps.beneficiaries, this.createBeneficiariesForm());

    // if(hasHome)
    //   this.form.addControl(this.valid_steps.risk_location, this.createHomeForm());

    // if(hasCoverages)
    //   this.form.addControl(this.valid_steps.coverages, this.createAdditionalCoveragesForm());
    
   // this.form.addControl(this.valid_steps.payment_method, this.createPaymentForm());
  }

  createPersonalDataForm(){
    return this.fb.group({
      email : [''],
      telefono : [''],
      domicilio : ['']
    })
  }

  createVehicleForm(){
    return this.fb.group({
        patent    : ['',],
        chassis    : ['',[Validators.required]],
        year      : ['', [Validators.required]],
        isNew     : ['', [Validators.required]],
        make      : ['', [Validators.required]],
        model     : ['', [Validators.required]],
        version   : ['', [Validators.required]],
        isLoJack  : ['', [Validators.required]],
        zipCode   : ['',[Validators.required] ],
        isArmored : ['',[Validators.required] ],
        sumSecured: [''],
        departuraDate : ['',],
        tracker   : ['',],
        question  :[''],
        fiduciaryAlienation  :['',[Validators.required] ],
        financier   :[''],
        taxExemption   :['',[Validators.required]],
        exemptionReason   :[''],
        benefitStartDate   :[''],
        typeUse   :[''],
        is0Km   :[''],
    })
  }
  createInsuredForm(){
    return this.fb.group({
      nameInsured      : ['', [Validators.required]],
      surnameInsured   : ['', [Validators.required]],
      cpf              : ['', [Validators.required]],
      phone            : ['', [Validators.required]],
      phoneArea         : ['', [Validators.required]],

      civilStatus      : ['', [Validators.required]],
      gender           : ['', [Validators.required]],
      birthdate        : ['',[Validators.required]],
      disclaimer       : ['',[Validators.required]],
    })
  }

  createRenewalForm(){
    return this.fb.group({
      policyStartDate  :[''],
      policyEndDate  :[''],
      budget  :['',[Validators.required]],
      claim   :[''],
      susepCode   :[''],
      renewalPolicyNumber   :[''],
      renewalPolicyId   :[''],
      bonus   :[''],
      isReplacement   :[''],
    })
  }
  createOwnerForm(){
    return this.fb.group({
      isOwner         : ['', [Validators.required]],
      disclaimer       : ['',],
      nameOwner       : ['',],
      surnameOwner       : ['',],
      isDriver        : ['', ],
      birthdate       : ['', ],
      cpf             : ['', ],
      typeDocument    : ['',],
      gradeRelationship   : ['',],
      gender          : ['', ],
      civilStatus     : ['',],
    })
  }
  createDriverForm(){
    return this.fb.group({
      isDriver            : ['', [Validators.required]],
      disclaimer          : ['',],
      nameDriver          : ['', ],
      surnameDriver       : ['', ],
      cpf                 : ['', ],
      gradeRelationship   : ['',],
      typeDocument        : ['',],
      civilStatus         : ['', ],
      gender              : ['', ],
      birthdate           : ['',],
      genderElder         :['',],
      isResident          : ['', ],
      isCoverageResidente : ['', ],
    })
  }

  createProtectionSystemForm(){
    return this.fb.group({
      tieneAlarma : [''],
      cercaElectronicas : [''],
      inmuebleConBarandilla : [''],
    })
  }

  createRenewalResidentialForm(){
    return this.fb.group({
      esRenovacion : [''],
      codigoSupep : [''],
      numeroPoliza : [''],
      siniestro : [''],
    })
  }

  createRiskForm(){
    return this.fb.group({
      domicilio : [''],
      calle : [''],
      numero : [''],
      pisodepto : [''],
      codigoPostal : [''],
      distrito : [''],
      ciudad : [''],
      idCiudad: [''],
      estado : [''],
      region : [''],
      tipoUso:[''],
      tipoPropiedad:[''],
      tipoConstruccion:[''],
      tipoActividad:[''],
      propietarioAsegurado:[''],
      esPatrimonioPublico:[''],
      esPropiedadDesocupada:[''],
      maderaParedes:[''],
    })
  }

  createBeneficiariesForm(){
    return this.fb.group({
      nombre : [''],
      apellido : [''],
      tipoDocumento : [''],
      nroDocumento : [''],
      fechaNacimiento : [''],
      domicilio : [''],
      telefono : [''],
      email : [''],
      parentesco : [''],
      porcentaje : [''],
    })
  }

  createHomeForm(){
    return this.fb.group({
      calle : [''],
      numero : [''],
      pisodepto : [''],
      codigoPostal : [''],
      barrio : [''],
      ciudad : [''],
      estado : [''],
    })
  }

  createAdditionalCoveragesForm(){
    return this.fb.group({
      coberturasAdicionales : [],

    })
  }

  createPaymentForm(){
    return this.fb.group({
      periodicidad : [],
    })
  }

  createFreeDataForm(){
    return this.fb.group({
      email : [''],
      telefono : [''],
      domicilio : ['']
    })  
  }

  createInsuredMexForm(){
    return this.fb.group({
      zipCode            : ['', [Validators.required]],
      zipCodeDriver            : ['',],
      isDriver      : ['',[Validators.required]],
      gender           : ['',],
      driverGender           : ['',],
      civilStatus           : ['',],
      driverCivilStatus           : ['',],
      birthdate        : ['',],
      driverBirthdate        : ['',],
    })
  }

  createVehicleMexForm(){
    return this.fb.group({
        year      : ['', [Validators.required]],
        make      : ['', [Validators.required]],
        model     : ['', [Validators.required]],
        version   : ['', [Validators.required]],
        NroPolizaAnterior   : ['', ],
        salvage   : ['', ],
        isNew   : ['', ],
        invoice   : ['', ],
        invoiceDate   : ['', ],
        invoiceAmount   : ['', ],
    })
  }
  
}
