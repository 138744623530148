import { Component, Injector, Input, OnDestroy, OnInit } from '@angular/core';
import { IProductsTypeModel } from '../../../main/models/products-type.model';
import { LABELS } from 'src/app/main/constants/labels.enum';
import { ConsultantService } from 'src/app/main/services/consultant.service';
import { ERRORS } from 'src/app/main/constants/errors.enum';
import { Unary } from '@angular/compiler';
import { RedirectorService } from 'src/app/main/services/redirector.service';
import { QuotingInfoState } from 'src/app/state/models/quoting-info.state';
import { IssueState } from 'src/app/state/issue/issue.state';
import { States } from 'src/app/state/models/states.enum';
import { Subscription } from 'rxjs';
import { PopupOpenerService } from 'src/app/shared/services/popup-opener.service';
import { PopUpTypesEnum } from 'src/app/shared/enums/popup-types.enum';
import { PopupMessageComponent } from 'src/app/shared/popup-message/popup-message.component';

@Component({
  selector: 'app-products-list',
  templateUrl: './products-list.component.html',
  styleUrls: ['./products-list.component.scss']
})
export class ProductsListComponent implements OnInit, OnDestroy{
  @Input() fullBoard:boolean|undefined;
  products : IProductsTypeModel[] |undefined ;
  ShowAllProducts=false;
  title:string |undefined;
  consultantError:string|undefined;
  showconsultantError=false;
  showAll:boolean|undefined;
  componentData:any;

  quotingState$ : Subscription = new Subscription();
  quotingState1$ : Subscription = new Subscription();
  productId  = -1;

  constructor(private consultantProposal: ConsultantService, private redirectorService: RedirectorService,
    private issueState: IssueState,
    private dialog : PopupOpenerService) {   
  }

ngOnInit(): void {
  this.showAll=false;
  this.title=LABELS.PRODUCTS_TITLE;

  this.subscribeToQuotingState();

  this.consultantProposal.listBoardProducts().subscribe({
    next  : (data) => {
      if(data.IsSuccess){
        this.products=data.Result;
        if(this.products!.length >= 5)
          this.products!.splice(5, 0, { Id: 6, Descripcion: '', Nombre: "VER MÁS", Logo: "see_more" });
        if(this.fullBoard==undefined || this.fullBoard==null)
          this.ShowAllProducts=true;
        return;
      }   
    },
    error : (err) => {
      this.showError();

    }
  })

}

  showError(msg  = ""){
  this.consultantError = ERRORS.COULD_NOT_REGISTER;
  if(msg)
    this.consultantError = msg;   
  this.showconsultantError = true;
  }

  showMessagePopUp(title : string, messages : string[], type : PopUpTypesEnum = PopUpTypesEnum.warning){    
      const component ={
        component:PopupMessageComponent,
        config : {
          closable : true,          
        },
        data : {
          type : type,
          title : title,
          messages : messages
        }      
      };
      this.dialog.hide();
      setTimeout(() => {this.dialog.show(component)})
  }

  subscribeToQuotingState(){
    this.quotingState$ = this.issueState.checkState().subscribe({
      next : ({quotingState}) => {
        
        if(quotingState.state === States.loading)
          return;          

        if(this.productId === -1)
          return;
        
        if(quotingState.state === States.success){                    
          if(quotingState.quotingInfo?.EsMascota){
            this.redirectorService.redirectToPetPricing();
          } 
          else if(quotingState.quotingInfo?.EsDental){
            this.showMessagePopUp(LABELS.COMING_SOON, [LABELS.WORKING_FOR_YOUR_EXPERIENCE]);
            //this.redirectorService.redirectToDentalPricing();            
          }             
          else if(quotingState.quotingInfo?.EsVehiculo){
            this.issueState.fullState.quoteState.goToPlans=false;
            this.redirectorService.redirectToProposalVehicle();
          }
          else if(quotingState.quotingInfo?.EsResidencial){
            this.showMessagePopUp(LABELS.COMING_SOON, [LABELS.WORKING_FOR_YOUR_EXPERIENCE]);
            //this.redirectorService.redirectToProposalResidential();            
          }     
          else{
            this.showMessagePopUp(LABELS.COMING_SOON, [LABELS.WORKING_FOR_YOUR_EXPERIENCE]);
          }          
        }
        //this.quotingState$.unsubscribe();
      }
    })      
  }



  
  selectProduct(product:IProductsTypeModel,index:any){
    if(index===5 && !this.ShowAllProducts){
      this.componentData={
        component:ProductsListComponent,
        filtros:this.products
      };
      this.showAll=true;
    }
    
    this.productId = product.Id;
    this.issueState.clearQuoteAndPriceState();

    //if(!product.Habilitado){
    //  this.showMessagePopUp(LABELS.COMING_SOON, [LABELS.WORKING_FOR_YOUR_EXPERIENCE]);
    //  return;
    //}

    this.issueState.getQuotingInfo(this.productId);
  }

  filterProducts(): IProductsTypeModel[] {
    if (this.ShowAllProducts) {
      return this.products?.filter((_, index) => index !== 5) || [];
    } else {
      return this.products?.slice(0, 6) || [];
    }
  }

  onCancel() {
    this.showAll=false;
  }
  ngOnDestroy(): void {
    this.quotingState$.unsubscribe();
  }
}
