<div class="m-auto d-flex flex-row flex-wrap justify-items-center align-items-start align-content-start">
    <div class="w-100 px-2" *ngIf="prices && priceConfig">
        <app-pricing-list 
            [prices]="prices"
            [config]="priceConfig"
            [pricingId]="pricingId" 
            (optionClicked)="onOptionClick($event)" 
            (planChecked)="onCheckPlan($event)"
            (continueClicked)="continue()">
        </app-pricing-list>
    </div>
</div>
