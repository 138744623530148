<grid-card [model]="model"
    [titles]= "titles"
    [columns]="['TipoPoliza', 'Compania', 'Solicitud','PremioFormat','Estado']"
    [smSizes]="[2,2,2,2,2]"
    [hideClasses]="[false,true,true,false,true,false]"
    (detailClicked)="detail($event)">
</grid-card>
<proposal-detail [model]="model" *ngIf="showDetail"></proposal-detail>

<custom-popup *ngIf="showDownloads"
[show]="true" 
[hiddenHeader]="true"
[hiddenButtons]="true"
[maxSize]="false"
[title]="titleDownload" 
[componentData]="componentData"
(dismissed)="onCancel()">
</custom-popup>