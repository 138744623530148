import { Component, ElementRef, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { DomSanitizer } from '@angular/platform-browser';
import { Subscription } from 'rxjs';
import { ButtonStylesEnum, ButtonTypesEnum } from 'src/app/main/constants/buttons.enum';
import { ERRORS } from 'src/app/main/constants/errors.enum';
import { ICONS } from 'src/app/main/constants/icons.enum';
import { InputTypesEnum } from 'src/app/main/constants/inputs-types.enum';
import { LABELS } from 'src/app/main/constants/labels.enum';
import { IPaymentInfo } from 'src/app/main/models/emission/payment-info.model';
import { ConsultantService } from 'src/app/main/services/consultant.service';
import { RedirectorService } from 'src/app/main/services/redirector.service';
import { UrlCleanerService } from 'src/app/main/services/url-cleaner.service';
import { PopUpTypesEnum } from 'src/app/shared/enums/popup-types.enum';
import { ButtonModel } from 'src/app/shared/models/button.model';
import { InputModel } from 'src/app/shared/models/input.model';
import { PopupMessageComponent } from 'src/app/shared/popup-message/popup-message.component';
import { ModelCreatorService } from 'src/app/shared/services/model-creator.service';
import { PopupOpenerService } from 'src/app/shared/services/popup-opener.service';
import { IssueState } from 'src/app/state/issue/issue.state';
import { States } from 'src/app/state/models/states.enum';

@Component({
  selector: 'app-emission-payment',
  templateUrl: './emission-payment.component.html',
  styleUrls: ['./emission-payment.component.scss']
})
export class EmissionPaymentComponent implements OnInit, OnDestroy {

  @ViewChild('payment_frame', { static: true }) iframe?: ElementRef;

  readonly loading_payment = LABELS.LOADING_PAYMENT;
  readonly loading_download = LABELS.LOADING_DOWNLOAD;
  readonly loading_download_external = LABELS.LOADING_DOWNLOAD_EXTERNAL;
  readonly loading_download1 = LABELS.LOADING_DOWNLOAD1;
  readonly policy_message = LABELS.POLICY_MESSAGE;
  popupData : any;
  isLoading  = true;

  safeUrl : any;
  urlGangway:any;
  externalGangway : any;
  popupSub : Subscription | undefined = new Subscription();
  issue$ : Subscription = new Subscription();
  currentOrigin  = "";
  nroSol : number | undefined;
  btnExternalGangway = new ButtonModel();
  btnDownload = new ButtonModel();
  btnContinue = new ButtonModel();
  isGnp=false

  isDownload=false
  formPayment: FormGroup |undefined;
  numberPolicy? : InputModel;
  constructor(private domSanitizer : DomSanitizer,
    private redirector : RedirectorService,
    private popupOpener : PopupOpenerService,
    private issue_state : IssueState,
    private urlCleaner : UrlCleanerService,
    private modelCreator : ModelCreatorService,
    private consultantProposal:ConsultantService,
    private fb: FormBuilder,
  ) {
    this.formPayment = this.fb.group({
      policy: ['', Validators.required] 
    });
    this.numberPolicy = this.modelCreator.createInputModel('', InputTypesEnum.common, 'policy', this.formPayment,undefined,undefined,true,50);
    this.safeUrl = this.domSanitizer.bypassSecurityTrustResourceUrl('');
    this.btnExternalGangway = this.modelCreator.createButtonModel("Ir a Pasarela de Pago", ButtonTypesEnum.Common, ButtonStylesEnum.black);
    this.btnDownload = this.modelCreator.createButtonModel("Descargar póliza", ButtonTypesEnum.Common, ButtonStylesEnum.black);
    this.btnContinue = this.modelCreator.createButtonModel("Continuar", ButtonTypesEnum.Common, ButtonStylesEnum.black);

    this.issue$ = issue_state.checkState().subscribe({
      next : ({quotingState, emissionState,emissionDataState}) => {
        if(emissionState.state !== States.success)
          return;

        this.isGnp=emissionDataState.EmissionInfo?.IdCompania==80;
        const url = emissionState.emissionResult?.Result.urlPasarela
        this.urlGangway=emissionState.emissionResult?.Result.urlPasarela
        this.externalGangway=emissionState.emissionResult?.Result.pasarelaExterna
        if(url){
          this.safeUrl = this.domSanitizer.bypassSecurityTrustResourceUrl(url); 
          this.currentOrigin = this.urlCleaner.getOriginFromUrl(url)
          this.nroSol = emissionState.emissionResult?.Id;
        }
        
      }


    })
    
  }

  ngOnInit() {
    window.addEventListener('message', this.receiveMessage.bind(this), false);
  }

  handler(request : any){
    console.log(request);
  if (request.readyState === 'DONE') {
    if (request.status === 200) {
      // this.response is a Blob, because we set responseType above
      const data_url = URL.createObjectURL(request.response);      
      //document.querySelector('#output-frame-id').src = data_url;
    } else {
      console.error('no pdf :(');
    }
  }
  }


  
  receiveMessage(event: MessageEvent) {
    if(event.origin !== this.currentOrigin && !this.issue_state.fullState.quotingState.quotingInfo?.EsVehiculo)
      return;

    if(!event.data)
      return;
    if (event.data.eventName === 'EmissionOk') {
      this.showSuccessPopup({NroSol : this.nroSol, DatosExtra : null});
    }
    // if(event.data.sub_id){
    //   // es petlove    
    //   this.showSuccessPopup({NroSol: this.nroSol, DatosExtra: [{Id : event.data.sub_id, Descripcion : "subId"}] });
    //   return;
    // }
    // else{
    //   const eventData = JSON.parse(event.data);
    //   if (eventData && eventData.eventName === "LibertyResultCardPostMessage" && eventData.eventValue) {
    //     this.showSuccessPopup({NroSol : this.nroSol, DatosExtra : null});
    //   }
    //   return;
    // }



  }

  showSuccessPopup(paymentInfo : IPaymentInfo){
    this.issue$.unsubscribe();
    this.issue_state.setPaymentInfo(paymentInfo);
    const request={
      Id:this.nroSol,
      Descripcion:''
    }
    this.consultantProposal.updatePolicy(request).subscribe({
      next  : (data) => {
        if(data.IsSuccess){
          this.redirector.redirectToProposalConfirmation()
          return;
        }  
      },
      error : (err) => {
        return;
      }
    })

  }

  onCloseSuccess(){
    
    this.popupSub = this.popupOpener.getOnCloseObs()?.subscribe({
      next: (ev) => {
          this.popupOpener.hide();          
          this.redirector.redirectToProposalList();          
          this.popupSub?.unsubscribe();
      }

  })
    
  }

  updatePolicy(){
    const numberPolicy = this.formPayment?.value.policy
    if(numberPolicy==""||numberPolicy==undefined){
      this.formPayment!.get('policy')!.markAsTouched();
      return
    }
    const request={
      Id:this.nroSol,
      Descripcion:numberPolicy
    }
    this.consultantProposal.updatePolicy(request).subscribe({
      next  : (data) => {
        if(data.IsSuccess){
          this.isDownload=true
          return;
        }  
      },
      error : (err) => {
        return;
      }
    })
  }

  requestDocument(): void {
    const requestObject = {
      NroPoliza: this.nroSol,
      TipoDocumento: '1'
    };
    this.consultantProposal.downloadDocuments(requestObject).subscribe({
      next: (response: any) => this.handleDocumentResponse(response),
      error: (err) => console.error('Error:', err)
    });
  }
  showMessagePopUp(title : string, messages : string[], type : PopUpTypesEnum = PopUpTypesEnum.success){
    this.popupData={
      component:PopupMessageComponent,
      config : {
        title : title,
        hideHeader : true
      },
      
      data : {
        type : type,
        messages : messages,
        button:true
      }
    };
    this.popupOpener.show(this.popupData)
  }
  private handleDocumentResponse(response: any): void {
    if (response.IsSuccess) {
      const byteCharacters = atob(response.Result);
      const byteNumbers = new Array(byteCharacters.length);
      for (let i = 0; i < byteCharacters.length; i++) {
        byteNumbers[i] = byteCharacters.charCodeAt(i);
      }
      const byteArray = new Uint8Array(byteNumbers);

      const blob = new Blob([byteArray], { type: 'application/pdf' });

      if (blob.size === 0) {
        return;
      }

      const url = window.URL.createObjectURL(blob);

      const a = document.createElement('a');
      a.href = url;
      a.download = response.Message;
      document.body.appendChild(a);
      a.click();

      window.open(url);
      window.URL.revokeObjectURL(url);
      this.showMessagePopUp('', ['Documento descargado exitosamente.', '', ''], PopUpTypesEnum.success);
    }
    else{
      this.popupOpener.show({
        component : PopupMessageComponent,
        config: {closable : true, closeOnEscape : true},
        data : {
            type  : PopUpTypesEnum.warning,
            icon : ICONS.WARNING,
            messages : [ERRORS.FAIL_DOCUMENT_DOWNLOAD]
        }
      })
    }
  }
  onIframeLoad(ev : any){    
    setTimeout(() => {
      this.isLoading = false  
    }, 30000);    
  }

  ngOnDestroy(): void {
    this.issue$.unsubscribe();
    this.popupSub?.unsubscribe();
    window.removeEventListener("message", this.receiveMessage, true);
  }
}
