import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from "@angular/core";
import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import { Subscription } from "rxjs";
import { ButtonStylesEnum, ButtonTypesEnum } from "src/app/main/constants/buttons.enum";
import { LABELS } from "src/app/main/constants/labels.enum";
import { QuotingInfoModel } from "src/app/main/models/emission/quoting-info.model";
import { QuotingResidentialValidStepsEnum } from "src/app/main/models/issue/quoting-residential-steps.enum";
import { ISelectItem } from "src/app/main/models/select-item.model";
import { ConsultantService } from "src/app/main/services/consultant.service";
import { LocatorService } from "src/app/main/services/issue/locator.service";
import { QuotingFormHandlerService } from "src/app/main/services/issue/quoting-form-handler.service";
import { ButtonModel } from "src/app/shared/models/button.model";
import { DropdownCustomModel } from "src/app/shared/models/dropdown.model";
import { ModelCreatorService } from "src/app/shared/services/model-creator.service";
import { IssueState } from "src/app/state/issue/issue.state";
import { LoginState } from "src/app/state/login/login.state";
import { States } from "src/app/state/models/states.enum";

@Component({
    selector: 'issue-risk',
    templateUrl: './issue-risk.component.html',
    styleUrls: ['./issue-risk.component.scss']
  })
  export class IssueRiskComponent implements OnDestroy{
    @Input() parentGroup: FormGroup |undefined;
    @Output() completedClicked = new EventEmitter<any>();
    @Output() backClicked = new EventEmitter<any>();

 
    btnContinue : ButtonModel|undefined;
    btnReturn : ButtonModel|undefined;

    isOwnerInsured :string|undefined;
    publicAssets :string|undefined;
    vacantProperty :string|undefined;
    woodWalls :string|undefined;
    riskForm: FormGroup |undefined;

    propertyTypeSelected:string|undefined;
    selectPropertyType: ISelectItem[] |undefined;
    propertyType? : DropdownCustomModel;

    typeUseSelected:string|undefined;
    selectTypeUse: ISelectItem[] |undefined;
    typeUseSelect? : DropdownCustomModel;

    typeConstructionSelected:string|undefined;
    selectConstruction: ISelectItem[] |undefined;
    constructionSelect? : DropdownCustomModel;

    typeActivitySelected:string|undefined;
    selectActivity: ISelectItem[] |undefined;
    activitySelect? : DropdownCustomModel;

    title=LABELS.RISK_DATA;
    steps: number[] = [0, 1,2];
    currentStep = 0;

    quotingInfo? : QuotingInfoModel;
    quotingInfoSub : Subscription = new Subscription();

    regions? : any[];

    readonly type_use = LABELS.TYPE_USE
    readonly yes=LABELS.QUESTION_ISNEW_YES
    readonly no=LABELS.QUESTION_ISNEW_NO
    readonly birthdate_label=LABELS.BITHDATE
    readonly gender = LABELS.SELECT_GENDER
    readonly data_insured=LABELS.VEHICLE_DATA_INSURED

    readonly type_activity= LABELS.TYPE_ACTIVITY
    readonly type_construction= LABELS.TYPE_CONSTRUCTION
    readonly type_use_residential= LABELS.TYPE_USE_RESIDENTIAL
    readonly type_property= LABELS.TYPE_PROPERTY
    readonly owner_insured=LABELS.OWNER_INSURED
    readonly public_assets=LABELS.PUBLIC_ASSETS
    readonly vacant_property=LABELS.VACANT_PROPERTY
    readonly wood_walls=LABELS.WOOD_WALLS

    constructor(private fb : FormBuilder,private modelCreator : ModelCreatorService,private consultantProposal:ConsultantService, 
      private issue_state : IssueState,
      private formHandler : QuotingFormHandlerService,
      private loginState:LoginState,
      private locatorSvc : LocatorService){
      this.riskForm = this.formHandler.getFormControlResident(QuotingResidentialValidStepsEnum.risk_residence) as FormGroup;

      this.loadQuotingInfo();
      this.loadDataBack();
    }


    loadData( quotingInfo : QuotingInfoModel){{
    
      this.loadInputs();
    }}
    loadInputs(){
        this.activitySelect = this.modelCreator.createSelectModel('tipoActividad', LABELS.SELECT, [{'id': 1, 'descripcion': 'Actividad 1 '},{'id': 2, 'descripcion': 'Actividad 2'}], this.riskForm!,'','',true,false,false,LABELS.SELECT_ERROR);
        this.constructionSelect = this.modelCreator.createSelectModel('tipoConstruccion', LABELS.SELECT, [{'id': 1, 'descripcion': 'Ladrillo'},{'id': 2, 'descripcion': 'Cemento'}], this.riskForm!,'','',true,false,false,LABELS.SELECT_ERROR);
        this.typeUseSelect = this.modelCreator.createSelectModel('tipoUso', LABELS.SELECT, [{'id': 1, 'descripcion': 'Doméstico'},{'id': 2, 'descripcion': 'Trabajo'}], this.riskForm!,'','',true,false,false,LABELS.SELECT_ERROR);
        this.propertyType = this.modelCreator.createSelectModel('tipoPropiedad',  LABELS.SELECT,[{'id': 1, 'descripcion': 'Casa'},{'id': 2, 'descripcion': 'Depto'}], this.riskForm!,'','',true,false,false,LABELS.SELECT_ERROR);
        this.btnContinue = this.modelCreator.createButtonModel(LABELS.CONTINUE_ISSUE, ButtonTypesEnum.Raised, ButtonStylesEnum.black);
        this.btnReturn = this.modelCreator.createButtonModel(LABELS.RETURN_ISSUE, ButtonTypesEnum.Raised, ButtonStylesEnum.without_white_outline);
    }
    loadDataBack(){
      return;
    }
    loadQuotingInfo() {
      this.quotingInfoSub = this.issue_state.checkState().subscribe({
        next :(state) => {
          const quotingState = state.quotingState;
          if(quotingState.state === States.success){
            if(!this.regions){
                this.getRegions();           
              }
            this.quotingInfo = quotingState.quotingInfo;
            this.loadData(this.quotingInfo!);
            
          }
        }      
      })
    }
    
    navigateStep(step:any){
        this.currentStep=step;
    }

    validatePropertyType(){   
      
      const propertyTypeControl = this.riskForm!.get('tipoPropiedad');
      const propertyType =propertyTypeControl!.value;
      if(propertyType!="")
          this.propertyTypeSelected= propertyType.descripcion;
    }
    validateTypeUse(){
    const typeUseControl = this.riskForm!.get('tipoUso');
    const typeUse =typeUseControl!.value;
    if(typeUse!="")
        this.typeUseSelected= typeUse.descripcion;
    }
    validateTypeConstruction(){
        const typeConstructionControl = this.riskForm!.get('tipoConstruccion');
        const typeConstruction =typeConstructionControl!.value;
        if(typeConstruction!="")
            this.typeConstructionSelected= typeConstruction.descripcion;
    }

    validateActivity(){
      const activityControl = this.riskForm!.get('tipoActividad');
      const activity =activityControl!.value;
      if(activity!="")
          this.typeActivitySelected= activity.descripcion;
    }

    validateQuestion(value: any) {
      this.riskForm!.get('propietarioAsegurado')?.setValue(value);
      this.isOwnerInsured = value.toString();
    }

    validateHeritage(value: any) {
      this.riskForm!.get('esPatrimonioPublico')?.setValue(value);
      this.publicAssets = value.toString();
    }

    validateVacantProperty(value: any) {
      this.riskForm!.get('esPropiedadDesocupada')?.setValue(value);
      this.vacantProperty = value.toString();
    }

    validateWoodWalls(value: any) {
      this.riskForm!.get('maderaParedes')?.setValue(value);
      this.woodWalls = value.toString();
    }

    validateStep0(){
      const zipCodeDriverControl=this.riskForm?.get('codigoPostal')
      return zipCodeDriverControl?.value=="" ? this.riskForm!.markAllAsTouched() :  this.currentStep++;
    }

    validateStep1(){
        this.propertyTypeSelected==""  || this.typeUseSelected=="" || this.typeConstructionSelected=="" ? this.riskForm?.markAllAsTouched : this.currentStep++;    
      }
    validateStep2(){
      this.typeActivitySelected=="" || this.isOwnerInsured=="" ? this.riskForm?.markAllAsTouched : this.currentStep++;    
    }
    validateStep3(){
      this.publicAssets=="" || this.vacantProperty==""  || this.woodWalls==""? this.riskForm?.markAllAsTouched : this.completedClicked.emit();    
    }
      
    
    next(){
      if(this.currentStep==0){
        this.validateStep0()
        return
      }
      if(this.currentStep==1){
        this.validateStep1()
        return
      }
      if(this.currentStep==2){
        this.validateStep2()
        return
      }
      if(this.currentStep==3){
        this.validateStep3()
        return
      }
    }

    return(){
  if(this.currentStep === 0){
        this.backClicked.emit();
      }

      if(this.currentStep >0){
        this.currentStep--;
      }
    }




    getRegions(){
        this.locatorSvc.getRegions().subscribe({
          next : (data : any) =>{ 
            if(data.Result){

              this.regions = data.Result.map((e : any) => {
                return {id : e.Id, descripcion : e.Descripcion}
              })
            }
          }
        })
      }
    ngOnDestroy(): void {
      this.quotingInfoSub.unsubscribe();
    }
  }
