import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { DashboardComponent } from './dashboard.component';
import { SharedModule } from 'src/app/shared/shared.module';
import { ProductsListComponent } from 'src/app/features/dashboard/products-list/products-list.component';
import { InsuranceAdComponent } from 'src/app/features/dashboard/insurance-ad/insurance-ad.component';
import { InsuranceRecommendationComponent } from 'src/app/features/dashboard/insurance-recommendation/insurance-recommendation.component';
import { ProposalCardComponent } from 'src/app/features/dashboard/proposal-card/proposal-card.component';
import { ProposalListComponent } from 'src/app/features/dashboard/proposal-list/proposal-list.component';
import { ProposalDetailComponent } from 'src/app/features/dashboard/proposal-detail/proposal-detail.component';
import { GridCardComponent } from 'src/app/features/dashboard/grid-card/grid-card.component';
import { ProposalCoveragesComponent } from 'src/app/features/dashboard/proposal-coverages/proposal-coverages.component';
import { RenewalListComponent } from 'src/app/features/dashboard/renewal-list/renewal-list.component';
import { RenewalTableComponent } from 'src/app/features/dashboard/renewal-table/renewal-table.component';
import { DetailProspectComponent } from 'src/app/features/dashboard/detail-prospect/detail-prospect.component';
import { EmissionListComponent } from 'src/app/features/dashboard/emission-list/emission-list.component';



@NgModule({
  declarations: [
    DashboardComponent,
    ProductsListComponent,
    InsuranceAdComponent,
    InsuranceRecommendationComponent,
    ProposalCardComponent,
    ProposalListComponent,
    ProposalDetailComponent,
    GridCardComponent,
    ProposalCoveragesComponent,
    RenewalListComponent,
    RenewalTableComponent,
    DetailProspectComponent,
    EmissionListComponent
  ],
  imports: [
    CommonModule,
    SharedModule
  ]
})
export class DashboardModule { }
