<div class="col-12 align-items-center d-flex flex-column">
    <div class="col-12 col-md-8 pb-4">
        <p class="fw-medium fs-4">{{title}}</p>
        <!--<p class="fw-light fs-6">{{subtitle}}</p>-->
    </div>

    <div class="col-12 col-md-8 py-2"><custom-input [model]="nameInput!"></custom-input></div>
    <div class="col-12 col-md-8 py-2"><custom-input [model]="lastNameInput!"></custom-input></div>
    <div class="col-12 col-md-8 py-2"><custom-input [model]="maternaLastNameInput!"></custom-input></div>
    <div class="col-12 col-md-8 py-2"><custom-input [model]="documentInput!"></custom-input></div>
    <!--<div class="col-12 col-md-8 py-2" *ngIf="showFullInfo"><custom-select [model]="documentType"></custom-select></div> -->
    <!--<div class="col-12 col-md-8 py-2" *ngIf="showFullInfo"><custom-select [model]="personType"></custom-select></div>-->
    <div class="col-12 col-md-8 py-3" *ngIf="showFullInfo"><custom-select [model]="gender"></custom-select></div>
    <div class="col-12 col-md-8 py-3" *ngIf="showFullInfo"><custom-select [model]="civilStatus"></custom-select></div>
    <!--<div class="col-12 col-md-8 py-2" *ngIf="showFullInfo"><custom-select [model]="country"></custom-select></div>-->
    <div class="col-12 col-md-8 py-2" *ngIf="showFullInfo"><app-input-datetime-custom [model]="birthDateInput"></app-input-datetime-custom></div>        
    
    <div *ngIf="!showFullInfo" class="col-12 col-md-8 py-2 d-flex" [formGroup]="userForm">
        <div class="align-content-start pe-2">
            <p-checkbox [id]="'disclaimer'" (onChange)="enableOrDisableSave()" [binary]="true" [formControlName]="'disclaimer'"></p-checkbox>
        </div>        
        <div class="smallFont">
            <label for="'disclaimer'" [innerHTML]="chk_lbl"> </label>
            <!-- <a class="text-dark" target="_blank" href="https://www.aon.com/brasil/politica-de-privacidade.jsp">{{chk_link}}</a> -->
        </div>
        
    </div>

    <div class="col-12 col-md-8 pt-4 mt-4 d-flex justify-content-end">
        <custom-button *ngIf="showFullInfo" class="me-2" [model]="deleteButton" (customClick)="deleteClient()"></custom-button>
        <custom-button *ngIf="showFullInfo && !enableButton" [model]="editButton" (customClick)="disableButton(false)"></custom-button>
        <custom-button *ngIf="enableButton" [model]="saveButton" (customClick)="saveUser()"></custom-button>
    </div>
    
    
    
</div>
