export class GenericErrorResponse{
    error  = "";
    statusCode : number | undefined = 0 ;
    url  = "";

    constructor(obj: any) {
        this.statusCode = obj.status;
        this.error = obj.error;
        this.url = obj.url;        
    }
}