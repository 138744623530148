import { Component, OnDestroy } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { Subscription } from 'rxjs';
import { ButtonStylesEnum, ButtonTypesEnum } from 'src/app/main/constants/buttons.enum';
import { ERRORS } from 'src/app/main/constants/errors.enum';
import { InputTypesEnum } from 'src/app/main/constants/inputs-types.enum';
import { LABELS } from 'src/app/main/constants/labels.enum';
import { IDomicile } from 'src/app/main/models/domicile.model';
import { EmissionDataInfoModel } from 'src/app/main/models/emission/emission-info-data.model';
import { EmissionValidStepsEnum } from 'src/app/main/models/emission/emission-valid-steps.enum';
import { QuotingInfoModel } from 'src/app/main/models/emission/quoting-info.model';
import { ISelectItem } from 'src/app/main/models/select-item.model';
import { ConsultantService } from 'src/app/main/services/consultant.service';
import { EmissionFormHandlerService } from 'src/app/main/services/issue/emission-form-handler.service';
import { Masks } from 'src/app/shared/enums/masks.enum';
import { InputDateTimeModel } from 'src/app/shared/input-datetime-custom/model/input-datetime-model';
import { ButtonModel } from 'src/app/shared/models/button.model';
import { DropdownCustomModel } from 'src/app/shared/models/dropdown.model';
import { InputModel } from 'src/app/shared/models/input.model';
import { ModelCreatorService } from 'src/app/shared/services/model-creator.service';
import { AppState } from 'src/app/state/app/app.state';
import { IssueState } from 'src/app/state/issue/issue.state';
import { LoginState } from 'src/app/state/login/login.state';
import { States } from 'src/app/state/models/states.enum';

@Component({
  selector: 'emission-driver',
  templateUrl: './emission-driver.component.html',
  styleUrls: ['./emission-driver.component.scss']
})
export class EmissionDriverInfoComponent implements OnDestroy{

  personalDriverInfoForm : FormGroup;
  personalInfoForm : FormGroup;
  continueBtn : ButtonModel|undefined;
  btnReturn : ButtonModel|undefined;
  title = LABELS.MANAGER_DATA_TITLE;
  typePersonSelect? : DropdownCustomModel;
  listOcupaciones: ISelectItem[] ;
  ocupacion?                  : DropdownCustomModel;
  nameModel? : InputModel;

  paternalSurname? : InputModel;
  maternalSurname? : InputModel;
  rfc? : InputModel;


  selectTypePerson: ISelectItem[] |undefined;
  checkLbl = LABELS.ARE_YOU_POLITCALLY_EXPOSED;
  chk_lbl : string = LABELS.LEGAL_DATA_DISCLAIMER;
  isPet  = false;
  isDental  = false;

  issue$ = new Subscription();
  steps: number[] = [0, 1, 2];
  currentStep = 0;
  lastStep=false

  emissionDataInfo? : EmissionDataInfoModel;
  readonly yes = LABELS.QUESTION_ISNEW_YES
  readonly no=LABELS.QUESTION_ISNEW_NO

  readonly relationship_with_owner=LABELS.RELATIONSHIP_WITH_OWNER
  readonly type_person=LABELS.TYPE_PERSON
  regions? : any[];

  quotingInfo? : QuotingInfoModel;
  quotingInfoSub : Subscription = new Subscription();
  constructor(private modelCreator : ModelCreatorService,
    private formHandler : EmissionFormHandlerService,
    private issueState : IssueState,
    private loginState : LoginState,    
    private consultantProposal: ConsultantService
  ) {    
    this.listOcupaciones=[]
    this.personalInfoForm = this.formHandler.getFormControl(EmissionValidStepsEnum.personal_data) as FormGroup;
    this.personalDriverInfoForm = this.formHandler.getFormControl(EmissionValidStepsEnum.driver_data) as FormGroup;
    //this.personalInfoForm!.controls['responsableEsTitular'].setValue(1);
    this.loadQuotingInfo();
    this.createInputs();
    //this.patchValuesFromState();
  }


  loadQuotingInfo() {
    this.quotingInfoSub = this.issueState.checkState().subscribe({
      next :({quotingState, pricingState,emissionDataState}) => {
        if(pricingState.state !== States.success || quotingState.state !== States.success)
          return;

        this.quotingInfo = quotingState.quotingInfo;
        if(!this.regions){
          this.regions = quotingState.quotingInfo?.Regiones ?? [];
        }

        const insuredData = pricingState.initialRequest;
        const personalData=this.personalInfoForm.value;
        if(insuredData.Asegurado?.EsConductor && insuredData.Asegurado?.TipoPersona=='1'){
          const formValue = {
            nombres : personalData.nombres,
            apellidoPaterno:personalData.apellidoPaterno,
            apellidoMaterno:personalData.apellidoMaterno,
            rfc :  personalData.rfc,
            tipoPersona:this.personalInfoForm.get('tipoPersona')?.value
          }
          this.personalDriverInfoForm.patchValue(formValue);
        }
        this.loadData(this.quotingInfo!,insuredData.Asegurado);  

        if(emissionDataState.state===States.success){
          this.emissionDataInfo = emissionDataState.EmissionInfo;
          this.loadDataEmission(this.emissionDataInfo!);    
        }
      }      
    })
  }


  loadData(quotingInfo : QuotingInfoModel,insured:any){
    this.selectTypePerson = quotingInfo.TipoPersonas.map(status => ({
      id: status.Id,
      descripcion: status.Descripcion,
    }));
    const typePersonControl = this.personalDriverInfoForm!.get('tipoPersona');
    if(insured?.EsConductor && insured?.TipoPersona =='1'){
      typePersonControl!.patchValue({id:typePersonControl?.value.id.toString(),descripcion:this.selectTypePerson?.find(a=>a.id==typePersonControl?.value.id.toString())?.descripcion?.toString()});
      this.paternalSurname!.disabled=true
      this.maternalSurname!.disabled=true
      this.nameModel!.disabled=true
      this.rfc!.disabled=true
    }
    else{
      typePersonControl!.patchValue({id:'1',descripcion:this.selectTypePerson?.find(a=>a.id=='1')?.descripcion?.toString()});
      this.paternalSurname!.disabled=false
      this.maternalSurname!.disabled=false
      this.nameModel!.disabled=false
      this.rfc!.disabled=false
      //this.typePersonSelect!.disabled=false
    }

    this.typePersonSelect = this.modelCreator.createSelectModel('tipoPersona', LABELS.SELECT,this.selectTypePerson, this.personalDriverInfoForm!,'','',true,false,false,LABELS.SELECT_ERROR);
    this.typePersonSelect.outsideLabel=this.type_person
  }

  loadDataEmission(emissionDataInfo : EmissionDataInfoModel){
    this.listOcupaciones = emissionDataInfo.OcupacionConductor != null ? emissionDataInfo?.OcupacionConductor?.map(r => ({
      id: r.Id,
      descripcion: r.Descripcion,
    })):[];

    if(this.listOcupaciones!.length > 0){
      this.ocupacion = this.modelCreator.createSelectModel('ocupacion',LABELS.SELECT, this.listOcupaciones!,this.personalDriverInfoForm!, LABELS.USE, '', true,false,false,LABELS.SELECT_ERROR);
      this.ocupacion.outsideLabel=LABELS.OCCUPATION
    }
  }

  createInputs(){

    this.btnReturn = this.modelCreator.createButtonModel(LABELS.RETURN_ISSUE, ButtonTypesEnum.Raised, ButtonStylesEnum.without_white_outline);
    this.continueBtn = this.modelCreator.createButtonModel(LABELS.CONTINUE, ButtonTypesEnum.Common, ButtonStylesEnum.black);

    this.rfc = this.modelCreator.createInputModel(LABELS.DOCUMENT_ID, InputTypesEnum.common, 'rfc', this.personalDriverInfoForm,undefined,undefined,true,15);
    this.nameModel = this.modelCreator.createInputModel(LABELS.FULL_NAME, InputTypesEnum.common, 'nombres', this.personalDriverInfoForm,undefined,undefined,true,30);
    this.maternalSurname = this.modelCreator.createInputModel('Apellido materno', InputTypesEnum.common, 'apellidoMaterno', this.personalDriverInfoForm,undefined,undefined,true,30);
    this.paternalSurname = this.modelCreator.createInputModel('Apellido paterno', InputTypesEnum.common, 'apellidoPaterno', this.personalDriverInfoForm,undefined,undefined,true,30);
    
  }

    validateStep0(){{
        const typePerson = this.personalDriverInfoForm!.get('tipoPersona')?.value;
        const rfc = this.personalDriverInfoForm!.get('rfc')?.value;
        const names = this.personalDriverInfoForm!.get('nombres')?.value;
        if(typePerson=="")
          this.personalDriverInfoForm!.get('tipoPersona')?.markAllAsTouched();
        if(rfc=="")
          this.personalDriverInfoForm!.get('rfc')?.markAllAsTouched();
        if(names=="")
          this.personalDriverInfoForm!.get('numeroInterno')?.markAllAsTouched();
        const paternalSurname = this.personalDriverInfoForm!.get('apellidoPaterno')?.value;
        const maternalSurname = this.personalDriverInfoForm!.get('apellidoMaterno')?.value;
        const occupation = this.personalDriverInfoForm!.get('ocupacion')?.value;
        if(paternalSurname=="")
          this.personalDriverInfoForm!.get('apellidoPaterno')?.markAllAsTouched();
        if(maternalSurname=="")
          this.personalDriverInfoForm!.get('apellidoMaterno')?.markAllAsTouched();
        if(occupation=="" && this.listOcupaciones.length>0){
          this.personalDriverInfoForm!.get('ocupacion')?.markAllAsTouched();
          return
        }
        return typePerson!="" && rfc!="" && names!="" && maternalSurname!="" && paternalSurname!="" ? this.consultantProposal.notifyNextStep() : false;
    }}

    validateStep1(){{
        const paternalSurname = this.personalDriverInfoForm!.get('apellidoPaterno')?.value;
        const maternalSurname = this.personalDriverInfoForm!.get('apellidoMaterno')?.value;
        const occupation = this.personalDriverInfoForm!.get('ocupacion')?.value;
        if(paternalSurname=="")
          this.personalDriverInfoForm!.get('apellidoPaterno')?.markAllAsTouched();
        if(maternalSurname=="")
          this.personalDriverInfoForm!.get('apellidoMaterno')?.markAllAsTouched();
        if(occupation=="" && this.listOcupaciones.length>0){
          this.personalDriverInfoForm!.get('ocupacion')?.markAllAsTouched();
          return
        }
        return maternalSurname!="" && paternalSurname!="" ? this.consultantProposal.notifyNextStep() : false;
    }}

    next(){
        switch (this.currentStep) {
        case 0:
            this.validateStep0();
            break; 
        case 1:
            this.validateStep1();
            break;     
        default:
            break;
        }
    }

    return(){
      if(this.currentStep > 0)
        this.currentStep--
      else
        this.consultantProposal.notifyPreviousStep()
      }
    ngOnDestroy(): void {
        this.issue$.unsubscribe();
        this.quotingInfoSub.unsubscribe();
    }
}
