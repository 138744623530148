<div class="h-100 w-100 text-center">
    <img [alt]="'Cancel Icon'" [src]="'/assets/icons/' + 'cruz.svg'" 
    class="d-block m-auto error-icon"    
    width="25%"         
    height="auto"
    style="max-width: 5em;" >
    
    <h2>{{cancel_question}}</h2>

    <p>{{cancellation_warning1}}</p>
    <p>{{cancellation_warning2}}</p>

    <p class="mt-2">{{number_proposal_cancel}} {{proposal}}</p>

    <div class="select-container">
        <label for="motivo-select">{{select_reason}}</label>
        <custom-select id="motivo-select" [model]="reasonSelect"></custom-select>
    </div>

    <div class="col-sm-2 offset-sm-5">
        <custom-button [model]="btnContinue!" (customClick)="showNotAvailablePopUp()"></custom-button>
    </div>
</div>