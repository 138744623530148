
<div class="stepper-container row">
    <app-custom-stepper [currentStep]="currentStep" [steps]="steps" class="custom-stepper"></app-custom-stepper>
  </div>
  
<div class="w-100">    
    <p class="fw-medium fs-5">{{title}}</p>

    <div class="" *ngIf="(currentStep ?? 0)===0">
        <div class="col-12">
            <custom-select [model]="typePersonSelect!"></custom-select>
        </div>

        <div class="py-2">
            <custom-input [model]="rfc!"></custom-input>
        </div>

        <div class="py-2">
            <custom-input [model]="nameModel!"></custom-input>
        </div>

        <div class="py-2">
            <custom-input [model]="paternalSurname!"></custom-input>
        </div>

        <div class="py-2">
            <custom-input [model]="maternalSurname!"></custom-input>
        </div>

        <div class="col-12" *ngIf="listOcupaciones!.length > 0">
            <custom-select [model]="ocupacion!"></custom-select>
        </div>
    </div>
    <div class="" *ngIf="(currentStep ?? -1)===1">        
    </div>
    
    <div class="row mb-4 mt-2">
        <custom-button [model]="btnReturn!" (customClick)="return()" class="col-sm-6 mt-2"></custom-button>
        <custom-button [model]="continueBtn!" (customClick)="next()" class="col-sm-6 mt-2"></custom-button>
    </div>  
</div>


