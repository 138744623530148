import { Injectable } from "@angular/core";
import { BehaviorSubject, Observable } from "rxjs";
import { QuotingInsuranceInfoState } from "../models/quoting-insurance-info.state";
import { States } from "../models/states.enum";
import { environment } from "src/environments/environment.development";
import { IGenericResponseModel } from "src/app/main/models/generic-response.model";
import { HttpClient } from "@angular/common/http";

@Injectable()
export class IssueInsuranceState {
  private readonly base_url_quoting = environment.quoting_url;
  private quotingInsuranceInfo$: BehaviorSubject<QuotingInsuranceInfoState> = new BehaviorSubject<QuotingInsuranceInfoState>({
    state: States.empty,
    quotingInsuranceInfo: undefined,
  });

  constructor(private http : HttpClient) {}

  getQuotingInfo$(): Observable<QuotingInsuranceInfoState> {
    return this.quotingInsuranceInfo$.asObservable();
  }

  getCurrentQuotingInfo(): QuotingInsuranceInfoState {
    return this.quotingInsuranceInfo$.getValue();
  }

  getQuotingInfo(quotingId: number): void {

    this.quote(quotingId).subscribe(
      (response: IGenericResponseModel) => {
        if (response.IsSuccess) {
          const quotingInfoState: QuotingInsuranceInfoState = {
            state: States.success,
            quotingInsuranceInfo: response.Result
          };
          this.quotingInsuranceInfo$.next(quotingInfoState);
        } else {
          // Manejar el caso en que la respuesta no sea exitosa
          const errorState: QuotingInsuranceInfoState = {
            state: States.error,
            quotingInsuranceInfo: undefined
          };
          this.quotingInsuranceInfo$.next(errorState);
        }
      },
      (_) => {
        // Manejar errores de la solicitud HTTP
        const errorState: QuotingInsuranceInfoState = {
          state: States.error,
          quotingInsuranceInfo: undefined
        };
        this.quotingInsuranceInfo$.next(errorState);
      }
    );
  }

  quote(data: any) {
    return this.http.post<IGenericResponseModel>(this.base_url_quoting + '/ObtenerDatosCotizacion', parseInt(data));
  }


}
