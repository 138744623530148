import { Component, HostListener } from '@angular/core';
import { ActivatedRoute, NavigationEnd, NavigationStart, Router } from '@angular/router';
import { Subscription, filter } from 'rxjs';

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.scss']
})
export class HomeComponent {
  menuOpen=false;
  // smSizeMenu:string='0';
  // smSizeBody:string='10';
  questId = 0;
  readonly url_partnership = 'partnership';
  readonly url_tips= 'tips';
  readonly url_quotes= 'quotes';
  readonly url_tip_pattern = /tip\?idTip=\d+/;
  showQuestions  = true;
  route$ = new Subscription();
  isSmall = this.isSmallScreen();
  smSizeMenu = this.isSmall ? '0' : '3';  // Tamaño inicial según pantalla
  smSizeBody = this.isSmall ? '12' : '9'; // Ajusta el body al tamaño
  constructor(private route : Router) {
    this.route$ = route.events.pipe(filter(event => event instanceof NavigationEnd)).subscribe({
      next : (route : any) => {
        const a = route.url.search(this.url_partnership);
        this.showQuestions = route.url.search(this.url_partnership) === -1 && route.url.search(this.url_quotes) === -1 && route.url.search(this.url_tips) === -1 && !this.url_tip_pattern.test(route.url);
        this.scrollToTop();
      }
    })
  }
   
  @HostListener('window:resize', ['$event'])
  onResize() {
    this.isSmall = this.isSmallScreen(); // Actualiza el estado

    if (this.isSmall) {
      this.smSizeMenu = this.menuOpen ? '12' : '0';
      this.smSizeBody = this.menuOpen ? '10' : '12';
    } else {
      this.smSizeMenu = this.menuOpen ? '3' : '2';
      this.smSizeBody = this.menuOpen ? '9' : '11';
    }
  }
    
  isSmallScreen(): boolean {
    return window.innerWidth < 576; // 576px es el límite para pantallas pequeñas en Bootstrap
  }

  toggleMenu() {
    this.menuOpen = !this.menuOpen;

    if (this.isSmall) {
      this.smSizeMenu = this.menuOpen ? '12' : '0';
      this.smSizeBody = this.menuOpen ? '10' : '12'; 
    } else {
      this.smSizeMenu = this.menuOpen ? '2' : '2';
      this.smSizeBody = this.menuOpen ? '9' : '11'; 
    }
  }

  scrollToTop(){    
    setTimeout(() => {
      const container = document.getElementById('home-container');
      if(container)
        container.scrollIntoView({behavior: 'smooth'});  
    }, 0);
  }

}
