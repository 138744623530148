<div class="position-absolute d-flex justify-content-lg-start justify-content-center w-100 super-container">
    <div class="d-flex banner-container gap-3">
        <div *ngIf="enterpriseId" class="banner-size bg-white rounded-bottom align-items-end d-flex p-1">
            <img alt="company-logo" class="img-sizing " [src]="'../../../assets/companies/' + enterpriseId +'.png'"/>
        </div>
        
        <div class="banner-size rounded-bottom align-items-end d-flex p-2">
            <img alt="aon-logo" class="img-sizing" src="../../../assets/aon-logo-white.svg">
        </div>
    </div>    
            
</div>