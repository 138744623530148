import { Component} from '@angular/core';
import { ICONS } from 'src/app/main/constants/icons.enum';
import { PopUpTypesEnum } from '../enums/popup-types.enum';
import { PopupOpenerService } from '../services/popup-opener.service';
import { ModelCreatorService } from '../services/model-creator.service';
import { LABELS } from 'src/app/main/constants/labels.enum';
import { ButtonTypesEnum } from '../enums/buttons.enum';
import { ButtonStylesEnum } from 'src/app/main/constants/buttons.enum';
import { ButtonModel } from '../models/button.model';

@Component({
  selector: 'app-popup-message',
  templateUrl: './popup-message.component.html',
  styleUrls: ['./popup-message.component.scss']
})
export class PopupMessageComponent {

  title? : string;
  icon? : string = ICONS.WARNING;
  type? : PopUpTypesEnum;
  button? : boolean;
  buttonStyle : ButtonStylesEnum = ButtonStylesEnum.black;
  messages? : string[];
  btnContinue : ButtonModel|undefined;

  onAccept : () => void | undefined;

  pop_icons : any[] = [
    {id : PopUpTypesEnum.success.toString(), icon :  ICONS.SUCCES_SVG },
    {id : PopUpTypesEnum.warning.toString(), icon :  ICONS.WARNING_SVG },    
    {id : PopUpTypesEnum.error.toString(), icon :  ICONS.ERROR_SVG }  
  ]

  constructor(private popSvc : PopupOpenerService,private modelCreator : ModelCreatorService,) {    
    const data = this.popSvc.getData();
    this.title = data.title ?? '';
    this.icon = data.icon ?? ICONS.WARNING;
    this.onAccept = data.onAccept;
    this.type = data.type;
    this.button=data.button
    
    this.btnContinue = data.buttonModel;
    if(!this.btnContinue)
      this.btnContinue = this.modelCreator.createButtonModel(LABELS.ACCEPT, ButtonTypesEnum.Raised, this.buttonStyle);
    
    this.messages = data.messages ?? [];
    
  }

  findIcon(){
    return this.pop_icons.find(i => i.id === this.type?.toString())?.icon ?? ICONS.WARNING_SVG;
  }
  onClick(){
    if(this.onAccept)
      this.onAccept.call(this);
    else
      this.popSvc.hide();
  }
}
