import { LABELS } from "src/app/main/constants/labels.enum";
import { ISelectItem } from "src/app/main/models/select-item.model";
import { MultiOption } from "./multi-option.model";

export class PricingListConfigModel {
    showFilters? : boolean = false;
    title : string = LABELS.TITLE_ISSUE_PLANS;
    subtitle  = '';    
    secondSubtitle  = '';    
    showBackBtn  = true;

    // mascotas
    allowMultiPlanSelection  = false; 
    multiOptions? : MultiOption[];
    showFranquicia  = true;

    filters? : PricesFilterModel[] = []
}

export class PricesFilterModel{
    id? : string;
    descripcion  = "";
    field  = "";
    opciones : FilterOption[] = [];      
}

export class FilterOption{
    id? : string
    descripcion? : string;
    value? : string;
}