export class ERRORS {
   static CONTROL_UNFILLED = 'Por favor, preencha o campo para continuar';
   static EMAIL_INVALID = 'Por favor, digite um endereço de e-mail válido';
   static EMAIL_NOT_REGISTERED = 'Seu e-mail não está registrado';
   static REDIRECT_TO_REGISTER = 'Nós o redirecionaremos para se juntar a nós!';
   static EMAIL_NULLED = 'Seu acesso não é permitido. Entre em contato com o administrador para obter mais informações.';

   static UNAUTHORIZED = 'Não foi possível verificar sua identidade, tente novamente.';
   static PASSWORD_UNFILLED = 'Por favor, preencha a senha.';
   static PASSWORD_UNCONFIRMED = 'Por favor, confirme sua senha.';
   static PASSWORD_DOES_NOT_MATCH = 'As senhas não correspondem';
   static NAME_INVALID = 'Por favor, digite um nome válido';
   static LAST_NAME_INVALID = 'Por favor, digite um apellido válido';
   static WRONG_RECOVER_INFO = 'Desculpe, não foi possível recuperar sua senha.';

   static COULD_NOT_RESET_MAIL = 'Ocorreu um problema ao enviar o e-mail... Mas não se preocupe, tente novamente mais tarde!';
   static COULD_NOT_REGISTER = 'Ocorreu um problema durante o registro... Mas não se preocupe, tente novamente mais tarde!';  
   static CODE_UNFILLED = 'Por favor, preencha o código';
   static CODE_INVALID = 'Não foi possível validar seu código, tente novamente.';

   static EMAIL_ALREADY_REGISTERED = 'Seu e-mail já está registrado! Vá para o login para começar.';
   static EMAIL_NEEDS_VALIDATION = 'Seu e-mail já está registrado! Só precisamos que você o valide! Aguarde um momento...';
   static EMAIL_NEEDS_RECOVER = 'Sua conta está em recuperação! Verifique sua caixa de correio para continuar...';

   static BLOCKED_USER = 'Para sua segurança, decidimos bloquear sua conta... Verifique sua caixa de entrada para continuar';
   static NO_PROPOSALS ='Não encontramos nenhum seguro associado';

   static PATENT_INVALID = 'Por favor digite uma placa';
   static NAME_OWNER_INVALID = 'Por favor digite um nome';
   static CPF_OWNER_INVALID = 'Por favor digite um CPF';
   static NAME_DRIVER_INVALID = 'Por favor digite um nome';
   static CPF_DRIVER_INVALID = 'Por favor digite um CPF';
   static CPF_PHONE_INVALID='Introduzir um número de telefone com 8 ou 9 caracteres';

   static MAX_PET_QUANTITY = 'O número máximo de animais de estimação possíveis foi atingido. Não é possível adicionar mais, desculpe!';
   static ZIPCODE_INVALID = 'Por favor, insira um CEP';
   static INPUT_INVALID = 'Por favor, insira um valor';
   static CHASSIS_INVALID = 'Por favor introduza o chassis';
   static SURNAME_OWNER_INVALID = 'Por favor digite um apellido';
   static COULD_NOT_EMIT = 'Ocorreu um erro ao emitir a sua apólice, tente novamente mais tarde.';
   static ARMING_INVALID = 'Por favor, insira um valor da blindado.';

   static REGION_DIFFERENCE = 'O código postal é de uma região diferente da utilizada na cotação.';
   static COULD_NOT_DELETE_CLIENT = 'Os seus dados não podem ser excluídos. Lembre-se de que não os pode apagar se tiver apólices.';
   static INVALID_LINK = 'Inicie a sessão com o link fornecido pela sua empresa.';
   static INVALID_REGISTER_LINK = 'Para se registar, deve entrar através do link fornecido pela sua empresa.';
   static COULD_NOT_UPDATE_CLIENT = 'Não foi possível atualizar os seus dados, tente novamente mais tarde.';
    
   static ENGINE_NUMBER = 'Por favor, indique el número de motor';
   static SERIAL_NUMBER = 'Por favor, indique el número de serie';
   static REPUVE_INVALID = 'Por favor, indique su REPUVE';
   static FAIL_DOCUMENT_DOWNLOAD= 'No se puede descargar el documento..';

   static PATERNAL_SURNAME = 'Por favor, indique su apellido paterno';
   static MATERNAL_SURNAME = 'Por favor, indique su apellido materno';
   static INVALID_PHONE = 'Por favor, indique su número de teléfono';

   static CAPITALS_ACTIVE = 'Tiene las letras mayúsculas activas';
}
