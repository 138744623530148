import { Component, Input } from '@angular/core';

@Component({
  selector: 'app-brands-banner',
  templateUrl: './brands-banner.component.html',
  styleUrls: ['./brands-banner.component.scss']
})
export class BrandsBannerComponent {

  @Input() enterpriseId : number | undefined = 1;


}
