import { Component, OnDestroy } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { debug } from 'node:console';
import { readFile } from 'node:fs';
import { Subscription } from 'rxjs';
import { ButtonStylesEnum, ButtonTypesEnum } from 'src/app/main/constants/buttons.enum';
import { ERRORS } from 'src/app/main/constants/errors.enum';
import { InputTypesEnum } from 'src/app/main/constants/inputs-types.enum';
import { LABELS } from 'src/app/main/constants/labels.enum';
import { IDomicile } from 'src/app/main/models/domicile.model';
import { EmissionDataInfoModel } from 'src/app/main/models/emission/emission-info-data.model';
import { EmissionValidStepsEnum } from 'src/app/main/models/emission/emission-valid-steps.enum';
import { QuotingInfoModel } from 'src/app/main/models/emission/quoting-info.model';
import { ISelectItem } from 'src/app/main/models/select-item.model';
import { ConsultantService } from 'src/app/main/services/consultant.service';
import { EmissionFormHandlerService } from 'src/app/main/services/issue/emission-form-handler.service';
import { Masks } from 'src/app/shared/enums/masks.enum';
import { InputDateTimeModel } from 'src/app/shared/input-datetime-custom/model/input-datetime-model';
import { ButtonModel } from 'src/app/shared/models/button.model';
import { DropdownCustomModel } from 'src/app/shared/models/dropdown.model';
import { InputModel } from 'src/app/shared/models/input.model';
import { ModelCreatorService } from 'src/app/shared/services/model-creator.service';
import { AppState } from 'src/app/state/app/app.state';
import { IssueState } from 'src/app/state/issue/issue.state';
import { LoginState } from 'src/app/state/login/login.state';
import { States } from 'src/app/state/models/states.enum';

@Component({
  selector: 'emission-personal-info',
  templateUrl: './emission-personal-info.component.html',
  styleUrls: ['./emission-personal-info.component.scss']
})
export class EmissionPersonalInfoComponent implements OnDestroy{
  continueBtn : ButtonModel|undefined;
  btnReturn : ButtonModel|undefined;
  personalInfoForm : FormGroup;

  title = LABELS.PERSONAL_DATA_TITLE;

  nameModel? : InputModel;
  phoneModel? : InputModel;
  emailModel? : InputModel;
  genderModel? : DropdownCustomModel;
  civilStatusModel? : DropdownCustomModel;
  birthdateModel? : InputDateTimeModel;
  companyNameModel? : InputModel;

  
  disabledCp  = false;
  typePerson?                  : DropdownCustomModel;
  rfc? : InputModel;
  paternalSurname? : InputModel;
  maternalSurname? : InputModel;
  occupation?                  : DropdownCustomModel;
  estado?                  : DropdownCustomModel;
  municipio?                  : DropdownCustomModel;
  colonia?                  : DropdownCustomModel;
  street?                  : DropdownCustomModel;
  turn?                  : DropdownCustomModel;
  typeCompany?                  : DropdownCustomModel;

  selectTypePerson: ISelectItem[] |undefined;
  regimenesSocietarios?                  : DropdownCustomModel;
  regimenesFiscales?                  : DropdownCustomModel;
  ocupacion?                  : DropdownCustomModel;
  giro?                  : DropdownCustomModel;
  listRegimenesSocietarios: ISelectItem[] ;
  listRegimenesFiscales: ISelectItem[] ;
  listRegimenesFiscalesFisica: ISelectItem[] ;
  listRegimenesFiscalesMoral: ISelectItem[] ;
  listOcupacion: ISelectItem[] ;
  listGiro: ISelectItem[];
  checkLbl = LABELS.ARE_YOU_POLITCALLY_EXPOSED;
  chk_lbl : string = LABELS.LEGAL_DATA_DISCLAIMER;
  readonly type_person=LABELS.TYPE_PERSON
  isPet  = false;
  isDental  = false;


  steps: number[] = [0, 1, 2,3];
  currentStep = 0;
  lastStep=false
  issue$ = new Subscription();
  regions? : any[];
  quotingInfo? : QuotingInfoModel;
  emissionDataInfo? : EmissionDataInfoModel;
  quotingInfoSub : Subscription = new Subscription();
  constructor(private modelCreator : ModelCreatorService,
    private formHandler : EmissionFormHandlerService,
    private issueState : IssueState,
    private loginState : LoginState,    
    private consultantProposal: ConsultantService
  ) {    
    this.listRegimenesSocietarios=[]
    this.listRegimenesFiscalesMoral=[]
    this.listRegimenesFiscalesFisica=[]
    this.listRegimenesFiscales=[]
    this.listOcupacion=[]
    this.listGiro=[]

    this.personalInfoForm = this.formHandler.getFormControl(EmissionValidStepsEnum.personal_data) as FormGroup;
    this.createInputs();
    this.patchValuesFromState();
    this.loadQuotingInfo();
  }

  createInputs(){
    this.btnReturn = this.modelCreator.createButtonModel(LABELS.RETURN_ISSUE, ButtonTypesEnum.Raised, ButtonStylesEnum.without_white_outline);
    this.continueBtn = this.modelCreator.createButtonModel(LABELS.CONTINUE, ButtonTypesEnum.Common, ButtonStylesEnum.black);
    this.genderModel = this.modelCreator.createSelectModel('genero',LABELS.GENDER, [], this.personalInfoForm, LABELS.GENDER, '', false);
    this.civilStatusModel = this.modelCreator.createSelectModel('estadoCivil', LABELS.SELECT, [], this.personalInfoForm!,LABELS.CIVIL_STATUS,'',false);

    this.genderModel.outsideLabel = LABELS.GENDER;
    this.civilStatusModel.outsideLabel = LABELS.CIVIL_STATUS;
    
    this.birthdateModel = this.modelCreator.createCalendarModel('fechaNacimientoConstitucion', this.personalInfoForm, true,LABELS.BIRTHDATE, false,LABELS.DATE_OF_BIRTH_CONSTITUTION);
    this.phoneModel = this.modelCreator.createInputModel(LABELS.PHONE, InputTypesEnum.phone, 'telefono', this.personalInfoForm, ERRORS.CONTROL_UNFILLED);
    this.emailModel = this.modelCreator.createInputModel(LABELS.EMAIL, InputTypesEnum.email, 'email', this.personalInfoForm, ERRORS.CONTROL_UNFILLED, undefined, true);
    //this.cpfModel = this.modelCreator.createInputModel(LABELS.DOCUMENT_ID, InputTypesEnum.mask, 'cpf', this.personalInfoForm!, ERRORS.CPF_OWNER_INVALID,undefined,false,14,false,false,Masks.cpf);
    //this.socialNameModel = this.modelCreator.createInputModel(LABELS.SOCIAL_NAME, InputTypesEnum.common, 'nombreSocial', this.personalInfoForm, undefined, undefined, false);

    
    this.rfc = this.modelCreator.createInputModel(LABELS.DOCUMENT_ID, InputTypesEnum.common, 'rfc', this.personalInfoForm,ERRORS.CPF_OWNER_INVALID,undefined,true,13);
    this.nameModel = this.modelCreator.createInputModel(LABELS.FULL_NAME, InputTypesEnum.common, 'nombres', this.personalInfoForm,ERRORS.NAME_OWNER_INVALID,undefined,true,30);
    this.paternalSurname = this.modelCreator.createInputModel(LABELS.SURNAME_PATERNAL, InputTypesEnum.common, 'apellidoPaterno', this.personalInfoForm,ERRORS.PATERNAL_SURNAME,undefined,true,30);
    this.maternalSurname = this.modelCreator.createInputModel(LABELS.SURNAME_MATERNAL, InputTypesEnum.common, 'apellidoMaterno', this.personalInfoForm,ERRORS.MATERNAL_SURNAME,undefined,true,30);
    // this.occupation = this.modelCreator.createSelectModel('ocupacion',LABELS.SELECT, [{'id': 1, 'descripcion': 'Abogado'},{'id': 2, 'descripcion': 'Profesor'},{'id': 3, 'descripcion': 'Otro'}],this.personalInfoForm!, LABELS.USE, '', false,false,false,LABELS.SELECT_ERROR);
    // this.occupation.outsideLabel='Ocupación'
    //this.turn = this.modelCreator.createSelectModel('giro',LABELS.SELECT, [{'id': 1, 'descripcion': 'Giro 1'},{'id': 2, 'descripcion': 'Giro 2'},{'id': 3, 'descripcion': 'Otro'}],this.personalInfoForm!, LABELS.USE, '', true,false,false,LABELS.SELECT_ERROR);
    //this.turn.outsideLabel='Giro'
    //this.estado = this.modelCreator.createSelectModel('estado',LABELS.SELECT, [{'id': 1, 'descripcion': 'Yucatán'},{'id': 2, 'descripcion': 'Nuevo León'},{'id': 3, 'descripcion': 'Jalisco'}],this.personalInfoForm!, LABELS.USE, '', true,false,false,LABELS.SELECT_ERROR);
    // this.typeCompany = this.modelCreator.createSelectModel('tipoSociedad',LABELS.SELECT, [{'id': 1, 'descripcion': 'privada'},{'id': 2, 'descripcion': 'pública'},{'id': 3, 'descripcion': 'Otro'}],this.personalInfoForm!, LABELS.USE, '', false,false,false,LABELS.SELECT_ERROR);
    // this.typeCompany.outsideLabel=LABELS.COMPANY_TYPE
    this.street = this.modelCreator.createInputModel(LABELS.STREET, InputTypesEnum.common, 'calle', this.personalInfoForm,undefined,undefined,false,20);
    this.companyNameModel = this.modelCreator.createInputModel(LABELS.COMPANY_NAME, InputTypesEnum.common, 'razonSocial', this.personalInfoForm,undefined,undefined,false,20);
    //this.nameMotherModel = this.modelCreator.createInputModel(LABELS.NAME_MOTHER, InputTypesEnum.common, 'nombreMadreAsegurado', this.personalInfoForm);
    // this.salaryModel = this.modelCreator.createSelectModel('rangoSalarial',LABELS.SALARY_RANGE, [], this.personalInfoForm, LABELS.GENDER, '', false);
    // this.jobModel = this.modelCreator.createSelectModel('trabajo',LABELS.JOB, [], this.personalInfoForm, LABELS.GENDER, '', false);
  }

  patchValuesFromState(){
    if(!this.loginState.user)
      return;
    const user = this.loginState.user;

    const civilStatus = user.EstadosCiviles?.map((e: any) => {return {descripcion : e.Descripcion, id : e.Id}} );
    this.civilStatusModel!.options = civilStatus ?? [];
    
    const genders = user.Generos;
    this.genderModel!.options = genders?.map((e: any) => {return {descripcion : e.Descripcion, id : e.Id}} );    

    this.issue$ =  this.issueState.checkState().subscribe({
      next : ({quotingState, pricingState}) => {
        if(pricingState.state !== States.success || quotingState.state !== States.success)
          return;

        this.isPet = quotingState.quotingInfo?.EsMascota ?? false; 
        this.isDental = quotingState.quotingInfo?.EsDental ?? false; 
        if(!this.isPet){
          this.isPet = false;
        }
        if(this.isDental){
          this.civilStatusModel!.required=false;
          this.emailModel!.required=true;
          this.nameModel!.required=true;
          //this.personalInfoForm.get('disclaimer')?.setValidators([Validators.required]);
        }

        let insuredData = pricingState.initialRequest;
        this.getDataFromQuoteRequest(insuredData)
        insuredData = this.loginState.user;

        const client = this.issueState?.fullState?.prospectusQuoteState?.quoteInfo?.Cliente;

        if(insuredData && !this.personalInfoForm.value.cpf){          
          const phone = insuredData.Telefono?.toString().split('-') ?? ['', ''];          
          const formValue = {
            nombres :         client?.Nombre,
            apellidoPaterno:  client?.Apellido,
            apellidoMaterno:  client?.ApellidoMaterno,
            rfc:              client?.NroDocumento,
            email:            client?.Email,
            telefono:         phone[1],
            telefonoPais:     '52', 
            telefonoArea:     phone[0], 
          }

          this.personalInfoForm.patchValue(formValue);
        }

      }
    })

  }

  loadQuotingInfo() {
    this.quotingInfoSub = this.issueState.checkState().subscribe({
      next :(state) => {
        const quotingState = state.quotingState;
        const emissionDataState = state.emissionDataState;
        if(emissionDataState.state===States.success){
          this.emissionDataInfo = emissionDataState.EmissionInfo;
          this.loadDataEmission(this.emissionDataInfo!);    
        }
        if(quotingState.state === States.success){
          this.quotingInfo = quotingState.quotingInfo;
          if(!this.regions){
            this.regions = quotingState.quotingInfo?.Regiones ?? [];
          }
          this.loadData(this.quotingInfo!);            
        }
        else{
          // de nuevo, no debería pasar nunca
        }
      }      
    })
  }

  loadDataEmission(emissionDataInfo : EmissionDataInfoModel){
    this.listRegimenesSocietarios = emissionDataInfo?.RegimenesSocietarios != null ? emissionDataInfo?.RegimenesSocietarios?.map(r => ({
      id: r.Id,
      descripcion: r.Descripcion,
    })):[];
    this.listRegimenesFiscales =emissionDataInfo.RegimenesFiscales!=null ? emissionDataInfo.RegimenesFiscales.map(r => ({
      id: r.Id,
      descripcion: r.Descripcion,
    })) :[];
    this.listRegimenesFiscalesMoral =emissionDataInfo.RegimenesFiscalesMoral!=null ? emissionDataInfo.RegimenesFiscalesMoral.map(r => ({
      id: r.Id,
      descripcion: r.Descripcion,
    })) :[];
    this.listRegimenesFiscalesFisica =emissionDataInfo.RegimenesFiscalesFisica!=null ? emissionDataInfo.RegimenesFiscalesFisica.map(r => ({
      id: r.Id,
      descripcion: r.Descripcion,
    })) :[];
    this.listOcupacion =emissionDataInfo.Ocupacion != null ? emissionDataInfo.Ocupacion.map(o => ({
      id: o.Id,
      descripcion: o.Descripcion,
    })):[];
    this.listGiro =emissionDataInfo.Giro!=null ? emissionDataInfo.Giro.map(g => ({
      id: g.Id,
      descripcion: g.Descripcion,
    })):[];

    if(this.listRegimenesFiscalesFisica!.length > 0 || this.listRegimenesFiscalesMoral!.length > 0 || this.listRegimenesFiscales!.length > 0){
      this.regimenesFiscales = this.modelCreator.createSelectModel('regimenFiscal',LABELS.SELECT, this.listRegimenesFiscalesFisica!,this.personalInfoForm!, LABELS.USE, '', true,false,false,LABELS.SELECT_ERROR);
      this.regimenesFiscales.outsideLabel=LABELS.TAX_REGIME
    }
    if(this.listRegimenesSocietarios!.length > 0 ){
      this.regimenesSocietarios = this.modelCreator.createSelectModel('regimenSocietario',LABELS.SELECT, this.listRegimenesSocietarios!,this.personalInfoForm!, LABELS.USE, '', false,false,false,LABELS.SELECT_ERROR);
      this.regimenesSocietarios.outsideLabel=LABELS.CORPORATE_REGIME
    }
    if(this.listGiro!.length > 0){
      this.giro = this.modelCreator.createSelectModel('giro',LABELS.SELECT, this.listGiro!,this.personalInfoForm!, LABELS.USE, '', true,false,false,LABELS.SELECT_ERROR);
      this.giro.outsideLabel=LABELS.TURN
    }
    if(this.listOcupacion!.length > 0){
      this.occupation = this.modelCreator.createSelectModel('ocupacion',LABELS.SELECT, this.listOcupacion!,this.personalInfoForm!, LABELS.USE, '', true,false,false,LABELS.SELECT_ERROR);
      this.occupation.outsideLabel=LABELS.OCCUPATION
    }

  }

  loadData(quotingInfo : QuotingInfoModel){
    this.selectTypePerson = quotingInfo.TipoPersonas.map(status => ({
      id: status.Id,
      descripcion: status.Descripcion,
    }));
    const typePersonControl = this.personalInfoForm!.get('tipoPersona');
    typePersonControl!.patchValue({id:typePersonControl?.value.toString(),descripcion:this.selectTypePerson?.find(a=>a.id==typePersonControl?.value.toString())?.descripcion?.toString()});
    this.typePerson = this.modelCreator.createSelectModel('tipoPersona',LABELS.SELECT, this.selectTypePerson!,this.personalInfoForm!, LABELS.USE, '', true,false,false,LABELS.SELECT_ERROR);
    this.typePerson.outsideLabel=this.type_person
    this.typePerson.disabled=true;
  }

  getDataFromQuoteRequest(initialRequest : any){
    let data = initialRequest.Asegurado
    if(!data)
      data = initialRequest.Asegurado
    
    if(data.CodigoPostal){  
      this.disabledCp=true
      this.personalInfoForm.get('codigoPostal')?.setValue(data.CodigoPostal)
    }else{
      this.disabledCp=false
    }   
    if(data.TipoPersona){  
      this.personalInfoForm.get('tipoPersona')?.setValue(data.TipoPersona)
    }   
    // return {
    //   Nombre : data.NombreAsegurado,
    //   Apellido : data.ApellidoAsegurado,
    //   NroDocumento : data.NroDocAsegurado,
    //   IdGenero :  data.GeneroAsegurado,
    //   FechaNacimiento : data.FechaNacAsegurado,
    //   IdEstadoCivil : data.EstadoCivilAsegurado,
    //   Telefono : data.TelefonoAsegurado,
    // } ;
  }

  next(){
    switch (this.currentStep) {
      case 0:
        this.validateStep0();
        break;
      case 1:
        this.validateStep1();
        break;
      case 2:
        this.validateStep2();
        break;
      // case 3:
      //   this.validateStep3();
      //   break;  
      default:
        break;
    }
  }

  validateTypePerson(){
    if(this.personalInfoForm!.get('tipoPersona')!.value.id=='1'){
      this.nameModel!.required=true;
      this.paternalSurname!.required=true;
      this.maternalSurname!.required=true;
      this.occupation!.required=true
    }else{
      this.birthdateModel!.required=true;
      this.companyNameModel!.required=true;
      //this.turn!.required=true
      this.typeCompany!.required=true
    }
  }

  validateStep0(){
    if(this.personalInfoForm!.get('tipoPersona')!.value.id=='1'){
      if(this.regimenesFiscales){
        if(this.listRegimenesFiscalesFisica.length > 0)
          this.regimenesFiscales!.options=this.listRegimenesFiscalesFisica
        else
          this.regimenesFiscales!.options=this.listRegimenesFiscales
      }
      this.phoneModel!.required=true;
      const tipoPersona = this.personalInfoForm!.get('tipoPersona')?.value;
      const rfc = this.personalInfoForm!.get('rfc')?.value;
      const nombres = this.personalInfoForm!.get('nombres')?.value;
      const apellidoPaterno = this.personalInfoForm!.get('apellidoPaterno')?.value;
      const apellidoMaterno = this.personalInfoForm!.get('apellidoMaterno')?.value;
      if(tipoPersona=="")
        this.personalInfoForm!.get('tipoPersona')?.markAllAsTouched();
      if(rfc=="" || rfc == null || rfc == undefined){
        this.personalInfoForm!.get('rfc')?.markAllAsTouched();
      }
      if(nombres=="")
        this.personalInfoForm!.get('nombres')?.markAllAsTouched();
      if(apellidoPaterno=="")
        this.personalInfoForm!.get('apellidoPaterno')?.markAllAsTouched();
      if(apellidoMaterno=="")
        this.personalInfoForm!.get('apellidoMaterno')?.markAllAsTouched();
      return tipoPersona!="" && rfc!="" && rfc!=null && nombres!="" && apellidoPaterno!="" && apellidoPaterno!=undefined ? this.currentStep++ : false;
    }else{
      if(this.regimenesFiscales){
        if(this.listRegimenesFiscalesMoral.length > 0)
          this.regimenesFiscales!.options=this.listRegimenesFiscalesMoral
        else
          this.regimenesFiscales!.options=this.listRegimenesFiscales
      }      
      this.phoneModel!.required=true;
      this.paternalSurname!.required = false;
      this.maternalSurname!.required = false;
      this.nameModel!.required = false;
      this.rfc!.required = false;
      //const giro = this.personalInfoForm!.get('giro')?.value;
      const fechaNacimientoConstitucion = this.personalInfoForm!.get('fechaNacimientoConstitucion')?.value;
      const razonSocial = this.personalInfoForm!.get('razonSocial')?.value;
      // if(giro=="")
      //   this.personalInfoForm!.get('giro')?.markAllAsTouched();
      if(fechaNacimientoConstitucion=="")
        this.personalInfoForm!.get('fechaNacimientoConstitucion')?.markAllAsTouched();
      if(razonSocial=="")
        this.personalInfoForm!.get('razonSocial')?.markAllAsTouched();
      
      return fechaNacimientoConstitucion!="" && razonSocial!="" ? this.currentStep++ : false;
    }
  }

  validateStep1(){
    if(this.personalInfoForm!.get('tipoPersona')!.value.id=='1'){
      const ocupacion = this.personalInfoForm!.get('ocupacion')?.value;
      const regimenFiscal = this.personalInfoForm!.get('regimenFiscal')?.value;
      // const regimenSocietario = this.personalInfoForm!.get('regimenSocietario')?.value;
      const telefono = this.personalInfoForm!.get('telefono')?.value;
      const email = this.personalInfoForm!.get('email')?.value;

      const markAsTouchedIfEmpty = (fieldName: string, value: any, condition = true) => {
        if (value === "" && condition) {
          this.personalInfoForm!.get(fieldName)?.markAllAsTouched();
        }
      };
      markAsTouchedIfEmpty('ocupacion', ocupacion, this.listOcupacion!.length > 0);
      markAsTouchedIfEmpty('regimenFiscal', regimenFiscal, this.listRegimenesFiscalesFisica!.length > 0);
      markAsTouchedIfEmpty('regimenFiscal', regimenFiscal, this.listRegimenesFiscales!.length > 0);
      markAsTouchedIfEmpty('telefono', telefono);
      markAsTouchedIfEmpty('email', email);

      const isStepValid = telefono !== "" 
        && email !== "" 
        && !this.personalInfoForm!.get('telefono')?.invalid
        && (this.listOcupacion!.length === 0 || ocupacion !== "") 
        && (this.listRegimenesFiscalesFisica!.length === 0 || regimenFiscal !== "")
        && (this.listRegimenesFiscales!.length === 0 || regimenFiscal !== "")
      return isStepValid ? this.currentStep++ : false;
    }else{
      const giro = this.personalInfoForm!.get('giro')?.value;
      const rfc = this.personalInfoForm!.get('rfc')?.value;
      const telefono = this.personalInfoForm!.get('telefono')?.value;
      const email = this.personalInfoForm!.get('email')?.value;
      const markAsTouchedIfEmpty = (fieldName: string, value: any, condition = true) => {
        if (value === "" && condition) {
          this.personalInfoForm!.get(fieldName)?.markAllAsTouched();
        }
      };
      markAsTouchedIfEmpty('giro', giro, this.listGiro!.length > 0);
      if(rfc=="")
        this.personalInfoForm!.get('rfc')?.markAllAsTouched();
      if(telefono=="")
        this.personalInfoForm!.get('telefono')?.markAllAsTouched();
      if(email=="")
        this.personalInfoForm!.get('email')?.markAllAsTouched();
      return rfc!="" && telefono!="" && email!="" && (this.listGiro!.length === 0 || giro !== "") ? this.currentStep++ : false;
    }
  }

  validateStep2(){
    if(this.personalInfoForm!.get('tipoPersona')!.value.id=='1'){
      const birthdateControl = this.personalInfoForm!.get('fechaNacimientoConstitucion');
      const errorBirthdate  = birthdateControl!.errors;
      if(errorBirthdate)        {
        birthdateControl?.setErrors(null);
      }
      const companyControl = this.personalInfoForm!.get('razonSocial');
      const errorCompany  = companyControl!.errors;
      if(errorCompany)        {
        companyControl?.setErrors(null);
      }
    }else{
      const namesControl = this.personalInfoForm!.get('nombres');
      const errorNames  = namesControl!.errors;
      if(errorNames)        {
        namesControl?.setErrors(null);
      }
      const paternalSurnameControl = this.personalInfoForm!.get('apellidoPaterno');
      const errorPaternalSurname  = paternalSurnameControl!.errors;
      if(errorPaternalSurname)        {
        paternalSurnameControl?.setErrors(null);
      }
      const maternalSurnameControl = this.personalInfoForm!.get('apellidoMaterno');
      const errorMaternalSurname  = maternalSurnameControl!.errors;
      if(errorMaternalSurname)        {
        maternalSurnameControl?.setErrors(null);
      }
      // const occupationControl = this.personalInfoForm!.get('ocupacion');
      // const errorOccupation  = occupationControl!.errors;
      // if(errorOccupation)        {
      //   occupationControl?.setErrors(null);
      // }
    }

    const estado = this.personalInfoForm!.get('estado')?.value;
    const municipio = this.personalInfoForm!.get('municipio')?.value;
    const colonia = this.personalInfoForm!.get('colonia')?.value;
    const externalnumber = this.personalInfoForm!.get('numeroExterno')?.value;
    // const internalnumber = this.personalInfoForm!.get('numeroInterno')?.value;
    const calle = this.personalInfoForm!.get('calle')?.value;
    if(externalnumber=="")
      this.personalInfoForm!.get('numeroExterno')?.markAllAsTouched();
    // if(internalnumber=="")
    //   this.personalInfoForm!.get('numeroInterno')?.markAllAsTouched();
    if(calle=="")
      this.personalInfoForm!.get('calle')?.markAllAsTouched();
    if(estado=="")
      this.personalInfoForm!.get('estado')?.markAllAsTouched();
    if(municipio=="")
      this.personalInfoForm!.get('municipio')?.markAllAsTouched();
    if(colonia=="")
      this.personalInfoForm!.get('colonia')?.markAllAsTouched();

    return externalnumber!="" && calle!="" && estado!="" && municipio!="" && colonia!="" ? this.consultantProposal.notifyNextStep() : false;
  }

  return(){
    if(this.currentStep > 0)
      this.currentStep--
    else
      this.consultantProposal.notifyPreviousStep()
  }

  ngOnDestroy(): void {
    this.issue$.unsubscribe();
    this.quotingInfoSub.unsubscribe();
  }
}
