<div class="col-12">
    <p class="fs-3 fw-bold border-bottom">{{general_title}}</p>
    <p class="">{{proposal?.Plan}}</p>

    <div *ngFor="let mascota of proposal?.Mascotas ?? []; index as i"
        class=" border-bottom" >
        <p class="">{{pet_number+ (i + 1).toString()}}</p>
        <p class="">{{name_title +' : ' + mascota.descripcion}}</p>
        <p class="">{{plan_title + ' : ' + mascota.id + " - " + mascota.tipo}}</p>
    </div>

</div>
