<!-- 
<div class="justify-content-start col-12 px-3-py-2">
    <app-custom-stepper [steps]="steps" [currentStep]="currentStep"></app-custom-stepper>
</div> -->

<!-- <div class="stepper-container">
    <div class="justify-content-left col-12 col-md-11 m-auto">
      <div class="row pt-3 align-items-start stepper-title-container">
        <div class="d-flex flex-column align-items-start">
          <app-custom-stepper [currentStep]="currentStep" [steps]="steps" class="custom-stepper"></app-custom-stepper>
        </div>
      </div>
    </div>
</div> -->
<div class="stepper-container row">
    <app-custom-stepper [currentStep]="currentStep" [steps]="steps" class="custom-stepper"></app-custom-stepper>
  </div>
  
<div class="w-100">    
    <p class="fw-medium fs-5">{{title}}</p>
    <div class="" *ngIf="currentStep===0">

        <div class="col-12">
            <custom-select [model]="typePerson!" (selectChanged)="validateTypePerson()"></custom-select>
        </div>

        <div *ngIf="personalInfoForm!.get('tipoPersona')!.value.id==='1'">
            <div class="py-2">
                <custom-input [model]="rfc!"></custom-input>
            </div>
    
            <div class="py-2">
                <custom-input [model]="nameModel!"></custom-input>
            </div>
            
            <div class="py-2">
                <custom-input [model]="paternalSurname!"></custom-input>
            </div>

            <div class="py-2">
                <custom-input [model]="maternalSurname!"></custom-input>
            </div>
        </div>

        <div *ngIf="personalInfoForm!.get('tipoPersona')!.value.id==='2'">
            <!-- <div class="col-12" style="margin-top: 10px;">
                <custom-select [model]="turn!"></custom-select>
            </div> -->
            <div class="py-2">
                <custom-input [model]="companyNameModel!"></custom-input>
            </div>
    
            <div class="mt-2 mb-2">
                <app-input-datetime-custom [model]="birthdateModel!"></app-input-datetime-custom>
            </div>
        
        </div>

    </div>


    <div class="" *ngIf="currentStep===1">


        <div *ngIf="personalInfoForm!.get('tipoPersona')!.value.id==='2'">
            <div class="py-2">
                <custom-input [model]="rfc!"></custom-input>
            </div>
            <!-- <div class="col-12">
                <custom-select [model]="typeCompany!"></custom-select>
            </div> -->

            <div class="col-12" *ngIf="listGiro!.length > 0">
                <custom-select [model]="giro!"></custom-select>
            </div>

            <div class="col-12 mt-4" *ngIf="listRegimenesFiscalesMoral!.length > 0">
                <custom-select [model]="regimenesFiscales!"></custom-select>
            </div>

            <div class="col-12 " *ngIf="listRegimenesFiscales!.length > 0">
                <custom-select [model]="regimenesFiscales!"></custom-select>
            </div>

            <div class="col-12 mt-4" *ngIf="listRegimenesSocietarios!.length > 0">
                <custom-select [model]="regimenesSocietarios!"></custom-select>
            </div>

            <div class="py-2">
                <custom-input [model]="phoneModel!"></custom-input>
            </div>
        
            <div class="py-2">
                <custom-input [model]="emailModel!"></custom-input>
            </div>

        </div>
        <div *ngIf="personalInfoForm!.get('tipoPersona')!.value.id==='1'">
            <div class="py-2">
                <custom-input [model]="phoneModel!"></custom-input>
            </div>
        
            <div class="py-2">
                <custom-input [model]="emailModel!"></custom-input>
            </div>
    
            <div class="col-12 " *ngIf="listRegimenesFiscalesFisica!.length > 0">
                <custom-select [model]="regimenesFiscales!"></custom-select>
            </div>

            <div class="col-12 " *ngIf="listRegimenesFiscales!.length > 0">
                <custom-select [model]="regimenesFiscales!"></custom-select>
            </div>
    
            <div class="col-12 mt-4" *ngIf="listOcupacion!.length > 0">
                <custom-select [model]="occupation!"></custom-select>
            </div>

        </div>



    </div>


    <div class="" *ngIf="currentStep===2">
        <div class="py-3 ">
            <app-new-home [form]="personalInfoForm" [regions]="regions ?? []" [disabled]="disabledCp"></app-new-home>
        </div>
    </div>

    
    <div class="row mb-4 mt-2">
        <custom-button [model]="btnReturn!" (customClick)="return()" class="col-6 col-sm-6 mt-2"></custom-button>
        <custom-button [model]="continueBtn!" (customClick)="next()" class="col-6 col-sm-6 mt-2"></custom-button>
    </div>   
</div>


