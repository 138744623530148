import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Subscription } from 'rxjs';
import { ERRORS } from 'src/app/main/constants/errors.enum';
import { LABELS } from 'src/app/main/constants/labels.enum';
import { IProposalModel } from 'src/app/main/models/proposal.model';
import { AuthenticatorService } from 'src/app/main/services/authenticator.service';
import { ConsultantService } from 'src/app/main/services/consultant.service';
import { RedirectorService } from 'src/app/main/services/redirector.service';
import { LabelModel } from 'src/app/shared/models/label.model';
import { ModelCreatorService } from 'src/app/shared/services/model-creator.service';

@Component({
  selector: 'proposal-list',
  templateUrl: './proposal-list.component.html',
  styleUrls: ['./proposal-list.component.scss']
})
export class ProposalListComponent implements OnInit{
  consultantError:string|undefined;
  showconsultantError=false;
  readonly my_proposals= LABELS.MY_PROPOSALS;
  readonly message:string=ERRORS.NO_PROPOSALS
  readonly proposal_list_error1=LABELS.PROPOSALS_LIST_ERROR1
  readonly proposal_list_error2=LABELS.PROPOSALS_LIST_ERROR2
  readonly go_quote = LABELS.GO_QUOTE
  showMessage  = false;  
  proposals : IProposalModel[]=[]
  private subscription: Subscription = new Subscription();
  constructor(private fb: FormBuilder,private modelCreator : ModelCreatorService,private consultantProposal:ConsultantService,private redirectorService: RedirectorService) {
  }

  ngOnInit(): void {
    this.subscription.add(
      this.consultantProposal.refreshList$.subscribe(() => {
        this.proposals=[]
        this.listProposal();
      })
    );
    this.listProposal();
  }

  goDasboard(){
    this.redirectorService.redirectToStart()
  }
  showError(msg  = ""){
    this.consultantError = ERRORS.COULD_NOT_REGISTER;
    if(msg)
      this.consultantError = msg;   
    this.showconsultantError = true;
  }
  listProposal(){
    this.showMessage=false
    this.consultantProposal.consultProposal({}).subscribe({
      next  : (data) => {
        if(data.IsSuccess){
          this.proposals=data.Result;
          if(this.proposals.length==0){
            this.showMessage=true
          }
          return;
        }  
      },
      error : (err) => {
        this.showError();

      }
    })
  }

  ngOnDestroy() {
    this.subscription.unsubscribe(); 
  }
}
