import { Component, OnDestroy, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { Subscription } from 'rxjs';
import { ButtonStylesEnum, ButtonTypesEnum } from 'src/app/main/constants/buttons.enum';
import { ERRORS } from 'src/app/main/constants/errors.enum';
import { InputTypesEnum } from 'src/app/main/constants/inputs-types.enum';
import { LABELS } from 'src/app/main/constants/labels.enum';
import { QuotingInfoModel } from 'src/app/main/models/emission/quoting-info.model';
import { ClienteProducto, DatosLiberty, IDataDriver, IDataOwner, IInsured, IRenewal, IVehicles, Vehiculo } from 'src/app/main/models/issue/issue-data.model';
import { QuoteInfoModel } from 'src/app/main/models/issue/quote-info.model';
import { ISelectItem } from 'src/app/main/models/select-item.model';

import { ConsultantService } from 'src/app/main/services/consultant.service';
import { LocatorService } from 'src/app/main/services/issue/locator.service';
import { QuotingFormHandlerService } from 'src/app/main/services/issue/quoting-form-handler.service';
import { RedirectorService } from 'src/app/main/services/redirector.service';
import { ButtonModel } from 'src/app/shared/models/button.model';
import { DropdownCustomModel } from 'src/app/shared/models/dropdown.model';
import { InputModel } from 'src/app/shared/models/input.model';
import { ModelCreatorService } from 'src/app/shared/services/model-creator.service';
import { IssueInsuranceState } from 'src/app/state/issue/issue-insurance.state';
import { IssueState } from 'src/app/state/issue/issue.state';
import { LoginState } from 'src/app/state/login/login.state';
import { States } from 'src/app/state/models/states.enum';

@Component({
  selector: 'issue-dental',
  templateUrl: './issue-dental.component.html',
  styleUrls: ['./issue-dental.component.scss']
})

export class IssueDentalComponent implements OnInit,OnDestroy{
  zipCodeInput: InputModel | undefined;
  form: FormGroup |undefined;
  btnNext : ButtonModel| undefined;


  readonly load_plans=LABELS.LOAD_PLANS;
  readonly back_dashboard=LABELS.BACK_DASHBOARD

  readonly title_banner_vehicle1 = LABELS.TITLE_BANNER_DENTAL_1;
  readonly title_banner_vehicle2 = LABELS.TITLE_BANNER_DENTAL_2;
  readonly title_banner_vehicle3 = LABELS.TITLE_BANNER_DENTAL_3;

  readonly subtitle_banner_vehicle1 = LABELS.SUBTITLE_BANNER_DENTAL1;
  readonly body1_banner_vehicle1 = LABELS.BODY1_BANNER_DENTAL1;
  readonly body2_banner_vehicle1 = LABELS.BODY2_BANNER_DENTAL1;
  readonly subtitle_banner_vehicle2 = LABELS.SUBTITLE_BANNER_DENTAL2;
  readonly body1_banner_vehicle2 = LABELS.BODY1_BANNER_DENTAL2;
  readonly body2_banner_vehicle2 = LABELS.BODY2_BANNER_DENTAL2;
  readonly subtitle_banner_vehicle3 = LABELS.SUBTITLE_BANNER_DENTAL3;
  readonly body1_banner_vehicle3 = LABELS.BODY1_BANNER_DENTAL3;
  readonly body2_banner_vehicle3 = LABELS.BODY2_BANNER_DENTAL3;
  readonly subtitle_banner_vehicle4 = LABELS.SUBTITLE_BANNER_DENTAL4;
  readonly body1_banner_vehicle4 = LABELS.BODY1_BANNER_DENTAL4;
  readonly body2_banner_vehicle4 = LABELS.BODY2_BANNER_DENTAL4;
  
  readonly title_dental=LABELS.TITLE_DENTAL
  readonly subtitle_dental=LABELS.SUBTITLE_DENTAL
  

  quotingInfo? : QuotingInfoModel;
  quoteInfo? : QuoteInfoModel[];
  quoteInfoSelected? : QuoteInfoModel;
  quotingInfoSub : Subscription = new Subscription();
  quoteInfoSub : Subscription = new Subscription();

  step = 0;
  steps: any[] = [];
  regions? : any[];

  quotes?: DropdownCustomModel;
  selectQuote: ISelectItem[] |undefined;
  prices:any[]=[]
  pricingRequest : any;
  
  constructor(
    private consultantProposal:ConsultantService,    
    private formHandler : QuotingFormHandlerService,    
    private redirector : RedirectorService,
    private fb: FormBuilder,
    private issue_state : IssueState,
    private login_state : LoginState,
    private modelCreator : ModelCreatorService,
    private locatorSvc : LocatorService){
        this.form=this.fb.group({
            calle : [''],
            numero : [''],
            pisodepto : [''],
            codigoPostal : ['',[Validators.required]],
            distrito : [''],
            ciudad : [''],
            idCiudad: [''],
            estado : [''],
            region : ['']
          })
  }  


  loadQuotingInfo() {    
    this.issue_state.clearPricing();

    const quotingId = 1;
    this.checkState();

  }

  checkState(){
    this.quotingInfoSub = this.issue_state.checkState().subscribe({
      next :(state) => {      
        if(state.quotingState.state === States.success){
          this.quotingInfo = state.quotingState.quotingInfo;
          if(!this.regions){
            this.getRegions();           
          }
          if(!this.steps || this.steps.length === 0){
            //   this.setValidSteps(this.quotingInfo!);
            //   this.checkStateQuote(this.quotingInfo!);
          }

        }
        else{
          // mensajito... 
          // En teoría siempre deberíamos tener los datos de cotización
          // peeero... 
        }
      }
    })
    
  }


  ngOnInit(): void {

    this.btnNext = this.modelCreator.createButtonModel(LABELS.CONTINUE, ButtonTypesEnum.Common, ButtonStylesEnum.base_button);
    this.zipCodeInput = this.modelCreator.createInputModel(LABELS.QUESTION_ZIPCODE, InputTypesEnum.zipCode, 'zipCode', this.form!, ERRORS.ZIPCODE_INVALID, undefined, true, 9, true);
    this.loadQuotingInfo();
  }


  
  back(){
    this.redirector.redirectToDashboard();
  }

  price(){
    if(this.form!.invalid)
      return; //mensajito
    //this.issue_state.quoteExemple();
    this.redirector.redirectToDentalPlansQuoted();
    
  }
  getRegions(){
    this.locatorSvc.getRegions().subscribe({
      next : (data : any) =>{ 
        if(data.Result){
          this.regions = data.Result.map((e : any) => {
            return {id : e.Id, descripcion : e.Descripcion}
          })
        }
      }
    })
  }

  ngOnDestroy(): void {
    this.quotingInfoSub.unsubscribe();
    this.quoteInfoSub.unsubscribe();
  }
}
