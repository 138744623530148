<div class="popup-header">
    <img [src]="'/assets/icons/' + 'download.svg'" class="header-icon" alt="icon">
    <h5>{{documents_downloads}}</h5>
  </div>
  <div class="popup-content">
    <div class="checkbox-group" *ngIf="parentGroup">
      <div class="col-12 col-md-12 py-2" 
           *ngFor="let document of documents" 
           [formGroup]="parentGroup!" 
           style="font-size: 1rem !important; color: gray;">
          <p-checkbox [label]="document.Descripcion" 
                      [binary]="true"
                      [formControlName]="document.Descripcion">
          </p-checkbox>
      </div>
    </div>
      <div class="button-group">
        <button class="btn btn-black" (click)="showNotAvailablePopUp()">{{download}}</button>
      </div>
  </div>
  