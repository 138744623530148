import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { Observable, Subject } from 'rxjs';
import { SelectOption } from 'src/app/shared/models/options.model';

@Injectable({
  providedIn: 'root'
})
export class HeaderService {

  headerVisibility : Subject<boolean> = new Subject();

  constructor(private http : HttpClient) {

  }

  hideHeader(){
    this.headerVisibility.next(false);
  }

  showHeader(){
    this.headerVisibility.next(true);
  }

  getVisibilityObs(){
    return this.headerVisibility.asObservable();
  }
}
