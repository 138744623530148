<full-cover-card class="align-items-center" [model]="coverModel" *ngIf="loadedLabels">
    <div class="col-12 align-self-center justify-content-center">

        <div *ngIf="!isMessageShown" class=" col-12">
            <p class="fs-1 text-center">{{title}}</p>
        </div>

        <div *ngIf="!isMessageShown" class="col-12 d-flex justify-content-center ">
            <div class="col-10 ">
                <app-password-confirmation [model]="passwordModel"></app-password-confirmation>
            </div>

        </div>

        <div *ngIf="!isMessageShown" class="col-12 d-flex justify-content-center">
            <div class="col-6 mb-3">
                <custom-button [model]="continueModel" (customClick)="recoverPassword()"></custom-button>
            </div>


        </div>

        <div *ngIf="isMessageShown" class="d-flex flex-column justify-content-center align-items-center">
            <div class="col-12 justify-content-center d-flex">
                <img [alt]="isErrorShown ? 'error-icon' : 'done-icon'" [src]="'../../../assets/icons/'+ (isErrorShown? 'error.svg' : 'done.svg') "
                    style="max-width: 25%; width:7.5em;">
            </div>

            <div class="col-12 col-md-8 text-center">
                <p class="fs-4">{{message}}</p>

                <p *ngIf="canContinueError" tabindex="0" class="fw-semibold pointer" (keypress)="resendEmail()" (click)="resendEmail()">{{retry_message}}</p>
                <p *ngIf="canContinueError" >o</p>
                <p class="fw-semibold pointer" tabindex="0" (keypress)="redirectToLogin()" (click)="redirectToLogin()">{{home_message}}</p>
            </div>

        </div>

    </div>
</full-cover-card>