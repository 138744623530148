import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from "@angular/core";
import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import { Subscription } from "rxjs";
import { ButtonStylesEnum, ButtonTypesEnum } from "src/app/main/constants/buttons.enum";
import { LABELS } from "src/app/main/constants/labels.enum";
import { QuotingInfoModel } from "src/app/main/models/emission/quoting-info.model";
import { QuotingResidentialValidStepsEnum } from "src/app/main/models/issue/quoting-residential-steps.enum";
import { ConsultantService } from "src/app/main/services/consultant.service";
import { LocatorService } from "src/app/main/services/issue/locator.service";
import { QuotingFormHandlerService } from "src/app/main/services/issue/quoting-form-handler.service";
import { ButtonModel } from "src/app/shared/models/button.model";
import { ModelCreatorService } from "src/app/shared/services/model-creator.service";
import { IssueState } from "src/app/state/issue/issue.state";
import { LoginState } from "src/app/state/login/login.state";
import { States } from "src/app/state/models/states.enum";

@Component({
    selector: 'issue-protections-system',
    templateUrl: './issue-protections-system.component.html',
    styleUrls: ['./issue-protections-system.component.scss']
  })
  export class IssueProtectionSystemComponent implements OnDestroy{
    @Input() parentGroup: FormGroup |undefined;
    @Output() completedClicked = new EventEmitter<any>();
    @Output() backClicked = new EventEmitter<any>();

 
    btnContinue : ButtonModel|undefined;
    btnReturn : ButtonModel|undefined;

    alarm :string|undefined;
    electricFence :string|undefined;
    propertyWithRailing :string|undefined;
    riskForm: FormGroup |undefined;


    title=LABELS.PROTECTION_SYSTEM_DATA;
    steps: number[] = [0,];
    currentStep = 0;

    quotingInfo? : QuotingInfoModel;
    quotingInfoSub : Subscription = new Subscription();

    regions? : any[];

    readonly type_use = LABELS.TYPE_USE
    readonly yes=LABELS.QUESTION_ISNEW_YES
    readonly no=LABELS.QUESTION_ISNEW_NO
    readonly birthdate_label=LABELS.BITHDATE
    readonly gender = LABELS.SELECT_GENDER
    readonly data_insured=LABELS.VEHICLE_DATA_INSURED
    readonly have_alarm=LABELS.HAVE_ALARM
    readonly electric_fence=LABELS.ELECTRICFENCE
    readonly property_with_railing=LABELS.PROPERTY_WITH_RAILING
    constructor(private fb : FormBuilder,private modelCreator : ModelCreatorService,private consultantProposal:ConsultantService, 
      private issue_state : IssueState,
      private formHandler : QuotingFormHandlerService,
      private loginState:LoginState,
      private locatorSvc : LocatorService){
      this.riskForm = this.formHandler.getFormControlResident(QuotingResidentialValidStepsEnum.protection_system) as FormGroup;

      this.loadQuotingInfo();
      this.loadDataBack();
    }


    loadData( quotingInfo : QuotingInfoModel){{
    //   this.selectTypeUse = quotingInfo.EstadosCiviles.map(status => ({
    //     id: status.Id,
    //     descripcion: status.Descripcion,
    //   }));

    //   this.selectPropertyType = quotingInfo.Generos.map(gender => ({
    //     id: gender.Id,
    //     descripcion: gender.Descripcion,
    //   }));
      this.loadInputs();
    }}
    loadInputs(){
        this.btnContinue = this.modelCreator.createButtonModel(LABELS.CONTINUE_ISSUE, ButtonTypesEnum.Raised, ButtonStylesEnum.black);
        this.btnReturn = this.modelCreator.createButtonModel(LABELS.RETURN_ISSUE, ButtonTypesEnum.Raised, ButtonStylesEnum.without_white_outline);
    }
    loadDataBack(){
      return;
    }
    loadQuotingInfo() {
      this.quotingInfoSub = this.issue_state.checkState().subscribe({
        next :(state) => {
          const quotingState = state.quotingState;
          if(quotingState.state === States.success){
            if(!this.regions){
                this.getRegions();           
              }
            this.quotingInfo = quotingState.quotingInfo;
            this.loadData(this.quotingInfo!);
            
          }          
        }      
      })
    }
    
    navigateStep(step:any){
        this.currentStep=step;
    }


    validateAlarm(value: any) {
      this.riskForm!.get('tieneAlarma')?.setValue(value);
      this.alarm = value.toString();
    }

    validateElectricFence(value: any) {
      this.riskForm!.get('cercaElectronicas')?.setValue(value);
      this.electricFence = value.toString();
    }

    validatePropertyWithRailing(value: any) {
      this.riskForm!.get('inmuebleConBarandilla')?.setValue(value);
      this.propertyWithRailing = value.toString();
    }


    validateStep0(){
        this.propertyWithRailing=="" || this.alarm=="" || this.electricFence=="" ? this.riskForm?.markAllAsTouched() : this.completedClicked.emit();
    } 
    next(){
      if(this.currentStep==0){
        this.validateStep0()
        return
      }

    }

    return(){
  if(this.currentStep === 0){
        this.backClicked.emit();
      }

      if(this.currentStep >0){
        this.currentStep--;
      }
    }




    getRegions(){
        this.locatorSvc.getRegions().subscribe({
          next : (data : any) =>{ 
            if(data.Result){

              this.regions = data.Result.map((e : any) => {
                return {id : e.Id, descripcion : e.Descripcion}
              })
            }
          }
        })
      }
    ngOnDestroy(): void {
      this.quotingInfoSub.unsubscribe();
    }
  }
