import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { LABELS } from 'src/app/main/constants/labels.enum';
import { ButtonModel } from '../models/button.model';
import { ModelCreatorService } from '../services/model-creator.service';
import { ButtonTypesEnum } from '../enums/buttons.enum';
import { ButtonStylesEnum } from 'src/app/main/constants/buttons.enum';
import { DropdownCustomModel } from '../models/dropdown.model';
import { ISelectItem } from 'src/app/main/models/select-item.model';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { PopupOpenerService } from '../services/popup-opener.service';
import { PopupMessageComponent } from '../popup-message/popup-message.component';
import { PopUpTypesEnum } from '../enums/popup-types.enum';
import { ConsultantService } from 'src/app/main/services/consultant.service';
import { RedirectorService } from 'src/app/main/services/redirector.service';

@Component({
  selector: 'popup-cancel',
  templateUrl: './popup-cancel.component.html',
  styleUrls: ['./popup-cancel.component.scss']
})
export class PopupCancelComponent implements OnInit{
  @Input() proposal : any | undefined;
  @Input() reasons : any | undefined;
  @Output() closePopup = new EventEmitter<boolean>();
  parentGroup: FormGroup |undefined;
  reasonSelect?: DropdownCustomModel;
  reasonSelectList: ISelectItem[] | undefined;
  btnContinue : ButtonModel|undefined;
  request:any;
  readonly cancel_question= LABELS.CANCEL_QUESTION
  readonly cancellation_warning1= LABELS.CANCELLATION_WARNING1
  readonly cancellation_warning2= LABELS.CANCELLATION_WARNING2
  readonly number_proposal_cancel= LABELS.NUMBER_PROPOSAL_CANCEL
  readonly select_reason= LABELS.SELECT_REASON
  popupData : any;
  popupData1 : any;

    constructor(private modelCreator : ModelCreatorService,    private fb: FormBuilder,private popupSvc : PopupOpenerService,private consultantProposal: ConsultantService,private redirectorService: RedirectorService){
      this.btnContinue = this.modelCreator.createButtonModel(LABELS.CONFIRM, ButtonTypesEnum.Raised, ButtonStylesEnum.black);
      this.parentGroup = this.fb.group({
        reason: ['', Validators.required] 
      });

    }
    ngOnInit(): void {
      this.reasonSelectList = this.reasons?.map((reason: { Id: any; Descripcion: any; }) => ({
        id: reason.Id,
        descripcion: reason.Descripcion
      }));
      this.reasonSelect = this.modelCreator.createSelectModel('reason', LABELS.SELECT, this.reasonSelectList ?? [], this.parentGroup!, LABELS.TRACKER, '', false,true,false);
    }  

    showMessagePopUp(title : string, messages : string[], type : PopUpTypesEnum = PopUpTypesEnum.success,confirmation=false){
      if(confirmation){
        this.popupData={
          component:PopupMessageComponent,
          config : {
            title : title,
            hideHeader : true
          },
          
          data : {
            type : type,
            messages : messages,
            button:true,
            onAccept: () => this.handlePopupAccept()
          }
        };

        this.popupSvc.show(this.popupData)
      }else{
        this.popupData1={
          component:PopupMessageComponent,
          config : {
            title : title,
            hideHeader : true
          },
          
          data : {
            type : type,
            messages : messages,
            button:true,
          }
        };
        this.popupSvc.show(this.popupData1)
      }

    }

    handlePopupAccept() {
      this.popupSvc.hide();
      this.consultantProposal.cancelProposal(this.request).subscribe({
        next: (data) => {
          if (data.IsSuccess) {
            this.showMessagePopUp('', [LABELS.CANCELLATION_REQUEST,'',''], PopUpTypesEnum.success ,false)
            this.closePopup.emit(true);
            this.consultantProposal.triggerRefresh();
          }
        },
        error: (err) => {
          //this.showError();
        }
      });
    }

    showNotAvailablePopUp(){
      const tipo=this.parentGroup?.value.reason?.id
      if(tipo==undefined){
        this.showMessagePopUp('', [LABELS.CANCELLATION_ERROR,'',''], PopUpTypesEnum.warning )
        return
      }

      this.showMessagePopUp('', [LABELS.CANCEL_QUESTION,'',''], PopUpTypesEnum.warning,true)

      this.request={
        Tipo:tipo.toString(),
        Id:this.proposal
      }     
    }
    
}
