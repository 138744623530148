import { Component, EventEmitter, Input, OnDestroy, Output } from '@angular/core';
import { ButtonModel } from '../models/button.model';
import { ModelCreatorService } from '../services/model-creator.service';
import { ICONS } from 'src/app/main/constants/icons.enum';
import { Location } from '@angular/common';
import { CoverCardModel } from 'src/app/main/models/cover-card.model';
import { AppState } from 'src/app/state/app/app.state';
import { Subscription } from 'rxjs';

@Component({
  selector: 'full-cover-card',
  templateUrl: './full-cover-card.component.html',
  styleUrls: ['./full-cover-card.component.scss']
})
export class FullCoverCardComponent implements OnDestroy {

  @Input() showBackButton  = true;
  @Input() model : CoverCardModel = new CoverCardModel();
  
  @Output() backPressed = new EventEmitter<any>();
  
  btnBack : ButtonModel;
  enterpriseId? : number;

  enterpriseSub : Subscription =new Subscription();

  constructor(
    private modelCreator : ModelCreatorService,
    private location : Location,
    private appState : AppState) {
    
    this.btnBack =  this.modelCreator.createIconButtonModel('', ICONS.ARROW_BACK, false, 'rounded fs-6 flat-button text-dark p-0 pb-4 justify-content-start')      
    const enterpriseId = appState.getEnterpriseId();
    this.enterpriseId = enterpriseId === -1 ? undefined : enterpriseId;

    this.enterpriseSub = appState.getEnterpriseSub().subscribe({
      next : (value) => {
        if(!Number.isNaN(+value.id))
          this.enterpriseId = +value.id;
      }
    })
  }  

  navigateBack(){
    if(this.model.useDefaultBack){
      this.location.back();
      return;
    }
    
    this.backPressed.emit();
  }

  ngOnDestroy(): void {
    this.enterpriseSub.unsubscribe();
  }

}
