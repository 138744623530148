import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from "@angular/core";
import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import { Subscription } from "rxjs";
import { ButtonStylesEnum, ButtonTypesEnum } from "src/app/main/constants/buttons.enum";
import { LABELS } from "src/app/main/constants/labels.enum";
import { QuotingInfoModel } from "src/app/main/models/emission/quoting-info.model";
import { QuotingVehicleValidStepsEnum } from "src/app/main/models/emission/quoting-vehicle-steps.enum";
import { ConsultantService } from "src/app/main/services/consultant.service";
import { QuotingFormHandlerService } from "src/app/main/services/issue/quoting-form-handler.service";
import { ButtonModel } from "src/app/shared/models/button.model";
import { DropdownCustomModel } from "src/app/shared/models/dropdown.model";
import { ModelCreatorService } from "src/app/shared/services/model-creator.service";
import { IssueState } from "src/app/state/issue/issue.state";
import { LoginState } from "src/app/state/login/login.state";
import { States } from "src/app/state/models/states.enum";

@Component({
    selector: 'issue-payment-data',
    templateUrl: './issue-payment-data.component.html',
    styleUrls: ['./issue-payment-data.component.scss']
  })
  export class IssuePaymentDataComponent implements OnDestroy{
    @Input() parentGroup: FormGroup |undefined;
    @Output() completedClicked = new EventEmitter<any>();
    @Output() backClicked = new EventEmitter<any>();
    paymentForm: FormGroup |undefined;
    btnContinue : ButtonModel|undefined;
    btnReturn : ButtonModel|undefined;

    periodicityModel? : DropdownCustomModel;

    title=LABELS.PERIODICITY_DATA_TITLE;
    steps: number[] = [0, 1,2];
    currentStep = 0;

    quotingInfo? : QuotingInfoModel;
    quotingInfoSub : Subscription = new Subscription();

    
    constructor(private modelCreator : ModelCreatorService, 
      private issue_state : IssueState,
      private formHandler : QuotingFormHandlerService){
      this.paymentForm = this.formHandler.getFormControl(QuotingVehicleValidStepsEnum.payment_data) as FormGroup;

      this.loadQuotingInfo();
    }

    loadData( quotingInfo : QuotingInfoModel){{
      this.loadInputs();
    }}

    loadInputs(){
      this.periodicityModel = this.modelCreator.createSelectModel('periodicidad', LABELS.SELECT,  [{'id': 1, 'descripcion': 'Semestral'},{'id':2, 'descripcion': 'Trimestral'},{'id': 3, 'descripcion': 'Anual'}], this.paymentForm!,'','',true,false,false,LABELS.SELECT_ERROR);
      this.periodicityModel.outsideLabel = LABELS.PERIODICITY;  
      this.btnContinue = this.modelCreator.createButtonModel(LABELS.QUOTE_ISSUE, ButtonTypesEnum.Raised, ButtonStylesEnum.black);
      this.btnReturn = this.modelCreator.createButtonModel(LABELS.RETURN_ISSUE, ButtonTypesEnum.Raised, ButtonStylesEnum.without_white_outline); 
    }


    loadQuotingInfo() {
      this.quotingInfoSub = this.issue_state.checkState().subscribe({
        next :(state) => {
          const quotingState = state.quotingState;
          if(quotingState.state === States.success){
            this.quotingInfo = quotingState.quotingInfo;
            this.loadData(this.quotingInfo!);
            
          }
          else{
            // de nuevo, no debería pasar nunca
          }
        }      
      })
    }
    
    navigateStep(step:any){
      this.currentStep=step;
    }



  
  validateStep0(){
    const periodicidad = this.paymentForm?.get('periodicidad');

    [periodicidad].forEach(field => {
        if (!field?.value) 
            field!.markAllAsTouched();
    });

    if (periodicidad?.value) {
        this.completedClicked.emit()
    } 
  }


    next(){     
      if(this.currentStep==0){
        this.validateStep0()
        return
      }
    }

    return(){
      if(this.currentStep === 0)
        this.backClicked.emit();
      if(this.currentStep >0)
        this.currentStep--;
    }

    ngOnDestroy(): void {
      this.quotingInfoSub.unsubscribe();
    }
  }
