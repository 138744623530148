export class MENUS {
   static START = 'INÍCIO';
   static PROFILE = 'MEU PERFIL';
   static INSURANCES = 'MEUS SEGUROS';
   static INSURANCES_CLIENT = 'MEUS SEGUROS';
   static INSURANCES_RENEWAL = 'PÓLIZAS';
   static CLOSE_SESSION = 'CERRAR SESIÓN';
   static ABOUT = 'PARCERIA';
   static NEW_POLICY = 'NOVA PÓLIZA';
   static POLICY = 'PÓLIZAS';
}
