  
  <div class="stepper-container row">
    <app-custom-stepper [currentStep]="currentStep" [steps]="steps" class="custom-stepper"></app-custom-stepper>
  </div>

  <div class="justify-content-center col-12 col-md-11 m-auto">
    <p class="title-quote">{{title}}</p>
  </div>

  <div>
        <div class="justify-content-center col-12 col-md-11 m-auto mb-5" *ngIf="currentStep===0">
            
            <app-question-input
            [question]="vehicle_salvage"
            [selected]="vehicleForm!.controls['salvage'].value"
            [showError]= "yesOrNoIsSalavge"
            (answer)="validateIsSalvage($event)"
          ></app-question-input>
            <p class="mt-4 mb-4" style="font-size: 0.8rem; color: rgb(67, 67, 67);">Los Autos de Salvamento o Recuperación facturados por una aseguradora, son aquellos vehículos que han sido refacturados por una compañía de seguros, lo que indica que sufrieron una pérdida total y fueron indemnizados por dicha aseguradora</p>
            <custom-button [model]="btnContinue!" (customClick)="nextStep()" class="col-sm-12 mt-4" *ngIf="currentStep<=7"></custom-button>
        </div>

        <div class="justify-content-center col-12 col-md-11 m-auto mb-5" *ngIf="currentStep===1">
      
            <div class="row pt-3">
                <div>
                    <custom-select
                    [model]="yearSelect"
                    (selectChanged)="validateYear()">
                    </custom-select>
                </div>
            </div>
            <div class="col-12 mt-3">
              <p class="text-secondary text-left" style="color:rgb(172, 172, 172) !important; font-size: 1rem;"><img [src]="'/assets/icons/' + 'sparkles-sharp.svg'" class="header-icon" alt="icon"> {{proposal_suggestion}}</p>
            </div>
            <div class="card-container d-flex flex-wrap justify-content-center col-12 col-md-10 m-auto mb-4">
                <div class="card" *ngFor="let year of selectYears?.slice(0,5); let i = index" [class.selected]="year.id === yearSelected!" (keypress)="selectYear(year.id)" (click)="selectYear(year.id)" tabindex="0">
                  <div class="card-body">
                    <p class="card-title" >{{ year.descripcion }}</p>
                  </div>
                </div>
            </div>


        </div>

        <div class="justify-content-center col-12 col-md-11 m-auto mb-5" *ngIf="currentStep===2">
      
          <app-question-input
          [question]="vehicle_is_new"
          [selected]="vehicleForm!.controls['isNew'].value"
          [showError]= "yesOrNoIsNew"
          (answer)="validateIsNew($event)"
          ></app-question-input>

          <div *ngIf="vehicleForm!.controls['isNew'].value==='1'">
            <div class="row pt-3 ">
              <custom-input [model]="invoiceModel!"></custom-input>
            </div>

            <div class="row pt-3 ">
              <app-input-datetime-custom [model]="invoiceDateModel!"></app-input-datetime-custom>
              <p class="mt-1 mb-1" style="font-size: 1rem; color: rgb(67, 67, 67);">• La fecha debe ser menor a 30 días</p>
            </div>

            <div class="row pt-3">
              <custom-input [model]="invoiceAmountModel!"></custom-input>
            </div>
          </div>

        </div>
        

        <div class="justify-content-center col-12 col-md-11 m-auto" *ngIf="currentStep===3" >

            <div class="row pt-3">
                <div>
                    <custom-select
                    [model]="makeSelect"
                    (selectChanged)="validateMake()"
                    >
                    </custom-select>
                </div>
            </div>
            <!-- <p class="fs-6 text-secondary text-left" style="color:rgb(172, 172, 172) !important;">{{proposal_suggestion}}</p> -->
            <div class="col-12 mt-3">
              <p class="text-secondary text-left" style="color:rgb(172, 172, 172) !important; font-size: 1rem;"><img [src]="'/assets/icons/' + 'sparkles-sharp.svg'" class="header-icon" alt="icon"> {{proposal_suggestion}}</p>
            </div>
            <div class="card-container d-flex flex-wrap justify-content-center col-12 col-md-10 m-auto">
              <div class="card" *ngFor="let make of filteredMakes?.slice(0, 5); let i = index" [class.selected]="make.id === makeSelected" (click)="selectMake(make.id)" (keypress)="selectMake(make.id)" tabindex="0">
                <div class="card-body px-1">
                  <p class="card-title">{{ make.descripcion }}</p>
                </div>
              </div>
            </div>
        </div>

        
        <div class="justify-content-center col-12 col-md-11 m-auto" *ngIf="currentStep===4">

            <div class="row pt-3">
                <div>
                    <custom-select
                    [model]="modelSelect"                    
                    (selectChanged)="validateModel()"
                    >
                    </custom-select>
                </div>
            </div>
        </div>

        <div class="justify-content-center col-12 col-md-11 m-auto">
          <div class="row pt-3"  *ngIf="currentStep===5">
              <div>
                  <custom-select
                    [model]="versionSelect"                    
                    (selectChanged)="validateVersion()"
                    >
                  </custom-select>
              </div>
          </div>
          <div class="row mb-4 mt-4" *ngIf="currentStep!==0">
            <custom-button [model]="btnReturn!" (customClick)="return()" class="col-6 col-sm-4 mt-2" *ngIf="currentStep<=7"></custom-button>
            <custom-button [model]="btnContinue!" (customClick)="nextStep()" class="col-6 col-sm-6 offset-sm-2 mt-2" *ngIf="currentStep<=7"></custom-button>
          </div>
        </div>



</div>        