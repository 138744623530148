import { Component, EventEmitter, Input, Output } from '@angular/core';
import { Subscription } from 'rxjs';
import { MENUS } from 'src/app/main/constants/menus.enum';
import { AuthenticatorService } from 'src/app/main/services/authenticator.service';
import { RedirectorService } from 'src/app/main/services/redirector.service';
import { LoginState } from 'src/app/state/login/login.state';

@Component({
  selector: 'app-sidebar',
  templateUrl: './sidebar.component.html',
  styleUrls: ['./sidebar.component.scss']
})
export class SidebarComponent {
  @Input() menuOpen=false;
  @Output() itemClicked = new EventEmitter<void>();
  issue$ : Subscription = new Subscription();
  callCenter:any;
  menus: any[] = [];  

  constructor(private redirectorService: RedirectorService, private auth : AuthenticatorService,
    private loginState : LoginState,
  ) {
    this.callCenter = this.loginState.user?.isCallCenter;
    this.menu();
  }

  menu(){
    if(!this.callCenter){
      this.menus = [
        {
          description: MENUS.START, icon: 'fa-solid fa-house',
          click : () => {this.redirectorService.redirectToStart()}
        },
        {
          description: MENUS.PROFILE, icon: 'fa-regular fa-circle-user',
          click : () => {this.redirectorService.redirectToUserProfile()}
        },
        {
          description: MENUS.INSURANCES_CLIENT, icon: 'fa-regular fa-rectangle-list',
          click : () => {this.redirectorService.redirectToProposaClientlList()}
        }, 
        {
          description: MENUS.INSURANCES, icon: 'fa-regular fa-rectangle-list',
          click : () => {this.redirectorService.redirectToProposalList()}
        }, 
        {
          description: MENUS.INSURANCES_RENEWAL, icon: 'fa-solid fa-file-lines',
          click : () => {this.redirectorService.redirectToRenewalList()}
        }, 
        {
          description: MENUS.ABOUT, icon: 'fa-solid fa-users-between-lines',
          click : () => {this.redirectorService.redirectToPartnership()}
        },
        {
          description: MENUS.CLOSE_SESSION, icon: 'fa-solid fa-arrow-right-from-bracket',
          click : () => {this.logout()}
        }, 
      
      ];
    }
    else{
      this.menus = [
        {
          description: MENUS.POLICY, icon: 'fa-solid fa-house',
          click : () => {this.redirectorService.redirectToRenewalList()}
        },
        {
          description: MENUS.INSURANCES, icon: 'fa-regular fa-rectangle-list',
          click : () => {this.redirectorService.redirectToProposalList()}
        }, 
        // {
        //   description: MENUS.NEW_POLICY, icon: 'fa-solid fa-circle-plus',
        //   click : () => {this.redirectorService.redirectToProposalVehicle()}
        // },
        // {
        //   description: MENUS.ABOUT, icon: 'fa-solid fa-users-between-lines',
        //   click : () => {this.redirectorService.redirectToPartnership()}
        // },
        {
          description: MENUS.CLOSE_SESSION, icon: 'fa-solid fa-arrow-right-from-bracket',
          click : () => {this.logout()}
        }, 
      
      ];
    }
  }

  navigateTo(menu: any) {
    menu.click.call(this)
    if(this.menuOpen){
      this.itemClicked.emit();
    }
  }

  logout(){
    this.auth.logout();
  }
}
