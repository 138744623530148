import { Component, DoCheck, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges } from '@angular/core';
import { InputDateTimeModel } from './model/input-datetime-model';
import { ValidatorFn, Validators } from '@angular/forms';
import { LABELS } from 'src/app/main/constants/labels.enum';

@Component({
  selector: 'app-input-datetime-custom',
  templateUrl: './input-datetime-custom.component.html',
  styleUrls: ['./input-datetime-custom.component.scss']
})
export class InputDatetimeCustomComponent implements OnInit, OnChanges, DoCheck {

  @Input() model?: InputDateTimeModel;
  @Output() inputChanged: EventEmitter<string> = new EventEmitter<string>();
  @Output() inputEntered: EventEmitter<string> = new EventEmitter<string>();

  defaultMinDate = new Date(-2179009999999999); //Thu Dec 13 1900 23:26:40 GMT+0000
  defaultMaxDate = new Date();
  readonly custom_error=LABELS.DATA_ERROR  

  ngOnInit(): void {
    if (this.model) {
      this.setCustomValidators();
    }
  }

  ngDoCheck(): void {
    const formControl = this.model?.formGroup?.controls[this.model?.formControlName ?? ''];

    if (!formControl)
      return;

    if (this.model?.disabled)
      formControl?.disable();
    else
      formControl.enable();

    if (this.model?.required) {
      formControl?.addValidators(Validators.required);
    }
    else {
      formControl.removeValidators(Validators.required)
    }
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes['model'] && changes['model'].currentValue && this.model) {
      if (this.model.formGroup && this.model.formGroup.controls[this.model.formControlName ?? "genericControl"]) {
        this.setCustomValidators();
      }
    }
  }

  setCustomValidators() {
    if (!this.model?.formGroup || !this.model.formControlName || !this.model.formGroup.controls[this.model.formControlName]) {      
      return;
    }    

    const validators: ValidatorFn[] = [];

    if (this.model.required) {
      validators.push(Validators.required);
    }

    if (validators.length > 0) {
      this.model.formGroup.controls[this.model.formControlName!].setValidators(validators);
    }
  }

  onInputChange(event: any) {
    const value = event.target.value;
    this.inputChanged.emit(value);
  }

  onBlurEvent(event: any) {
    const value = event.target.value;
    this.inputChanged.emit(value);
  }

  onEnterEvent(event: any) {
    const value = event.target.value;
    this.inputEntered.emit(value);
  }
  preventCalendarOpening(event: MouseEvent) {
    event.stopPropagation();
  }

  onInputEvent(event: any) {
    let value = event.target.value.replace(/\D/g, '');
    
    if (this.model?.showTime) {
      value = this.formatDateTimeWithTime(value);
    } else {
      value = this.formatDate(value);
    }
  
    event.target.value = value;
    this.inputChanged.emit(value);
  }
  
  formatDateTimeWithTime(value: string): string {
    if (value.length > 12) value = value.substring(0, 12);
    if (value.length > 2) value = this.insertCharacter(value, 2, '/');
    if (value.length > 5) value = this.insertCharacter(value, 5, '/');
    if (value.length > 10) value = this.insertCharacter(value, 10, ' ');
    if (value.length > 13) value = this.insertCharacter(value, 13, ':');
  
    return value;
  }
  
  formatDate(value: string): string {
    if (value.length > 8) value = value.substring(0, 8);
    if (value.length > 2) value = this.insertCharacter(value, 2, '/');
    if (value.length > 5) value = this.insertCharacter(value, 5, '/');
  
    return value;
  }
  
  private insertCharacter(value: string, position: number, char: string): string {
    return value.slice(0, position) + char + value.slice(position);
  }
}
