import { Injectable, OnDestroy } from '@angular/core';
import { DialogService, DynamicDialogConfig, DynamicDialogRef } from 'primeng/dynamicdialog';

@Injectable({
  providedIn: 'root'
})
export class PopupOpenerService {

  ref: DynamicDialogRef | undefined;
  data : any;

  constructor(private dialogService: DialogService) { }

  show(popupData: any) {
    this.data = popupData.data
    this.ref = this.dialogService.open(popupData.component, popupData.config);
  }

  getOnCloseObs(){
    return this.ref?.onClose;
  }

  hide() {
    this.data = undefined;
    this.ref?.close();
  }

  closeAll(){
    this.dialogService.dialogComponentRefMap.forEach(d => {
      d.destroy();
    })
  }

  getData(){
    return this.data;
  }
}
