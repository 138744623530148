import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { CrypterService } from './crypter.service';
import { IRegisterModel } from '../models/auth/register.model';
import { ValidationRoutingInfo } from '../models/validation-routing-info.model';
import { ISSUE_CONFIRMATION } from '../constants/routes.enum';

@Injectable({
  providedIn: 'root'
})
export class RedirectorService {

  private readonly validation_route = 'validate';
  private readonly login_route = 'login';
  private readonly register_route = 'register';
  private readonly landing_route = 'about';
  private readonly dashboard_route = 'my/dashboard';
  private readonly proposal_list_route = 'my/insurance';
  private readonly proposal__client_list_route = 'my/insurance-client';
  private readonly renewal_list_route = 'my/renewal';
  private readonly proposal_vehicle_route = 'my/quoting-vehicle';
  private readonly proposal_residential_route = 'my/quoting-residential';
  private readonly plans_quoted = 'my/cotizacion/plans';
  private readonly proposal_dental_route = 'my/cotizacion/dental';
  private readonly proposal_pet_route = 'my/cotizacion/pet';
  private readonly proposal_pet_pricing_route = 'my/cotizacion/pet_pricing';  
  private readonly proposal_dental_pricing_route = 'my/cotizacion/dental_pricing';  
  private readonly proposal_vehicle_plans_route = 'my/quoting-vehicle-plans';
  private readonly start_dashboard_route = 'my/inicio';
  private readonly emission_route = 'my/emission';
  private readonly renewal_route = 'my/renewals';
  private readonly emission_payment = 'my/emission/payment';
  private readonly profile_route = 'my/user'
  private readonly partnership = 'my/partnership'
  private readonly tips = 'my/tips'
  private readonly tip = 'my/tip'
  private readonly quotes = 'my/quotes'
  private readonly call_center = 'call-center';
  
  
  constructor(
    private router : Router,
    private crypter : CrypterService ) { }

  // Mmm dudosa división de métodos
  redirectToValidation(user : IRegisterModel, emailSended : boolean, redirectionOrigin : string){
    const data : ValidationRoutingInfo = {
       user : user,
       emailSended : emailSended,
       origin : redirectionOrigin      
    }
    this.router.navigate([this.validation_route], {queryParams : {data : this.crypter.encrypt(JSON.stringify(data))}});  
  }

  redirectToLogin(){
    this.router.navigate([this.login_route]);
  }

  redirectToRegister(){
    this.router.navigate([this.register_route]);
  }
  
  redirectToLanding(){
    this.router.navigate([this.landing_route])
  }

  redirectToDashboard(){
    this.router.navigate([this.dashboard_route]);
  }
  redirectToProposalVehicle(){
    this.router.navigate([this.proposal_vehicle_route]);
  }
  redirectToProposalResidential(){
    this.router.navigate([this.proposal_residential_route]);
  }
  redirectToProposalPlansVehicle(){
    this.router.navigate([this.proposal_vehicle_plans_route]);
  }
  redirectToProposalList(){
    this.router.navigate([this.proposal_list_route]);
  }
  redirectToProposaClientlList(){
    this.router.navigate([this.proposal__client_list_route]);
  }
  redirectToRenewalList(){
    this.router.navigate([this.renewal_list_route]);
  }
  redirectToStart(){
    this.router.navigate([this.start_dashboard_route]);
  }
  redirectToEmission(pricingId : number){
    this.router.navigate([this.emission_route], {queryParams :  {pricingId : pricingId}});
  }

  redirectToRenewal(pricingId : number){
    this.router.navigate([this.renewal_route], {queryParams :  {pricingId : pricingId}});
  }

  redirectToUserProfile(){
    this.router.navigate([this.profile_route]);
  }

  redirectToPlansQuoted(){
    this.router.navigate([this.plans_quoted]);
  }
  redirectToPetPricing(){
    this.router.navigate([this.proposal_pet_route]);
  }

  redirectToDentalPricing(){
    this.router.navigate([this.proposal_dental_route]);
  }

  redirectToPETPlansQuoted(){
    this.router.navigate([this.proposal_pet_pricing_route]);
  }

  redirectToDentalPlansQuoted(){
    this.router.navigate([this.proposal_dental_pricing_route]);
  }


  redirectToPayment(){
    this.router.navigate([this.emission_payment]);
  }

  redirectToProposalConfirmation(){
    this.router.navigate([ISSUE_CONFIRMATION]);
  }

  redirectToPartnership(){
    this.router.navigate([this.partnership]);
  }

  redirectToTips(){
    this.router.navigate([this.tips]);
  }
  redirectToQuotes(){
    this.router.navigate([this.quotes]);
  }
  redirectToTip(idTip : number){
    this.router.navigate([this.tip], {queryParams :  {idTip : idTip}});
  }
  

  redirectToCallCenterLogin(){
    this.router.navigate([this.call_center]);
  }


}

