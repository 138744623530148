import { FormGroup } from "@angular/forms";
import { ICommonInput } from "./common-input.model";

export class InputModel implements ICommonInput {
  placeholder = '';
  type?: string = 'text';
  formControlName?: string;
  parentGroup?: FormGroup;
  customError?: string;
  showCustomError?: boolean;
  icon?: string | undefined;
  required?: boolean = true;
  disabled?: boolean | undefined;
  max?: number;
  min?: number;
  maxLength?: number | null;
  minLength?: number | null;
  onlyNumber?: boolean;
  mask?: string;
  subtitle?: string;
}