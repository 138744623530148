import { ISelectItem } from "./select-item.model";

export interface IVehicle {
    year : string;
    motor? : string;
    chasis? : string;
    uso : ISelectItem
}


export class Vehicle {
    IdMarca: number;
    IdGrupo: number;
    IdVersion: number;
    Anio: number;
    IdUso: number;
    CeroKm: boolean;
    
    SumaAsegurada?: number;
    Factura? : string | null;
    ValorFactura? : string | null;
    FechaFacturacion? : string | null;
    Concesionaria? : string | null;
  
    numeroSerie?: string | null;
    motor?: string | null;
    placa?: string | null;
    repuve?: string | null;
    condicion?: string | null;
    precio?: string | null;

    constructor() {
      this.IdMarca = 0;
      this.IdGrupo = 0;
      this.IdVersion = 0;
      this.Anio = 0;
      this.IdUso = 0;
      this.CeroKm = false;
      this.SumaAsegurada = undefined;
    }
  }