<div *ngIf="model && model.formGroup && ((model?.formControlName ?? '') !== '')">
  <form [formGroup]="model.formGroup">
  <p *ngIf="model.outsideLabel" class="fs-6 text-dark text-left">{{model.outsideLabel}}</p>
  <p-dropdown
  class="custom-dropdown"
    [id]="model.formControlName"
    formControlName={{model.formControlName!}}
    [options]="model.options ?? []"      
    optionLabel="descripcion"
    [filter]="!model.hideFilter && model.options && model.options.length > 4 "
    filterBy="descripcion"
    [showClear]="model.showClear!"
    [placeholder]="model.placeholder! === ''?  'Seleccione una opción' : model.placeholder"
    [virtualScroll]="model.virtualScroll!"
    [virtualScrollItemSize]="model.virtualScrollItemSize!"
    [group]="model.group!"
    [editable]="model.editable!"    
    [disabled]="model.disabled || false"
    [styleClass]="'full-width' + (model.styleClass ?? '')"
    [ngClass]="{'ng-invalid ng-dirty': (model.formGroup.get(model.formControlName!)?.errors || model.formGroup.get(model.formControlName!)?.invalid) && (model.formGroup.get(model.formControlName!)?.touched ?? false), 'inverted-colors': model.invertedColors}"      
    [styleClass]="'w-100 rounded ' + (model.invertedColors ? 'bg-primary ' : '') + (model.closedStyleClass ?? '')"
    [emptyMessage]="'No hay elementos'"
    (onChange)="onSelectChange()"  
    (blur)="onDropdownBlur()"      
  >
  </p-dropdown>

  <div class="col-12">
    <div class="col-12">
        <p *ngIf="model.customError && model.formGroup.controls[model.formControlName!].touched && model.formGroup.controls[model.formControlName!].invalid"
            class="text-danger ">
            {{model.customError}}</p>
    </div>
  </div>
</form>
</div>
