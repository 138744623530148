export enum ButtonTypesEnum {
    Common,
    Raised,
    Icon,
    Image,        
}

export enum ButtonStylesEnum {
    black = 'bg-dark text-light w-100 rounded-3',
    grey = 'bg-secondary bg-opacity-25 px-3 w-100',
    grey_rounded = 'bg-secondary bg-opacity-25 p-0 w-100 rounded-circle',
    without_white_outline='button-custom',
    white_outline = 'rounded bg-white shadow-none border border-dark bg text-dark w-100 bg-opacity-10',
    text_secondary = 'bg-none shadow-none secondary-color-text w-100 underline',
    grey_white='rounded bg-secondary shadow-none border border-gray-400 text-white w-100',
    base_button      = 'rounded base-background text-white    px-3',
    secondary_button = 'rounded company-background text-white px-3 '
}