import { Component, Input } from '@angular/core';
import { LABELS } from 'src/app/main/constants/labels.enum';
import { ConsultantService } from 'src/app/main/services/consultant.service';

@Component({
  selector: 'proposal-coverages',
  templateUrl: './proposal-coverages.component.html',
  styleUrls: ['./proposal-coverages.component.scss']
})
export class ProposalCoveragesComponent{
  @Input() coverages_details : any | undefined;
  @Input() plan : string | undefined;
  @Input() tipo : string | undefined;
  @Input() prod : string | undefined;
  request:any
  
  readonly description_coverage = ""; // = LABELS.DESCRIPTION_COVERAGE;
  readonly value_coverage = ""; //=LABELS.VALUE_COVERAGE;
  readonly title:string=LABELS.TITLE_COVERAGES_DETAIL;
  readonly money_symbol=LABELS.MONEY_SYMBOL
  readonly restrictions=LABELS.RESTRICTIONS
  readonly general_conditions=LABELS.GENERAL_CONDITIONS
    constructor(private consultantProposal:ConsultantService){}

    goPdf(tipo:number){
      this.request={
        Tipo:tipo,
        TipoCategoria:'1',
        IdPlan:this.plan,
        IdProd:this.prod
      }
      this.consultantProposal.getPdf(this.request).subscribe({
        next: (data) => {
          if (data.IsSuccess) {
            this.consultantProposal.openPdf(data.Result,data.Message)
          }
        },
        error: (err) => {
          //this.showError();
        }
      });


    }
}
