<div *ngIf="model" class="col-12">
    <button *ngIf="model.type === buttonTypes.Common"
        mat-button 
        color={{model.color}}
        [ngClass]="model.classes"
        type="button"
        [disabled]="model.disabled"
        (click)="emitClick()"
        >
        {{model.text}}        
    </button>

    <button *ngIf="model.type === buttonTypes.Raised"
        mat-raised-button 
        color={{model.color}}
        [ngClass]="model.classes"
        type="button"
        [disabled]="model.disabled"
        (click)="emitClick()"
        >
        {{model.text}}        
    </button>
    
    <button *ngIf="model.type === buttonTypes.Icon" 
        mat-fab 
        extended
        (click)="emitClick()"
        color = {{model.color}}
        [disabled]="model.disabled"
        type="button"
        [ngClass]="model.classes"
        >
        <mat-icon *ngIf="!model.useSvgIcon">{{model.icon}}</mat-icon>
        <mat-icon *ngIf="model.useSvgIcon" [svgIcon]="model.icon ?? ''"></mat-icon>
        {{model.text}}
    </button>    
</div>

