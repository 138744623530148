import { Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { ButtonStylesEnum, ButtonTypesEnum } from 'src/app/main/constants/buttons.enum';
import { InputTypesEnum } from 'src/app/main/constants/inputs-types.enum';
import { LABELS } from 'src/app/main/constants/labels.enum';
import { IOrdenItem } from 'src/app/main/models/select-item.model';
import { ButtonModel } from 'src/app/shared/models/button.model';
import { InputModel } from 'src/app/shared/models/input.model';
import { ModelCreatorService } from 'src/app/shared/services/model-creator.service';

@Component({
  selector: 'renewal-table',
  templateUrl: './renewal-table.component.html',
  styleUrls: ['./renewal-table.component.scss']
})
export class RenewalTableComponent implements OnInit, OnChanges {
  @Input() columns: { title: string, field?: string, styleClass?: string }[] = [];
  @Input() data: any[] = [];
  @Input() totalRegistros = 50;
  @Input() paginaActual = 1;
  @Input() registrosPorPagina = 10;
  @Input() totalPaginas = 5;
  @Input() isRenewal= false;

  @Output() detailClicked = new EventEmitter<any>();
  @Output() pageChange = new EventEmitter<any[]>();

  parentGroup: FormGroup;
  showDetailBox = false;
  showDetailCardBox = false;
  selectedPolicy: any = null;

  dataFilter: any[] = [];
  sortField: string | undefined;
  sortDirection?: 'asc' | 'desc' = 'asc';
  listOrden:IOrdenItem[]=[];

  personal?: InputModel;
  vehicle?: InputModel;
  zipCode?: InputModel;
  rfc?: InputModel;
  
  btnQuote: ButtonModel;
  btnDownload: ButtonModel;

  title = LABELS.RENEWAL_PROPOSALS;
  activeFilter = 'todas';

  readonly search_table = LABELS.SEARCH_TABLE;
  readonly quote = LABELS.QUOTE_ISSUE;
  readonly renewed_policy = LABELS.RENEWED_POLICY;
  readonly not_interested = LABELS.NOT_INTERESTED;
  textTitle: string = LABELS.SEE_PROPOSAL_DETAIL;
  typeDetail = "renewal";
  constructor(private fb: FormBuilder, private modelCreator: ModelCreatorService) { 
    this.parentGroup = this.fb.group({
      personal: [''],
      vehicle: [''],
      zipCode: [''],
      rfc: [''],
    });

    this.btnDownload = this.modelCreator.createButtonModel("Descargar documentación", ButtonTypesEnum.Raised, ButtonStylesEnum.white_outline);
    this.btnQuote = this.modelCreator.createButtonModel("Cotizar", ButtonTypesEnum.Common, ButtonStylesEnum.black);
  }

  ngOnInit() {
    this.dataFilter = this.data;
    this.initializeInputModels();
  }

  ngOnChanges() {
      this.dataFilter = [...this.data];
  }

  initializeInputModels() {
    if(!this.isRenewal)
      this.title=LABELS.EMISSION_LIST;
    this.personal = this.modelCreator.createInputModel('Nombres y apellidos', InputTypesEnum.common, 'personal', this.parentGroup, undefined, undefined, false, 30);
    this.vehicle = this.modelCreator.createInputModel('Vin', InputTypesEnum.common, 'vehicle', this.parentGroup, undefined, undefined, false, 30);
    this.zipCode = this.modelCreator.createInputModel('Código postal', InputTypesEnum.common, 'zipCode', this.parentGroup, undefined, undefined, false, 30);
    this.rfc = this.modelCreator.createInputModel('Rfc', InputTypesEnum.common, 'rfc', this.parentGroup, undefined, undefined, false, 30);
  }

  search() {
    const { personal, vehicle, zipCode ,rfc } = this.parentGroup.value;
    if(this.isRenewal)
      this.pageChange.emit([1, this.registrosPorPagina,  personal, zipCode, vehicle,this.listOrden]);
    else
      this.pageChange.emit([1, this.registrosPorPagina,  personal, rfc,this.listOrden]);
  }

  emitPageChange() {    
    const { personal, vehicle, zipCode,rfc } = this.parentGroup.value;
    if(this.isRenewal)
      this.pageChange.emit([this.paginaActual, this.registrosPorPagina,  personal, zipCode, vehicle,this.listOrden]);
    else
      this.pageChange.emit([this.paginaActual, this.registrosPorPagina,  personal, rfc,this.listOrden]);
  }

  goToPage(page: number) {
    if (page >= 1 && page <= this.totalPaginas) {
      this.paginaActual = page;
      this.emitPageChange();
    }
  }

  sortDataByField(field?: string, direction?: 'asc' | 'desc') {
    this.listOrden=[]
    if(field=='')
      field='Estado'
    if (this.sortField === field && this.sortDirection === direction) {
      this.sortField = '';
      this.sortDirection = undefined;
    } else {
      this.sortField = field;
      this.sortDirection = direction;
    }
    if(this.sortDirection){
      const orden:IOrdenItem={
        Ascendente:direction==='asc',
        Columna:this.sortField
      }
      this.listOrden.push(orden)
      this.search();
    }else{
      this.search();
    }

  }
  

  toggleDetailBox() {
    this.showDetailBox = !this.showDetailBox;
  }

  openDetailCard(row: any) {
    this.selectedPolicy = row;
    this.showDetailCardBox = true;
  }

  closeDetailCard() {
    this.showDetailCardBox = false;
    this.selectedPolicy = null;
  }

  getDisplayedItemsCount(): number {
    return Math.min(this.paginaActual * this.registrosPorPagina, this.totalRegistros);
  }

  goDetail(row: any) {
    if(!this.showDetailCardBox){
      this.openDetailCard(row);
    }
    else{
      this.closeDetailCard()
    }
  }
  detail(detailId: number,row:any) {
    if(detailId==1){
      this.openDetailCard(row);
      return
    }
    this.detailClicked.emit([detailId,row]);
  }

  goQuote(value:any){
    const id = value[1]['Id'];
    this.detailClicked.emit([value[0],id]);
  }

  
  setFilter(filter: string): void {
    this.activeFilter = filter;
  }
  colorMap: { [key: number]: string } = {
    0:'gray',
    1: 'green',
    2: 'red',
    3: 'red',
    4: 'red',
    9: 'red', 
  };
}
