import { Component, OnDestroy } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { Subscription } from 'rxjs';
import { ERRORS } from 'src/app/main/constants/errors.enum';
import { InputTypesEnum } from 'src/app/main/constants/inputs-types.enum';
import { LABELS } from 'src/app/main/constants/labels.enum';
import { IDomicile } from 'src/app/main/models/domicile.model';
import { EmissionValidStepsEnum } from 'src/app/main/models/emission/emission-valid-steps.enum';
import { QuotingInfoModel } from 'src/app/main/models/emission/quoting-info.model';
import { ISelectItem } from 'src/app/main/models/select-item.model';
import { EmissionFormHandlerService } from 'src/app/main/services/issue/emission-form-handler.service';
import { Masks } from 'src/app/shared/enums/masks.enum';
import { InputDateTimeModel } from 'src/app/shared/input-datetime-custom/model/input-datetime-model';
import { DropdownCustomModel } from 'src/app/shared/models/dropdown.model';
import { InputModel } from 'src/app/shared/models/input.model';
import { ModelCreatorService } from 'src/app/shared/services/model-creator.service';
import { AppState } from 'src/app/state/app/app.state';
import { IssueState } from 'src/app/state/issue/issue.state';
import { LoginState } from 'src/app/state/login/login.state';
import { States } from 'src/app/state/models/states.enum';

@Component({
  selector: 'emission-manager',
  templateUrl: './emission-manager.component.html',
  styleUrls: ['./emission-manager.component.scss']
})
export class EmissionManagerInfoComponent implements OnDestroy{

  personalInfoForm : FormGroup;

  title = LABELS.MANAGER_DATA_TITLE;

  nameModel? : InputModel;
  sobreNameModel? : InputModel;
  nameMotherModel? : InputModel;
  phoneModel? : InputModel;
  cpfModel? : InputModel;
  emailModel? : InputModel;
  genderModel? : DropdownCustomModel;
  civilStatusModel? : DropdownCustomModel;
  birthdateModel? : InputDateTimeModel;
  socialNameModel? : InputModel;
  salaryModel? : DropdownCustomModel;
  jobModel? : DropdownCustomModel;
  checkLbl = LABELS.ARE_YOU_POLITCALLY_EXPOSED;
  chk_lbl : string = LABELS.LEGAL_DATA_DISCLAIMER;
  isPet  = false;
  isDental  = false;

  issue$ = new Subscription();

  readonly yes = LABELS.QUESTION_ISNEW_YES
  readonly no=LABELS.QUESTION_ISNEW_NO

  readonly relationship_with_owner=LABELS.RELATIONSHIP_WITH_OWNER
  regions? : any[];
  selectGradeRelationship: ISelectItem[] |undefined;
  relationshipSelect? : DropdownCustomModel;
  quotingInfo? : QuotingInfoModel;
  quotingInfoSub : Subscription = new Subscription();
  constructor(private modelCreator : ModelCreatorService,
    private formHandler : EmissionFormHandlerService,
    private issueState : IssueState,
    private loginState : LoginState,    
  ) {    
    this.personalInfoForm = this.formHandler.getFormControl(EmissionValidStepsEnum.manager_data) as FormGroup;
    //this.personalInfoForm!.controls['responsableEsTitular'].setValue(1);
    this.loadQuotingInfo();
    this.createInputs();
    this.patchValuesFromState();
  }


  loadQuotingInfo() {
    this.quotingInfoSub = this.issueState.checkState().subscribe({
      next :(state) => {
        const quotingState = state.quotingState;
        if(quotingState.state === States.success){
          this.quotingInfo = quotingState.quotingInfo;
          if(!this.regions){
            this.regions = quotingState.quotingInfo?.Regiones ?? [];
          }
          this.loadData(this.quotingInfo!);            
        }
        else{
          // de nuevo, no debería pasar nunca
        }
      }      
    })
  }
  loadData(quotingInfo : QuotingInfoModel){
    this.selectGradeRelationship = quotingInfo.Relaciones!.map(status => ({
      id: status.Id,
      descripcion: status.Descripcion,
    }));
    this.relationshipSelect = this.modelCreator.createSelectModel('relacionTitular',LABELS.SELECT, this.selectGradeRelationship!, this.personalInfoForm!, '','',false)
  }
  createInputs(){
    this.genderModel = this.modelCreator.createSelectModel('genero',LABELS.GENDER, [], this.personalInfoForm, LABELS.GENDER, '', true);
    this.civilStatusModel = this.modelCreator.createSelectModel('estadoCivil', LABELS.SELECT, [], this.personalInfoForm!,LABELS.CIVIL_STATUS,'',true);

    this.genderModel.outsideLabel = LABELS.GENDER;
    this.civilStatusModel.outsideLabel = LABELS.CIVIL_STATUS;

    this.birthdateModel = this.modelCreator.createCalendarModel('fechaNacimiento', this.personalInfoForm, true,LABELS.BIRTHDATE, false, LABELS.BIRTHDATE);
    this.phoneModel = this.modelCreator.createInputModel(LABELS.PHONE, InputTypesEnum.phone, 'telefono', this.personalInfoForm, undefined, undefined, true);
    this.emailModel = this.modelCreator.createInputModel(LABELS.EMAIL, InputTypesEnum.email, 'email', this.personalInfoForm, undefined, undefined, false);
    this.cpfModel = this.modelCreator.createInputModel(LABELS.DOCUMENT_ID, InputTypesEnum.mask, 'cpf', this.personalInfoForm!, ERRORS.CPF_OWNER_INVALID,undefined,false,14,false,false,Masks.cpf);
    this.socialNameModel = this.modelCreator.createInputModel(LABELS.SOCIAL_NAME, InputTypesEnum.common, 'nombreSocial', this.personalInfoForm);
    this.nameModel = this.modelCreator.createInputModel(LABELS.NAME, InputTypesEnum.common, 'nombreResponsable', this.personalInfoForm);
    this.sobreNameModel = this.modelCreator.createInputModel(LABELS.LASTNAME, InputTypesEnum.common, 'apellidoResponsable', this.personalInfoForm);
    this.nameMotherModel = this.modelCreator.createInputModel(LABELS.NAME_MOTHER, InputTypesEnum.common, 'nombreMadreAsegurado', this.personalInfoForm);
    this.salaryModel = this.modelCreator.createSelectModel('rangoSalarial',LABELS.SALARY_RANGE, [], this.personalInfoForm, LABELS.GENDER, '', false);
    this.jobModel = this.modelCreator.createSelectModel('trabajo',LABELS.JOB, [], this.personalInfoForm, LABELS.GENDER, '', false);
  }

  patchValuesFromState(){
    if(!this.loginState.user)
      return;

    const user = this.loginState.user;

    const civilStatus = user.EstadosCiviles?.map((e: any) => {return {descripcion : e.Descripcion, id : e.Id}} );
    this.civilStatusModel!.options = civilStatus ?? [];

    const genders = user.Generos;
    this.genderModel!.options = genders?.map((e: any) => {return {descripcion : e.Descripcion, id : e.Id}} );    

  }
  validateIsResident(value:any){
    this.personalInfoForm!.get('responsableEsTitular')?.setValue(value);
    if(value==0){
      this.cpfModel!.required=true;
      this.sobreNameModel!.required=true;
      this.nameModel!.required=true;
      this.phoneModel!.required=true;
      this.emailModel!.required=true;
      this.relationshipSelect!.required=true;
      this.personalInfoForm.get('disclaimer')?.setValidators([Validators.required]);
    }else{
      if(value==0){
        this.cpfModel!.required=false;
        this.sobreNameModel!.required=false;
        this.nameModel!.required=false;
        this.phoneModel!.required=false;
        this.emailModel!.required=false;
        this.relationshipSelect!.required=false;
        this.personalInfoForm.get('disclaimer')?.clearValidators();
        this.personalInfoForm.get('disclaimer')?.updateValueAndValidity();
      }
    }
  }
  ngOnDestroy(): void {
    this.issue$.unsubscribe();
  }
}
