import { inject } from '@angular/core';
import { CanActivateFn, Router } from '@angular/router';
import { AuthenticatorService } from '../services/authenticator.service';


export const authenticationGuard: CanActivateFn = (route, state) => {  
  const router = inject(Router)
  const auth = inject(AuthenticatorService)
  
  const token = auth.isLogged();
  if(token){
    return true;
  }
  else{
    router.navigate(['about']);
    return false;
  } 

};
