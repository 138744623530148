import { Component } from '@angular/core';

@Component({
  selector: 'app-emission-beneficiaries',
  templateUrl: './emission-insured.component.html',
  styleUrls: ['./emission-insured.component.scss']
})
export class EmissionBeneficiariesComponent {

}
