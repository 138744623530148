import { EmissionInfoState } from "./emission-info.state";
import { EmissionState } from "./emission.state";
import { PricingState } from "./pricing.state";
import { ProspectusQuoteState } from "./prospectus-quote-info.state";
import { QuoteInfoState } from "./quote-info.state";
import { QuotingInfoState } from "./quoting-info.state";
import { States } from "./states.enum";

export class IssueFullState {
    pricingState : PricingState;
    quotingState : QuotingInfoState;
    quoteState : QuoteInfoState;
    prospectusQuoteState : ProspectusQuoteState;
    emissionDataState : EmissionInfoState;
    emissionState : EmissionState    

    constructor() {
        this.quotingState = { state : States.empty }
        this.pricingState = { state : States.empty }
        this.emissionState = { state : States.empty }  
        this.emissionDataState = { state : States.empty }  
        this.quoteState = { state : States.empty }   
        this.prospectusQuoteState = { state : States.empty }        
    }
}