import { Injectable } from '@angular/core';
import * as CryptoJS from 'crypto-js';

@Injectable({
  providedIn: 'root'
})
export class CrypterService {
  
  // Esto hay que moverlo a una variable de entorno según el arquitecto :)
  private readonly key : string = "coscunrhdkCnsaholifdMvbjo3deand3";
  private readonly iv  : string = "jsKidfrankkatyb4";

  encrypt(data : string){
    if((data as any) instanceof Object){
      data = JSON.stringify(data);
    }

    const _key = CryptoJS.enc.Utf8.parse(this.key);
    const _iv = CryptoJS.enc.Utf8.parse(this.iv);
    const encrypted = CryptoJS.AES.encrypt(
      data.toString(), _key,
      {
        keySize: 8,
        iv: _iv,
        mode: CryptoJS.mode.ECB,
        padding: CryptoJS.pad.Pkcs7        
      });
    
    return encrypted.toString();
  }


  decrypt(body: any): any {
    
    const _key = CryptoJS.enc.Utf8.parse(this.key);
    const _iv = CryptoJS.enc.Utf8.parse(this.iv);

    const decrypted = CryptoJS.AES.decrypt(body, _key, {
        keySize: 8,
        iv: _iv,
        mode: CryptoJS.mode.ECB,
        padding: CryptoJS.pad.Pkcs7        
    });
    const result = decrypted.toString(CryptoJS.enc.Utf8);
    return result;
  }
}
