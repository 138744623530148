

<div class="col-12 d-flex text-center align-content-center" *ngIf="steps">
  <div class="step-container responsive-stepper justify-content-start" [ngClass]="'step-count-' + steps.length">
    <div class="step" *ngFor="let step of steps; let i = index" [class.completed]="i <= (currentStep ?? 0)">
      <div class="step-check" 
        tabindex="0"
        (keypress)="emitStepClick(i)"
        (click)="emitStepClick(i)">
        <i class="fa-solid fa-check icon"></i>
      </div>
      <div class="step-line" *ngIf="i < steps.length - 1"></div>
    </div>
  </div>
</div>