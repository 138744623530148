import { Component, OnDestroy, OnInit } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { Subscription } from 'rxjs';
import { LABELS } from 'src/app/main/constants/labels.enum';
import { QuotingInfoModel } from 'src/app/main/models/emission/quoting-info.model';
import { QuotingVehicleValidStepsEnum } from 'src/app/main/models/emission/quoting-vehicle-steps.enum';
import { ClienteProducto, Conductor, DatosGenerales, DatosLiberty, IAsegurado, IDataDriver, IDataOwner, IInsured, IRenewal, IRenovaciones, IVehicles, IVehiculo, RequestData, Vehiculo } from 'src/app/main/models/issue/issue-data.model';
import { PriceModel, PricingResult } from 'src/app/main/models/issue/price.model';
import { PricedPlanModel } from 'src/app/main/models/issue/priced-plan.enum';
import { QuoteInfoModel } from 'src/app/main/models/issue/quote-info.model';
import { ISelectItem } from 'src/app/main/models/select-item.model';

import { ConsultantService } from 'src/app/main/services/consultant.service';
import { QuotingFormHandlerService } from 'src/app/main/services/issue/quoting-form-handler.service';
import { RedirectorService } from 'src/app/main/services/redirector.service';
import { PopUpTypesEnum } from 'src/app/shared/enums/popup-types.enum';
import { DropdownCustomModel } from 'src/app/shared/models/dropdown.model';
import { PopupMessageComponent } from 'src/app/shared/popup-message/popup-message.component';
import { PopupOpenerService } from 'src/app/shared/services/popup-opener.service';
import { IssueState } from 'src/app/state/issue/issue.state';
import { LoginState } from 'src/app/state/login/login.state';
import { States } from 'src/app/state/models/states.enum';

@Component({
  selector: 'issue-insurance-vehicle',
  templateUrl: './issue-insurance-vehicle.component.html',
  styleUrls: ['./issue-insurance-vehicle.component.scss']
})

export class IssueInsuranceVehicleComponent implements OnInit,OnDestroy{
  selectedDate: Date | null = null;
  parentGroup: FormGroup |undefined;
  isQuote= false;
  isQuoteSteps= true;
  isLoadPlans= false;
  hasExecutedCheckState= false;
  exemptionReasonSelectList=[{ id: "1", descripcion : "Pessoa com deficiencia" }, { id: "2", descripcion : "Outros" }]
  questions:string[]=[LABELS.INSURANCE_QUESTIONS_PROPOSAL,LABELS.INSURANCE_QUESTIONS_PROPOSAL1,LABELS.INSURANCE_QUESTIONS_PROPOSAL2];

  readonly title_issue=LABELS.ISSUE_DATA_TITLE;
  readonly title_vehicle=LABELS.VEHICLE_DATA_TITLE;
  readonly title_insured=LABELS.VEHICLE_DATA_INSURED;
  readonly title_payment=LABELS.PERIODICITY_DATA_TITLE;
  readonly title_renewal=LABELS.VEHICLE_DATA_RENEWAL;
  readonly title_personal=LABELS.PERSONAL_DATA_TITLE;

  readonly title_beneficiary=LABELS.BENEFICIARY_DATA_TITLE;
  readonly title_responsible=LABELS.RESPONSIBLE_DATA_TITLE;
  readonly title_driver=LABELS.DRIVER_DATA_TITLE;
  readonly load_plans=LABELS.LOAD_PLANS;
  readonly back_dashboard=LABELS.BACK_DASHBOARD

  readonly title_banner_vehicle1 = LABELS.TITLE_BANNER_VEHICLE_1;
  readonly title_banner_vehicle2 = LABELS.TITLE_BANNER_VEHICLE_2;
  readonly title_banner_vehicle3 = LABELS.TITLE_BANNER_VEHICLE_3;
  readonly title_banner_vehicle4 = LABELS.TITLE_BANNER_VEHICLE_4;
  readonly subtitle_banner_vehicle1 = LABELS.SUBTITLE_BANNER_VEHICLE1;
  readonly body1_banner_vehicle1 = LABELS.BODY1_BANNER_VEHICLE1;
  readonly body2_banner_vehicle1 = LABELS.BODY2_BANNER_VEHICLE1;
  readonly subtitle_banner_vehicle2 = LABELS.SUBTITLE_BANNER_VEHICLE2;
  readonly body1_banner_vehicle2 = LABELS.BODY1_BANNER_VEHICLE2;
  readonly body2_banner_vehicle2 = LABELS.BODY2_BANNER_VEHICLE2;
  readonly subtitle_banner_vehicle3 = LABELS.SUBTITLE_BANNER_VEHICLE3;
  readonly body1_banner_vehicle3 = LABELS.BODY1_BANNER_VEHICLE3;
  readonly body2_banner_vehicle3 = LABELS.BODY2_BANNER_VEHICLE3;
  readonly subtitle_banner_vehicle4 = LABELS.SUBTITLE_BANNER_VEHICLE4;
  readonly body1_banner_vehicle4 = LABELS.BODY1_BANNER_VEHICLE4;
  readonly body2_banner_vehicle4 = LABELS.BODY2_BANNER_VEHICLE4;
  

  quotingInfo? : QuotingInfoModel;
  quoteInfo? : QuoteInfoModel[];
  quoteInfoSelected? : QuoteInfoModel;
  quotingInfoSub : Subscription = new Subscription();
  quoteInfoSub : Subscription = new Subscription();

  step =0;
  steps: any[] = [];

  vigency ="";  
  quotes?: DropdownCustomModel;
  prices:any[]=[]
  
  pricingRequest : any;
  popupData : any;
  constructor(
    private consultantProposal:ConsultantService,    
    private formHandler : QuotingFormHandlerService,    
    private redirector : RedirectorService,
    private issue_state : IssueState,
    private login_state : LoginState,
    private popupSvc : PopupOpenerService){
  }  


  loadQuotingInfo() {    
    // ya no me importa nada, si tocó el botón volver no se limpia nada
    const userPressedBack = this.issue_state.fullState.pricingState.backedFromPlans;
    if(!userPressedBack)
      this.issue_state.clearPricing();
      
    this.checkState();

  }

  checkState(){
    this.quotingInfoSub = this.issue_state.checkState().subscribe({
      next :(state) => {      
        if(state.quotingState.state === States.success){
          this.quotingInfo = state.quotingState.quotingInfo;
          this.vigency = state.prospectusQuoteState.vigency!;
          if(!this.steps || this.steps.length === 0){
              this.setValidSteps(this.quotingInfo!);
              this.checkStateQuote(this.quotingInfo!);

              // Si se fue de los planes con el botón volver
              if(state.pricingState.backedFromPlans){
                this.step = this.steps.length - 1;          
                state.pricingState.backedFromPlans = false;
              }
          }

        }
        else{
          // mensajito... 
          // En teoría siempre deberíamos tener los datos de cotización
          // peeero... 
        }
        
        //let pricingState = state.pricingState
        //if(pricingState.state === States.success){
        //  if (pricingState.pricing?.IsSuccess && (pricingState.pricing.Result?.Planes?.length ?? 0) > 0) {
        //    //this.isQuote=true;
        //    this.isLoadPlans=false;            
        //    this.issue_state.setQuotingRequest(this.pricingRequest);
        //  }
        //  else{
        //    // mensajito
        //  }
        //}
      }
    })
    
  }


  checkStateQuote(quotingInfo:QuotingInfoModel){
    this.quoteInfoSub = this.issue_state.checkState().subscribe({
      next :(state) => {      
        if(state.prospectusQuoteState.state === States.success && state.quotingState.state === States.success){
          this.quoteInfoSelected = state.prospectusQuoteState.quoteInfo
        if(this.quoteInfoSelected?.CotDatosMinimos)
          this.setForms(this.quoteInfoSelected!.CotDatosMinimos!.toString(),quotingInfo,this.quoteInfoSelected!.PlanesCotizados,state.prospectusQuoteState?.quoteInfo?.Cliente)
        }
        else{
          // mensajito... 
          // En teoría siempre deberíamos tener los datos de cotización
          // peeero... 
        }
      }
    })
    this.quotingInfoSub.unsubscribe();
  }


  ngOnInit(): void {
    this.loadQuotingInfo();
  }

  setForms(quoteData:string, quotingInfo:QuotingInfoModel, plans:PricedPlanModel[],client:any){
    if(client){
      this.login_state.user=client
      this.login_state.user!.IdCliente=client.Id
      this.login_state.user!.esCliente=true;
      this.login_state.setUser(client)
    }

    const clienteProducto = JSON.parse(quoteData);

    // quito lo de ir a planes cotizados
    // plans = plans?.filter(p => {
    //   let fechaParts = p.FechaVencimiento!.split("/");
    //   let fecha = new Date(+fechaParts[2], +fechaParts[1] - 1, +fechaParts[0]);
    //   return fecha >= new Date();
    // });

    // if(plans && plans?.length>0 && this.issue_state.fullState?.prospectusQuoteState?.goToPlans){
    //   const pricingResult: PricingResult = {
    //     Id: quotingInfo.CotId,
    //     Planes: plans,
    //     Producto:''
    //   };
    //   const priceModel: PriceModel = {
    //     Result: pricingResult,
    //     Message:'Ok',
    //     Code:200,
    //     Id:quotingInfo.CotId,
    //     IsSuccess:true
    //   };
    //   // no se debería actualizar así pero bueno
    //   this.issue_state.fullState.pricingState.initialRequest=clienteProducto
    //   this.issue_state.fullState.pricingState.state=States.success
    //   this.issue_state.fullState.pricingState.pricing=priceModel;
    //   this.redirector.redirectToProposalPlansVehicle();
    // }


    const vehicleForm = this.parentGroup!.controls['vehicleMex'];
    const insuredForm = this.parentGroup!.controls['insuredMex'];

    const vehiculo  = clienteProducto.Vehiculo;
    const asegurado  = clienteProducto.Asegurado;
    const renovaciones  = clienteProducto.Renovaciones;

    vehicleForm.patchValue({
      year: { id: vehiculo.Anio.toString(),descripcion:vehiculo.Anio?.toString()},
      make: { id: vehiculo.IdMarca.toString(),descripcion:vehiculo.Marca?.toString() },
      model: { id: vehiculo.IdGrupo.toString(),descripcion:vehiculo.Grupo?.toString()},
      version: { id: vehiculo.IdVersion.toString(),descripcion:vehiculo.Version?.toString() },
      NroPolizaAnterior:renovaciones ? renovaciones.NroPolizaAnterior : null
    });


    const fechaParts = this.login_state.user!.FechaNacimiento && this.login_state.user!.FechaNacimiento !== '' 
    ? this.login_state.user!.FechaNacimiento.split("/") 
    : [];

    let fechaNac: Date | null = null; 

    if (fechaParts.length === 3) {
        const day = +fechaParts[0];
        const month = +fechaParts[1] - 1;
        const year = +fechaParts[2];
        
        if (!isNaN(day) && !isNaN(month) && !isNaN(year)) {
            fechaNac = new Date(year, month, day);
        }
    }
    insuredForm.patchValue({
      zipCode: asegurado.CodigoPostal,
      zipCodeDriver: asegurado.EsConductor ? asegurado.CodigoPostal : vehiculo.Conductor.CodigoPostal,
      // typePerson: this.login_state.user!.TipoPersona ? { id: this.login_state.user!.TipoPersona?.toString(), descripcion: quotingInfo.TipoPersonas.find(t => t.Id === this.login_state.user!.TipoPersona?.toString())?.Descripcion ?? "" } : "",
      isDriver:asegurado.EsConductor ? '1' : '0',
      birthdate:fechaNac,
      gender:this.login_state.user!.IdGenero ? { id: this.login_state.user!.IdGenero.toString(), descripcion: quotingInfo.Generos.find(g => g.Id === this.login_state.user!.IdGenero?.toString())?.Descripcion ?? "" } : "",
      civilStatus:this.login_state.user!.IdEstadoCivil ? { id: this.login_state.user!.IdEstadoCivil.toString(), descripcion: quotingInfo.EstadosCiviles.find(g => g.Id === this.login_state.user!.IdEstadoCivil?.toString())?.Descripcion ?? "" } : ""
    });
    this.quoteInfoSub.unsubscribe();
  }

  selectMenuItem(item: any) {

    const currentForm : string = this.steps[item].formName;
    if (!this.parentGroup!.controls[currentForm].valid) {
      this.parentGroup!.markAllAsTouched();
      return;
    }
    this.step=item;
  }


  setValidSteps(quotingInfo?: QuotingInfoModel) {
    this.steps = [];
    if (quotingInfo !== undefined) {
      this.quotingInfo = quotingInfo;
    }
  
    if (this.quotingInfo?.EsVehiculo) {
      this.steps.push({ id: 0, descripcion: this.title_vehicle, formName: QuotingVehicleValidStepsEnum.vehicleMexico, label: '' });
      this.steps.push({ id: 1, descripcion: this.title_insured, formName: QuotingVehicleValidStepsEnum.insured_data_mex, label: '' });
      this.steps.push({ id: 2, descripcion: this.title_payment, formName: QuotingVehicleValidStepsEnum.payment_data, label: '' });
    }
  
    this.formHandler.createForm(this.quotingInfo?.EsVehiculo, false, false, false);
    this.parentGroup = this.formHandler.getForm();
  }
  
  

  next() {
    const currentFormName: string = this.steps[this.step].formName;
    const currentFormControl = this.parentGroup!.controls[currentFormName];
    this.steps[this.step].checked = currentFormControl?.valid;
  
    if (currentFormControl && !currentFormControl.valid) {
        this.parentGroup!.markAllAsTouched();
        return;
    }
  
    if (this.step + 1 === this.steps.length) {
      const vehicle: IVehicles = this.parentGroup!.controls['vehicleMex'].value;
      const insured: IInsured = this.parentGroup!.controls['insuredMex'].value;
      const client = this.login_state.user;
      const quotingInfo = this.issue_state.fullState.quotingState.quotingInfo!;
  
          //traer la vigencia de la póliza base
          //const generalInfo = JSON.parse(this.quoteInfoSelected!.CotDatosMinimos!.toString());
          //this.issue_state.fullState.prospectusQuoteState.vigency=generalInfo.DatosGenerales.FechaInicioVigencia
        const datosGenerales: DatosGenerales = {
            FechaInicioVigencia: new Date().toDateString(),
            FechaFinVigencia: new Date(new Date().setFullYear(new Date().getFullYear() + 1)).toDateString(),
            IdTipoProducto: quotingInfo.idTipoProducto,
            IdCampania: 1,
            FrecuenciaPago: "",
            Plazo: "",
            EsRenovacion: false
        };
  
        const asegurado: IAsegurado = {
            Nombre: client?.Nombre || "",
            Apellido: client?.Apellido || "",
            EstadoCivil: insured.civilStatus?.id,
            Genero: insured.gender?.id,
            TipoPersona: '1',
            FechaNac: insured.birthdate,
            Telefono: client?.Telefono?.toString() || "",
            Mail: client?.Email || "",
            CodigoPostal: insured.zipCode,
            EsConductor: insured.isDriver === '1',
            IdCliente: client?.IdCliente || 1
        };
  
        const conductor: Conductor = {
            Nombre: insured.isDriver === '1' ? client?.Nombre || "" : "",
            Apellido: insured.isDriver === '1' ? client?.Apellido || "" : "",
            Genero: insured.isDriver === '1' ? insured.gender?.id : insured.driverGender?.id,
            FechaNac: insured.isDriver === '1' ? insured.birthdate : insured.driverBirthdate,
            EstadoCivil: insured.isDriver === '1' ? insured.civilStatus?.id : insured.driverCivilStatus?.id,
            CodigoPostal: insured.isDriver === '1' ? insured.zipCode : insured.zipCodeDriver,
            Telefono: "",
            Mail: ""
        };
  
        const vehiculo: IVehiculo = {
            Patente: "",
            TipoVehiculo: "AUT",
            IdMarca: vehicle.make?.id,
            Descripcion: `${vehicle.make?.descripcion} ${vehicle.version?.descripcion}`,
            IdGrupo: vehicle.model?.id,
            IdVersion: vehicle.version?.id,
            Anio: vehicle.year?.id || vehicle.year,
            //estamos mandando uso y CeroKm harcodeado
            IdUso: 1,
            CeroKm: false,
            Chasis: "",
            FechaFacturacion: null,
            EstadoCirculacion: null,
            SumaAsegurada: 0,
            CodigoPostal: insured.zipCode,
            Conductor: conductor
        };
  
        const renovaciones: IRenovaciones = { NroPolizaAnterior: vehicle.NroPolizaAnterior };
  
        const clienteProducto: ClienteProducto = {
            CotId: quotingInfo.CotId,
            idCliente: client?.IdCliente ?? 1,
            idTipoProducto: quotingInfo.idTipoProducto,
            Vehiculo: vehiculo,
            Asegurado: asegurado,
            DatosGenerales: datosGenerales,
            Renovaciones: vehicle.NroPolizaAnterior ? renovaciones : undefined
        };
  
        this.isQuoteSteps = false;
        this.isLoadPlans = true;
        this.consultantProposal.pricesSelect = this.prices;
        this.pricingRequest = { ...clienteProducto };

        //this.issue_state.quote(clienteProducto);
        //por ahora simulamos la cotizacion
        this.issue_state.quoteExemple();
        this.unsubscribeSubscriptions();
        //this.redirector.redirectToRenewal(16);
        this.redirector.redirectToProposalPlansVehicle();
        this.issue_state.setQuotingRequest(this.pricingRequest);
  
        return;
    }
    this.step++
  }

  unsubscribeSubscriptions() {
    this.quotingInfoSub.unsubscribe();
    this.quoteInfoSub.unsubscribe();
  }


  backStep(){
    if(this.step === 0){
      this.redirector.redirectToDashboard();
    }
    else{
      this.step --;
    }
  }
  
  back(){
    this.redirector.redirectToDashboard();
  }
  showMessagePopUp(title : string, messages : string[], type : PopUpTypesEnum = PopUpTypesEnum.success){
    this.popupData={
      component:PopupMessageComponent,
      config : {
        title : title,
        hideHeader : true
      },
      
      data : {
        type : type,
        messages : messages,
        button:true
      }
    };
    this.popupSvc.show(this.popupData)
  }

  ngOnDestroy(): void {
    this.issue_state.fullState.quoteState.quoteId=null;
    this.quotingInfoSub.unsubscribe();
    this.quoteInfoSub.unsubscribe();
  }
}
