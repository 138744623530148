import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { CustomButtonComponent } from './custom-button/custom-button.component';
import { CustomSelectComponent } from './custom-select/custom-select.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MaterialModule } from './material/material.module';
import { CustomInputComponent } from './custom-input/custom-input.component';
import { DividedInputComponent } from './divided-input/divided-input.component';
import { PasswordConfirmationComponent } from './password-confirmation/password-confirmation.component';
import { MatIconModule } from '@angular/material/icon';
import { CustomLabelComponent } from './custom-label/custom-label.component';
import { DetailButtonComponent } from './detail-button/detail-button.component';
import { CustomPopUpComponent } from './custom-popup/custom-popup.component';
import { ModalPopUpComponent } from './modal-popup/modal-popup.component';
import { InputDatetimeCustomComponent } from './input-datetime-custom/input-datetime-custom.component';
import { CustomStepperComponent } from './custom-stepper/custom-stepper.component';
import { CalendarModule } from 'primeng/calendar';
import {DropdownModule} from 'primeng/dropdown';
import { OverlayModule } from 'primeng/overlay';
import { PopupMessageComponent } from './popup-message/popup-message.component';
import { InputTextModule } from 'primeng/inputtext';
import { CheckboxModule } from 'primeng/checkbox';
import { RadioButtonModule } from 'primeng/radiobutton';
import { DialogService, DynamicDialogModule } from 'primeng/dynamicdialog';
import { PasswordModule } from 'primeng/password';
import { InsuranceQuestionComponent } from './insurance-question/insurance.question.component';
import { SideAdvicesComponent } from './side-advices/side-advices.component';
import { PricingListComponent } from './pricing-list/pricing-list.component';
import { PriceComponent } from '../features/issue/price/price.component';
import { FooterComponent } from './footer/footer.component';
import { NewHomeComponent } from './new-home/new-home.component';
import { IssueStepsAsideComponent } from './issue-steps-aside/issue-steps-aside.component';
import { PopupCancelComponent } from './popup-cancel/popup-cancel.component';
import { PopupDownloadsComponent } from './popup-downloads/popup-downloads.component';
import { BrandsBannerComponent } from './brands-banner/brands-banner.component';
import { InputMaskModule } from 'primeng/inputmask';
import { PlanComparerComponent } from '../features/issue/plan-comparer/plan-comparer.component';
import { InputNumberModule } from 'primeng/inputnumber';
import { OverlayPanelModule } from 'primeng/overlaypanel';
import { TooltipModule } from 'primeng/tooltip';
import { QuestionInputComponent } from './question-input/question-input.component';
import { PopupInsuranceComponent } from './popup-insurance-data/popup-insurance-data.component';
import { EmissionPlanDescriptionComponent } from '../features/issue/issue-plan-description/issue-plan-description.component';




@NgModule({
  declarations: [
    CustomButtonComponent,
    CustomSelectComponent,
    CustomInputComponent,
    DividedInputComponent,
    PasswordConfirmationComponent,  
    CustomLabelComponent,
    DetailButtonComponent,
    CustomPopUpComponent,
    ModalPopUpComponent,
    InputDatetimeCustomComponent,    
    CustomStepperComponent, 
    PopupMessageComponent,
    SideAdvicesComponent,
    PricingListComponent,
    PriceComponent,
    InsuranceQuestionComponent,
    FooterComponent,
    NewHomeComponent,
    IssueStepsAsideComponent,
    PopupCancelComponent,
    PopupDownloadsComponent,
    BrandsBannerComponent,
    PlanComparerComponent,
    QuestionInputComponent,
    PopupInsuranceComponent,
    EmissionPlanDescriptionComponent
  ],
  imports: [
    CommonModule,
    FormsModule,
    MaterialModule,
    ReactiveFormsModule,
    CalendarModule,
    DropdownModule,
    OverlayModule,
    InputTextModule,
    CheckboxModule ,
    RadioButtonModule,
    DynamicDialogModule,
    PasswordModule,
    InputMaskModule,
    InputNumberModule,
    TooltipModule 
  
  ],
  exports:[
    CustomButtonComponent,
    CustomStepperComponent,
    CustomSelectComponent,
    CustomInputComponent,
    DividedInputComponent, 
    PasswordConfirmationComponent,
    MaterialModule,
    CustomLabelComponent,
    DetailButtonComponent,
    CustomPopUpComponent,
    ModalPopUpComponent,
    InputDatetimeCustomComponent,
    CalendarModule,
    OverlayModule,
    CheckboxModule,
    PopupMessageComponent,
    ReactiveFormsModule,
    RadioButtonModule,
    DynamicDialogModule,
    SideAdvicesComponent,
    PricingListComponent,
    PriceComponent,
    InsuranceQuestionComponent,
    FooterComponent,
    NewHomeComponent,
    IssueStepsAsideComponent,
    PopupMessageComponent,
    PopupDownloadsComponent,
    BrandsBannerComponent,
    PlanComparerComponent,
    InputNumberModule,
    OverlayPanelModule,
    TooltipModule,
    QuestionInputComponent,
    PopupInsuranceComponent,
    EmissionPlanDescriptionComponent
  ],
  providers: [
    DialogService
  ]
})
export class SharedModule { }
