<div class="justify-content-center col-12 col-md-11 m-auto">
  <p class="title-quote title">{{title}}</p>
</div>

<div class="justify-content-center col-12 col-md-11 m-auto">

  <div *ngIf="currentStep===0">
    <div class="row pt-3">  
      <custom-input [model]="zipCodeInput!"></custom-input>
    </div>  

    <div class="row pt-3">
      <div>
        <custom-select [model]="civilStatusSelect" (selectChanged)="validateCivilStatus()">
        </custom-select>
      </div>
    </div>

    <div class="row pt-3">
      <div>
        <custom-select [model]="genderSelect" (selectChanged)="validateGender()">
        </custom-select>
      </div>
    </div>
    <div class="mt-4 mb-4">
      <app-input-datetime-custom [model]="driverBirthdateModel!"></app-input-datetime-custom>
    </div>
  </div>

  <div class="row mb-4 mt-4">
    <custom-button [model]="btnReturn!" (customClick)="return()" class="col-6 col-sm-4 mt-2"
      *ngIf="currentStep<=3"></custom-button>
    <custom-button [model]="btnContinue!" (customClick)="next()" class="col-6 col-sm-6 offset-sm-2 mt-2"
      *ngIf="true"></custom-button>
  </div>
</div>