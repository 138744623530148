import { ChangeDetectorRef, Component, Input, OnInit } from '@angular/core';
import { LABELS } from 'src/app/main/constants/labels.enum';
import { ConsultantService } from 'src/app/main/services/consultant.service';

@Component({
  selector: 'popup-insurance-data',
  templateUrl: './popup-insurance-data.component.html',
  styleUrls: ['./popup-insurance-data.component.scss']
})
export class PopupInsuranceComponent implements OnInit{
  @Input() company : any | undefined;
  content="";
  title:string=LABELS.INSURANCE_DATA
    constructor(private consultantProposal:ConsultantService){
    }
    ngOnInit(): void {
      this.consultantProposal.getInsuranceData(this.company).subscribe({
        next: (data) => {
          if (data.IsSuccess) {
            this.content=data.Result
          }
        },
        error: (err) => {
          //this.showError();
        }
      });
    }

}
