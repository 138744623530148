import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { SharedModule } from 'src/app/shared/shared.module';
import { IssueInsuranceComponent } from './issue-insurance.component';
import { DashboardModule } from '../../dashboard/dashboard.module';
import { CalendarModule } from 'primeng/calendar';
import { IssueOwnerDataComponent } from 'src/app/features/issue/issue-owner-data/issue-owner-data.component';
import { IssueDriverDataComponent } from 'src/app/features/issue/issue-driver-data/issue-driver-data.component';
import { IssuePetComponent } from 'src/app/features/issue/issue-pet/issue-pet.component';
import { IssueInsuranceRoutingModule } from './issue-insurance-routing.module';
import { PricePetComponent } from 'src/app/features/issue/price-pet/price-pet.component';
import { ReactiveFormsModule } from '@angular/forms';
import { PopupPetConfirmComponent } from 'src/app/features/issue/popup-pet-confirm/popup-pet-confirm.component';
import { RouterModule } from '@angular/router';
import { IssueDentalComponent } from 'src/app/features/issue/issue-dental/issue-dental.component';
import { PriceDentalComponent } from 'src/app/features/issue/price-dental/price-dental.component';

@NgModule({
  declarations: [
    IssuePetComponent,
    PricePetComponent,
    PopupPetConfirmComponent,
    IssueInsuranceComponent,
    IssueDentalComponent,
    PriceDentalComponent
  ],
  imports: [
    CommonModule,
    SharedModule,
    DashboardModule,
    CalendarModule, 
    RouterModule,
    IssueInsuranceRoutingModule,    
  ],
  exports : [
    IssuePetComponent,
    PopupPetConfirmComponent,
    IssueDentalComponent
  ]
})
export class IssueInsuranceModule { }
