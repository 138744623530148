import { Injectable } from '@angular/core';
import { LoginState } from './login/login.state';
import { AppState } from './app/app.state';

@Injectable({
  providedIn: 'root'
})
export class StateHandlerService {

  constructor(private app_state : AppState,
    private login_state : LoginState,
  ) { }

  getLoginState(){
    return this.login_state;
  }


  getAppState(){
    return this.app_state;
  }

}
