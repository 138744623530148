import { Component, EventEmitter, Input, Output } from '@angular/core';
import { LABELS } from 'src/app/main/constants/labels.enum';

@Component({
  selector: 'grid-card',
  templateUrl: './grid-card.component.html',
  styleUrls: ['./grid-card.component.scss']
})
export class GridCardComponent {
  @Input() model: any;
  @Input() titles: string[] = [];
  @Input() columns: string[] = [];
  @Input() smSizes: number[] = [];  
  @Input() hideClasses: boolean[] = [];
  @Output() detailClicked = new EventEmitter<number>();
  typeDetail="detailProposal"
  showDetailBox=false;
  showDetail:boolean |undefined;
  textDetail:string=LABELS.SEE_PROPOSAL_DETAIL;
  toggleDetailBox() {
    this.showDetailBox = !this.showDetailBox;
  }
  detail(detaill:number){
    this.detailClicked.emit(detaill);
    if(detaill==1){
      this.showDetail = !this.showDetail;
      if(this.showDetail===true)
        this.textDetail =LABELS.HIDE_PROPOSAL_DETAIL;
      else
        this.textDetail =LABELS.SEE_PROPOSAL_DETAIL;
    }
  }
  
  getColClasses(index: number): string {
    if(this.hideClasses){
      const hideClasses = this.hideClasses[index] ? 'd-none d-md-block' : '';
      return hideClasses;
    }
    return ''
  }

  getCircleColor(estadoId: number): string {
    switch (estadoId) {
        case 1:
            return 'green';
        case 9:
            return 'red';
        default:
            return 'yellow';
    }
  }

  goDetail(){
    this.detailClicked.emit(1);
    this.showDetail = !this.showDetail;
    if(this.showDetail===true)
      this.textDetail =LABELS.HIDE_PROPOSAL_DETAIL;
    else
      this.textDetail =LABELS.SEE_PROPOSAL_DETAIL;
  }
  

}
