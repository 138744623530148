<div class="h-100 w-100">
  <div class="header" style="background-image: url('/assets/tips/content {{randomTips[0].Id}}.jpg');" *ngIf="!showDetail">
    <div class="text-content">
      <h1 style="margin-left: 30px;">{{ titulo }}</h1>
    </div>
    <div class="top-tips">
      <div class="tip" *ngFor="let tip of randomTips">
        <h2>{{ tip.Titulo }}</h2>
        <a tabindex="0" (keypress)="showDetailTip(tip)" (click)="showDetailTip(tip)" style="color: white;">{{seguir_leyendo}}</a>
      </div>
    </div>
  </div>
  <div class="description-section" *ngIf="!showDetail">
    <h2 style="margin-left: 30px; color: black;">{{consejos}}</h2>
    <div class="card-container">
      <div class="card" *ngFor="let tip of tips">
        <h2 style="color: rgb(73, 73, 73);">{{ tip.Titulo }}</h2>
        <a tabindex="0" (keypress)="showDetailTip(tip)" (click)="showDetailTip(tip)" style="color: rgb(2, 2, 135);">{{seguir_leyendo}}</a>
      </div>
    </div>
  </div>
  <div *ngIf="showDetail">
    <div class="header detail-header" style="background-color: rgb(7, 12, 37);">
      <div class="text-content">
        <h1>{{ detailTip!.Titulo }}</h1>
        <p>{{ detailTip!.Parrafo }}</p>
      </div>
      <div class="image-content" style="background-image: url('/assets/partnership/advantage-1.jpg');"></div>
    </div>
    <div class="description-section">
      <div [innerHTML]="detailTip!.Descripcion"></div>
    </div>
    <div class="footer">
      <p>{{ detailTip!.Texto }}</p>
    </div>
  </div>
</div>
