import { Component, Input, OnDestroy, OnInit} from '@angular/core';
import { Location } from '@angular/common';
import { ButtonModel } from 'src/app/shared/models/button.model';
import { InputModel } from 'src/app/shared/models/input.model';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ButtonStylesEnum, ButtonTypesEnum } from 'src/app/main/constants/buttons.enum';
import { MaterialHelperService } from 'src/app/shared/material/material-helper.service';
import { ModelCreatorService } from 'src/app/shared/services/model-creator.service';
import { AuthenticatorService } from 'src/app/main/services/authenticator.service';
import { CrypterService } from 'src/app/main/services/crypter.service';
import { ERRORS } from 'src/app/main/constants/errors.enum';
import { Router } from '@angular/router';
import { LABELS } from 'src/app/main/constants/labels.enum';
import { ICONS } from 'src/app/main/constants/icons.enum';
import { InputTypesEnum } from 'src/app/main/constants/inputs-types.enum';
import { RedirectorService } from 'src/app/main/services/redirector.service';
import { CoverCardModel } from 'src/app/main/models/cover-card.model';
import { StateHandlerService } from 'src/app/state/state-handler.service';
import { LoginState } from 'src/app/state/login/login.state';
import { IGenericResponseModel } from 'src/app/main/models/generic-response.model';
import { AppState } from 'src/app/state/app/app.state';
import { environment } from 'src/environments/environment';
import { TranslationsService } from 'src/app/main/services/translations.service';
import { LabelModel } from 'src/app/shared/models/label.model';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss']
})
export class LoginComponent implements OnInit, OnDestroy{
  
  no_account = LABELS.NO_ACCOUNT;
  register = LABELS.REGISTER;
  continue_with = LABELS.CONTINUE_WITH;
  forgot_password = LABELS.FORGOT_PASSWORD;
  OR = LABELS.OR;
  VERSION = environment.appVersion;
  email = LABELS.EMAIL;
  password = LABELS.PASSWORD;
  capitals = ERRORS.CAPITALS_ACTIVE;

  campaignId   : number;
  enterpriseId : number;  
  btnBegin           : ButtonModel = new ButtonModel();
  //btnLoginGoogle     : ButtonModel = new ButtonModel();
  //btnLoginMicrosoft  : ButtonModel = new ButtonModel();  

  userForm : FormGroup;
  userInput : InputModel = new InputModel();
  passwordInput : InputModel = new InputModel();
  
  stepNumber  = 0;
  showPasswordControl  = false;
    
  coverCardModel : CoverCardModel = {showBackButton : false, useDefaultBack : false };  
  title = LABELS.LOGIN_TO_YOUR_ACCOUNT;

  couldNotLog  = false;
  loginError : string = ERRORS.UNAUTHORIZED;

  isCapsLockActive = false;

  constructor(private location : Location,
              private fb : FormBuilder,
              private modelCreator : ModelCreatorService,
              private auth : AuthenticatorService,
              private router : Router,
              private appState : AppState,
              private redirector : RedirectorService,
              private loginState : LoginState,
              private translations : TranslationsService,) {  

    this.userForm = this.fb.group({
      user : ['', [Validators.required]],
      password : ['', [Validators.required]]
    });      

    this.loadTranslations();
    this.createInputs();
    this.createButtons();
    this.enterpriseId = appState.getEnterpriseId();
    this.campaignId = appState.getCampaignId();

    setTimeout(() => {
      this.updateLabels();
    }, 3);
  }
  
  ngOnInit(): void {
    this.auth.logout(false);  
    window.addEventListener('keydown', this.checkCapsLockState.bind(this));
  }

  checkCapsLockState(event: KeyboardEvent): void {
    if (event instanceof KeyboardEvent && typeof event.getModifierState === 'function') {
      this.isCapsLockActive = event.getModifierState('CapsLock');
    }
  }

  createInputs(){
    this.userInput = this.modelCreator.createInputModel(this.email, InputTypesEnum.email, 'user', this.userForm, ERRORS.EMAIL_INVALID,undefined,true);
    this.passwordInput = this.modelCreator.createInputModel(this.password ,InputTypesEnum.password, 'password', this.userForm, ERRORS.PASSWORD_UNFILLED, ICONS.SHOW,true);
  }

  createButtons(){    
    this.btnBegin = this.modelCreator.createButtonModel(LABELS.CONTINUE, ButtonTypesEnum.Raised, ButtonStylesEnum.black)
    //this.btnLoginGoogle = this.modelCreator.createIconButtonModel(LABELS.CONTINUE_GOOGLE, ICONS.GOOGLE, true, ButtonStylesEnum.white_outline)
    //this.btnLoginMicrosoft = this.modelCreator.createIconButtonModel(LABELS.CONTINUE_MICROSOFT, ICONS.MICOROSFT, true, ButtonStylesEnum.white_outline)

    this.onMouseInOut(true, true);
    this.onMouseInOut(false, true);
    this.onMouseInOut(true, false);
    this.onMouseInOut(false, false);    
  }

  updateLabels(){
    // updatear labels con el valor actual
    this.btnBegin.text = LABELS.CONTINUE;
    this.userInput.placeholder = LABELS.EMAIL
    this.passwordInput.placeholder = LABELS.PASSWORD
    this.title = LABELS.LOGIN_TO_YOUR_ACCOUNT    
  }

  
  navigateBack(){    
    if(this.stepNumber > 0){
      this.userForm.reset();
      this.stepNumber = 0;      
      this.userInput.customError = ERRORS.EMAIL_INVALID;
      this.btnBegin.text = LABELS.CONTINUE;
      this.showPasswordControl = false;

      this.userInput.disabled = false;
      this.passwordInput.disabled = false;
      this.btnBegin.disabled = false;

    }
    else{
      this.location.back();
    }
    
  }  

  continue(){       
    this.enterpriseId = this.appState.getEnterpriseId(); 
    this.campaignId = this.appState.getCampaignId();
    
    this.passwordInput.customError = ERRORS.PASSWORD_UNFILLED;
    if(this.stepNumber == 0){
      this.btnBegin.text = LABELS.CONTINUE;
      if(!this.userForm.controls['user'].valid)
      {
        this.userForm.get('user')?.markAllAsTouched();
        return;
      }

      this.checkEmailValidity(this.userForm.controls['user'].value);
      
    }
    else if(this.stepNumber == 1){      
      if(!this.userForm.value || !this.userForm.value.user || !this.userForm.value.password)
        {        
          this.userForm.get('password')?.markAllAsTouched();
          return;
        }

       this.login(this.userForm.value.user, this.userForm.value.password);
    }    
  }

  checkEmailValidity(email : string, hasToRedirect  = true){
    if(this.userForm.controls['user'].invalid || !this.userForm.value.user)
      return;

    this.userInput.customError = ERRORS.EMAIL_INVALID;
    this.auth.isEmailValid(email).subscribe({
      next: (data) => { 
        if(!data || !data.IsSuccess){          
          this.handleEmailValidationError(data.Code, email, hasToRedirect, data.Result.Referencia);
          return;
        }

        this.btnBegin.text = LABELS.LOGIN;
        this.showPasswordControl = true;
        this.stepNumber = 1;

      },
      error: (err) => {
        this.showError(ERRORS.EMAIL_NOT_REGISTERED);
       }
    })
  }

  handleEmailValidationError(code : number, email : string, hasToRedirect : boolean, referencia : string){   
    // No sé que quise inventar acá jajajaj
    // Básicamente según el código de error hace una cosa u otra y muestra el error que corresponda ajjaja   
    let currentAction = () => {return;};
    
    let error : any= ERRORS.UNAUTHORIZED;

    if(code === 101){
      error = ERRORS.EMAIL_NOT_REGISTERED + "\n. " + ERRORS.REDIRECT_TO_REGISTER;
      currentAction = () => {this.redirector.redirectToRegister()}
    }
    else if(code === 102){
      error = ERRORS.EMAIL_NEEDS_VALIDATION;
      currentAction = () => {
        this.redirector.redirectToValidation({Username : email, Password : "", Referencia: referencia, IdEmpresa : this.enterpriseId}, false, 'login')
      }          
    }
    else if(code === 103){
      error = ERRORS.EMAIL_NULLED;
    }
    else if(code === 105){
      error = ERRORS.EMAIL_NEEDS_RECOVER;
    }

    this.showError(error);

    if(hasToRedirect){
      setTimeout(() => {      
        currentAction.call(this);  
      }, 3000);
    }
  }

  login(user : string, password: string){
    
    this.couldNotLog = false;
    
    this.loginState.user = {
      Email :user,      
    }
    this.enterpriseId = this.appState.getEnterpriseId();
    this.auth.login(user, password, this.enterpriseId, this.campaignId).subscribe({
      next : (data : IGenericResponseModel) => {     

        if(!data || !data.IsSuccess || !data.Token){
          
          if(data.Code === 102){
            this.redirector.redirectToValidation({Username : user, Password : password, Referencia: data.Result.Referencia,  IdEmpresa : this.enterpriseId}, false, 'login');
            return;
          }
          else if(data.Code === 105){
            this.userInput.disabled = true;
            this.passwordInput.disabled = true;
            this.btnBegin.disabled = true;
            this.showError(ERRORS.BLOCKED_USER)

            return;
          }
          else if(data.Code === 302){
            this.showError(ERRORS.INVALID_LINK)
            return;
          }

 
          this.showError(ERRORS.UNAUTHORIZED);
          return;
        }     
        
        this.appState.setEnterpriseId(data.Result.IdEmpresa);
        this.appState.setIsCallCenter(data.Result.IdEmpresa === 0);
        this.loginState.setUser(data.Result, data.Result.IdEmpresa === 0);
        this.auth.setToken(data.Token);
        this.redirect(data.Result.IdEmpresa === 0);
      },
      error : () => {

        this.showError(ERRORS.UNAUTHORIZED);
      }
    });
  }

  redirect(isAdmin : boolean){
    this.redirector.redirectToDashboard();

    //Antes estaba como abajo porque asi estaba en brasil
    //Aca es distinto, asi que hay solo un redirector, que es a dashboard.
    
    // if(isAdmin){
    //   this.redirector.redirectToCallCenterLogin();
      
    // }else{
    //   this.redirector.redirectToDashboard();
    // }    
  }

  showError(error : string){
    this.loginError = error;
    this.couldNotLog = true;
  }

  showOrHidePassword(){
    if(this.passwordInput.type === 'password'){
      this.passwordInput.type = 'text';
      this.passwordInput.icon = ICONS.HIDE
    }
    else{
      this.passwordInput.type = 'password';
      this.passwordInput.icon = ICONS.SHOW
    }
  }

  navigateTo(route : string){
    this.router.navigate([route]);
  }

  loginWithProvider(provider : string){
    //if(provider === 'google'){
    //  this.auth.loginGoogle(1);
    //}
    //else if(provider === 'microsoft'){
    //  this.auth.loginMicrosoft(1);
    //}

  }

  onMouseInOut(isMicrosoft : boolean, mouseIn : boolean){
      //if(isMicrosoft){
      //  this.btnLoginMicrosoft.icon = mouseIn ? ICONS.MICOROSFT_COLOR : ICONS.MICOROSFT;
      //}
      //else{
      //  this.btnLoginGoogle.icon = mouseIn ? ICONS.GOOGLE_COLOR : ICONS.GOOGLE;
      //}        
  }

  onUserFocusOut(){
    this.checkEmailValidity(this.userForm.value?.user ?? '', false);
  }


  hideErrors(){
    this.couldNotLog = false;
  }

  onKeyPress(keyEvent : KeyboardEvent){
    if(keyEvent.key == "Enter")
      this.continue();
  }

  loadTranslations() {
    this.translations.getTranslationsObservable()?.subscribe(translations => {
      if (translations.LABELS) {
        this.no_account = LABELS.NO_ACCOUNT;
        this.register = LABELS.REGISTER;
        this.continue_with = LABELS.CONTINUE_WITH;
        this.forgot_password = LABELS.FORGOT_PASSWORD;
        this.OR = LABELS.OR;
        this.title = LABELS.LOGIN_TO_YOUR_ACCOUNT;
        this.email = LABELS.EMAIL;
        
        this.password = LABELS.PASSWORD;
        this.passwordInput.placeholder = LABELS.PASSWORD;
      }
      if(translations.ERRORS){
        this.userInput.customError = ERRORS.EMAIL_INVALID;
        this.passwordInput.customError = ERRORS.PASSWORD_UNFILLED;
        this.capitals = ERRORS.CAPITALS_ACTIVE;
      }      
      
    });
  }

  
  ngOnDestroy(): void {
    window.removeEventListener('keydown', this.checkCapsLockState.bind(this));
  }
}
