import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { ButtonStylesEnum, ButtonTypesEnum } from 'src/app/main/constants/buttons.enum';
import { LABELS } from 'src/app/main/constants/labels.enum';
import { EmissionInfoModel } from 'src/app/main/models/emission/emission-info.model';
import { QuotingInfoModel } from 'src/app/main/models/emission/quoting-info.model';
import { ButtonModel } from 'src/app/shared/models/button.model';
import { ModelCreatorService } from 'src/app/shared/services/model-creator.service';

@Component({
  selector: 'issue-insurance',
  templateUrl: './issue-insurance.component.html',
  styleUrls: ['./issue-insurance.component.scss']
})

export class IssueInsuranceComponent implements OnInit {
  selectedDate: Date | null = null;
  parentGroup: FormGroup | undefined;
  isQuote = false;
  questions: string[] = [LABELS.INSURANCE_QUESTIONS_PROPOSAL, LABELS.INSURANCE_QUESTIONS_PROPOSAL1, LABELS.INSURANCE_QUESTIONS_PROPOSAL2];

  readonly title_issue = LABELS.ISSUE_DATA_TITLE;
  readonly title_vehicle = LABELS.VEHICLE_DATA_TITLE;
  readonly title_personal = LABELS.PERSONAL_DATA_TITLE;

  readonly title_beneficiary = LABELS.BENEFICIARY_DATA_TITLE;
  readonly title_responsible = LABELS.RESPONSIBLE_DATA_TITLE;
  readonly title_driver = LABELS.DRIVER_DATA_TITLE;

  quotingInfo?: EmissionInfoModel

  step = 0;
  steps: any[] = [];


  prices = [];

  constructor(private fb: FormBuilder, private modelCreator: ModelCreatorService) {
    this.parentGroup = this.fb.group({
      vehicle: [],
      owner: [],
      driver: [],
    });
  }

  ngOnInit(): void {
    this.setValidSteps();
  }

  selectMenuItem(item: any) {
    
    this.step = item;
  }
  setValidSteps() {
    this.steps = [];

    // aca se define el flujo de la cotización en base al tipo de producto

    this.steps.push({ id: 0, formName: 'vehicle', label: '' })
    this.steps.push({ id: 1, formName: 'dataOwner', label: '' })
    this.steps.push({ id: 2, formName: "driver", label: '' });

  }

  next() {  
    
    const currentForm: string = this.steps[this.step].formName;
    if (this.parentGroup!.controls[currentForm] && this.parentGroup!.controls[currentForm].invalid) {
      this.parentGroup!.markAllAsTouched();

      return;
    }

    if (this.step + 1 == this.steps.length) {
      this.isQuote = true;
      return;
    }

    this.step++;
  }
}
