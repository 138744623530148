import { ChangeDetectorRef, Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { ButtonStylesEnum, ButtonTypesEnum } from 'src/app/main/constants/buttons.enum';
import { LABELS } from 'src/app/main/constants/labels.enum';
import { ButtonModel } from 'src/app/shared/models/button.model';
import { ModelCreatorService } from 'src/app/shared/services/model-creator.service';
import { ProposalCoveragesComponent } from '../../dashboard/proposal-coverages/proposal-coverages.component';
import { PricedPlanModel } from 'src/app/main/models/issue/priced-plan.enum';
import { IssueState } from 'src/app/state/issue/issue.state';


@Component({
  selector: 'app-price',
  templateUrl: './price.component.html',
  styleUrls: ['./price.component.scss']
})
export class PriceComponent implements OnInit{

  @Input() data?:PricedPlanModel;
  @Output() priceChecked = new EventEmitter<boolean>();
  @Input() showFranquicia = true;

  readonly quota_plan= LABELS.QUOTA_PLAN
  readonly sum_insured_plan= LABELS.SUM_INSURED_PLAN
  btnDetail : ButtonModel|undefined;


  coverages_details:any[]=[];
  titleCoverages:string=LABELS.TITLE_COVERAGES_DETAIL;
  showCoverages=false;
  componentData:any;

  constructor(private modelCreator : ModelCreatorService,private cdr: ChangeDetectorRef,private issue_state : IssueState){
  }
    ngOnInit(): void {
        this.btnDetail = this.modelCreator.createButtonModel(LABELS.SEE_PROPOSAL_DETAIL, ButtonTypesEnum.Raised, ButtonStylesEnum.black);
    }

    toggleSelection() {
        this.priceChecked.emit(!this.data?.isSelected);
    }

    showDetail(){
      this.coverages_details = [...this.data?.Coberturas ?? []];
      const tipo=this.issue_state.fullState.quotingState.quotingInfo!.idTipoProducto;
        this.showCoverages=true;
        this.componentData={
          component:ProposalCoveragesComponent,
          coverages_details:this.coverages_details,
          plan:this.data!.IdPlan,
          prod:this.data!.IdProducto,
          tipo:tipo?.toString()
        };
        this.cdr.detectChanges();
      }
      onCancel(){
        this.showCoverages=false;
      }

}
