<full-cover-card [model]="coverCardModel" (backPressed)="logout()" >               
        <div class="col-12">

            <div class=" text-center">
                <p class="fs-3">{{title}}</p>
            </div>

            <div class="text-center">
                <p class="fs-smaller text-secondary">{{subtitle}}</p>
            </div>

        
            <div class=" col-12 pt-3 pb-1">
                <div class="col-12 col-md-6 justify-content-center m-auto">
                    <custom-input [model]="emailInput"/>
                </div>                
            </div>

            <div class=" col-12 text-center pt-2"> 
                <div class="col-12 col-md-6 m-auto">
                    <custom-button [model]="btnBegin" (customClick)="continue()"/>
                </div>
            </div>                    
        </div>
</full-cover-card>
