import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { LABELS } from 'src/app/main/constants/labels.enum';
import { ConsultantService } from 'src/app/main/services/consultant.service';
import { FormBuilder, FormGroup} from '@angular/forms';
import { ButtonModel } from 'src/app/shared/models/button.model';
import { InputModel } from 'src/app/shared/models/input.model';
import { ButtonStylesEnum, ButtonTypesEnum } from 'src/app/main/constants/buttons.enum';
import { ModelCreatorService } from 'src/app/shared/services/model-creator.service';
import { DropdownCustomModel } from 'src/app/shared/models/dropdown.model';
import { ISelectItem } from 'src/app/main/models/select-item.model';
import { Vehicle } from 'src/app/main/models/vehicle.model';
import { QuotingFormHandlerService } from 'src/app/main/services/issue/quoting-form-handler.service';
import { QuotingVehicleValidStepsEnum } from 'src/app/main/models/emission/quoting-vehicle-steps.enum';
import { Subscription } from 'rxjs';
import { States } from 'src/app/state/models/states.enum';
import { QuotingInfoModel } from 'src/app/main/models/emission/quoting-info.model';
import { IssueState } from 'src/app/state/issue/issue.state';
import { ERRORS } from 'src/app/main/constants/errors.enum';
import { InputTypesEnum } from 'src/app/main/constants/inputs-types.enum';
import { InputDateTimeModel } from 'src/app/shared/input-datetime-custom/model/input-datetime-model';

@Component({
  selector: 'issue-vehicle-data',
  templateUrl: './issue-vehicle-data.component.html',
  styleUrls: ['./issue-vehicle-data.component.scss']
})
export class IssueVehicleDataComponent implements OnInit, OnDestroy {
  @Input() parentGroup: FormGroup | undefined;
  @Output() completedClicked = new EventEmitter<any>();
  @Output() backClicked = new EventEmitter<any>();

  vehicleForm: FormGroup | undefined;
  readonly title: string = LABELS.VEHICLE_DATA;
  readonly proposal_question_year = LABELS.QUESTION_VEHICLE_YEAR;

  readonly proposal_suggestion = LABELS.PROPOSAL_SUGGESTION;
  readonly proposal_question_model = LABELS.VEHICLE_MODEL_QUESTION;
  readonly proposal_question_make = LABELS.VEHICLE_MAKE_QUESTION;
  readonly proposal_question_isnew = LABELS.QUESTION_ISNEW;
  readonly proposal_question_armored = LABELS.QUESTION_ARMORED;
  readonly proposal_question_version = LABELS.VEHICLE_VERSION_QUESTION;
  readonly vehicle_salvage = LABELS.VEHICLE_SALVAGE
  readonly vehicle_is_new = LABELS.QUESTION_ISNEW
  vehicle: Vehicle;

  selectYears: ISelectItem[] | undefined;
  selectMakes: ISelectItem[] | undefined;
  selectModels: ISelectItem[] | undefined;
  selectVersions: ISelectItem[] | undefined;

  btnContinue: ButtonModel | undefined;
  btnContinueWithPatent: ButtonModel | undefined;
  btnSend: ButtonModel | undefined;
  btnReturn: ButtonModel | undefined;
  bonusInput: InputModel | undefined;
  desiredMakes = ['CHEVROLET', 'FORD', 'VOLKSWAGEN', 'RENAULT', 'PEUGEOT'];
  yearSelected: string | undefined;
  makeSelected: string | undefined;
  modelSelected: string | undefined;
  versionSelected: string | undefined;

  yesOrNoIsSalavge = false;
  yesOrNoIsNew = false;
  isSearchCar = false;
  yearIsCurrent=false;
  steps: number[] = [0, 1, 2, 3, 4];
  currentStep = 0;
  versionSelect?: DropdownCustomModel;
  yearSelect?: DropdownCustomModel;
  makeSelect?: DropdownCustomModel;
  modelSelect?: DropdownCustomModel;
  invoiceModel? : InputModel;
  invoiceAmountModel? : InputModel;
  invoiceDateModel       : InputDateTimeModel|undefined;
  filteredMakes: any | undefined;
  quotingInfo?: QuotingInfoModel;
  quotingInfoSub: Subscription = new Subscription();

  constructor(private consultantProposal: ConsultantService, 
    private fb: FormBuilder,
     private modelCreator: ModelCreatorService, 
     private formHandler: QuotingFormHandlerService, 
     private issue_state: IssueState) {
    this.vehicle = new Vehicle();
    this.vehicleForm = this.formHandler.getFormControl(QuotingVehicleValidStepsEnum.vehicleMexico) as FormGroup;

    this.loadDataBack();
    this.loadQuotingInfo();
    this.loadInputs();
  }

  ngOnInit(): void {
    this.loadDataBack();
  }

  loadData(quotingInfo: QuotingInfoModel) {
    this.consultantProposal.listYearsVehicle().subscribe({
      next: (data) => {
        if (data.IsSuccess) {
          this.selectYears = [];
          data.Result?.forEach((y: any) => {
            this.selectYears?.push({ descripcion: y.Descripcion, id: y.Id })
          }),
          this.yearSelect = this.modelCreator.createSelectModel('year', LABELS.SELECT, this.selectYears ?? [], this.vehicleForm!, LABELS.VERSION, '', true,false,false,LABELS.SELECT_ERROR);
          this.yearSelect.outsideLabel=this.proposal_question_year
        }
      },
      error: (err) => {
        this.showError();

      }
    });

  }

  loadDataBack() {
    this.yearSelected = this.vehicleForm!.get('year')?.value?.id?.toString() ||this.vehicleForm!.get('year')?.value?.toString();
    this.makeSelected = this.vehicleForm!.get('make')?.value?.id?.toString() || this.vehicleForm!.get('make')?.value?.toString() ;
    this.modelSelected = this.vehicleForm!.get('model')?.value?.id?.toString() || this.vehicleForm!.get('model')?.value?.toString();
    this.versionSelected = this.vehicleForm!.get('version')?.value?.id?.toString() || this.vehicleForm!.get('version')?.value?.toString();
    this.vehicle.Anio = parseInt(this.yearSelected! || '0', 10);
    this.vehicle.IdMarca = isNaN(parseInt(this.makeSelected!, 10)) ? 0 : parseInt(this.makeSelected!, 10);
    this.vehicle.IdGrupo = parseInt(this.modelSelected! || '0', 10);
    this.vehicle.IdVersion = parseInt(this.versionSelected! || '0', 10);
    //this.listMakes();

  }

  loadQuotingInfo() {

    this.quotingInfoSub = this.issue_state.checkState().subscribe({
      next: (state) => {
        const quotingState = state.quotingState;
        if (quotingState.state === States.success) {
          this.quotingInfo = quotingState.quotingInfo;
          this.loadData(this.quotingInfo!);
        } else {
          // mensajito ??? Nunca debería pasar salvo que hagan algo muy raro
        }
      }
    })

    
  }

  loadInputs() {
    this.btnSend = this.modelCreator.createButtonModel(LABELS.CONTINUE_ISSUE, ButtonTypesEnum.Raised, ButtonStylesEnum.black);
    this.btnContinue = this.modelCreator.createButtonModel(LABELS.CONTINUE_ISSUE, ButtonTypesEnum.Raised, ButtonStylesEnum.black);
    this.btnContinueWithPatent = this.modelCreator.createButtonModel(LABELS.CONTINUE_ISSUE_WITH_PATENT, ButtonTypesEnum.Raised, ButtonStylesEnum.black);
    this.btnReturn = this.modelCreator.createButtonModel(LABELS.RETURN_ISSUE, ButtonTypesEnum.Raised, ButtonStylesEnum.without_white_outline);
    this.invoiceModel = this.modelCreator.createInputModel(LABELS.INVOICE, InputTypesEnum.common, 'invoice', this.vehicleForm!,ERRORS.INPUT_INVALID,undefined,false,30);
    this.invoiceAmountModel = this.modelCreator.createInputModel(LABELS.INVOICE_AMOUNT, InputTypesEnum.common, 'invoiceAmount', this.vehicleForm!,ERRORS.INPUT_INVALID,undefined,false,30);
    this.invoiceDateModel   = this.modelCreator.createCalendarModel('invoiceDate', this.vehicleForm!, false,  LABELS.BITHDATE_DATE, false,LABELS.INVOICE_DATE);  
  }

  showError(msg = "") {
    // this.consultantError = ERRORS.COULD_NOT_REGISTER;
    // if(msg)
    //   this.consultantError = msg;   
    // this.showconsultantError = true;
  }


  validateIsSalvage(value:any){
    this.vehicleForm!.get('salvage')?.setValue(value.toString());
  }
  validateIsNew(value:any){
    this.vehicleForm!.get('isNew')?.setValue(value.toString());
    if(value.toString()=='1'){
      this.invoiceAmountModel!.required=true
      this.invoiceModel!.required=true
      this.invoiceDateModel!.required=true
    }else{
      this.invoiceAmountModel!.required=false
      this.invoiceModel!.required=false
      this.invoiceDateModel!.required=false
    }
  }

  nextStep() {
    if (this.currentStep == 0) {
      const salvege =this.vehicleForm!.get('salvage')?.value?.toString();
      salvege === undefined || salvege == '' ? this.vehicleForm!.get('salvage')?.markAsTouched() : this.currentStep++;
      return
    }
    if (this.currentStep == 1) {
      this.validateStepYear();
      return
    }
    if (this.currentStep == 2) {
      this.validateStepInvoice();
      return
    }
    if (this.currentStep == 3) {
      if(this.makeSelected === undefined || this.makeSelected == '' || this.makeSelected == '[object Object]'){
        this.vehicleForm!.get('make')?.markAsTouched();
        return;
      }
      else{
        this.modelSelected=this.vehicleForm!.get('model')?.value?.id?.toString() || this.vehicleForm!.get('model')?.value?.toString();
        this.vehicle.IdMarca = parseInt(this.makeSelected!)
        this.listModels()
        this.currentStep++;
      }

      return
    }
    if (this.currentStep == 4) {
      if(this.modelSelected === undefined || this.modelSelected == '' || this.modelSelected == '[object Object]'){
        this.vehicleForm!.get('model')?.markAsTouched();
        return
      }
      else{
        this.versionSelected=this.vehicleForm!.get('version')?.value?.id?.toString() || this.vehicleForm!.get('version')?.value?.toString();
        this.vehicle.IdGrupo = parseInt(this.modelSelected!)
        this.listVersions();
        this.currentStep++;
      }
      return
    }
    if (this.currentStep == 5) {    
      const isNew = this.vehicleForm!.controls['isNew'].value
      if(isNew=='0'){
        const invoiceControl = this.vehicleForm!.get('invoice');
      const invoiceAmountControl = this.vehicleForm!.get('invoiceAmount');
      const invoiceDateControl = this.vehicleForm!.get('invoiceDate');

      if (invoiceControl!.errors) {
          invoiceControl?.setErrors(null);
      }

      if (invoiceAmountControl!.errors) {
          invoiceAmountControl?.setErrors(null);
      }

      if (invoiceDateControl!.errors) {
          invoiceDateControl?.setErrors(null);
      }
      }
      if(this.versionSelected === undefined || this.versionSelected == '' || this.versionSelected == '[object Object]')
        this.vehicleForm!.get('version')?.markAsTouched() 
      else
        this.completedClicked.emit();

      return
    }
  }

  return() {

    if (this.currentStep > 0){
      if(this.currentStep != 3)
        this.currentStep--;
      else{
        const currentYear = new Date().getFullYear();
        const selectedYear = parseInt(this.yearSelected ?? '');
        if (selectedYear === currentYear || selectedYear === currentYear - 1) {
            this.currentStep--;
        } else {
            this.currentStep -= 2;
        }
      }
    }

  }

  selectYear(year: any) {
    this.yearIsCurrent=false;
    this.yearSelected = year;
    const patentControl = this.vehicleForm!.get('year');
    patentControl!.patchValue({id:this.selectYears?.find(a=>a.descripcion==year.toString())?.id?.toString(),descripcion:year?.toString()});
    this.vehicle.Anio = parseInt(this.yearSelected!);
    //this.listMakes();
  }

  validateStepYear(){
    const currentYear = new Date().getFullYear();
    this.yearSelected = this.vehicleForm!.get('year')?.value?.id?.toString() || this.vehicleForm!.get('year')?.value?.toString();
    this.vehicle.Anio = parseInt(this.yearSelected ?? '');
    this.listMakes();

    if (this.yearSelected === undefined || this.yearSelected == '') {
        this.vehicleForm!.get('year')?.markAsTouched();
    } else {
        if (parseInt(this.yearSelected) >= currentYear || parseInt(this.yearSelected) === currentYear - 1) {
            this.currentStep++;
        } else {
            this.currentStep += 2;
        }
    }
  }
  validateStepInvoice(){
    const isNew = this.vehicleForm!.controls['isNew'].value
    if(isNew=='0')
      return this.currentStep++
    else{
      const invoice = this.vehicleForm!.get('invoice')?.value;
      const invoiceAmount = this.vehicleForm!.get('invoiceAmount')?.value;
      const invoiceDate = this.vehicleForm!.get('invoiceDate')?.value;
      
      if (invoice === "") {
          this.vehicleForm!.get('invoice')?.markAllAsTouched();
      }
      
      if (invoiceAmount === "") {
          this.vehicleForm!.get('invoiceAmount')?.markAllAsTouched();
      }
      
      if (invoiceDate === "" || this.vehicleForm!.get('invoiceDate')?.invalid) {
          this.vehicleForm!.get('invoiceDate')?.markAllAsTouched();
      }
    
      return invoice !== "" && invoiceAmount !== "" && invoiceDate !== "" && !this.vehicleForm!.get('invoiceDate')?.invalid ? this.currentStep++ : false;
    }
  }

  validateYear() {
    this.yearIsCurrent=false;
    const yearControl = this.vehicleForm!.get('year');
    const year = yearControl!.value;
    if (year != "")
      this.yearSelected = year.descripcion ?? year;
    this.vehicle.Anio = parseInt(this.yearSelected!);
    //this.listMakes();
  }

  listMakes() {
    this.consultantProposal.listMakeVehicle(this.vehicle).subscribe({
      next: (data) => {
        if (data.IsSuccess) {
          this.selectMakes = [];
          data.Result?.forEach((y: any) => {
            this.selectMakes?.push({ descripcion: y.Descripcion, id: y.Id })
          })
          // this.vehicleForm!.get('make')?.setValue({ id: this.makeSelected,descripcion:this.selectMakes.find(y=>y.id==this.makeSelected)?.descripcion});
          this.vehicleForm!.get('make')?.setValue(
            this.selectMakes.find(y => y.id == this.makeSelected) || null // Asignamos el valor seleccionado si existe, de lo contrario, asigna null
          );
          
          this.makeSelect = this.modelCreator.createSelectModel('make', LABELS.SELECT, this.selectMakes ?? [], this.vehicleForm!, LABELS.VERSION, '', true,false,false,LABELS.SELECT_ERROR);
          this.makeSelect.outsideLabel=this.proposal_question_make
          this.makeSelect.required = true;
          this.filteredMakes = this.selectMakes?.filter(make => this.desiredMakes.includes(make.descripcion));
        }
      },
      error: (err) => {
        this.showError();

      }
    });
  }

  validateMake() {
    const patentControl = this.vehicleForm!.get('make');
    const make = patentControl!.value;
    if (make != "")
      this.makeSelected = make.id ?? make;
    this.vehicle.IdMarca = parseInt(this.makeSelected!)
    this.listModels()
    this.makeSelected === undefined || this.makeSelected === '' ? this.vehicleForm!.markAllAsTouched() : this.currentStep++;
  }

  selectMake(make: any) {
    this.makeSelected = make;
    const patentControl = this.vehicleForm!.get('make');
    patentControl!.patchValue({id:make.toString(),descripcion:this.selectMakes?.find(a=>a.id==make.toString())?.descripcion?.toString()});
    this.vehicle.IdMarca = parseInt(this.makeSelected!)
    this.listModels();
    this.makeSelected === undefined ? this.vehicleForm!.markAllAsTouched() : "";
  }

  listModels() {
    this.consultantProposal.listModelsVehicle(this.vehicle).subscribe({
      next: (data) => {
        if (data.IsSuccess) {
          this.selectModels = [];
          data.Result?.forEach((y: any) => {
            this.selectModels?.push({ descripcion: y.Descripcion, id: y.Id })
          })
          // this.vehicleForm!.get('model')?.setValue({ id: this.modelSelected,descripcion:this.selectModels.find(y=>y.id==this.modelSelected)?.descripcion});
          this.vehicleForm!.get('model')?.setValue(
            this.selectModels.find(y => y.id == this.modelSelected) || null
          );
          this.modelSelect = this.modelCreator.createSelectModel('model', LABELS.SELECT, this.selectModels ?? [], this.vehicleForm!, LABELS.VERSION, '', true,false,false,LABELS.SELECT_ERROR);
          this.modelSelect.outsideLabel=this.proposal_question_model
        }
      },
      error: (err) => {
        this.showError();
      }
    });
  }

  validateModel() {
    const patentControl = this.vehicleForm!.get('model');
    const model = patentControl!.value;
    if (model != "")
      this.modelSelected = model.id;
    this.vehicle.IdGrupo = parseInt(this.modelSelected!);
    this.modelSelected === undefined || this.modelSelected === '' ? this.vehicleForm!.markAllAsTouched() : "";
    this.listVersions();
  }

  selectModel(model: any) {
    this.modelSelected = model;
    const patentControl = this.vehicleForm!.get('model');
    patentControl!.patchValue((this.modelSelected ?? -1));
    this.vehicle.IdGrupo = parseInt(this.modelSelected!);
    this.listVersions();
    this.currentStep++;
  }

  listVersions() {
    this.consultantProposal.listVersionsVehicle(this.vehicle).subscribe({
      next: (data) => {
        if (data.IsSuccess) {
          this.selectVersions = [];
          data.Result?.forEach((y: any) => {
            this.selectVersions?.push({ descripcion: y.Descripcion, id: y.Id })
          })
          // this.vehicleForm!.get('version')?.setValue({ id: this.versionSelected,descripcion:this.selectVersions.find(y=>y.id==this.versionSelected)?.descripcion});
          this.vehicleForm!.get('version')?.setValue(
            this.selectVersions.find(y => y.id == this.versionSelected) || null
          );
          
          this.versionSelect = this.modelCreator.createSelectModel('version', LABELS.SELECT, this.selectVersions ?? [], this.vehicleForm!, LABELS.VERSION, '', true,false,false,LABELS.SELECT_ERROR);
          this.versionSelect.outsideLabel=this.proposal_question_version
        }
      },
      error: (err) => {
        this.showError();
      }
    });
  }

  validateVersion() {
    const patentControl = this.vehicleForm!.get('version');
    const version = patentControl!.value;
    if (version != "")
      this.versionSelected = version.id;
    this.versionSelected === undefined || this.versionSelected === '' ? this.vehicleForm!.markAllAsTouched() : "";
  }

  selectVersion(version: any) {
    this.versionSelected = version;
    const patentControl = this.vehicleForm!.get('version');
    patentControl!.patchValue(+(this.versionSelected ?? -1));
    this.currentStep++;
  }

  ngOnDestroy(): void {
    this.quotingInfoSub.unsubscribe();
  }

  next() {
    this.currentStep++
  }
}
