import { Component } from '@angular/core';
import { Router } from '@angular/router';
import { LABELS } from 'src/app/main/constants/labels.enum';
import { Tip } from 'src/app/main/models/issue/tips.model';
import { ConsultantService } from 'src/app/main/services/consultant.service';
import { RedirectorService } from 'src/app/main/services/redirector.service';

@Component({
  selector: 'app-tips',
  templateUrl: './tips.component.html',
  styleUrls: ['./tips.component.scss']
})
export class TipsComponent {

  titulo = 'Dicas';
  readonly consejos = LABELS.HINTS;
  readonly seguir_leyendo = LABELS.KNOW_MORE;

  tips: Tip[] = [];
  randomTips: Tip[] = [];
  showDetail = false;
  detailTip: Tip | null = null;
  constructor(private consultantProposal:ConsultantService, private redirectorService: RedirectorService,){
  }
  ngOnInit() {

    this.tips=this.consultantProposal.getTips()
    if(this.tips== undefined){

      this.tips = [];
      this.consultantProposal.listTips().subscribe({
        next: (data:any) => {
          if (data.IsSuccess) {
            this.tips = data.Result;
            this.consultantProposal.tips=this.tips;
            this.randomTips = this.tips.sort(() => 0.5 - Math.random()).slice(0, 3);
          }
        },
        error: (err) => {
          return;
        }
      });
    }else{
      this.randomTips = this.tips.sort(() => 0.5 - Math.random()).slice(0, 3);
    }

  }


  showDetailTip(tip: Tip) {
    this.detailTip = tip;
    this.showDetail = true;
    this.redirectorService.redirectToTip(this.detailTip.Id);
  }


}
