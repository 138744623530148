import { Component, OnDestroy } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { Subscription } from 'rxjs';
import { LABELS } from 'src/app/main/constants/labels.enum';
import { NewProposalModel } from 'src/app/main/models/issue/new-proposal.model';
import { PricedPlanModel } from 'src/app/main/models/issue/priced-plan.enum';
import { ISelectItem } from 'src/app/main/models/select-item.model';
import { RedirectorService } from 'src/app/main/services/redirector.service';
import { ButtonModel } from 'src/app/shared/models/button.model';
import { InputModel } from 'src/app/shared/models/input.model';
import { ModelCreatorService } from 'src/app/shared/services/model-creator.service';
import { IssueState } from 'src/app/state/issue/issue.state';
import { LoginState } from 'src/app/state/login/login.state';
import { QuotingInfoState } from 'src/app/state/models/quoting-info.state';
import { States } from 'src/app/state/models/states.enum';
import { EmissionSummaryComponent } from '../emission-summary/emission-summary.component';
import { PopupPetConfirmComponent } from '../popup-pet-confirm/popup-pet-confirm.component';
import { ICONS } from 'src/app/main/constants/icons.enum';
import { PricesFilterModel, PricingListConfigModel } from 'src/app/shared/models/pricing-list-config.model';

@Component({
  selector: 'app-price-pet',
  templateUrl: './price-pet.component.html',
  styleUrls: ['./price-pet.component.scss']
})
export class PricePetComponent implements OnDestroy {

  readonly quote_error1=LABELS.QUOTE_ERROR1
  readonly quote_error2=LABELS.QUOTE_ERROR2

  readonly quote_help1=LABELS.QUOTE_ERROR_HELP1
  readonly quote_help2=LABELS.QUOTE_ERROR_HELP2
  readonly retry_quote = LABELS.RETRY_QUOTE
  readonly load_plans=LABELS.LOAD_PLANS_PET;

  prices : PricedPlanModel[] = [];

  petInput? : InputModel;
  form : FormGroup;

  loadingPet  = true;

  title = LABELS.PET_TITLE;
  subtitle = LABELS.PET_ADD;

  
  plans_title = LABELS.ENTER_PET_PLAN
  insured_amount = LABELS.INSURED_AMOUNT;
  money = LABELS.MONEY_SYMBOL;
  quota = LABELS.QUOTA_PLAN
  confirm_text = LABELS.EMIT_INSURANCE;
  your_pets = LABELS.YOUR_PETS;
  change_region = LABELS.CHANGE_REGION
  currentRegion = "";


  btnEmit? : ButtonModel;
  btnAddPet? : ButtonModel;

  issue$ : Subscription = new Subscription();

  pricingId? : number;
  maxPetQuantity  = 1;
  proposal? : NewProposalModel;

  showPopup  = false;
  popupData : any = {
    component : EmissionSummaryComponent,
    freeData : [] // Acá voy a poner las Mascotas
  };

  priceConfig? : PricingListConfigModel = undefined;
  pets : string[] = [];

  load  = true;

  constructor(
    private fb : FormBuilder,
    private modelCreator : ModelCreatorService,
    private issueState : IssueState,
    private loginState : LoginState,
    private redirector : RedirectorService) {
      this.form = this.fb.group({
        mascotas : [[]],
      });

    this.checkState();
  }

  setPriceConfig(){
    const config = new PricingListConfigModel();
    config.allowMultiPlanSelection = true;
    config.multiOptions = this.pets.map(p => {      
      return {
        id : p,
        descripcion : p,
        logo : ICONS.PET_HEARTH_WHITE,        
      }
    
    });
    config.title = LABELS.SELECT_PET_PLAN;
    config.subtitle = LABELS.PET_SELECTION_SUBTITLE;
    config.secondSubtitle = LABELS.PET_SELECTION_SUBTITLE2;
    config.multiOptions[0].selected = true;
    config.showFranquicia = false;

    config.filters = this.setFilterOptions(this.prices);

    this.priceConfig = config;

  }

  checkState(){
    this.issue$ = this.issueState.checkState().subscribe({
      next : (state) => {
        const pricingState = state.pricingState;
        const quotingState = state.quotingState;
        const emissionState = state.emissionState;
        this.currentRegion = pricingState.regionDescription ?? '';

        if(pricingState.state !== States.success || !pricingState.pricing?.Result?.Planes || pricingState.pricing.Result.Planes.length === 0){
          this.prices = [];
          // mostrar error o mensajito
          return;
        }

        this.load=false
        
        this.pricingId = pricingState.pricing.Result.Id;
        this.prices = pricingState.pricing.Result.Planes;
        
        this.pets = quotingState.mascotas?.map(m=>{
          return m.nombre
        }) ?? [];
        
        this.maxPetQuantity = quotingState.quotingInfo?.CantidadMascotas ?? this.maxPetQuantity;

        if(emissionState.state === States.success){
          this.redirector.redirectToPayment();
        }
        this.setPriceConfig();
      }
    })
  }

  showConfirmPopup(){
    if(!this.form.valid){
      this.form.markAllAsTouched();
      return;
    }

    const value = this.form.value;
    
    const mascotas : ISelectItem[] = [];
    // Cargó más de 1 mascota
    if(value.mascotas){
      value.mascotas.forEach((m : any) => {
        mascotas.push({id: m.plan, descripcion: m.nombre, tipo : this.prices.find(p => p.IdCotizacionPlan === m.plan)?.Plan})
      });
    }
    //solo está cargando una ahora    
    mascotas.push({id : value.plan, descripcion:  value.nombre, tipo : this.prices.find(p => p.IdCotizacionPlan === value.plan)?.Plan});

    this.proposal = {
      //IdCliente : +this.loginState.user!.Id!,
      //IdFormaPago: 0,
      Telefono : "",
      Cliente : {
        Email : ''
      },
      IdPlanCotizado : this.pricingId!,
      Mascotas : [...mascotas],
    }

    this.showPopup = true;
    this.popupData = {
      component : PopupPetConfirmComponent,
      proposal: {...this.proposal}
    }    
  }

  ngOnDestroy(): void {
    this.issue$.unsubscribe();
  }

  onCheckPlan(planAndValue : any){
    const option = this.priceConfig?.multiOptions?.find(m => m.selected);    
    if(this.form.value.mascotas && option){
      option.completed = false;
      const pets = [...this.form.value.mascotas];
      const currentPet = pets.find(p => p.nombre === option?.id);
      if(!planAndValue.value){        
        currentPet.plan = undefined;
        currentPet.descripcion = undefined;
      }
      else{
        if(!currentPet){
          this.form.value.mascotas.push({nombre : option?.id, plan: planAndValue.plan.IdCotizacionPlan, descripcion : planAndValue.plan.Plan});
          option.completed = true;
        }          
        else{
          currentPet.plan = planAndValue.plan.IdCotizacionPlan;
          currentPet.descripcion = planAndValue.plan.Plan;
          option.completed = true;
        }
          
      }      

      let petWithoutPlan = pets.find(p => !p.plan); // Si seleccionó y lo borró
      if(!petWithoutPlan)
        petWithoutPlan = this.priceConfig?.multiOptions?.find(m => !pets.find(p => p.plan === m.id)); // Si tiene que ir al proximo

      const nextPet = this.priceConfig?.multiOptions?.find(m => petWithoutPlan.nombre === m.id);
      if(nextPet){
        this.priceConfig?.multiOptions?.forEach(p=> {
          p.selected = false;
        });
        
        nextPet.selected = true;
      }
      else{
        // todos con plan GG EZ
        option.completed = true;
      }
    }
    
  }

  onOptionClick(option : any){
    if(!option.selected){
      this.priceConfig?.multiOptions?.forEach(m => {
        m.selected = false;
      })

      option.selected = true;

      this.prices.forEach(p => {p.isSelected = false});
    }
  }

  setFilterOptions(prices : PricedPlanModel[]){
    // Esto es ineficiente, pero vamos a tener 20 planes como MUCHISIMO
    const companies : ISelectItem[] = [];
    const plans : ISelectItem[] = [];
    prices.forEach(p => {
      if(!companies.find(c => c.id === p.IdCompania.toString()))
        companies.push({id: p.IdCompania.toString(), descripcion: p.Compania ?? ""});

      if(!plans.find(c => c.id === p.IdPlan.toString()))
        plans.push({id: p.IdPlan.toString(), descripcion : p.Plan});
    })
    
    const filter : PricesFilterModel[] = [
      { id:'COMPANY', descripcion : LABELS.FILTERS_COMPANY, opciones : companies, field: 'IdCompania'},
      { id:'PLAN', descripcion : LABELS.PLAN, opciones : plans, field: 'IdPlan'},
    ]

    return filter;
  }

  continue(){
    if(this.pets.length !== this.form.value.mascotas.length){
      this.onOptionClick(this.priceConfig?.multiOptions?.find(p => !this.form.value.mascotas.find((m : any) => m.nombre === p.descripcion) ))
    }
    else{
      const petWithoutPlan = this.form.value.mascotas.find((p : any) => !p.plan)
      if(petWithoutPlan)
        this.onOptionClick(this.priceConfig?.multiOptions?.find(p => p.descripcion === petWithoutPlan.nombre))
      else
        this.beginEmission();
    }
  }
  
  beginEmission(){
    // esto no debería hacerse :(
    this.issueState.fullState.quotingState.mascotas = this.form.value.mascotas;
    
    this.redirector.redirectToEmission(this.form.value.mascotas[0].plan);
  }
}

