import { Component, Input, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { LABELS } from 'src/app/main/constants/labels.enum';
import { IProposalModel } from 'src/app/main/models/proposal.model';
import { LabelModel } from 'src/app/shared/models/label.model';
import { ModelCreatorService } from 'src/app/shared/services/model-creator.service';
import { ProductsListComponent } from '../products-list/products-list.component';
import { ProposalCoveragesComponent } from '../proposal-coverages/proposal-coverages.component';
import { PopupOpenerService } from 'src/app/shared/services/popup-opener.service';
import { PopupMessageComponent } from 'src/app/shared/popup-message/popup-message.component';
import { PopUpTypesEnum } from 'src/app/shared/enums/popup-types.enum';
import { PopupCancelComponent } from 'src/app/shared/popup-cancel/popup-cancel.component';
import { ButtonModel } from 'src/app/shared/models/button.model';
import { ButtonStylesEnum, ButtonTypesEnum } from 'src/app/main/constants/buttons.enum';
import { RedirectorService } from 'src/app/main/services/redirector.service';
import { IssueState } from 'src/app/state/issue/issue.state';

@Component({
  selector: 'proposal-detail',
  templateUrl: './proposal-detail.component.html',
  styleUrls: ['./proposal-detail.component.scss']
})
export class ProposalDetailComponent implements OnInit {
    @Input() model : IProposalModel | undefined;
    readonly proposal_detail= LABELS.PROPOSAL_DETAIL;
    readonly view_coverage = LABELS.VIEW_COVERAGES;
    readonly regret_button = LABELS.REGRET_BUTTON;
    readonly cancel_button = LABELS.CANCEL_BUTTON;
    popupData : any;
    proposalBlockDetails:any[] =[];
    coverages_detail:any[]=[];
    coverages_details:any[]=[];
    quota:LabelModel= new LabelModel();
    titleCoverages:string=LABELS.TITLE_COVERAGES;
    btnOptions = new ButtonModel();
    btnRenewal = new ButtonModel();

    showCoverages=false;
    componentData:any;
    constructor(private modelCreator : ModelCreatorService,    
      private popupSvc : PopupOpenerService,
      private redirector:RedirectorService,
      private issue_state : IssueState) {
    }
    ngOnInit(): void {
      this.processData();
    }
    processData(): void {
      this.model?.Bloques.forEach(bloque => {
        const dataLabels = bloque.Datos.map(dato => this.modelCreator.createLabelModel(dato.Valor, dato.Etiqueta));
        const blockLabel = { data: dataLabels, title: bloque.Titulo };
        this.proposalBlockDetails.push(blockLabel);
      });
      this.btnOptions = this.modelCreator.createButtonModel("Ver otras opciones", ButtonTypesEnum.Common,ButtonStylesEnum.black);
      this.btnRenewal = this.modelCreator.createButtonModel("Confirmar renovación", ButtonTypesEnum.Common,ButtonStylesEnum.base_button);
      this.quota=this.modelCreator.createLabelModel(LABELS.MONEY_SYMBOL+" "+this.model!.CuotaMensual,LABELS.MONTHLY_QUOTA_DETAIL)
    }

    goQuote(){
      this.issue_state.getQuotingInfo(8);
      this.redirector.redirectToProposalVehicle()
    }
    goRenewal(){
      this.issue_state.getQuotingInfo(8);
      this.issue_state.quoteExemple();
      this.redirector.redirectToRenewal(101);
    }

    showCancel(){
      if(this.model?.EstadoId==9){
        this.showMessagePopUp('', [LABELS.MESSAGE_CANCELED_POLICY, '',''], PopUpTypesEnum.warning )
        return
      }

      this.showCoverages=true;
      this.componentData={
        component:PopupCancelComponent,
        proposal:this.model?.Solicitud,
        reasons:this.model?.MotivosBaja
      };
    }


    processCoverages(){
      this.coverages_details=[]
      this.model!.Coberturas.forEach(c => {
        const coverage={
          Descripcion:c.Descripcion,
          Tipo:c.Valor,
        }
        this.coverages_details.push(coverage);
      });
      this.showCoverages=true;
      this.componentData={
        component:ProposalCoveragesComponent,
        coverages_details:this.coverages_details,
        plan:parseInt(this.model!.IdPlan),
        prod:parseInt(this.model!.IdProducto),
        tipo:this.model!.TipoPoliza
      };
    }
    onCancel(){
      this.showCoverages=false;
    }

    showMessagePopUp(title : string, messages : string[], type : PopUpTypesEnum = PopUpTypesEnum.success){
      this.popupData={
        component:PopupMessageComponent,
        config : {
          title : title,
          hideHeader : true
        },
        
        data : {
          type : type,
          messages : messages,
          button:true
        }
      };
      this.popupSvc.show(this.popupData)
    }


    showNotAvailablePopUp(){
      this.showMessagePopUp('', [LABELS.MESSAGE_CONFIRMATION, LABELS.MESSAGE_CONFIRMATION1,LABELS.MESSAGE_CONFIRMATION2], PopUpTypesEnum.warning )
    }
}




