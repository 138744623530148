
<div class="row">
  <div class="col-sm-6 textTag">
    <p class="col-form-label" [ngStyle]="{ 'margin': '0', 'padding': '0'}">{{ model.label }}</p>
  </div>
  <div class="col-sm-6 textLabel">
    <p [ngStyle]="{ 'font-weight':'normal', 'margin': '0', 'padding': '0' }" class="basic-font">
      {{model.text}}
    </p>
  </div>
</div>
