<full-cover-card class="align-items-center">    
        <div class="m-auto px-4">

            <div class="text-center col-12">
                <img alt="lock" src="../../../assets/icons/lock.svg">
                <h1 class="text-center">{{title}}</h1>
            </div>
            
            <div *ngIf="!emailSended" class="col-12">
                <div class="text-center pt-md-5">                
                    <div class="row text-center">
                        <div class="col-2"></div>
                        <div class="col-8 text-center"><p>{{subtitle}}</p></div>
                        <div class="col-2"></div>
                    </div>
                </div>
                
                
                <div class="justify-content-center col-12 col-md-8 m-auto">
                    <div class="row pt-3 ">  
                        <custom-input [model]="emailInput"></custom-input>
                    </div>        
                    <div class="row py-4">
                        <custom-button [model]="btnSend" (customClick)="submit()"></custom-button>
                    </div>
                </div>
            </div>
            
    
            <div *ngIf="emailSended" class="final-message text-center">
                <p>{{emailStatus}}</p>
            </div>

            <div class="py-4 col-12 col-md-8 m-auto">
                <custom-button [model]="btnBackToLogin" (customClick)="navigateToLogin()"/>
            </div>
            
        </div>

        
    


</full-cover-card>