<div class="justify-content-left col-12 col-md-8 m-auto">
    <div class="row pt-3 align-items-start stepper-title-container">
      <div class="d-flex flex-column align-items-start">
        <app-custom-stepper [currentStep]="currentStep" [steps]="steps" class="custom-stepper"></app-custom-stepper>
        <p class="fw-medium title">{{title}}</p>
      </div>
    </div>
  </div>
  
  <div class="justify-content-center col-12 col-md-8 m-auto">
  
    <div *ngIf="currentStep===0">

        <div class="col-12 mt-4">
            <app-new-home [form]="riskForm" [regions]="regions ?? []" [isNumberRequired]="false"></app-new-home>
        </div>          
      <div class="row mb-4 mt-2">
      <custom-button [model]="btnContinue!" (customClick)="next()" class="col-sm-12 mt-2" *ngIf="currentStep===0"></custom-button>
      </div>
    </div>
  
  
    <div *ngIf="currentStep===1" >
  
        <div class="row pt-3" style="margin-bottom: 20px;">
          <p class="fs-6 text-secondary text-left" style="color:rgb(172, 172, 172) !important;">{{type_property}}</p>
          <div>
            <custom-select [model]="propertyType" (selectChanged)="validatePropertyType()">
            </custom-select>
          </div>
        </div>

        <div class="row pt-3" style="margin-bottom: 20px;">
            <p class="fs-6 text-secondary text-left" style="color:rgb(172, 172, 172) !important;">{{type_use_residential}}</p>
            <div>
              <custom-select [model]="typeUseSelect" (selectChanged)="validateTypeUse()">
              </custom-select>
            </div>
        </div>
        <div class="row pt-3" style="margin-bottom: 20px;">
            <p class="fs-6 text-secondary text-left" style="color:rgb(172, 172, 172) !important;">{{type_construction}}</p>
            <div>
              <custom-select [model]="constructionSelect" (selectChanged)="validateTypeConstruction()">
              </custom-select>
            </div>
        </div>

          
    </div>
    
  
    <div *ngIf="currentStep===2" >
      <div class="row pt-3" style="margin-bottom: 20px;">
        <p class="fs-6 text-secondary text-left" style="color:rgb(172, 172, 172) !important;">{{type_activity}}</p>
        <div>
          <custom-select [model]="activitySelect" (selectChanged)="validateActivity()">
          </custom-select>
        </div>
      </div>


      <p class="fs-6 text-secondary text-center mt-4" style="color:rgb(172, 172, 172) !important;">{{owner_insured}}</p>
      <div class="card-container d-flex flex-wrap justify-content-center">
          <div class="card" (keypress)="validateQuestion(1)" (click)="validateQuestion(1)" [class.selected]="riskForm!.controls['propietarioAsegurado']!.value ===1" tabindex="0">
            <div class="card-body" >
              <p class="card-title">{{yes}}</p>
            </div>
          </div>
          <div class="card" (keypress)="validateQuestion(0)" (click)="validateQuestion(0)" [class.selected]="riskForm!.controls['propietarioAsegurado']!.value ===0" tabindex="0">
            <div class="card-body" >
              <p class="card-title">{{no}}</p>
            </div>
          </div>
      </div>
  
    </div>


    <div *ngIf="currentStep===3" >
      <p class="fs-6 text-secondary text-center mt-4" style="color:rgb(172, 172, 172) !important;">{{public_assets}}</p>
      <div class="card-container d-flex flex-wrap justify-content-center">
          <div class="card" (keypress)="validateHeritage(1)" (click)="validateHeritage(1)" [class.selected]="riskForm!.controls['esPatrimonioPublico']!.value ===1" tabindex="0">
            <div class="card-body" >
              <p class="card-title">{{yes}}</p>
            </div>
          </div>
          <div class="card" (keypress)="validateHeritage(0)" (click)="validateHeritage(0)" [class.selected]="riskForm!.controls['esPatrimonioPublico']!.value ===0" tabindex="0">
            <div class="card-body" >
              <p class="card-title">{{no}}</p>
            </div>
          </div>
      </div>
  
      <p class="fs-6 text-secondary text-center mt-4" style="color:rgb(172, 172, 172) !important;">{{vacant_property}}</p>
      <div class="card-container d-flex flex-wrap justify-content-center">
          <div class="card" (keypress)="validateVacantProperty(1)" (click)="validateVacantProperty(1)" [class.selected]="riskForm!.controls['esPropiedadDesocupada']!.value ===1" tabindex="0">
            <div class="card-body" >
              <p class="card-title">{{yes}}</p>
            </div>
          </div>
          <div class="card" (keypress)="validateVacantProperty(0)" (click)="validateVacantProperty(0)" [class.selected]="riskForm!.controls['esPropiedadDesocupada']!.value ===0" tabindex="0">
            <div class="card-body" >
              <p class="card-title">{{no}}</p>
            </div>
          </div>
      </div>

      <p class="fs-6 text-secondary text-center mt-4" style="color:rgb(172, 172, 172) !important;">{{wood_walls}}</p>
      <div class="card-container d-flex flex-wrap justify-content-center">
          <div class="card" (keypress)="validateWoodWalls(1)" (click)="validateWoodWalls(1)" [class.selected]="riskForm!.controls['maderaParedes']!.value ===1" tabindex="0">
            <div class="card-body" >
              <p class="card-title">{{yes}}</p>
            </div>
          </div>
          <div class="card" (keypress)="validateWoodWalls(0)" (click)="validateWoodWalls(0)" [class.selected]="riskForm!.controls['maderaParedes']!.value ===0" tabindex="0">
            <div class="card-body" >
              <p class="card-title">{{no}}</p>
            </div>
          </div>
      </div>

    </div>
  
    <div class="row mb-4 mt-2" *ngIf="currentStep!==0">
      <custom-button [model]="btnReturn!" (customClick)="return()" class="col-sm-4 mt-2"
        *ngIf="currentStep<=3"></custom-button>
      <custom-button [model]="btnContinue!" (customClick)="next()" class="col-sm-6 offset-sm-2 mt-2"
        *ngIf="true"></custom-button>
    </div>
  </div>