<div class="col-12">
  <p class="ps-2 text-white pointer back" (keypress)="back()" (click)="back()" tabindex="0">
    < {{back_dashboard}}</p>
</div>
<div class="col-12 ">
  <div class="">
    <div class="">
      <div class="col-10" style="margin-left: 1.5rem">
        <div class="col-12 pt-3 d-flex align-items-center">
          <p class="fs-4 text-left title-font-white">
            <span class="underline-wide title-font-white">{{ my_quotes.slice(0, 5) }}</span>{{ my_quotes.slice(5) }}
          </p>
        </div>

        <div class="pb-4 user-container rounded ">
          <div class="d-flex flex-wrap"></div>

          

          <div class="row justify-content-center pt-4">
            <div class="row justify-content-center pt-4 pb-4">
              <div class="col-10 bg-white rounded mt-1 pt-1">
               <label for="" class="pt-1 pb-2">{{label_quote}}</label>
              </div>
            </div>
            <div class="col-10 ">
              <div style="margin-bottom: 250px;">
                <custom-select [model]="quotes!">
                </custom-select>
              </div>
            </div>
          </div>

          <div class="row justify-content-center">
            <div class="col-sm-4 mb-3">
              <button class="bg-dark btn text-light w-100 rounded-3"
                (click)="validationQuote(1)">{{my_re_quotes}}</button>
            </div>
            <div class="col-sm-4 mb-3">
              <button class="bg-dark btn text-light w-100 rounded-3"
                (click)="validationQuote(2)">{{go_to_plans}}</button>
            </div>
          </div>

        </div>

      </div>
    </div>
  </div>
</div>