import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { authenticationGuard } from 'src/app/main/guards/authentication.guard';
import { UserComponent } from './user/user.component';
import { UserInfoComponent } from 'src/app/features/user/user-info/user-info.component';



const routes: Routes = [  
  {
    path: '',
    component: UserComponent,
    canLoad : [authenticationGuard],
    canActivateChild: [authenticationGuard],
    children: [
        {
          path: 'user',
          component: UserInfoComponent,
          canLoad : []
        },        
    ]
  }
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class UserRoutingModule { }
