import { Component } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ButtonStylesEnum, ButtonTypesEnum } from 'src/app/main/constants/buttons.enum';
import { ERRORS } from 'src/app/main/constants/errors.enum';
import { InputTypesEnum } from 'src/app/main/constants/inputs-types.enum';
import { LABELS } from 'src/app/main/constants/labels.enum';
import { CoverCardModel } from 'src/app/main/models/cover-card.model';
import { AuthenticatorService } from 'src/app/main/services/authenticator.service';
import { RedirectorService } from 'src/app/main/services/redirector.service';
import { ButtonModel } from 'src/app/shared/models/button.model';
import { InputModel } from 'src/app/shared/models/input.model';
import { ModelCreatorService } from 'src/app/shared/services/model-creator.service';
import { AppState } from 'src/app/state/app/app.state';
import { IssueState } from 'src/app/state/issue/issue.state';
import { LoginState } from 'src/app/state/login/login.state';

@Component({
  selector: 'app-call-center-login',
  templateUrl: './call-center-login.component.html',
  styleUrls: ['./call-center-login.component.scss']
})
export class CallCenterLoginComponent {


  title : string = LABELS.USER_CALL_VALIDATION;
  subtitle : string = LABELS.USER_CALL_EMAIL_ENTER;
  coverCardModel : CoverCardModel = {showBackButton : true, useDefaultBack : false };  
  emailInput : InputModel = new InputModel();
  btnBegin : ButtonModel = new ButtonModel();

  form : FormGroup;

  constructor(private auth : AuthenticatorService,
    private modelCreator : ModelCreatorService,
    private fb : FormBuilder,
    private loginState : LoginState,
    private appState : AppState,
    private redirector : RedirectorService
  ) {
      this.form = fb.group({
        email : ['', Validators.required],
      })

    this.emailInput = this.modelCreator.createInputModel(LABELS.EMAIL, InputTypesEnum.email, 'email', this.form, ERRORS.EMAIL_INVALID);
    this.btnBegin = this.modelCreator.createButtonModel(LABELS.CONTINUE, ButtonTypesEnum.Raised, ButtonStylesEnum.black)
  }

  logout(){
    this.auth.logout();
  }

  continue(){
    
    if(this.form.invalid || !this.form.value.email){
      return;
    }

    this.auth.getUserCall(this.form.value.email).subscribe(data => {
      
      if(!data.IsSuccess || !data.Token){
        return; //mensajito
      }
      this.appState.setEnterpriseId(data.Result.IdEmpresa);
      this.loginState.setUser(data.Result, true);
      this.auth.setToken(data.Token);
      this.redirector.redirectToDashboard();
    })
  }
}
