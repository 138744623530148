import { Component, Input, OnInit } from '@angular/core';
import { LABELS } from 'src/app/main/constants/labels.enum';
import { IProposalModel } from 'src/app/main/models/proposal.model';
import { PopupDownloadsComponent } from 'src/app/shared/popup-downloads/popup-downloads.component';
import { PopupInsuranceComponent } from 'src/app/shared/popup-insurance-data/popup-insurance-data.component';

@Component({
  selector: 'proposal-card',
  templateUrl: './proposal-card.component.html',
  styleUrls: ['./proposal-card.component.scss']
})
export class ProposalCardComponent implements OnInit{
  @Input() model : IProposalModel | undefined;

  proposal_list_type             = LABELS.PROPOSAL_LIST_TYPE              ;
  proposal_list_insurance_company= LABELS.PROPOSAL_LIST_INSURANCE_COMPANY ;
  proposal_list_number           = LABELS.PROPOSAL_LIST_NUMBER            ;
  proposal_list_monthly_payment  = LABELS.PROPOSAL_LIST_MONTHLY_PAYMENT   ;
  proposal_list_status           = LABELS.PROPOSAL_LIST_STATUS            ;
  titles:string[]=[this.proposal_list_type,this.proposal_list_insurance_company,this.proposal_list_number,this.proposal_list_monthly_payment,this.proposal_list_status, '']
  popupSize=false;
  showDetailBox = false;
  showDetail:boolean |undefined;
  textDetail:string=LABELS.SEE_PROPOSAL_DETAIL;

  titleDownload:string=LABELS.TITLE_DOWNLOADS
  showDownloads=false;
  componentData:any;
  toggleDetailBox() {
    this.showDetailBox = !this.showDetailBox;
  }
  detail(detaill:any){
    if(detaill==1){
      this.showDetail = !this.showDetail;
      if(this.showDetail===true)
        this.textDetail =LABELS.HIDE_PROPOSAL_DETAIL;
      else
        this.textDetail =LABELS.SEE_PROPOSAL_DETAIL;
    }
    if(detaill==2){

      this.componentData={
        component:PopupDownloadsComponent,
        quote:this.model?.Solicitud,
        plan:this.model?.IdPlan,
        prod : this.model?.IdProducto,
        state:this.model?.EstadoId,
        type:this.model?.TipoPoliza,
        documents:this.model?.Documentos
      };
      this.showDownloads=true;
    }
    if(detaill==3){
      this.popupSize=false
      this.componentData={
        component:PopupInsuranceComponent,
        company:this.model?.IdCompania
      };
      this.showDownloads=true;
    }

  }
  ngOnInit(): void {
    if(this.model)
      this.model.PremioFormat='$'+this.model.CuotaMensual;
  }
  onCancel(){
    this.showDownloads=false;
  }
}
