import { ChangeDetectorRef, Component, EventEmitter, Input, OnChanges, OnDestroy, OnInit, Output, SimpleChanges } from '@angular/core';
import { FormBuilder} from '@angular/forms';
import { Subscription } from 'rxjs';
import { ButtonStylesEnum, ButtonTypesEnum } from 'src/app/main/constants/buttons.enum';
import { LABELS } from 'src/app/main/constants/labels.enum';
import { IProposalModel } from 'src/app/main/models/proposal.model';
import { ConsultantService } from 'src/app/main/services/consultant.service';
import { PopUpTypesEnum } from 'src/app/shared/enums/popup-types.enum';
import { ButtonModel } from 'src/app/shared/models/button.model';
import { InputModel } from 'src/app/shared/models/input.model';
import { PopupCancelComponent } from 'src/app/shared/popup-cancel/popup-cancel.component';
import { PopupMessageComponent } from 'src/app/shared/popup-message/popup-message.component';
import { ModelCreatorService } from 'src/app/shared/services/model-creator.service';
import { PopupOpenerService } from 'src/app/shared/services/popup-opener.service';
import { ProposalCoveragesComponent } from '../proposal-coverages/proposal-coverages.component';

@Component({
  selector: 'detail-prospect',
  templateUrl: './detail-prospect.component.html',
  styleUrls: ['./detail-prospect.component.scss']
})
export class DetailProspectComponent implements OnInit,OnDestroy {
  @Output() closeDetail = new EventEmitter<boolean>();
  @Output() goQuote = new EventEmitter<any[]>();
  @Input() policy:any;
  btnQuote= new ButtonModel();
  btnCancel= new ButtonModel();
  btnCoverages= new ButtonModel();
  selectedPolicyNumber:any=''
  proposalBlockDetails:any=[]
  coverages_details:any[]=[];
  proposal:IProposalModel| undefined;
  popupData : any;
  componentData:any;
  policyId:any;
  showPopup=false;
  isExpanded=true;
  private subscription: Subscription = new Subscription();
  titleCoverages:string=LABELS.TITLE_COVERAGES;
  constructor(private fb: FormBuilder, private modelCreator: ModelCreatorService,private consultantProposal:ConsultantService, private dialog : PopupOpenerService,private cdr: ChangeDetectorRef) { 
    this.btnCancel = this.modelCreator.createButtonModel("Cancelar seguro", ButtonTypesEnum.Raised, ButtonStylesEnum.white_outline);
    this.btnCoverages = this.modelCreator.createButtonModel("Ver coberturas del plan", ButtonTypesEnum.Raised, ButtonStylesEnum.white_outline);
    //this.btnDownload = this.modelCreator.createButtonModel("Descargar documentación", ButtonTypesEnum.Raised, ButtonStylesEnum.white_outline);
    this.btnQuote = this.modelCreator.createButtonModel("Cotizar", ButtonTypesEnum.Common, ButtonStylesEnum.black);
  }
  ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }
  ngOnInit(): void {
    this.policyId=this.policy.IdPoliza || this.policy.Solicitud 
    this.subscription.add(
      this.consultantProposal.refreshList$.subscribe(() => {
        this.consultProspect();
      })
    )
    this.consultProspect()
  }

  consultProspect(){
    this.consultantProposal.consultProspects(this.policyId).subscribe({
      next  : (data) => {
        if(data.IsSuccess){
          this.proposalBlockDetails=data.Result.Bloques
          this.proposal=data.Result
        }  
      },
      error : (_) => {
        console.log("error prospect");
      }
    })
  }
  showCancel(){
    this.isExpanded=false
    this.titleCoverages="";
    if(this.proposal?.EstadoId==9){
      this.showMessagePopUp('', [LABELS.MESSAGE_CANCELED_POLICY, '',''], PopUpTypesEnum.warning )
      return
    }

    this.showPopup=true;
    this.componentData={
      component:PopupCancelComponent,
      proposal:this.proposal?.Solicitud,
      reasons:this.proposal?.MotivosBaja
    };
    this.cdr.detectChanges();
  }

  onCancel(){
    this.isExpanded=true
    this.showPopup=false
  }

  processCoverages(){
    this.isExpanded=false
    this.titleCoverages=LABELS.TITLE_COVERAGES;
    this.coverages_details=[]
    this.proposal!.Coberturas.forEach(c => {
      const coverage={
        Descripcion:c.Descripcion,
        Tipo:c.Valor,
      }
      this.coverages_details.push(coverage);
    });
    this.showPopup=true;
    this.componentData={
      component:ProposalCoveragesComponent,
      coverages_details:this.coverages_details,
      plan:parseInt(this.proposal!.IdPlan),
      prod:parseInt(this.proposal!.IdProducto),
      tipo:this.proposal!.TipoPoliza
    };
    this.cdr.detectChanges();

  }

  showMessagePopUp(title : string, messages : string[], type : PopUpTypesEnum = PopUpTypesEnum.warning){    
    const component ={
      component:PopupMessageComponent,
      config : {
        closable : true,          
      },
      data : {
        type : type,
        title : title,
        messages : messages
      }      
    };
    this.dialog.hide();
    setTimeout(() => {this.dialog.show(component)})
}
  
  closeDetailCard(){
    this.closeDetail.emit();
  }
  quote(){
    this.goQuote.emit([0,this.policy])
  }
}
