import { CanActivateFn, Router } from '@angular/router';
import { AuthenticatorService } from '../services/authenticator.service';
import { inject } from '@angular/core';
import { LoginState } from 'src/app/state/login/login.state';

export const callCenterGuard: CanActivateFn = (route, state) => {

  const router = inject(Router)
  const login = inject(LoginState)
  
  if(login.isLogged && login.user?.isCallCenter){
    return true;
  }
  else{
    router.navigate(['about']);
    return false;
  } 
};
