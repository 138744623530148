<div class="d-flex col-12 justify-content-between main-bar">
    <div class="col-3 d-flex  m-left" >
        <div class="col-3 text-center align-content-center" (keypress)="toggleMenu()" (click)="toggleMenu()" tabindex="0">
            <i class="fa-solid fa-bars pointer" ></i>    
        </div>
        <div class="col-7 align-content-center text-start  d-sm-block d-none">
            <p class="my-0">{{welcome +' '+  (user?.Nombre ?? '') + '!'}}</p>
        </div>
        
    </div>
    
    <div class="col-sm-3 flex-grow-sm-1 justify-content-center align-items-center d-flex">
        <img alt="Enterprise Logo" *ngIf="enterpriseId" class=" nav-logo mx-1 mb-1 p-2" [src]="'../../../assets/companies/' + enterpriseId"/>
        <img alt="Aon Logo" class="aon-background nav-logo mx-1 mb-1 p-2"    [src]="'../../../assets/aon-logo-white.svg'"/>
    </div>
</div>
