<div class="col-12">
  <p class="ps-2 text-white pointer back" (keypress)="back()" (click)="back()" style="margin-left: 20px;" tabindex="0"> < {{back_dashboard}}</p>
</div>
<div class="h-100 w-100 p-4 d-flex gap-4">

  <div class="col-5 card scroll-y p-3">

      <div class="insurance-benefits">
        <h4 class="section-title" style="margin-top: 20px !important;">{{title_banner_vehicle1}}</h4>
        <h4 class="section-title" >{{title_banner_vehicle2}} <span class="bold-text">{{title_banner_vehicle3}}</span></h4>
        <div class="benefits-grid">
            <div class="benefit-item">
              <img alt="Dental banner 0" src="../../../../assets/banners/dental.svg"/>
                <h5 class="bold-text" style="color:#5a5959;  font-size: 0.9rem  !important">{{subtitle_banner_vehicle1}}</h5>
                <p><span class="bold-text">{{body1_banner_vehicle1}}</span> {{body2_banner_vehicle1}}</p>
            </div>
            <div class="benefit-item">
              <img alt="Dental banner 1" src="../../../../assets/banners/dental2.svg"/>
                <h5 class="bold-text" style="color:#5a5959;font-size: 0.9rem  !important">{{subtitle_banner_vehicle2}}</h5>
                <p><span class="bold-text">{{body1_banner_vehicle2}}</span> {{body2_banner_vehicle2}}</p>
            </div>
            <div class="benefit-item">
              <img alt="Dental banner 2" src="../../../../assets/banners/dental3.svg"/>
                <h5 class="bold-text" style="color:#5a5959; font-size: 0.9rem  !important">{{subtitle_banner_vehicle3}}</h5>
                <p><span class="bold-text">{{body1_banner_vehicle3}}</span> {{body2_banner_vehicle3}}</p>
            </div>
            <div class="benefit-item">
              <img alt="Dental banner 3" src="../../../../assets/banners/dental4.svg"/>
                <h5 class="bold-text" style="color:#5a5959; font-size: 0.9rem  !important">{{subtitle_banner_vehicle4}}</h5>
                <p><span class="bold-text">{{body1_banner_vehicle4}}</span> {{body2_banner_vehicle4}}</p>
            </div>
        </div>

    </div> 
  </div>  

  <div class="col-6">
      <div class="m-auto p-2 common-card h-100 d-flex col-12 flex-column align-items-center scroll-y justify-content-start pt-4">

          <div class="col-12 col-md-8">
              <p class="fs-5 fw-medium" style="margin-top: 60px;">{{title_dental}}</p>
              <p class="fs-7 text-secondary">{{subtitle_dental}}</p>
          </div>

          <div class="col-12 d-flex justify-content-center">
            <div class="d-flex col-12 col-md-8 justify-content-center flex-wrap">  

                <div class="col-12 mt-4">
                  <app-new-home [form]="form" [regions]="regions ?? []" [isNumberRequired]="false"></app-new-home>
              </div>

                <div class="col-12 flex-wrap text-end py-2 mt-4">
                  <custom-button [model]="btnNext!" (customClick)="price()"/>
                </div>
            </div>


          </div>

      </div>

</div>