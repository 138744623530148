import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class UrlCleanerService {

  retrieveClean(url : string) : string{
    url = url.replaceAll('%21', '!');
    url = url.replaceAll('%23', '#');
    url = url.replaceAll('%24', '$');
    url = url.replaceAll('%25', '%');
    url = url.replaceAll('%26', '&');
    url = url.replaceAll('%27', '\'');
    url = url.replaceAll('%28', '(');
    url = url.replaceAll('%29', ')');
    url = url.replaceAll('%2A', '*');
    url = url.replaceAll('%2B', '+');
    url = url.replaceAll('%2C', ',');
    url = url.replaceAll('%2F', '/');
    url = url.replaceAll('%3A', ':');
    url = url.replaceAll('%3B', ';');
    url = url.replaceAll('%3D', '=');
    url = url.replaceAll('%3F', '?');
    url = url.replaceAll('%40', '@');
    url = url.replaceAll('%5B', '[');
    url = url.replaceAll('%5D', ']');
    return url;
  }


  createClean(url : string) : string{
    url = url.replaceAll( '!', '%21',);
    url = url.replaceAll( '#', '%23',);
    url = url.replaceAll( '$', '%24',);
    url = url.replaceAll( '&', '%26',);
    url = url.replaceAll( '\'', '%27');
    url = url.replaceAll( '(', '%28',);
    url = url.replaceAll( ')', '%29',);
    url = url.replaceAll( '*', '%2A',);
    url = url.replaceAll( '+', '%2B',);
    url = url.replaceAll( ',', '%2C',);
    url = url.replaceAll( '/', '%2F',);
    url = url.replaceAll( ':', '%3A',);
    url = url.replaceAll( ';', '%3B',);
    url = url.replaceAll( '=', '%3D',);
    url = url.replaceAll( '?', '%3F',);
    url = url.replaceAll( '@', '%40',);
    url = url.replaceAll( '[', '%5B',);
    url = url.replaceAll( ']', '%5D',);
    return url;
  }

  getOriginFromUrl(url : string){
    const urlArray = url.split("/");
    
    const protocol = urlArray[0];
    const hostName = urlArray[2];
    return protocol + "//" + hostName
  }
}
