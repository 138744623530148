import { Component, Input, OnChanges, Output, SimpleChanges } from '@angular/core';
import { EventEmitter } from '@angular/core';

@Component({
  selector: 'app-issue-steps-aside',
  templateUrl: './issue-steps-aside.component.html',
  styleUrls: ['./issue-steps-aside.component.scss']
})
export class IssueStepsAsideComponent implements OnChanges{
  @Input() steps? : any[];
  @Input() currentStep? : number;
  @Output() stepClicked = new EventEmitter<any>();

  emitStepClick(id : any, step : any){
    this.stepClicked.emit({id : id});
  }

  ngOnChanges(changes: SimpleChanges): void {
    // lo dejamos para que detecte los cambios
    if(!changes)
      return;
  }

  checkSelected(index : number){
    if(this.steps){
      return this.steps[index].checked;
    }      
    return false;
  }
}
