
<form class="py-3" *ngIf="model && model.parentGroup && model.formControlName" [formGroup]="model.parentGroup">
    <div class="col-12 d-flex justify-content-evenly">

        <div *ngFor="let input of formControls; index as i"
             class="px-1">
                <form [formGroup]="input">
                    <input [id]="'input-' + model.formControlName + '-' + i" class="divided-input input-section w-100 text-center py-2 fs-1 input-size" [ngClass]="{'bg-dark text-light': model.parentGroup.controls[model.formControlName].value[i].value}"
                    formControlName="value"
                    [type]="model.type"                    
                    [attr.maxlength]="model.maxLength"
                    (focusout)="focusOut(i)"
                    (keyup)="keyUp($event, i)"
                    >
                
                </form>
        </div>               
    </div>

    <div class="row col-12 pt-2 pb-0">
        <p *ngIf="formInvalid"
        class="text-danger">
        {{model.customError}}</p>   
    </div>

</form>