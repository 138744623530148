<div class="justify-content-left col-12 col-md-8 m-auto">
    <div class="row pt-3 align-items-start stepper-title-container">
        <p class="fw-medium title mt-4">{{title}}</p>
    </div>
  </div>
      <div class="justify-content-center col-12 col-md-8 m-auto" >
            <!-- <p class="fw-medium">{{title}}</p> -->
            
            <div *ngIf="currentStep===0">
              <p class="fs-6 text-secondary text-center mt-4" style="color:rgb(172, 172, 172) !important;">{{renewal_quote}}</p>
              <div class="card-container d-flex flex-wrap justify-content-center">
                  <div class="card" (keypress)="validateBudget(1)" (click)="validateBudget(1)" [class.selected]="budgetSelected === '1'" tabindex="0">
                    <div class="card-body" >
                      <p class="card-title">{{proposal_question_isnew_yes}}</p>
                    </div>
                  </div>
                  <div class="card" (keypress)="validateBudget(0)" (click)="validateBudget(0)" [class.selected]="budgetSelected === '0'" tabindex="0">
                    <div class="card-body" >
                      <p class="card-title">{{proposal_question_isnew_no}}</p>
                    </div>
                  </div>                 
            </div> 
    
            <div *ngIf="+(this.budgetSelected ?? -1)===1">

              <p class="fs-6 text-secondary text-center mt-4" style="color:rgb(172, 172, 172) !important;" >{{claim_occurred}}</p>
              <div class="card-container d-flex flex-wrap justify-content-center" >
                <div class="card" (keypress)="validateClaim(1)" (click)="validateClaim(1)" [class.selected]="claimSelected === '1'" tabindex="0">
                  <div class="card-body" >
                    <p class="card-title">{{proposal_question_isnew_yes}}</p>
                  </div>
                </div>
                <div class="card" (keypress)="validateClaim(0)" (click)="validateClaim(0)" [class.selected]="claimSelected === '0'" tabindex="0">
                  <div class="card-body" >
                    <p class="card-title">{{proposal_question_isnew_no}}</p>
                  </div>
                </div>
              </div> 

              <p class="fs-6 text-secondary text-left">{{question_suped_code}}</p>
              <div>
                  <custom-select
                    [model]="susepCodeSelect"                    
                    (selectChanged)="validateSupepCode()">
                  </custom-select>
              </div>


              <div class="row pt-3">  
                <custom-input [model]="renewalPolicyNumberInput!"></custom-input>
              </div> 
            </div>       

            <div class="row mb-4 mt-2" >  
                <custom-button [model]="btnReturn!" (customClick)="return()" class="col-sm-6 mt-2" *ngIf="currentStep<=2"></custom-button>
                <custom-button [model]="btnContinue!" (customClick)="next()" class="col-sm-6 mt-2" *ngIf="currentStep<=2"></custom-button>
            </div>
      </div>  
        