import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { LandingComponent } from './pages/landing/landing.component';
import { LoginComponent } from './pages/login/login.component';
import { authenticationGuard } from './main/guards/authentication.guard';
import { DashboardComponent } from './pages/dashboard/dashboard.component';
import { RegisterComponent } from './pages/register/register.component';
import { ResetPasswordComponent } from './pages/reset-password/reset-password.component';
import { ValidateComponent } from './pages/validate/validate.component';
import { RedirectComponent } from './pages/redirect/redirect.component';
import { RecoverComponent } from './pages/recover/recover.component';
import { HomeComponent } from './pages/home/home.component';
import { CallCenterLoginComponent } from './pages/call-center-login/call-center-login.component';
import { callCenterGuard } from './main/guards/call-center.guard';

const routes: Routes = [  
  {
    path: 'my',
    component: HomeComponent,
    canLoad:[authenticationGuard],
    children:
      [
        {
          path: '' ,
          loadChildren: () => import('./pages/home/home.module').then(module => module.HomeModule),
          canLoad: [authenticationGuard],
        }
      ]
  },
  { path: 'landing', component : LandingComponent, data : {}  },    
  { path: 'login', component: LoginComponent,   data: {} },
  { path :'call-center', component : CallCenterLoginComponent, data : {}, canActivate: [callCenterGuard]},
  { path: 'register', component: RegisterComponent,   data: {}   },
  { path: 'reset', component: ResetPasswordComponent,   data: {} },
  { path: 'recover', component : RecoverComponent, data : {},},
  { path: 'validate', component : ValidateComponent, data : {}  },
  { path: 'redirect', component : RedirectComponent, data : {}  },

  { path: '**'   , component: LoginComponent, data: { showHeader: true } }
];

@NgModule({
  imports: [RouterModule.forRoot(routes, { useHash: true, initialNavigation: 'enabledBlocking' })],
  exports: [RouterModule]
})
export class AppRoutingModule { }
