import { Component, ElementRef, HostBinding, OnInit } from '@angular/core';
import { MaterialHelperService } from './shared/material/material-helper.service';
import { HeaderService } from './main/services/header.service';
import { ThemeService } from './main/services/theme.service';
import { NavigationEnd, Router } from '@angular/router';
import { AppState } from './state/app/app.state';
import { CrypterService } from './main/services/crypter.service';
import { UrlCleanerService } from './main/services/url-cleaner.service';
import { TranslationsService } from './main/services/translations.service';
import { environment } from 'src/environments/environment';
import { filter, Subscription } from 'rxjs';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent{
  title = 'Isol-Multitenant';
    
  route$ = new Subscription();
  // Le pongo referencia para que se agreguen los iconos apenas empieza la app :)
  constructor(private material : MaterialHelperService, 
    private themeService : ThemeService,
    private crypterService : CrypterService,
    private router : Router,
    private urlCleaner : UrlCleanerService,
    private elementRef : ElementRef,
    private translations : TranslationsService,
    private appState : AppState) 
  {
    this.getLanguage();    
    this.checkVersion();

    this.route$ = router.events.pipe(filter(event => event instanceof NavigationEnd)).subscribe({
      next : (route : any) => {
        if(route.url){
          const theme = this.decryptUrl(route.url);
          if(theme != null)
            this.getAppTheme(theme);
        }
          
      }
    })
  }

  checkVersion(){
    const version = localStorage.getItem("version")
    if(version !== environment.appVersion){
      localStorage.setItem("version", environment.appVersion ?? '');
    }
  }
  
  decryptUrl(route : string){
    const baseTheme : any = {Id : null, IdCampania : undefined};
    this.appState.setEnterpriseId(baseTheme.Id);

    const currentRoute = route.split("/");
    if(currentRoute[currentRoute.length - 1]?.trim() === "")
      return null;

    const url = currentRoute[currentRoute.length - 1];
    try{
      const decryptedObj :string = this.crypterService.decrypt(this.urlCleaner.retrieveClean(url))?.split('-');
      if(decryptedObj != "" && decryptedObj.length === 2 ){
        baseTheme.Id = +decryptedObj[0];
        baseTheme.IdCampania = +decryptedObj[1]
      }
    }
    catch{
      // se rompió, dejamos los colores por defecto
      return null;
    }

    return baseTheme;
  }

  getAppTheme(baseTheme : any){       
    this.appState.setEnterpriseId(baseTheme.Id);

    let link = this.crypterService.encrypt(JSON.stringify(baseTheme));
    link = this.urlCleaner.createClean(link);
    this.themeService.getOrganizationTheme(link).subscribe({
      next : (response) => {
        if(response.IsSuccess && response.Result){
          const theme = response.Result;

          if(theme.ColorPrincipal){
            const hue = this.themeService.getHueColor(theme.ColorPrincipal);
            this.elementRef.nativeElement.style.setProperty('--base-color',`hsl(${hue.degrees},${hue.saturation}%,${hue.lightness}%)`);
            this.elementRef.nativeElement.style.setProperty('--color-rotation',`${hue.degrees}deg`);

          }
          
          if(theme.ColorSecundario){
            const hue = this.themeService.getHueColor(theme.ColorSecundario);
            this.elementRef.nativeElement.style.setProperty('--secondary-color',`hsl(${hue.degrees},${hue.saturation}%,${hue.lightness}%)`);
          }
          
          if(!Number.isNaN(+(theme?.Id ?? 'hola')))
            this.appState.setEnterpriseId(theme.Id!);
          
          this.appState.setCampaignId(baseTheme.IdCampania);
          this.route$.unsubscribe();
        }                
      },
      error : (err) => {        
        this.appState.setEnterpriseId(baseTheme.Id);
      }
    });        
      
  }

  getLanguage(){
    // this.translations.getPtBr();
    this.translations.getEsMx();
  }

  // loadTranslations() {
  //   this.translations.getEsMx();
  //   this.translations.getTranslationsObservable()?.subscribe(translations => {
  //     if (translations.LABELS) {
  //       Object.entries(translations.LABELS).forEach(([key, value]) => {
  //         (LABELS as any)[key] = value; 
  //       });
  //     }
  
  //     console.log('LABELS recibidos:', translations.LABELS, translations.ERRORS, translations.MENUS);
  //   });
  // }
}
