
<div class="w-100">    
    <div class="">
        <p class="fw-medium fs-5">{{title}}</p>
        <p class="fs-6 text-secondary text-center mt-4" style="color:rgb(172, 172, 172) !important;">O responsável financeiro é a mesma pesssoa que o titular do plano?</p>
        <div class="card-container d-flex flex-wrap justify-content-center">
        <div class="card" (keypress)="validateIsResident(1)" (click)="validateIsResident(1)" [class.selected]="personalInfoForm!.controls['responsableEsTitular']!.value! === 1" tabindex="0">
            <div class="card-body" >
            <p class="card-title" >{{yes}}</p>
            </div>
        </div>
        <div class="card" (keypress)="validateIsResident(0)" (click)="validateIsResident(0)" [class.selected]="personalInfoForm!.controls['responsableEsTitular']!.value! === 0" tabindex="0">
            <div class="card-body">
            <p class="card-title">{{no}}</p>
            </div>
        </div>
        </div>


        <div *ngIf="personalInfoForm!.controls['responsableEsTitular']!.value! === 0">
            <div class="py-2">
                <custom-input [model]="cpfModel!"></custom-input>
            </div>
            <div class="py-2">
                <custom-input [model]="nameModel!"></custom-input>
            </div>
            <div class="py-2">
                <custom-input [model]="sobreNameModel!"></custom-input>
            </div>

            <div class="py-2">
                <p class="fs-6 text-secondary text-left" style="color:rgb(172, 172, 172) !important;">{{relationship_with_owner}}</p>
                <custom-select [model]="relationshipSelect"></custom-select>
            </div>

            <div class="py-2">
                <custom-input [model]="emailModel!"></custom-input>
            </div>
            <div class="py-2">
                <custom-input [model]="phoneModel!"></custom-input>
            </div>
        

            <div class="py-3 ">
                <app-new-home [form]="personalInfoForm" [regions]="regions ?? []"></app-new-home>
            </div>

            <div class="col-12 col-md-12 py-2" [formGroup]="personalInfoForm!" style="font-size: 0.6rem !important; color: gray;">
                <p-checkbox [label]="chk_lbl" [binary]="true" [formControlName]="'disclaimer'"></p-checkbox>
            </div>    
        </div>


    </div>
</div>


