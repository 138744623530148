import { Component, OnInit } from '@angular/core';
import { Tip } from 'src/app/main/models/issue/tips.model';
import { ConsultantService } from 'src/app/main/services/consultant.service';
import { RedirectorService } from 'src/app/main/services/redirector.service';

@Component({
  selector: 'app-insurance-recommendation',
  templateUrl: './insurance-recommendation.component.html',
  styleUrls: ['./insurance-recommendation.component.scss']
})
export class InsuranceRecommendationComponent implements OnInit{
  title= ''
  body=  ''
  currentIndex = 0;
  icon="fa-solid fa-chevron-right";
  tips: Tip[] = [];
  randomTips: Tip[] = [];
  constructor(private redirector : RedirectorService,private consultantProposal:ConsultantService){
  }
  ngOnInit(): void {
    this.tips = [];
    this.consultantProposal.listTips().subscribe({
      next: (data:any) => {
        if (data.IsSuccess) {
          this.tips = data.Result;
          this.consultantProposal.tips=this.tips;
          this.randomTips = this.tips.filter(t=>t.Id==9 || t.Id==13|| t.Id==18)
          this.title=this.randomTips[2].Titulo;
          this.body=this.randomTips[2].Parrafo;
        }
      },
      error: (_) => {
        return;
      }
    });
  }
  change(index?: number): void {
    if (index !== undefined) {
      this.currentIndex = index;
    } else {
      this.currentIndex = (this.currentIndex + 1) % this.randomTips.length;
    }
    this.updateTip(this.currentIndex);
  }

  updateTip(index: number): void {
    if (this.randomTips[index]) {
      this.title = this.randomTips[index].Titulo;
      this.body = this.randomTips[index].Parrafo;
    }
  }

  redirectToTips(){
    this.redirector.redirectToTip(this.randomTips[this.currentIndex].Id);
  }
}
