import { ChangeDetectorRef, Component, Input, OnInit } from '@angular/core';
import { LABELS } from 'src/app/main/constants/labels.enum';
import { Cobertura, IProposalModel, Item } from 'src/app/main/models/proposal.model';
import { ButtonModel } from '../models/button.model';
import { ModelCreatorService } from '../services/model-creator.service';
import { ButtonTypesEnum } from '../enums/buttons.enum';
import { ButtonStylesEnum } from 'src/app/main/constants/buttons.enum';
import { DropdownCustomModel } from '../models/dropdown.model';
import { ISelectItem } from 'src/app/main/models/select-item.model';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { PopupOpenerService } from '../services/popup-opener.service';
import { PopupMessageComponent } from '../popup-message/popup-message.component';
import { PopUpTypesEnum } from '../enums/popup-types.enum';
import { ConsultantService } from 'src/app/main/services/consultant.service';
import { IDocumentacion } from 'src/app/main/models/documentation.model';
import { ICONS } from 'src/app/main/constants/icons.enum';
import { ERRORS } from 'src/app/main/constants/errors.enum';

@Component({
  selector: 'popup-downloads',
  templateUrl: './popup-downloads.component.html',
  styleUrls: ['./popup-downloads.component.scss']
})
export class PopupDownloadsComponent implements OnInit{
  @Input() quote : any | undefined;
  @Input() state : any | undefined;
  @Input() plan : any | undefined;
  @Input() type : any | undefined;
  @Input() prod : any | undefined;
  @Input() documents : Item[] | undefined;

  parentGroup: FormGroup |undefined;
  typeUseSelect?: DropdownCustomModel;
  typeUseSelectList: ISelectItem[] | undefined;
  btnContinue : ButtonModel|undefined;
  requestObject:any;
  showDocumentPolicy:boolean|undefined=false;
  showDocumentProposta:boolean|undefined=false;
  popupData : any;
  readonly documents_downloads= LABELS.DOCUMENTS_DOWNLOADS
  readonly download_policy= LABELS.DOWNLOAD_POLICY
  readonly download_proposal= LABELS.DOWNLOAD_PROPOSAL
  readonly download_product_manual= LABELS.DOWNLOAD_PRODUCT_MANUAL
  readonly download=LABELS.DOWNLOAD
    constructor(private modelCreator : ModelCreatorService,    private fb: FormBuilder,private popupSvc : PopupOpenerService,private consultantProposal:ConsultantService,private cdr: ChangeDetectorRef){
      this.btnContinue = this.modelCreator.createButtonModel(LABELS.CONFIRM, ButtonTypesEnum.Raised, ButtonStylesEnum.black);
    }
    ngOnInit(): void {
      this.cdr.detectChanges();
      if (this.documents) {
        const formGroupConfig: { [key: string]: any } = {};
    
        this.documents.forEach(doc => {
          formGroupConfig[doc.Descripcion] = [false];
        });
    
        this.parentGroup = this.fb.group(formGroupConfig);
      }
    }
    showMessagePopUp(title : string, messages : string[], type : PopUpTypesEnum = PopUpTypesEnum.success){
      this.popupData={
        component:PopupMessageComponent,
        config : {
          title : title,
          hideHeader : true
        },
        
        data : {
          type : type,
          messages : messages,
          button:true
        }
      };
      this.popupSvc.show(this.popupData)
    }
    showNotAvailablePopUp(): void {
      const parentGroupValue = this.parentGroup?.value;

      if (this.documents) {
        this.documents.forEach((document) => {
          const controlValue = parentGroupValue[document.Descripcion];    
          if (controlValue) {
            this.requestDocument(parseInt(document.Id));
          }
        });
      }
    }

     requestPdf(): void {
      const request : IDocumentacion={
        Tipo:2,
        Id:+this.plan,
        TipoCategoria:'1'
      }
      if(this.type === 'PET'){
        request.Id =  +this.prod;      
        request.TipoCategoria='2'
      }      

      this.consultantProposal.getPdf(request).subscribe({
        next: (data) => {
          if (data.IsSuccess) {
            this.consultantProposal.openPdf(data.Result,data.Message)
          }
        },
        error: (err) => {
          //this.showError();
        }
      });
    }

    private requestDocument(tipoDocumento: number): void {
      const requestObject = {
        NroPoliza: this.quote,
        TipoDocumento: tipoDocumento
      };
      this.consultantProposal.downloadDocuments(requestObject).subscribe({
        next: (response: any) => this.handleDocumentResponse(response),
        error: (err) => console.error('Error:', err)
      });
    }
  
    private handleDocumentResponse(response: any): void {
      if (response.IsSuccess) {
        const byteCharacters = atob(response.Result);
        const byteNumbers = new Array(byteCharacters.length);
        for (let i = 0; i < byteCharacters.length; i++) {
          byteNumbers[i] = byteCharacters.charCodeAt(i);
        }
        const byteArray = new Uint8Array(byteNumbers); 
        const blob = new Blob([byteArray], { type: 'application/pdf' });
        if (blob.size === 0) {
          return;
        }
        const url = window.URL.createObjectURL(blob);
        const a = document.createElement('a');
        a.href = url;
        a.download = `${this.quote}-${response.Message}`;
        document.body.appendChild(a);
        a.click();
  
        window.open(url);
        window.URL.revokeObjectURL(url);
        this.showMessagePopUp('', ['Documentos descargados con éxito.', '', ''], PopUpTypesEnum.success);
      }
      else{
        this.popupSvc.show({
          component : PopupMessageComponent,
          config: {closable : true, closeOnEscape : true},
          data : {
              type  : PopUpTypesEnum.warning,
              icon : ICONS.WARNING,
              messages : [ERRORS.FAIL_DOCUMENT_DOWNLOAD]
          }
        })
      }
    }
    
}
