import { HttpErrorResponse, HttpEvent, HttpHandler, HttpInterceptor, HttpRequest, HttpResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, catchError, finalize, map, of, tap } from 'rxjs';
import { IGenericResponseModel } from '../models/generic-response.model';
import { CrypterService } from './crypter.service';
import { SpinnerService } from './spinner.service';
import { GenericErrorResponse } from '../models/generic-error-response.model';
import { AuthenticatorService } from './authenticator.service';
import { LoginState } from 'src/app/state/login/login.state';
import { AppState } from 'src/app/state/app/app.state';


@Injectable({
  providedIn: 'root'
})
export class InterceptorService implements HttpInterceptor {

  private readonly emulatedServices : string[] = ['ActualizarUsuario'];
  private token  = "";

  constructor(private crypter : CrypterService,private auth:AuthenticatorService,
    private appState : AppState,
    private spinner : SpinnerService) { }
  
  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<any> {    
    
    const emulated = this.emulatedServices.filter(f => req.url.indexOf(f) !== -1);
    // Simulado
    if(emulated.length !== 0){
      return of(new HttpResponse({
        status : 200, body: this.emulateGenericResponse()
      }));  
    }
  
    this.spinner.showOrHideSpinner(true);
    let cryptedRequest = req.clone({
      responseType: 'text'
    });
    const accessToken = this.auth.token;
    if(req.body){
      cryptedRequest = req.clone({
        body : '"' + this.crypter.encrypt(req.body) + '"',
        headers : req.headers.append('Content-Type', 'application/json').append('Authorization', 'Bearer ' + accessToken),
        responseType: 'text'
      });      
    }else{
      cryptedRequest = req.clone({
        headers : req.headers.append('Content-Type', 'application/json').append('Authorization', 'Bearer ' + accessToken),
        responseType: 'text'
      });     
    }

    return next.handle(cryptedRequest).pipe(map( (event : HttpEvent<any>) => {      
      if(event instanceof HttpResponse && !event.url?.includes('assets')){
        event = event.clone({body : event.body ? JSON.parse(this.crypter.decrypt(event.body)) : null});
        
        const token = event.body.Token;
        if(token)
          this.auth.setToken(token);
      }
      
      return event;
    }),
    catchError((err) => {
      if(err && err.message === 'Unexpected end of JSON input'){
        const res = new HttpErrorResponse({
          error: new GenericErrorResponse(err),
          headers: err.headers,
          status: err.status,
          statusText: err.statusText,          
          url: err.url
        });
        throw res;
      }
      
      if(err.status >= 200 && err.status <= 300){
        err = err.clone({body: this.crypter.decrypt(err.body)});
      }
      else if(err.status === 401){
        this.appState.clearAppState();
        this.auth.logout();
      }
      return err;
    }),
    finalize(() => {
      this.spinner.showOrHideSpinner(false);
    })
    );
  }
    
  emulateGenericResponse() : IGenericResponseModel{
    return {Id : 1, IsSuccess : true, Message : "Mensaje piola", Code:100};
  }

  isAuthenticated(){
    return this.token !== "" && this.token !== undefined && this.token !== null;
  }
}
