<div class="overlay" ></div>
  <div class="detail-card" [ngClass]="{ 'expanded': isExpanded, 'collapsed': !isExpanded }">
    <div class="detail-card-header">
      <h5 style="color: black; margin-left: 1rem;">Detalle de la póliza<span class="policy-number" style="margin-left: 20px; background-color:#d7e2f3; font-weight: bold; color: rgb(108, 108, 108);border-radius: 5px; padding: 2px 5px;font-weight: normal;">Nro {{proposal?.Solicitud }}</span></h5>
      <button class="close-button" (click)="closeDetailCard()">&#x2715;</button>
    </div>
    <div class="detail-card-content" *ngIf="proposalBlockDetails">
      <div *ngFor="let block of proposalBlockDetails">
        <h6>{{ block.Titulo }}</h6>
        <div class="detail-section">
          <p *ngFor="let item of block.Datos">
            <strong>{{ item.Etiqueta }}</strong> {{ item.Valor }}
          </p>
        </div>
      </div>
      <div class="detail-card-footer row">
        <custom-button [model]="btnCoverages" (customClick)="processCoverages()" class="col-sm-6 mt-1"></custom-button>
        <custom-button [model]="btnCancel" (customClick)="showCancel()" class="col-sm-6 mt-1" *ngIf="proposal?.EstadoId===1 && proposal?.MotivosBaja && proposal!.MotivosBaja.length>0"></custom-button>
        <!-- <custom-button [model]="btnQuote" (customClick)="quote()" class="col-md-4 col-sm-4"></custom-button> -->
      </div>
    </div>

  </div>

  <custom-popup *ngIf="showPopup"
  [show]="true" 
  [hiddenHeader]="true"
  [hiddenButtons]="true"
  [maxSize]="true"
  [title]="titleCoverages" 
  [componentData]="componentData"
  (dismissed)="onCancel()">
</custom-popup>