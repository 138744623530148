
<div class="px-0 px-sm-5 pb-3 pt-3 d-flex flex-wrap align-items-start align-content-start justify-content-center h-100 w-100">

    <div class="col-12 d-flex align-items-center">
        <p class="fs-4 text-left title-font-white">
            <span class="half-border title-font-white">{{my_account}}</span>
        </p>
    </div>

    <div class="user-container rounded p-0 p-sm-5 w-100 opacity-100 justify-content-center d-flex flex-column scroll-y">        
        <div class="col-10 d-flex flex-wrap justify-self-center align-self-center">

            <div *ngFor="let menu of menus; let index as i"
                 class="col-12 col-md-6 p-2 h-50 pointer" >
                 <div tabindex="0" class="col-12 common-card p-2 d-flex" (keypress)="handleClick(menu)" (click)="handleClick(menu)">

                    <div class="col-4 rounded-circle align-content-center mx-1 ">
                        <img [alt]="menu.descripcion + 'icon'" class="user-image m-auto d-block rounded-circle company-background p-2" [src]="'/assets/icons/' + menu.icon" width="auto" height="80%">
    
                    </div>
                    <div class="col-7 align-content-center fs-6">
                        <p class="d-inline"> {{menu.descripcion}}</p>
                    </div>

                 </div>
                
                
                 

            </div>

        </div>

        

    </div>


</div>
