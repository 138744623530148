import { Injectable } from "@angular/core";
import { Subject } from "rxjs";
import { PersonModel } from "src/app/main/models/person.model"
import { UserService } from "src/app/main/services/user/user.service";

@Injectable()
export class LoginState {
    isLogged  = false;
    user? : PersonModel;
    user$ : Subject<PersonModel | undefined> = new Subject();
    constructor(private userSvc : UserService) {        
    }

    setUser(user : PersonModel, isCallCenter = false){
        this.user = user;
        user.isCallCenter = isCallCenter;
        this.isLogged = true;
        this.user$.next(this.user);
    }

    clearState(){
        this.isLogged = false;
        this.user = undefined;
    }
    getUserObs(){
      return this.user$.asObservable();
    }
    getUserInfo(){
        this.userSvc.getUserInfo().subscribe( {
            next : (response) => {
              if(!response.IsSuccess){
                // mostrar cartelito
                return;
              }
              response.Result.esCliente = true;
              if(!response.Result.Email)
                response.Result.Email = this.user?.Email
              if(response.Result.IdEmpresa === undefined || response.Result.IdEmpresa === -1 || Number.isNaN(+response.Result.IdEmpresa))
                response.Result.IdEmpresa = this.user?.IdEmpresa

              response.Result.disclaimer=false
              this.setUser(response.Result);

            }
          });
    }
}
