import { Component, DoCheck, EventEmitter, Input, Output } from '@angular/core';
import { MaterialModule } from 'src/app/shared/material/material.module';
import { ButtonModel } from '../models/button.model';
import { ButtonTypesEnum } from '../../main/constants/buttons.enum';

@Component({
  selector: 'custom-button',
  templateUrl: './custom-button.component.html',
  styleUrls: ['./custom-button.component.scss'],
})
export class CustomButtonComponent implements DoCheck {
  @Input() model : ButtonModel = new ButtonModel();
  @Output() customClick = new EventEmitter<any>();
  @Output() iconClicked = new EventEmitter<any>();
  @Output() keyPressed = new EventEmitter<any>();

  readonly buttonTypes = ButtonTypesEnum

  emitClick(){
    this.customClick.emit();
  }

  ngDoCheck(): void {
    // este método si o si se necesita para detectar los cambios dentro del model
    console.log("");  
  }
  

}
