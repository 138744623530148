import { ChangeDetectorRef, Component, Input, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Subscription } from 'rxjs';
import { ERRORS } from 'src/app/main/constants/errors.enum';
import { LABELS } from 'src/app/main/constants/labels.enum';
import { IProposalModel } from 'src/app/main/models/proposal.model';
import { AuthenticatorService } from 'src/app/main/services/authenticator.service';
import { ConsultantService } from 'src/app/main/services/consultant.service';
import { RedirectorService } from 'src/app/main/services/redirector.service';
import { LabelModel } from 'src/app/shared/models/label.model';
import { ModelCreatorService } from 'src/app/shared/services/model-creator.service';
import { IssueState } from 'src/app/state/issue/issue.state';
import { LoginState } from 'src/app/state/login/login.state';
import { States } from 'src/app/state/models/states.enum';
import { UserInfoComponent } from '../../user/user-info/user-info.component';
import { PopupOpenerService } from 'src/app/shared/services/popup-opener.service';
import { PopupMessageComponent } from 'src/app/shared/popup-message/popup-message.component';
import { PopUpTypesEnum } from 'src/app/shared/enums/popup-types.enum';
import { PopupDownloadsComponent } from 'src/app/shared/popup-downloads/popup-downloads.component';
import { PopupInsuranceComponent } from 'src/app/shared/popup-insurance-data/popup-insurance-data.component';
import { ICONS } from 'src/app/main/constants/icons.enum';
import { IOrdenItem } from 'src/app/main/models/select-item.model';

@Component({
  selector: 'emission-list',
  templateUrl: './emission-list.component.html',
  styleUrls: ['./emission-list.component.scss']
})
export class EmissionListComponent implements OnInit{
  consultantError:string|undefined;
  showconsultantError=false;
  readonly my_proposals= LABELS.MY_PROPOSALS;
  readonly message:string=ERRORS.NO_PROPOSALS
  readonly proposal_list_error1=LABELS.PROPOSALS_LIST_ERROR1
  readonly proposal_list_error2=LABELS.PROPOSALS_LIST_ERROR2
  readonly go_quote = LABELS.GO_RENEWAL
  showMessage  = false;  
  hasFetchedQuotingInfo=false;
  proposals : IProposalModel[]=[]
  nameFilter="";
  rfcFilter="";
  listOrden:IOrdenItem[]=[];
  currentPage=1
  recordsPerPage=10
  totalRecords=0
  totalPages=0
  quotingState1$ : Subscription = new Subscription();


  needsToUpdateClient  = false;
  updateClientComponent : any ={
    component:UserInfoComponent,
    config : {
      closeOnEscape : false,
      closable : false,
      width: '80%',
    },
    data :{ 
      showFullInfo : false,
      onSave : () => {        
        this.needsToUpdateClient = false;
        this.showMessagePopUp(LABELS.SAVE_SUCCESS, [LABELS.CLIENT_UPDATE_SUCCESS])}   
      } 
  };
  columns = [
    { title: 'Nombre', field: 'Nombre', styleClass : 'col-2' },
    { title: 'RFC', field: 'RFC', styleClass : 'col-1' },
    { title: 'Póliza', field: 'NroPoliza', styleClass : 'col-1' },
    { title: 'Compañía', field: 'Compania', styleClass : 'col-1' },
    { title: 'Marca/Modelo', field: 'Modelo', styleClass : 'col-1' },
    { title: 'Estado', field: 'Estado', styleClass : 'col-2' },
    { title: 'Acciones', field: '', styleClass : 'col-1' } 
  ];
  proposalsList:IProposalModel[]=[]

  componentData:any;
  showPopUp=false;
  popupSize=false;
  titleDownload:string=LABELS.TITLE_DOWNLOADS

  private subscription: Subscription = new Subscription();
  constructor(private fb: FormBuilder,private modelCreator : ModelCreatorService,private consultantProposal:ConsultantService,private redirectorService: RedirectorService,    private issue_state : IssueState,private loginState : LoginState,    private dialog : PopupOpenerService,private cdr: ChangeDetectorRef) {
    this.checkLoginState();
  }

  ngOnInit(): void {
    this.subscription.add(
      this.consultantProposal.refreshList$.subscribe(() => {
        this.proposals=[]
        this.listEmission();
      })
    );
    this.listEmission();
  }
  subscribeToQuotingState(){
    this.quotingState1$ = this.issue_state.checkState().subscribe({
      next : ({prospectusQuoteState}) => {
        if(prospectusQuoteState.state === States.loading){
          return;
        }
        if(prospectusQuoteState.state === States.success && !this.hasFetchedQuotingInfo){
          this.hasFetchedQuotingInfo = true;
            this.issue_state.getQuotingInfo(prospectusQuoteState.quoteInfo!.TprId,prospectusQuoteState!.quoteInfo?.CotId)
          if(prospectusQuoteState.quoteInfo?.TprId==8){
            this.redirectorService.redirectToProposalVehicle();
          }            
        
        }
        this.quotingState1$.unsubscribe();
      }
    })      
  }

  checkLoginState(){
    if(!this.loginState.isLogged)
      return;
    
    if(!this.loginState.user?.esCliente){
      this.needsToUpdateClient = true;
      this.dialog.show(
        this.updateClientComponent
      );
    }
    else{
      this.loginState.getUserInfo();
    }
  }
  showMessagePopUp(title : string, messages : string[], type : PopUpTypesEnum = PopUpTypesEnum.success){
    this.updateClientComponent ={
      component:PopupMessageComponent,
      config : {
        closable : true
      },
      data : {
        type : type,
        title : title,
        messages : messages
      }      
    };
    this.needsToUpdateClient=true;
    this.dialog.hide();
    this.dialog.show(this.updateClientComponent)
  }
  goDasboard(){
    this.nameFilter="";
    this.rfcFilter="";
    this.consultantProposal.triggerRefresh();
  }
  showError(msg  = ""){
    this.consultantError = ERRORS.COULD_NOT_REGISTER;
    if(msg)
      this.consultantError = msg;   
    this.showconsultantError = true;
  }
  listEmission(){
    this.showMessage=false
    const request={
      PaginaActual:this.currentPage,
      RegistrosPorPagina:this.recordsPerPage,
      Nombre:this.nameFilter,
      RFC:this.rfcFilter,
      Orden:this.listOrden
    }
    this.consultantProposal.consultProposal(request).subscribe({
      next  : (data) => {
        if(data.IsSuccess){
          this.proposalsList=data.Result.Polizas;
          this.currentPage=data.Result.PaginaActual
          this.recordsPerPage=data.Result.RegistrosPorPagina
          this.totalPages=data.Result.TotalPaginas
          this.totalRecords=data.Result.TotalRegistros
          if(this.proposalsList.length==0){
            this.showMessage=true
          }
          return;
        }  
      },
      error : (err) => {
        this.showError();
      }
    })
  }
  detail(value:any){
    const policy=value[1]
    if(value[0]==2){
      this.titleDownload = LABELS.TITLE_DOWNLOADS;
      this.componentData={
        component:PopupDownloadsComponent,
        quote:policy?.Solicitud,
        plan:policy?.IdPlan,
        prod : policy?.IdProducto,
        state:policy?.EstadoId,
        type:policy?.TipoPoliza,
        documents:policy?.Documentos
      };
      this.showPopUp=true;
      this.cdr.detectChanges();
    }
    if(value[0]==3){
      this.titleDownload = LABELS.INSURANCE_DATA;
      this.componentData={
        component: PopupInsuranceComponent,
        company:policy.IdCompania
      };
      this.showPopUp=true;
    }
    this.cdr.detectChanges();

  }
  pageChange(value:any){
    this.currentPage=value[0];
    this.recordsPerPage=value[1];
    this.nameFilter=value[2];
    this.rfcFilter=value[3];
    this.listOrden=value[4];
    this.consultantProposal.triggerRefresh();
  }
  ngOnDestroy() {
    this.quotingState1$.unsubscribe();
    this.subscription.unsubscribe(); 
  }
  onCancel(){
    this.showPopUp=false;
  }
}
