import { Component, OnDestroy } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { debug } from 'node:console';
import { readFile } from 'node:fs';
import { Subscription } from 'rxjs';
import { ButtonStylesEnum, ButtonTypesEnum } from 'src/app/main/constants/buttons.enum';
import { ERRORS } from 'src/app/main/constants/errors.enum';
import { InputTypesEnum } from 'src/app/main/constants/inputs-types.enum';
import { LABELS } from 'src/app/main/constants/labels.enum';
import { IDomicile } from 'src/app/main/models/domicile.model';
import { EmissionDataInfoModel } from 'src/app/main/models/emission/emission-info-data.model';
import { EmissionValidStepsEnum } from 'src/app/main/models/emission/emission-valid-steps.enum';
import { QuotingInfoModel } from 'src/app/main/models/emission/quoting-info.model';
import { RenewalValidStepsEnum } from 'src/app/main/models/emission/renewal-valid-steps.enum';
import { ISelectItem } from 'src/app/main/models/select-item.model';
import { ConsultantService } from 'src/app/main/services/consultant.service';
import { EmissionFormHandlerService } from 'src/app/main/services/issue/emission-form-handler.service';
import { RenewalFormHandlerService } from 'src/app/main/services/issue/renewal-form-handler.service';
import { Masks } from 'src/app/shared/enums/masks.enum';
import { InputDateTimeModel } from 'src/app/shared/input-datetime-custom/model/input-datetime-model';
import { ButtonModel } from 'src/app/shared/models/button.model';
import { DropdownCustomModel } from 'src/app/shared/models/dropdown.model';
import { InputModel } from 'src/app/shared/models/input.model';
import { ModelCreatorService } from 'src/app/shared/services/model-creator.service';
import { AppState } from 'src/app/state/app/app.state';
import { IssueState } from 'src/app/state/issue/issue.state';
import { LoginState } from 'src/app/state/login/login.state';
import { States } from 'src/app/state/models/states.enum';

@Component({
  selector: 'emission-periodicity-info',
  templateUrl: './emission-periodicity-info.component.html',
  styleUrls: ['./emission-periodicity-info.component.scss']
})
export class EmissionPeriodicityInfoComponent implements OnDestroy{
  continueBtn : ButtonModel|undefined;
  btnReturn : ButtonModel|undefined;
  periodicityInfoForm : FormGroup;

  title = LABELS.PERIODICITY_DATA_TITLE;

  paymentInstrumentModel? : DropdownCustomModel;
  periodicityModel? : DropdownCustomModel;
  showButtons=true
  issue$ = new Subscription();
  regions? : any[];
  quotingInfo? : QuotingInfoModel;
  emissionDataInfo? : EmissionDataInfoModel;
  quotingInfoSub : Subscription = new Subscription();
  constructor(private modelCreator : ModelCreatorService,
    private formHandler : RenewalFormHandlerService,
    private issueState : IssueState,
    private loginState : LoginState,    
    private consultantProposal: ConsultantService
  ) {    

    this.periodicityInfoForm = this.formHandler.getFormControl(RenewalValidStepsEnum.periodicity) as FormGroup;
    this.createInputs();
    this.loadQuotingInfo();
  }

  createInputs(){
    this.btnReturn = this.modelCreator.createButtonModel(LABELS.RETURN_ISSUE, ButtonTypesEnum.Raised, ButtonStylesEnum.without_white_outline);
    this.continueBtn = this.modelCreator.createButtonModel(LABELS.CONTINUE, ButtonTypesEnum.Common, ButtonStylesEnum.black);
    
    this.periodicityModel = this.modelCreator.createSelectModel('periodicidad', LABELS.SELECT,  [{'id': 1, 'descripcion': 'Semestral'},{'id':2, 'descripcion': 'Trimestral'},{'id': 3, 'descripcion': 'Anual'}], this.periodicityInfoForm!,'','',true,false,false,LABELS.SELECT_ERROR);
    this.periodicityModel.outsideLabel = LABELS.PERIODICITY;

    this.paymentInstrumentModel = this.modelCreator.createSelectModel('instrumentoPago', LABELS.SELECT,  [{'id': 1, 'descripcion': 'Tarjeta'},{'id': 2, 'descripcion': 'Déposito'}], this.periodicityInfoForm!,'','',true,false,false,LABELS.SELECT_ERROR);
    this.paymentInstrumentModel.outsideLabel=LABELS.PAYMENT_INSTRUMENT

  }

  loadQuotingInfo() {
    this.quotingInfoSub = this.issueState.checkState().subscribe({
      next :(state) => {
        const quotingState = state.quotingState;
        // const emissionDataState = state.emissionDataState;
        // if(emissionDataState.state===States.success){
        //   this.emissionDataInfo = emissionDataState.EmissionInfo;
        //   this.loadDataEmission(this.emissionDataInfo!);    
        // }
        if(quotingState.state === States.success){
          this.quotingInfo = quotingState.quotingInfo;     
        }
        else{
          // de nuevo, no debería pasar nunca
        }
      }      
    })
  }
  next(){
    const periodicidad = this.periodicityInfoForm?.get('periodicidad');
    const instrumentoPago = this.periodicityInfoForm?.get('instrumentoPago');

    [periodicidad, instrumentoPago].forEach(field => {
        if (!field?.value) {
            field!.markAllAsTouched();
        }
    });

    if (periodicidad?.value && instrumentoPago?.value) {
        this.showButtons=false
        this.consultantProposal.notifyNextStep();
    }

  }

  return(){
    this.consultantProposal.notifyPreviousStep()
  }

  ngOnDestroy(): void {
    this.issue$.unsubscribe();
    this.quotingInfoSub.unsubscribe();
  }
}
