import { Injectable } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { EmissionValidStepsEnum } from '../../models/emission/emission-valid-steps.enum';

@Injectable({
  providedIn: 'root'
})
export class EmissionFormHandlerService {

  private form : FormGroup;
  private readonly valid_steps = EmissionValidStepsEnum;

  constructor(private fb : FormBuilder) {
    this.form = fb.group({      
    });
    this.cleanForm();
  }

  getForm(){
    return this.form;
  }

  getFormControl(step : EmissionValidStepsEnum){
    return this.form.controls[step];
  }

  setVigency(initialDate : any){
    this.form.controls['vigencia'].setValue(initialDate);
  }


  createForm(hasVehicle? : boolean, hasBenef? : boolean, hasHome? : boolean, hasCoverages? : boolean,hasDental?:boolean){
    this.form.addControl(this.valid_steps.personal_data, this.createPersonalDataForm());
    if(hasDental)
      this.form.addControl(this.valid_steps.manager_data, this.createFinancialManagerlDataDentalForm());

    if(hasVehicle)
      this.form.addControl(this.valid_steps.vehicle, this.createVehicleForm());
    
    if(hasVehicle)
      this.form.addControl(this.valid_steps.driver_data, this.createDriverForm());

    if(hasBenef)
      this.form.addControl(this.valid_steps.beneficiaries, this.createBeneficiariesForm());

    if(hasHome)
      this.form.addControl(this.valid_steps.risk_location, this.createHomeForm());

    if(hasCoverages)
      this.form.addControl(this.valid_steps.coverages, this.createAdditionalCoveragesForm());
    
    this.form.addControl(this.valid_steps.payment_method, this.createPaymentForm());
  }

  createPersonalDataForm(){
    return this.fb.group({
      tipoPersona : ['',],
      rfc : [''],
      nombres : [''],
      apellidoPaterno : [''],

      apellidoMaterno : [''],
      telefono : [''],
      telefonoArea : [''],
      email : [''],

      codigoPostal : [''],
      estado : [''],
      municipio : [''],
      colonia : [''],
      calle : [''],
      numeroInterno : [''],
      numeroExterno : [''],
      domicilio:[],
      pisodepto : [''],

      tipoSociedad : [''],
      giro : [''],
      razonSocial : [''],
      fechaNacimientoConstitucion : [''],

      regimenSocietario:[''],
      regimenFiscal:[''],
      ocupacion:[''],
      disclaimer : [''],
    })
  }
  createFinancialManagerlDataDentalForm(){
    return this.fb.group({
      responsableEsTitular : [''],
      nombreResponsable : [''],
      apellidoResponsable : [''],
      cpf : [''],
      relacionTitular: [''],
      email : [''],
      telefono : [''],
      telefonoArea : [''],
      cep:[''],
      domicilio : [''],
      calle : [''],
      numero : [''],
      pisodepto : [''],
      codigoPostal : [''],
      distrito : [''],
      ciudad : [''],
      idCiudad: [''],
      estado : [''],
      region : [''],
      disclaimer : [''],
    })
  }

  createVehicleForm(){
    return this.fb.group({
      numeroSerie : ['',[Validators.required]],
      motor : ['',],
      placa : ['',],
      // color : ['',[Validators.required]],
      fechaInicioVigencia : [''],
      condicion : ['',[Validators.required]],
      //precio : ['',[Validators.required]],
    })
  }

  createDriverForm(){
    return this.fb.group({
      tipoPersona : [''],
      rfc : [''],
      nombres : [''],
      apellidoPaterno : [''],
      apellidoMaterno : [''],
      ocupacion : [''],
    })
  }

  createBeneficiariesForm(){
    return this.fb.group({
      nombre : [''],
      apellido : [''],
      tipoDocumento : [''],
      nroDocumento : [''],
      fechaNacimiento : [''],
      domicilio : [''],
      telefono : [''],
      email : [''],
      parentesco : [''],
      porcentaje : [''],
    })
  }

  createHomeForm(){
    return this.fb.group({
      domicilio : [''],
      calle : [''],
      numero : [''],
      pisodepto : [''],
      codigoPostal : [''],
      distrito : [''],
      ciudad : [''],
      idCiudad: [''],
      estado : [''],
      region : ['']
    })
  }

  createAdditionalCoveragesForm(){
    return this.fb.group({
      coberturasAdicionales : [],

    })
  }

  createPaymentForm(){
    return this.fb.group({
      nroTarjeta : [],
      nombreTitular : [],
      fechaVencimiento : [],
      codigoSeguridad : [],    
    })
  }

  createFreeDataForm(){
    return this.fb.group({
      email : [''],
      telefono : [''],
      domicilio : ['']
    })  
  }

  getNewDomicileForm(){
    return this.fb.group({
      calle : [''],
      numero : [''],
      codigoPostal : [''],      
      distrito : [''],
      pisodepto: [''],
      ciudad : [''],
      region : [''],
    })
  }

  cleanForm(){
    this.form = this.fb.group({
      vigencia : ['']
    });
  }
  
}
