import { AfterViewInit, Component, ElementRef, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges, ViewChildren } from '@angular/core';
import { DividedInputModel } from '../models/divided-input.model';
import { FormArray, FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';

@Component({
  selector: 'divided-input',
  templateUrl: './divided-input.component.html',
  styleUrls: ['./divided-input.component.scss']
})
export class DividedInputComponent implements OnChanges, AfterViewInit {
  @Input() model : DividedInputModel | undefined;

  @Output() inputFocusOut = new EventEmitter<any>();

  formInvalid  = false;

  constructor(private fb : FormBuilder) {
  }


  ngAfterViewInit(): void {
    console.log("")
  }
  

  ngOnChanges(changes: SimpleChanges): void {
    if(changes && changes['model'] && changes['model'].currentValue && this.model && this.model.parentGroup){        
      this.addControlsToFormArray((this.model!.numberOfDivisions?? 1));     
    }
  }

  focusOut(index : number){
    this.formInvalid = this.checkForErrors();
    if(!this.model?.parentGroup || !this.model?.formControlName)
      return;

    this.inputFocusOut.emit(index);
  }

  
  keyUp(event : KeyboardEvent, index : number){
    
    const currentLenght = this.model?.maxLength ?? 1
    const pattern = new RegExp('^[\\w\\d]{' + currentLenght +'}$')
    if(pattern.test(event.key) && !event.ctrlKey){      
      const htmlId = 'input-' + this.model?.formControlName + '-' + (index + 1).toString();

      document.getElementById(htmlId)?.focus();// Bien casero el asunto
    }  
  }


  public get formControls() : any[] {
    if(!this.model || !this.model.parentGroup || !this.model.formControlName)
      return [];

    return (this.model?.parentGroup.controls[this.model.formControlName] as FormArray).controls;
  }
  

  addControlsToFormArray(count : number){   
    const controlsAlreadyAdded =  this.model!.parentGroup!.controls[this.model!.formControlName!].value && this.model!.parentGroup!.controls[this.model!.formControlName!].value.length != 0
    if(controlsAlreadyAdded)
      return;

    for (let index = 0; index < count; index++) {
      (this.model!.parentGroup!.controls[this.model!.formControlName!] as FormArray).push(this.fb.group({
        value : []
      })); 
      
    } 
    
    if(this.model?.required)
      this.model.parentGroup!.controls[this.model.formControlName!].addValidators(Validators.required);

    if(this.model?.maxLength)
    this.model.parentGroup!.controls[this.model.formControlName!].addValidators(Validators.maxLength(this.model.maxLength));
    
  }

  checkForErrors() : boolean{
    this.model?.parentGroup?.controls[this.model.formControlName ?? ""].setErrors(null)

    if(!this.model)
      return false;

    if(!this.model.customError)
      return false;

    const formTouched = this.formControls.filter(f => f.touched);
    if(formTouched.length === 0)
      return false;

    const formWithNoValue = this.formControls.filter(f =>f.controls['value'].value == null || f.controls['value'].value == "");

    const result = this.model?.parentGroup?.controls[this.model?.formControlName ?? ""].invalid || (formWithNoValue.length !== 0);
    
    if(result)
      this.model?.parentGroup?.controls[this.model.formControlName ?? ""].setErrors({invalid : true})

    return result;
  }
}
