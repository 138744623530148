  <div class="w-full h-full">
    <div class="col-12">
      <p tabindex="0"  class="ps-2 text-back pointer back text-white f-weight-500"       
        (click)="backQuote()"
        (keypress)="backQuote()"      
        *ngIf="!isComparer"><i class="fa-solid fa-angle-left"></i> {{back_quoting}}</p>
    </div>
    <div class="row justify-content-center">
      <!-- <div class="col-12"> -->
        <div class="col-12 col-md-11 col-lg-11 bg-white-light flex-wrap d-flex b-radius-8" *ngIf="!isComparer">
          <div class="row my-3 justify-content-center" *ngIf="prices.length > 0">
  
            <div class="col-12 col-md-4 col-lg-3 filter-section" [ngClass]="{'line': isLargeScreen()}">
              <div class="filter-section" *ngIf="config.filters">
                <div class="col-12">
                <p class="f-size-regular">{{vehicle}}</p>
              </div>
              <div class="col-12 mt-2">
                <p class="font-300 pt-2">{{filter_plans}}</p>
                <div class="filter-category mt-1 mb-4" *ngFor="let filter of config.filters">
                  <p class="font-500">{{filter.descripcion}}</p>
                  <div *ngFor="let opcion of filter.opciones" class="mt-2 mb-1 row align-items-center">
                    <div class="col-2" style="margin-right: -0.4rem !important;">
                      <input [(ngModel)]="opcion.value" type="checkbox" [id]="filter.id" [value]="opcion.id"
                        (change)="onFilterChange(filter)">
                    </div>
                    <div class="col-10 padCero">
                      <label [for]="opcion.id" class="font-200">{{ opcion.descripcion}}</label>
                    </div>
                  </div>
                </div>
              </div>
              </div>
            </div>
  
             <div class="col-12 col-md-12 col-lg-9  flex-wrap d-flex" style="border-radius: 8px;">
              <div class="row col-12 pb-2 pt-2 align-items-center">
                <div class="col-12 col-md-10 col-lg-9 fw-bold align-self-center" style="padding-left: 0px;">
                  <p class="m-0 p-2 f-size-regular">{{config.title}}</p>
                </div>
                <div class="col-12 col-md-2 col-lg-3">
                  <custom-select [model]="filterModel" (selectChanged)="onOrder($event)"></custom-select>
                </div>
              </div>
              <div *ngIf="config.subtitle || config.secondSubtitle" class="row col-12 col-md-8 align-content-center">
                <p *ngIf="config.subtitle" class="mb-min" style="margin-left: -0.2rem;">{{config.subtitle}}</p>
                <p *ngIf="config.secondSubtitle" class="" style="margin-left: -0.2rem;">{{config.secondSubtitle}}</p>
              </div>
              <div *ngIf="config.allowMultiPlanSelection"
                class="col-12 col-md-4 col-lg-4 d-flex flex-wrap justify-content-center justify-content-lg-end px-1 align-items-center">
                <div tabindex="0" class="d-inline-block pe-2 text-center icon-container" *ngFor="let a of config.multiOptions"
                  (keypress)="emitOptionClick(a)"
                  (click)="emitOptionClick(a)">
                  <div class="d-flex flex-column justify-content-between align-items-center h-100">
                    <!-- Ícono centrado -->
                    <div class="pet-icon d-flex justify-content-center align-content-center align-self-center m-auto position-relative"
                      [ngClass]="{'selected':a.selected, 'not-selected':!a.selected, 'completed':a.completed && !a.selected}">
                      <div *ngIf="a.completed" class="button__badge"></div>
                      <img [alt]="'Seu Pet'" src="/assets/product-types/pro9-white.svg" class="icon-logo">
                    </div>
                    <!-- Descripción abajo -->
                    <div class="description-container mt-auto">
                      <p class="text-sm text-center text-ellipsis">{{a.descripcion}}</p>
                    </div>
                  </div>
                </div>
              </div>
  
              <div
                [ngClass]="{'justify-content-center': (showFiltered ? filteredPrices : prices).length <= 3, 'justify-content-left': (showFiltered ? filteredPrices : prices).length > 3}"
                class="row flex-grow-1 align-items-center">
                  
                <div class="col-12 col-lg-4 col-md-12 col-sm-12 pb-3 pt-3" *ngFor="let price of (showFiltered ? filteredPrices : prices) ; index as i">
                  <app-price [showFranquicia]="config.showFranquicia" [data]="price" (priceChecked)="checkPricesSelected(price, $event)"></app-price>
                </div>
                <div class="button-container pb-3">
                  <p class="ps-2 text-back pointer" *ngIf="showMessageComparer" style="color: red;">
                    {{select_multiple_plans}}</p>
                    <div>
                      <custom-button [model]="btnBack!" (customClick)="goToLastStep()"></custom-button>
                    </div>
                    <div class="d-flex flex-row">
                      <custom-button [model]="btnComparePlans!" (customClick)="comparePlans()"
                      *ngIf="prices.length > 1"></custom-button>
                    <custom-button [model]="btnContinue!" (customClick)="beginEmission()"></custom-button>
                    </div>
                  
                  
                </div>
              </div>
  
             </div> 
          </div>
        </div>
      <!-- </div> -->
      <app-plan-comparer [data]="selectedPrices!" (planSelected)="onComparationSelected($event)" (backed)="back()"
        *ngIf="isComparer"></app-plan-comparer>
    </div>