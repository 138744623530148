import { Component } from '@angular/core';
import { LABELS } from 'src/app/main/constants/labels.enum';

@Component({
  selector: 'app-partnership',
  templateUrl: './partnership.component.html',
  styleUrls: ['./partnership.component.scss']
})
export class PartnershipComponent {


  second_section = LABELS.INTENTION_PARTNERSHIP;  
  third_section = {
    title : LABELS.ABOUT_PARTNERSHIP,
    paragraphs : [
      {
        question : '',
        content : LABELS.P1_PARTNERSHIP
      },
      {
        question : LABELS.P2_QUESTION,
        content : LABELS.P2_PARTNERSHIP
      },
      {
        question : LABELS.P3_QUESTION,
        content : LABELS.P3_PARTNERSHIP
      },
    ]
  }

  fourth_section = {
    title : LABELS.ADV_TITLE_PARTNERSHIP,
    advantages : [
      {
        title : LABELS.ADV_P1_TITLE,
        content : LABELS.ADV_P1_PARTNERSHIP
      },
      {
        title : LABELS.ADV_P2_TITLE,
        content : LABELS.ADV_P2_PARTNERSHIP
      },  
  ]}

  fifth_section = {
    title : '',
    advantages : [
      {
        title : LABELS.ADV_P3_TITLE,
        content : LABELS.ADV_P3_PARTNERSHIP
      },
      {
        title : LABELS.ADV_P4_TITLE,
        content : LABELS.ADV_P4_PARTNERSHIP
      },  
  ]
  }

  companies_section = [
    LABELS.P_PROVIDERS1,
    LABELS.P_PROVIDERS2,
    LABELS.P_PROVIDERS3,
    LABELS.P_PROVIDERS4,
    LABELS.P_PROVIDERS5,
    LABELS.P_PROVIDERS6,
    LABELS.P_PROVIDERS7,
    LABELS.P_PROVIDERS8,
    LABELS.P_PROVIDERS9,
    LABELS.P_PROVIDERS10,
    // LABELS.P_PROVIDERS11,
    // LABELS.P_PROVIDERS12,
    // LABELS.P_PROVIDERS13,
    // LABELS.P_PROVIDERS14,
    // LABELS.P_PROVIDERS15,
    // LABELS.P_PROVIDERS16,
  ]


}
