import { Component, DoCheck, EventEmitter, Input, OnChanges, Output, SimpleChanges } from '@angular/core';
import { InputModel } from '../models/input.model';
import { FormBuilder, FormGroup, ValidatorFn, Validators } from '@angular/forms';
import { ButtonTypesEnum } from '../enums/buttons.enum';
import { InputTypesEnum } from 'src/app/main/constants/inputs-types.enum';
import { LABELS } from 'src/app/main/constants/labels.enum';

@Component({
  selector: 'custom-input',
  templateUrl: './custom-input.component.html',
  styleUrls: ['./custom-input.component.scss']
})
export class CustomInputComponent implements OnChanges, DoCheck{
  @Input()  model : InputModel = new InputModel();
  @Output() iconClicked = new EventEmitter<any>();
  @Output() inputFocused = new EventEmitter<any>();
  @Output() inputFocusedOut = new EventEmitter<any>();
  @Output() keyPressed = new EventEmitter<any>();
  @Output() keyIsUp = new EventEmitter<any>();
  @Output() keyIsDown = new EventEmitter<any>();
  @Output() blurred = new EventEmitter<any>();

  private genericForm : FormGroup;

  private readonly input_types = [
    {type : InputTypesEnum.email, validators: [Validators.email, Validators.pattern('^[A-Za-z0-9._%+-]+@[A-Za-z0-9.-]+\\.[A-Za-z]{2,4}$')]},
    {type : InputTypesEnum.cpf,   validators :  [Validators.pattern('^[a-zA-Z0-9]{13}$')]}, 
    {type : InputTypesEnum.phone, validators : [Validators.pattern('^(\\d{7,8})$')]},
    {type : InputTypesEnum.patent, validators :  [Validators.pattern('^[a-zA-Z0-9]{7}$')]},
    {type: InputTypesEnum.serialNumber,validators: [Validators.pattern('^[a-hj-npr-zA-HJ-NPR-Z0-9]*$'),Validators.maxLength(17)]},
    {type : InputTypesEnum.repuve, validators :  [Validators.pattern('(^[a-zA-Z0-9]{8}$|^$)')]},
  ];

  readonly common_type = InputTypesEnum.common;
  readonly password_type = InputTypesEnum.password;
  readonly zipCode = InputTypesEnum.zipCode;
  readonly phone_type = InputTypesEnum.phone;
  readonly cpf_type = InputTypesEnum.cpf;
  readonly mail_type = InputTypesEnum.email;
  readonly mask_type = InputTypesEnum.mask;
  readonly number_type = InputTypesEnum.number;
  readonly patent_type = InputTypesEnum.patent;
  readonly serial_number_type = InputTypesEnum.serialNumber;
  readonly repuve_type = InputTypesEnum.repuve;
  readonly codeArea= LABELS.CODE_AREA
  readonly codeCountry= LABELS.CODE_COUNTRY
  
  readonly cpfInput= LABELS.CPF_FORMAT
  readonly zipCodeInput= LABELS.ZIPCODE_FORMAT
  readonly phone= LABELS.PHONE_INSURED_PLACEHOLDER


  constructor(private fb : FormBuilder) {    
    this.genericForm = this.fb.group({
      genericControl : []
    })
    
    if(!this.model?.parentGroup){
      this.model.parentGroup = this.genericForm;
      this.model.formControlName = 'genericControl'
    }
      

  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes['model'] && changes['model'].currentValue) {
      if (this.model && this.model.parentGroup && this.model.formControlName) {
        const controlName = this.model.formControlName;
        if (this.model.parentGroup.controls[controlName]) {
          this.setCustomValidators();


          
        }
      }
    }
  }

  ngDoCheck(): void {
    const formControl = this.model?.parentGroup?.controls[this.model?.formControlName ?? ''];

    if (!formControl)
      return;

    if (this.model?.disabled)
      formControl?.disable();
    else
      formControl.enable();

    if (this.model?.required) {
      formControl?.addValidators(Validators.required);
    }
    else {
      formControl.removeValidators(Validators.required)
    }
  }

  
  setCustomValidators(){
    if(!this.model.parentGroup || !this.model.formControlName || !this.model.parentGroup.controls[this.model.formControlName]){
      return;
    }    

    const validators : ValidatorFn[] = [];

    if(this.model.required)
      validators.push(Validators.required);
    
    const aux = this.input_types.find(t => t.type === this.model.type);
    if(aux)
      aux.validators.forEach(v => validators.push(v));


    if(validators.length > 0)
      this.model.parentGroup.controls[this.model.formControlName!].setValidators(validators);

    if(this.model.disabled)
      this.model.parentGroup.controls[this.model.formControlName!].disable()
    else
      this.model.parentGroup.controls[this.model.formControlName!].enable()
  }

  emitIconClick(){
    if(!this.model.disabled)
      this.iconClicked.emit();
  }

  emitOnFocus(){
    if(!this.model.disabled)
      this.inputFocused.emit();
  }
  emitOnFocusOut(event: any){
    const input = event.target as HTMLInputElement;
    if(this.model.type===this.zipCode)
      this.onInputCpf(input.value);
    if(this.model.type===this.cpf_type)
      this.onInputCpf(input.value);
    // if(this.model.type===this.serial_number_type)
    //   this.onInputCpf(input.value);
    if(!this.model.disabled)
      this.inputFocusedOut.emit();
  }

  restrictNonNumeric(event: KeyboardEvent): void {
    const input = String.fromCharCode(event.which);
    if (!/^[0-9]*$/.test(input)) {
      event.preventDefault();
    }
  }

  emitOnKeyPress(event: any){
    if(this.model.onlyNumber){
      const input = String.fromCharCode(event.which);
      if (!/^[0-9]*$/.test(input)) {
        event.preventDefault();
      }
    }
    if(!this.model.disabled)
      this.keyPressed.emit(event);

  }
  formatInputSerialNumber(value: string): string {
  value = value.replace(/[IOQ]/g, '');

  value = value.replace(/[^a-zA-Z0-9]/g, '');
  if (value.length > 17) {
    value = value.slice(0, 17);
  }
  
    return value;
  }
  formatInputZipCode(value: string): string {
    value = value.replace(/\D/g, ''); 
    if (value.length > 5) {
      return `${value.slice(0, 5)}-${value.slice(5, 8)}`;
    }
    return value;
  }
  formatInputCpf(value: string): string {
    value = value.replace(/\D/g, ''); 
    if (value.length > 3) {
      value = `${value.slice(0, 3)}.${value.slice(3)}`;
    }
    if (value.length > 7) {
      value = `${value.slice(0, 7)}.${value.slice(7)}`;
    }
    if (value.length > 11) {
      value = `${value.slice(0, 11)}-${value.slice(11)}`;
    }
    return value;
  }

  emitOnKeyUp(){
    if(!this.model.disabled)
      this.keyIsUp.emit()
  }

  emitOnKeyDown(){
    if(!this.model.disabled)
      this.keyIsDown.emit();
  }

  emitOnBlur(){
      this.blurred.emit();
  }



  onInputZipCode(event: any): void {
    const input = event.target as HTMLInputElement;
    const formattedValue = this.formatInputZipCode(input.value);
    input.value = formattedValue;
  }
  onInputSerialNumber(event: any): void {
    const input = event.target as HTMLInputElement;
    const formattedValue = this.formatInputSerialNumber(input.value);
    input.value = formattedValue;
  }
  onInputCpf(event: any): void {
    if(event?.target){
      const input = event.target as HTMLInputElement;
      const formattedValue = this.formatInputCpf(input.value);
      input.value = formattedValue;
    }
    else{
      // ??? que hago acá???
    }
    
  }

}
