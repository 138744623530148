import { Component, Injector, Input } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ButtonStylesEnum, ButtonTypesEnum } from 'src/app/main/constants/buttons.enum';
import { ERRORS } from 'src/app/main/constants/errors.enum';
import { InputTypesEnum } from 'src/app/main/constants/inputs-types.enum';
import { LABELS } from 'src/app/main/constants/labels.enum';
import { IGenericResponseModel } from 'src/app/main/models/generic-response.model';
import { PersonModel } from 'src/app/main/models/person.model';
import { User } from 'src/app/main/models/user.model';
import { AuthenticatorService } from 'src/app/main/services/authenticator.service';
import { UserService } from 'src/app/main/services/user/user.service';
import { Masks } from 'src/app/shared/enums/masks.enum';
import { PopUpTypesEnum } from 'src/app/shared/enums/popup-types.enum';
import { InputDateTimeModel } from 'src/app/shared/input-datetime-custom/model/input-datetime-model';
import { ButtonModel } from 'src/app/shared/models/button.model';
import { ICommonInput } from 'src/app/shared/models/common-input.model';
import { DropdownCustomModel } from 'src/app/shared/models/dropdown.model';
import { InputModel } from 'src/app/shared/models/input.model';
import { LabelModel } from 'src/app/shared/models/label.model';
import { PopupMessageComponent } from 'src/app/shared/popup-message/popup-message.component';
import { ModelCreatorService } from 'src/app/shared/services/model-creator.service';
import { PopupOpenerService } from 'src/app/shared/services/popup-opener.service';
import { LoginState } from 'src/app/state/login/login.state';

@Component({
  selector: 'app-user-info',
  templateUrl: './user-info.component.html',
  styleUrls: ['./user-info.component.scss']
})
export class UserInfoComponent {
  
  @Input() showFullInfo  = true;

  title : string = LABELS.ACCOUNT_ADMINISTRATION;
  //subtitle : string = LABELS.MODIFY_YOUR_INFO;
  chk_lbl : string = LABELS.LEGAL_DATA_DISCLAIMER;
  chk_link : string = LABELS.SEE_PRIVACY_POLICY;

  nameInput? : InputModel;
  lastNameInput? : InputModel;
  maternaLastNameInput? : InputModel;
  documentInput? : InputModel;
  documentType? : DropdownCustomModel;
  personType? : DropdownCustomModel;
  gender? : DropdownCustomModel;
  civilStatus? : DropdownCustomModel;
  country? :DropdownCustomModel;
  
  birthDateInput? : InputDateTimeModel;
  userForm : FormGroup;
  saveButton : ButtonModel;
  deleteButton : ButtonModel;
  user? : PersonModel;
  onSave? : () => void;

  editButton:ButtonModel;
  enableButton = true;

  confirmButton?:ButtonModel;

  constructor(private modelCreator : ModelCreatorService,
    private fb : FormBuilder,
    private loginState : LoginState,
    private userSvc : UserService,
    private injector : Injector,
    private auth : AuthenticatorService,
    private popService : PopupOpenerService,
  ) {
    this.userForm = this.fb.group({
      Nombre          : ['',[Validators.required]],
      Apellido        : ['',[Validators.required]],
      ApellidoMaterno        : ['',[Validators.required]],
      FechaNacimiento : [''],
      TipoDocumento   : [''],
      NroDocumento    : ['',[Validators.required]],
      TipoPersona     : [''],
      Genero          : [''],
      EstadoCivil     : [''],
      Pais            : [''],
      
      disclaimer : ['', [Validators.required]],
    })

    this.createInputModels()

    this.saveButton = this.modelCreator.createButtonModel(LABELS.SAVE, ButtonTypesEnum.Common, ButtonStylesEnum.secondary_button, false);
    this.deleteButton = this.modelCreator.createButtonModel(LABELS.DELETE, ButtonTypesEnum.Raised, ButtonStylesEnum.black, false);
    this.editButton = this.modelCreator.createButtonModel(LABELS.EDIT, ButtonTypesEnum.Common, ButtonStylesEnum.base_button,false);
    
    this.onSave = popService.getData().onSave;
    this.showFullInfo = popService.getData().showFullInfo;
    this.loadSelects()
    this.patchValues();

    if(this.showFullInfo){

      this.userForm.controls['disclaimer'].setValue(true);

      this.disableField(this.showFullInfo);
    }

    this.enableOrDisableSave();
  }

  patchValues(){
    this.user = this.loginState.user;
    if(this.user)
      this.userForm.patchValue(this.user);

    if(this.user?.IdEstadoCivil){
      const option = this.civilStatus?.options?.find((e : any) => e.id === this.user?.IdEstadoCivil?.toString());
      if(option)
        this.userForm.controls['EstadoCivil'].setValue(option);
    }

    if(this.user?.IdGenero){
      const option = this.gender?.options?.find((e : any) => e.id === this.user?.IdGenero?.toString());
      if(option)
        this.userForm.controls['Genero'].setValue(option);
    }

    if(this.user?.NroDocumento){
      this.patchDocument();
    }
  }

  createInputModels(){
    this.nameInput = this.modelCreator.createInputModel(LABELS.NAME, InputTypesEnum.common, 'Nombre', this.userForm, undefined, undefined, true);
    this.lastNameInput = this.modelCreator.createInputModel(LABELS.LASTNAME, InputTypesEnum.common, 'Apellido', this.userForm, undefined, undefined, true);
    this.maternaLastNameInput = this.modelCreator.createInputModel(LABELS.MATERNAL_LASTNAME, InputTypesEnum.common, 'ApellidoMaterno', this.userForm, undefined, undefined, true);
    this.documentInput = this.modelCreator.createInputModel(LABELS.DOCUMENT_ID, InputTypesEnum.cpf, 'NroDocumento', this.userForm, undefined, undefined, true, 13, false,false,Masks.cpf);
    //this.documentType = this.modelCreator.createSelectModel('TipoDocumento', LABELS.DOCUMENT_TYPE, [], this.userForm, '', '', true);
    this.personType = this.modelCreator.createSelectModel('TipoPersona', LABELS.PERSON_TYPE, [], this.userForm, '', '', true);
    this.gender = this.modelCreator.createSelectModel('Genero', LABELS.GENDER, [], this.userForm);
    this.gender.outsideLabel = LABELS.GENDER;
    this.civilStatus = this.modelCreator.createSelectModel('EstadoCivil', LABELS.CIVIL_STATUS, [], this.userForm, '', '', true);
    this.civilStatus.outsideLabel = LABELS.CIVIL_STATUS;
    this.country = this.modelCreator.createSelectModel('Pais', LABELS.COUNTRY, [], this.userForm, '', '', true);

    this.birthDateInput = this.modelCreator.createCalendarModel('FechaNacimiento', this.userForm, true, LABELS.BIRTHDATE, false)
this.birthDateInput.label = LABELS.BIRTHDATE;
  }

  saveUser(){
    if(this.userForm.controls['disclaimer'].invalid || !this.userForm.value.disclaimer)
      return; // mensajito

    if(this.userForm.invalid || !(this.user!.Email && !Number.isNaN(this.user!.IdEmpresa)))
      return this.userForm.markAllAsTouched(); // Debería mostrar un mensajito de error
    
    const cpf = this.userForm.value.NroDocumento?.toString()?.replaceAll("-", "").replaceAll(".", "")

    const user : PersonModel = {
        Nombre : this.userForm.value.Nombre,
        Apellido : this.userForm.value.Apellido,
        ApellidoMaterno : this.userForm.value.ApellidoMaterno,
        FechaNacimiento : this.formatDate(this.userForm.value.FechaNacimiento),
        Email : this.user!.Email,
        IdEmpresa : this.user!.IdEmpresa,
        NroDocumento : cpf,
        TipoDocumento : this.userForm.value.TipoDocumento?.id,
        TipoPersona : this.userForm.value.TipoPersona?.id,
        IdGenero : this.userForm.value.Genero?.id,
        IdEstadoCivil : this.userForm.value.EstadoCivil?.id,
        Pais : this.userForm.value.Pais?.id,
        disclaimer:this.userForm.value.disclaimer
    }

    // Si no es cliente lo agrega, sino lo edita
    let obs = this.userSvc.addUser(user);
    if(this.loginState.user?.esCliente){
      obs = this.userSvc.updateUser(user);
    }

    obs.subscribe({
      next : (response) => {
        this.handleResponse(response, user);
        this.disableField(true);
      },
      error : (err) => {
        this.handleError(err);
      }
    })

  }

  formatDate(date : Date){
    if(!date)
      return;

    if(!date.getDate)
      return date.toString();    
    
    const day = date.getDate()
    const month = date.getMonth() + 1;
    const year = date.getFullYear();
    return (day < 10 ? '0' + day : day).toString() + '/' +  (month < 10 ? '0' + month : month) + '/' + year
  }

  handleResponse(response : IGenericResponseModel, user : PersonModel){
    if(!response.IsSuccess){
      this.showPopup(false, ERRORS.COULD_NOT_UPDATE_CLIENT, () => {this.popService.hide();})
      return;
    }
    
    this.loginState.getUserInfo();
    this.onSave?.call(this);    
  }

  handleError(error : any){
    this.showPopup(false, ERRORS.COULD_NOT_UPDATE_CLIENT, () => {this.popService.hide();})
  }

  loadSelects(){
    this.gender!.options = this.mapToOptions(this.loginState.user?.Generos ?? [])
    this.civilStatus!.options = this.mapToOptions(this.loginState.user?.EstadosCiviles ?? [])
  }

  mapToOptions(arr : any[]){
    return arr.map(e => {
      if(e.Id){
        return {id : e.Id, descripcion : e.Descripcion, tipo: e.Tipo}
      }
      return e;
    })
  }

  enableOrDisableSave(){
    if(!this.userForm.value?.disclaimer){
      this.saveButton.disabled = true;  
      this.saveButton.classes = ButtonStylesEnum.grey
    }
    else{
      this.saveButton.disabled = false;
      this.saveButton.classes = ButtonStylesEnum.secondary_button;
    }  
  }

  patchDocument(){
    const doc = this.user?.NroDocumento?.toString();
    // if(!doc || doc.length !== 11)
    //   return;

    // let i = 0;
    // let pattern = "###.###.###-##"
    // doc = pattern.replace(/#/g, _ => doc![i++]);
    this.userForm.controls['NroDocumento'].setValue(doc);
  }

  disableField(isDisabled :boolean) {
      this.nameInput!.disabled = isDisabled;
      this.lastNameInput!.disabled = isDisabled;
      this.documentInput!.disabled = isDisabled;
      this.personType!.disabled = isDisabled;
      this.gender!.disabled = isDisabled;
      this.country!.disabled = isDisabled;
      this.birthDateInput!.disabled = isDisabled;
      this.civilStatus!.disabled = isDisabled;
      this.enableButton = false;
  }

  disableButton(isDisabled :boolean) {
      this.nameInput!.disabled = isDisabled;
      this.lastNameInput!.disabled = isDisabled;
      this.documentInput!.disabled = isDisabled;
      this.personType!.disabled = isDisabled;
      this.gender!.disabled = isDisabled;
      this.country!.disabled = isDisabled;
      this.birthDateInput!.disabled = isDisabled;
      this.civilStatus!.disabled = isDisabled;
      this.enableButton = true;
    
  }

  deleteClient(){
    this.confirmButton = this.modelCreator.createButtonModel(LABELS.CONFIRM, ButtonTypesEnum.Common, ButtonStylesEnum.base_button);


    const component ={
      component:PopupMessageComponent,
      config : {
        closable : true,          
      },
      data : {
        type : PopUpTypesEnum.warning,
        title : '',    
        button : true,
        buttonModel : this.confirmButton,
        messages : [LABELS.ARE_YOU_SURE_DELETE],
        onAccept : () => {this.delete()}
      }      
    };
    this.popService.show(component);
  }

  delete(){
    // Esta función deletearía el cliente
      this.userSvc.deleteUser().subscribe(data => {
        if(!data.IsSuccess){
          this.showPopup(false, ERRORS.COULD_NOT_DELETE_CLIENT, () => {this.popService.hide()}, true);
          return;
        }
        this.showPopup(true, LABELS.DATA_DELETED, this.onDeleteAccept);
      })
  }

  showPopup(isSuccess : boolean, message : string, onAccept : () => any, closable = false){
    
    
    const component ={
      component:PopupMessageComponent,
      config : {
        closable : closable,
      },
      data : {
        type : isSuccess ? PopUpTypesEnum.success : PopUpTypesEnum.warning,
        title : '',    
        button : isSuccess,
        buttonModel : isSuccess ? this.confirmButton :undefined,
        messages : [message],
        onAccept : () => {onAccept.call(this)}
      }      
    };
    this.popService.hide();
    setTimeout(() => {this.popService.show(component)}, 1000);
  }

  onDeleteAccept(){    
    this.auth.logout(true);
    this.popService.hide()
    this.popService.closeAll();
  }

}
