import { Component, OnDestroy } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { Subscription } from 'rxjs';
import { ButtonStylesEnum, ButtonTypesEnum } from 'src/app/main/constants/buttons.enum';
import { ERRORS } from 'src/app/main/constants/errors.enum';
import { InputTypesEnum } from 'src/app/main/constants/inputs-types.enum';
import { LABELS } from 'src/app/main/constants/labels.enum';
import { EmissionValidStepsEnum } from 'src/app/main/models/emission/emission-valid-steps.enum';
import { ConsultantService } from 'src/app/main/services/consultant.service';
import { EmissionFormHandlerService } from 'src/app/main/services/issue/emission-form-handler.service';
import { InputDateTimeModel } from 'src/app/shared/input-datetime-custom/model/input-datetime-model';
import { ButtonModel } from 'src/app/shared/models/button.model';
import { DropdownCustomModel } from 'src/app/shared/models/dropdown.model';
import { InputModel } from 'src/app/shared/models/input.model';
import { ModelCreatorService } from 'src/app/shared/services/model-creator.service';
import { IssueState } from 'src/app/state/issue/issue.state';
import { States } from 'src/app/state/models/states.enum';

@Component({
  selector: 'emission-vehicle-info',
  templateUrl: './emission-vehicle-info.component.html',
  styleUrls: ['./emission-vehicle-info.component.scss']
})
export class EmissionVehicleInfoComponent implements OnDestroy {
  continueBtn : ButtonModel|undefined;
  btnReturn : ButtonModel|undefined;
  placa?              : InputModel ;
  motor?               : InputModel ;  
  numeroSerie?               : InputModel ;
  color?                  : DropdownCustomModel;
  condicion?                  : DropdownCustomModel;
  precio?              : InputModel ;
  vigencyModel? : InputDateTimeModel;
  lastStep=false
  steps: number[] = [0, 1, 2];
  currentStep = 0;

  chk_lbl = LABELS.FIDUCIARY_ALINEATION

  form? : FormGroup;

  issue$ : Subscription = new Subscription();

  

  readonly title = LABELS.PUT_VEHICLE_DATA;
  readonly condition=LABELS.CONDITION
  constructor(private modelCreator : ModelCreatorService,
    private formHandler : EmissionFormHandlerService,
    private issue_state : IssueState,
    private consultantProposal: ConsultantService
  ) {
    this.form = this.formHandler.getFormControl(EmissionValidStepsEnum.vehicle) as FormGroup;
    this.createInputs();
    // this.checkState();
  } 

  createInputs(){
    this.btnReturn = this.modelCreator.createButtonModel(LABELS.RETURN_ISSUE, ButtonTypesEnum.Raised, ButtonStylesEnum.without_white_outline);
    this.continueBtn = this.modelCreator.createButtonModel(LABELS.CONTINUE, ButtonTypesEnum.Common, ButtonStylesEnum.black);
    this.vigencyModel = this.modelCreator.createCalendarModel('fechaInicioVigencia', this.form!, true,LABELS.VIGENCY, true, LABELS.STARTING_DATE_VIGENCY);
    this.vigencyModel.minDate = new Date();
    this.vigencyModel.maxDate = new Date(2648999000000000); // 10 de diciembre de 2053... Si llega vivo hasta esa fecha paguense el champagne

    let vigencyParts : any[] = [];
    const prospectVigency = this.issue_state.fullState.prospectusQuoteState.vigency;
    if(prospectVigency && prospectVigency !== ''){
      vigencyParts = prospectVigency!.split("-");
    }

    let vigencyDate: Date | null = null;

    if (vigencyParts.length === 3) {
        const day = +vigencyParts[2];
        const month = +vigencyParts[1] - 1;
        const year = +vigencyParts[0];

        if (!isNaN(day) && !isNaN(month) && !isNaN(year)) {
            vigencyDate = new Date(year, month, day);
        }
    }

    // Si la poliza vence en el futuro, le ponemos esa fecha de inicio vig
    // Si ya venció, le ponemos la fecha de hoy
    let newStartVigencyDate = new Date();
    if(vigencyDate && vigencyDate > newStartVigencyDate){
      newStartVigencyDate = vigencyDate
    }  

    //this.form!.get('fechaInicioVigencia')?.setValue(newStartVigencyDate);

    this.condicion              = this.modelCreator.createSelectModel('condicion',LABELS.SELECT, [{'id': 2, 'descripcion': 'Usado'}],this.form!, LABELS.USE, '', true);
    this.condicion.outsideLabel = this.condition
    this.placa         = this.modelCreator.createInputModel(LABELS.PATENT, InputTypesEnum.common, 'placa', this.form!, ERRORS.PATENT_INVALID, undefined, false, 10, false, false);
    this.motor         = this.modelCreator.createInputModel(LABELS.ENGINE, InputTypesEnum.common, 'motor', this.form!, ERRORS.ENGINE_NUMBER, undefined, true, 20, false, false);
    this.numeroSerie   = this.modelCreator.createInputModel(LABELS.SERIAL_NUMBER, InputTypesEnum.serialNumber, 'numeroSerie', this.form!, ERRORS.SERIAL_NUMBER, undefined, true, 17, false, false);
    //this.precio         = this.modelCreator.createInputModel(LABELS.VEHICLE_PRICE, InputTypesEnum.common, 'precio', this.form!, '', undefined, false, 10, false, false);
    this.validateStep0();    
  }

  ngOnDestroy(): void {
    this.issue$.unsubscribe();
  }
  next(){
    switch (this.currentStep) {
      case 0:
        this.validateStep0();
        break;
      case 1:
        this.validateStep1();
        break;
      default:
        break;
    }
  }
  return(){
    if(this.currentStep==0)
      this.consultantProposal.notifyPreviousStep()
    else
      this.currentStep--
  }

  
  validateStep1(){
    const condicion = this.form!.get('condicion')?.value;
    //const precio = this.form!.get('precio')?.value;
    // if(precio=="")
    //   this.form!.get('precio')?.markAllAsTouched();
    if(condicion=="")
      this.form!.get('condicion')?.markAllAsTouched();

    this.formHandler.setVigency(this.form?.getRawValue().fechaInicioVigencia);

    return condicion!="" && !this.form!.get('repuve')?.invalid ? this.consultantProposal.notifyNextStep() : false;
  }

  validateStep0(){
    // const placa = this.form!.get('placa')?.value;
    const motor = this.form!.get('motor')?.value;
    const numeroSerie = this.form!.get('numeroSerie')?.value;
    // if(placa=="")
    //   this.form!.get('placa')?.markAllAsTouched();
    // if(motor=="")
      this.form!.get('motor')?.markAllAsTouched();
    if(numeroSerie=="" || this.form!.get('numeroSerie')?.invalid)
      this.form!.get('numeroSerie')?.markAllAsTouched();
    //placa!="" && motor!="" && 
    return numeroSerie!="" && motor!="" && !this.form!.get('numeroSerie')?.invalid ? this.currentStep++ : false;
  }
}
