import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { authenticationGuard } from 'src/app/main/guards/authentication.guard';
import { EmissionPersonalInfoComponent } from 'src/app/features/issue/emission-personal-info/emission-personal-info.component';
import { IssueInsuranceComponent } from './issue-insurance.component';
import { PriceInsuranceComponent } from '../price-insurance/price-insurance.component';
import { IssueOwnerDataComponent } from 'src/app/features/issue/issue-owner-data/issue-owner-data.component';
import { PlanComparerComponent } from 'src/app/features/issue/plan-comparer/plan-comparer.component';
import { IssuePetComponent } from 'src/app/features/issue/issue-pet/issue-pet.component';
import { PricePetComponent } from 'src/app/features/issue/price-pet/price-pet.component';
import { IssueDentalComponent } from 'src/app/features/issue/issue-dental/issue-dental.component';
import { PriceDentalComponent } from 'src/app/features/issue/price-dental/price-dental.component';
import { IssueResidentialComponent } from '../issue-residential/issue-residential.component';



const routes: Routes = [
  {
    path: '',
    component: IssueInsuranceComponent,
    canActivateChild: [authenticationGuard],
    children: [
      {
        path: 'plan',
        component: PriceInsuranceComponent,
        canLoad: []
      },
      {
        path: 'comparacion',
        component: PlanComparerComponent,
        canLoad: []
      },
      {
        path: 'pet',
        component: IssuePetComponent,
        canLoad: []
      },
      {
        path: 'pet_pricing',
        component: PricePetComponent,
        canLoad: []
      },
      {
        path: 'dental_pricing',
        component: PriceDentalComponent,
        canLoad: []
      },
      {
        path: 'dental',
        component: IssueDentalComponent,
        canLoad: []
      },
      {
        path: 'quoting-residential',
        component: IssueResidentialComponent,
        canLoad: []
      },
    ]
  }
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class IssueInsuranceRoutingModule { }
