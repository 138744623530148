import { Component } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { Subscription } from 'rxjs';
import { LABELS } from 'src/app/main/constants/labels.enum';
import { EmissionValidStepsEnum } from 'src/app/main/models/emission/emission-valid-steps.enum';
import { EmissionFormHandlerService } from 'src/app/main/services/issue/emission-form-handler.service';
import { LocatorService } from 'src/app/main/services/issue/locator.service';
import { CustomSelectModel } from 'src/app/shared/models/custom-select.model';
import { NewHomeComponent } from 'src/app/shared/new-home/new-home.component';
import { ModelCreatorService } from 'src/app/shared/services/model-creator.service';
import { PopupOpenerService } from 'src/app/shared/services/popup-opener.service';
import { IssueState } from 'src/app/state/issue/issue.state';
import { LoginState } from 'src/app/state/login/login.state';
import { States } from 'src/app/state/models/states.enum';

@Component({
  selector: 'app-emission-risk-location',
  templateUrl: './emission-risk-location.component.html',
  styleUrls: ['./emission-risk-location.component.scss']
})
export class EmissionRiskLocationComponent {


  title : string = LABELS.RISK_LOCATION;

  domicileModel : CustomSelectModel
  form : FormGroup;

  newDomicileForm? : FormGroup;

  issue$ : Subscription = new Subscription();
  popupData : any; 
  regions? : any[];
  domiciles? : any[];
  
  constructor(private formHandler : EmissionFormHandlerService,
    private modelCreator : ModelCreatorService,
    private issue_state : IssueState,
    private popupOpener : PopupOpenerService,
    private login_state : LoginState,
    private locatorSvc : LocatorService
    
  ) {
    this.form = formHandler.getFormControl(EmissionValidStepsEnum.risk_location) as FormGroup;
    this.domicileModel = modelCreator.createSelectModel('domicilio', LABELS.SELECT, [], this.form, undefined, LABELS.DOMICILE, true);

    this.newDomicileForm = this.formHandler.getNewDomicileForm();

    this.checkState();
  }

  checkState(){
    this.issue$ = 
      this.issue_state.checkState().subscribe({
        next : ({quotingState, emissionState}) => {
          if(quotingState.state !== States.success){
            return;
          }
          if(!this.regions){
            this.getRegions();            
            this.domiciles = [...this.login_state.user?.Domicilios ?? []]
            this.domicileModel.options = this.login_state.user?.Domicilios?.map((d : any) => {
              return {id : d.Id , descripcion : d.Descripcion ?? `${d.Calle} ${d.Numero} - ${d.Ciudad ?? ''}`}
            }) ?? [];
            this.domicileModel.options?.push({id : '-1', descripcion : LABELS.ADD_DOMICILE});
          }
        }
      });
  }


  getRegions(){
    this.locatorSvc.getRegions().subscribe({
      next : (data : any) =>{ 
        if(data.Result){
          this.regions = data.Result.map((e : any) => {
            return {id : e.Id, descripcion : e.Descripcion}
          })
        }
      }
    })
  }

}
