import { Component, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { AuthenticatorService } from 'src/app/main/services/authenticator.service';
import { SpinnerService } from 'src/app/main/services/spinner.service';

@Component({
  selector: 'app-redirect',
  templateUrl: './redirect.component.html',
  styleUrls: ['./redirect.component.scss']
})
export class RedirectComponent implements OnInit, OnDestroy {
  
  private token : string | undefined;

  constructor(private spinner : SpinnerService,
    private auth : AuthenticatorService,
    private route : ActivatedRoute,
    private router : Router) {      

      this.route.paramMap.forEach(p => {
        const param = p.get('token');
        if(param)
          this.token = param;
      });
  

  }

  ngOnInit(): void {
    this.spinner.showOrHideSpinner(true);
    this.auth.setToken(this.token ?? "");
    this.router.navigate(['dashboard']);
  }

  ngOnDestroy(): void {
    this.spinner.showOrHideSpinner(false);
  } 
}
