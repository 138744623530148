import { Component, OnDestroy, OnInit } from '@angular/core';
import { HeaderService } from '../services/header.service';
import { ActivatedRoute, NavigationStart, Router } from '@angular/router';
import { Subscription } from 'rxjs';
import { ButtonModel } from 'src/app/shared/models/button.model';
import { ButtonTypesEnum } from 'src/app/main/constants/buttons.enum';
import { LABELS } from '../constants/labels.enum';

@Component({
  selector: 'custom-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss']
})
export class HeaderComponent implements OnInit, OnDestroy{

  private readonly routes : string[] = ["/about", "/faq", "/contact"];
  private visibilitySub : Subscription = new Subscription();
  showHeader  = false;  
  activeRoute  = "home";  
  btnLogin : ButtonModel = new ButtonModel();

  constructor(private headerSvc : HeaderService, 
    private router : Router) {
    
    router.events.forEach(element => {
      if(element instanceof(NavigationStart)){
        this.setActiveLink(element.url);
      }
    });          

    this.btnLogin = {
      text : LABELS.LOGIN,
      color : 'warn',
      type: ButtonTypesEnum.Raised
    }

  }

  ngOnInit(): void {  
    this.visibilitySub = this.headerSvc.getVisibilityObs().subscribe(data => {
      this.showHeader = data;
    })
  }

  ngOnDestroy(): void {
    this.visibilitySub.unsubscribe();
  }
  
  setActiveLink(route : string){
    this.activeRoute = this.routes.find(r => r === route) ?? "/home";    
  }

  navigateTo(route : string){
    this.router.navigateByUrl(route);
  }

}
