<div class="w-100 my-4 footer d-flex justify-content-center flex-wrap pb-3">
    <div class="d-flex flex-column flex-md-row col-12 col-md-10">
        <div class="col-12 col-md-4 align-content-center py-2">
            <img alt="AON White Logo" src="/assets/aon-logo-white.svg" class="aon-logo m-auto">
        </div>
        <div class="col-12 col-md-8 align-content-center py-2 fs-smaller ms-2">
            <p class="mb-0 footer-font">{{disclaimer}}</p>
        </div>
    </div>

    <div class="col-12 col-md-10 d-flex justify-content-center top-border">
        <a class="text-secondary px-2 mt-2" target="_blank"
            href="https://www.aon.com/mexico/about-aon/politica-de-privacidad.jsp">{{link1}}</a>
        <!-- <a class="text-secondary px-2" target="_blank" href="https://seguroseassistencias.com.br/politica-cookie">{{link2}}</a> -->
    </div>
</div>