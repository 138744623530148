<div class="card" >
    <div class="card-header" *ngIf="proposalBlockDetails && model">   
      <div style="margin-left:70px;">
        <div class="row" >
          <div class="col-sm-6">
            <p class="fs-4"style="text-align: left;" ><strong>{{proposal_detail}}</strong></p>
            <!-- <p class="fs-5"style="text-align: left;" ><strong>{{model.TipoPoliza}}</strong></p> -->
            <custom-label [model]="quota" *ngIf="quota"></custom-label>
          </div>
        </div>  

        <div class="row" style="margin-top: 40px;" *ngIf="proposalBlockDetails">
          <div class="col-sm-6" *ngFor="let block of proposalBlockDetails">
            <p class="fs-5" style="text-align: left;"><strong>{{ block.title }}</strong></p>
            <div *ngFor="let label of block.data" class="mb-2">
              <custom-label [model]="label"></custom-label>
            </div>
          </div>
        </div>

        <div class="row" style="margin-top: 40px;" *ngIf="proposalBlockDetails">
          <div class="col-12">
            <div class="row basic-font">
              <div class="col-6 d-flex align-items-center">
                <strong class="pointer text-start me-3" (keypress)="processCoverages()" (click)="processCoverages()" tabindex="0">
                  {{ view_coverage }}
                </strong>
                <strong *ngIf="model.EstadoId !== 9" class="pointer text-start" (keypress)="showCancel()" (click)="showCancel()" tabindex="0">
                  {{ cancel_button }}
                </strong>
              </div>
        
              <div class="col-6 d-flex justify-content-end">
                <div class="mt-4 me-3">
                  <custom-button [model]="btnOptions" class="btn-custom-width" (customClick)="goQuote()"></custom-button>
                </div>
                <div class="mt-4">
                  <custom-button [model]="btnRenewal" class="btn-custom-width" (customClick)="goRenewal()"></custom-button>
                </div>
              </div>
            </div>
          </div>
        </div>

        <custom-popup *ngIf="showCoverages"
          [show]="true" 
          [hiddenHeader]="true"
          [hiddenButtons]="true"
          [maxSize]="true"
          [title]="titleCoverages" 
          [componentData]="componentData"
          (dismissed)="onCancel()">
        </custom-popup>
     

      </div>
  </div>
</div>