import { Component, Input } from '@angular/core';
import { PlanDescriptionModel } from 'src/app/main/models/emission/plan-description.model';

@Component({
  selector: 'app-issue-plan-description',
  templateUrl: './issue-plan-description.component.html',
  styleUrls: ['./issue-plan-description.component.scss']
})
export class EmissionPlanDescriptionComponent {

  @Input() model? : PlanDescriptionModel;

}
