<div class="col-12">
    <div class="col-12 pl-0">
        <span class="text-lg primary-color-text fw-bold">INGRESA LOS DATOS DE TU MASCOTA</span>
      </div>


    <div *ngFor="let model of inputModels" class="pt-2 pl-0">
        <div *ngIf="model.control === 'TXT' " class="col-12 pl-0">
            <custom-input [model]="model"></custom-input>
        </div>
        <div *ngIf="model.control === 'CMB'" class="flex flex-column justify-content-start">
            <p class="text-left mb-1 pb-0">{{model.descripcion}}</p>
            <custom-select [model]="model"></custom-select>
        </div>


    </div>



</div>
