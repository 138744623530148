<full-cover-card [model]="coverCardModel" [showBackButton]="false">    
    <div class="back-button">
        <custom-button [model]="btnBack" (customClick)="navigateBack()" class="d-md-flex"/>
    </div>
    <div class="col-12 d-flex flex-column align-items-center justify-content-center text-center" [ngClass]="{'h-100':showMessage}">
      <div  class="container col-12 d-flex flex-column align-items-center justify-content-center text-center" *ngIf="!showMessage">
        <div>
            <img alt="email-icon" src="../../../assets/icons/email-unread.svg"/>
        </div>

        <div class="col-12 col-md-6"> <p class="fs-5">{{code_sended}}</p></div>
        <div class="col-12  col-md-6"> <p class="fs-6">{{spam_text}}</p></div>
        <div class="col-12  col-md-6"> <p class="fs-6">{{enter_code}}</p></div>

        <div class="col-12 col-md-6 py-2">
            <divided-input [model]="dividedInputModel"/>
        </div>  
        
        <div class="col-6 py-1">
            <custom-button (customClick)="next()" [model]="btnNext"/>
        </div>

        <div class="col-12 py-2">
            <p class="text-center" style="cursor:pointer" tabindex="0" (keypress)="resendCode()" (click)="resendCode()">{{resend_code}}</p>
            <div *ngIf="showTimer && !canSendCode">
                {{minutesLeft | number:'2.0-0' }} : {{ secondsLeft | number:'2.0-0'}}
            </div>
        </div>

        <div class="text-center m-auto" *ngIf="showMessage">
            <img alt="success_message" [src]="message === success_message ? '../../../assets/icons/done.svg' : '../../../assets/icons/error.svg' ">
            <p class="fs-3">{{message}}</p>
        </div>
    </div>
</div>     
</full-cover-card>


