import { Injectable } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { EmissionValidStepsEnum } from '../../models/emission/emission-valid-steps.enum';
import { RenewalValidStepsEnum } from '../../models/emission/renewal-valid-steps.enum';

@Injectable({
  providedIn: 'root'
})
export class RenewalFormHandlerService {

  private form : FormGroup;
  private readonly valid_steps = RenewalValidStepsEnum;

  constructor(private fb : FormBuilder) {
    this.form = fb.group({      
    });
    this.cleanForm();
  }

  getForm(){
    return this.form;
  }

  getFormControl(step : RenewalValidStepsEnum){
    return this.form.controls[step];
  }

  createForm(hasVehicle? : boolean, hasBenef? : boolean, hasHome? : boolean, hasCoverages? : boolean,hasDental?:boolean){
    if(hasVehicle)
      this.form.addControl(this.valid_steps.periodicity, this.createPeriodicityForm());

  }



  createPeriodicityForm(){
    return this.fb.group({
      periodicidad : ['',[Validators.required]],
      instrumentoPago : ['',]
    })
  }



  cleanForm(){
    this.form = this.fb.group({
      vigencia : ['']
    });
  }
  
}
