import { Component } from '@angular/core';
import { Subscription } from 'rxjs';
import { UserInfoComponent } from 'src/app/features/user/user-info/user-info.component';
import { ICONS } from 'src/app/main/constants/icons.enum';
import { LABELS } from 'src/app/main/constants/labels.enum';
import { IMenuModel } from 'src/app/main/models/menu.model';
import { ISelectItem } from 'src/app/main/models/select-item.model';
import { AuthenticatorService } from 'src/app/main/services/authenticator.service';
import { RedirectorService } from 'src/app/main/services/redirector.service';
import { UserService } from 'src/app/main/services/user/user.service';
import { PopUpTypesEnum } from 'src/app/shared/enums/popup-types.enum';
import { PopupMessageComponent } from 'src/app/shared/popup-message/popup-message.component';
import { PopupOpenerService } from 'src/app/shared/services/popup-opener.service';
import { AppState } from 'src/app/state/app/app.state';
import { IssueState } from 'src/app/state/issue/issue.state';
import { LoginState } from 'src/app/state/login/login.state';
import { States } from 'src/app/state/models/states.enum';

@Component({
  selector: 'app-user',
  templateUrl: './user.component.html',
  styleUrls: ['./user.component.scss']
})
export class UserComponent {

  my_account = LABELS.MY_ACCOUNT;
  quotingState1$ : Subscription = new Subscription();
  menus: IMenuModel[] = [
    {id : '1', descripcion: LABELS.PERSONAL_DATA_TITLE , icon:ICONS.USER, click : () => {this.showUserInfoPopUp()}},
    {id : '1', descripcion: LABELS.MY_PROPOSALS, icon:ICONS.PRODUCTS, click : () => {this.redirectToProposals()}},
    {id : '1', descripcion: LABELS.MY_QUOTES, icon:ICONS.PRODUCTS, click : () => {this.redirectToQuotes()}},
    //{id : '1', descripcion: 'Notificaciones'   , icon:ICONS.NOTIFICATIONS, click : () => {this.showNotAvailablePopUp()}},
    {id : '1', descripcion: LABELS.ADDRESSES      , icon:ICONS.DOMICILE, click : () => {this.showNotAvailablePopUp()}},
    {id : '1', descripcion: LABELS.LOGIN_AND_SECURITY, icon:ICONS.SECURITY, click : () => {this.showNotAvailablePopUp()}},
    {id : '1', descripcion: LABELS.LOGOUT, icon:ICONS.LOGOUT_SVG, click : () => {this.logout()}}
  ]

  popupData : any;
  showPopup  = false;  
  
  constructor(private loginState : LoginState,
    private userSvc : UserService,
    private issueState: IssueState,
    private auth : AuthenticatorService,
    private redirectorSvc:  RedirectorService ,
    private popupSvc : PopupOpenerService
  ) {
  }

  logout(){
    this.auth.logout();
  }

  handleClick(menu : IMenuModel){
    menu.click.call(this);
  }

  showNotAvailablePopUp(){
    this.showMessagePopUp('', [LABELS.ATTENTION, LABELS.FUNCTION_NOT_AVAILABLE,LABELS.WORKING_FOR_YOUR_EXPERIENCE], PopUpTypesEnum.warning )
  }

  redirectToProposals(){
    this.redirectorSvc.redirectToProposalList();
  }
  redirectToQuotes(){
    this.subscribeToQuoteState();
    this.redirectorSvc.redirectToQuotes();
  }

  subscribeToQuoteState(){
    this.quotingState1$ = this.issueState.checkState().subscribe({
      next : ({quoteState}) => {
        if(quoteState.state === States.loading)
          return;
        
        if(quoteState.state === States.success){
          if(quoteState.quoteInfo?.length==0){
            // if(quoteState.quoteInfo[0].TprId)
            //   this.redirectorService.redirectToProposalVehicle();
          }

        }
        
      }
    })      
  }

  showMessagePopUp(title : string, messages : string[], type : PopUpTypesEnum = PopUpTypesEnum.success){
    this.popupData={
      component:PopupMessageComponent,
      config : {
        title : title,
        hideHeader : true
      },
      
      data : {
        type : type,
        messages : messages
      }
    };
    this.popupSvc.show(this.popupData)
  }


  showUserInfoPopUp(){
      this.popupData={
        component:UserInfoComponent,
        config : { closable : true, width: '80%',},
        data : {
          showFullInfo : true,
          onSave : () => {
            this.onCancel()
            this.showMessagePopUp(LABELS.SAVE_SUCCESS, [LABELS.CLIENT_UPDATE_SUCCESS])
          }
        }
        
      };

      this.popupSvc.show(this.popupData)
  }

  onCancel(){
    this.showPopup = false;
  }
}
