import { Component } from '@angular/core';
import { LABELS } from 'src/app/main/constants/labels.enum';
import { CardData } from 'src/app/main/models/card-data.model';
import { IBeneficiary } from 'src/app/main/models/emission/beneficiary.model';
import { EmissionValidStepsEnum } from 'src/app/main/models/emission/emission-valid-steps.enum';
import { IPersonalData } from 'src/app/main/models/emission/personal-data.model';
import { IVehicle } from 'src/app/main/models/vehicle.model';
import { EmissionFormHandlerService } from 'src/app/main/services/issue/emission-form-handler.service';
import { LabelModel } from 'src/app/shared/models/label.model';
import { AppState } from 'src/app/state/app/app.state';
import { ProposalCoveragesComponent } from '../../dashboard/proposal-coverages/proposal-coverages.component';
import { ICoverage } from 'src/app/main/models/coverage.model';
import { IDomicile } from 'src/app/main/models/domicile.model';
import { FreeDataModel } from 'src/app/main/models/emission/free-data.model';
import { FormArray, FormGroup } from '@angular/forms';
import { IssueState } from 'src/app/state/issue/issue.state';
import { States } from 'src/app/state/models/states.enum';
import { ButtonModel } from 'src/app/shared/models/button.model';
import { ConsultantService } from 'src/app/main/services/consultant.service';
import { ButtonStylesEnum, ButtonTypesEnum } from 'src/app/main/constants/buttons.enum';
import { ModelCreatorService } from 'src/app/shared/services/model-creator.service';

@Component({
  selector: 'app-emission-summary',
  templateUrl: './emission-summary.component.html',
  styleUrls: ['./emission-summary.component.scss']
})
export class EmissionSummaryComponent {

  personalData?: any;
  vehicle?: any;
  beneficiaries?: IBeneficiary;
  riskLocation?: any;
  coverages?: ICoverage[];
  freeData?: FreeDataModel[];

  summary: CardData[] = [];

  title: string = LABELS.EMIT_SUMMARY;

  showButton=true;
  continueBtn : ButtonModel|undefined;
  btnReturn : ButtonModel|undefined;

  constructor(private formHandler: EmissionFormHandlerService,
    private issueState: IssueState,
    private consultantProposal: ConsultantService,
    private modelCreator : ModelCreatorService,
  ) {
    this.createSummary();
  }

  createSummary() {
    this.btnReturn = this.modelCreator.createButtonModel(LABELS.RETURN_ISSUE, ButtonTypesEnum.Raised, ButtonStylesEnum.without_white_outline);
    this.continueBtn = this.modelCreator.createButtonModel(LABELS.EMIT_INSURANCE, ButtonTypesEnum.Common, ButtonStylesEnum.black);

    this.createPersonalDataBlock();
    this.createVehicleBlock();
    //this.createBenficiariesBlock();
    this.createCoveragesBlock();
    this.createRiskLocationBlock();
    this.createFreeDataBlock();
  }

  createPersonalDataBlock() {
    const form = this.formHandler.getForm();
    this.personalData = this.formHandler.getFormControl(EmissionValidStepsEnum.personal_data)?.value;
    if (!this.personalData)
      return;

    const apellidoPaterno=this.personalData.apellidoPaterno ?? ""
    const apellidoMaterno=this.personalData.apellidoMaterno ?? ""
    const blocks: CardData[] = [
      {
        etiqueta: 'RFC',
        valor: this.personalData.rfc
      },
      {
        etiqueta: 'Nombres',
        valor: this.personalData.nombres+" "+apellidoPaterno+" "+apellidoMaterno
      },
      {
        etiqueta: LABELS.PHONE,
        valor: this.personalData.telefonoArea + "-" + this.personalData.telefono
      },
      {
        etiqueta: LABELS.RISK_LOCATION,
        valor: `${this.personalData.calle} ${this.personalData.numeroExterno} ${this.personalData.numeroInterno ? '- ' + this.personalData.numeroInterno : ''} - ${form?.controls[EmissionValidStepsEnum.personal_data].get('municipio')?.value?.descripcion ?? ''}, ${form?.controls[EmissionValidStepsEnum.personal_data].get('estado')?.value?.descripcion ?? ''}`
      }
      
      // {
      //   etiqueta : LABELS.CIVIL_STATUS,
      //   valor : this.personalData.estadoCivil.descripcion
      // },
      // {
      //   etiqueta : LABELS.GENDER,
      //   valor : this.personalData.genero?.descripcion
      // }
    ]

    this.summary.push(...blocks)
  }

  createVehicleBlock() {
    this.vehicle = this.formHandler.getFormControl(EmissionValidStepsEnum.vehicle)?.getRawValue();
    if (!this.vehicle)
      return;
    
    const blocks: CardData[] = [
      // {
      //   etiqueta: LABELS.QUESTION_ISNEW,
      //   valor: this.vehicle.ceroKm ? LABELS.YES : LABELS.NO,
      // },
      // {
      //   etiqueta: LABELS.PLATE,
      //   valor: this.vehicle.patente ?? '-'
      // },
      // {
      //   etiqueta: LABELS.CHASIS,
      //   valor: this.vehicle.chasis ?? '-'
      // },
      {
        etiqueta: 'Motor',
        valor: this.vehicle.motor ?? '-'
      },
      {
        etiqueta: 'Placa',
        valor: this.vehicle.placa ?? '-'
      },
      {
        etiqueta: 'Número de serie',
        valor: this.vehicle.numeroSerie ?? '-'
      }
    ]

    this.summary.push(...blocks);
  }

  createBenficiariesBlock() {
    this.beneficiaries = this.formHandler.getFormControl(EmissionValidStepsEnum.beneficiaries)?.value;
    if (!this.beneficiaries)
      return;

    const blocks: CardData[] = [{
      etiqueta: LABELS.FULL_NAME,
      valor: `${this.beneficiaries.nombre} ${this.beneficiaries.apellido}`
    },
    {
      etiqueta: LABELS.DOCUMENT_ID,
      valor: `${this.beneficiaries.nroDocumento} - ${this.beneficiaries.tipoDocumento}`
    },
    {
      etiqueta: LABELS.BIRTHDATE,
      valor: this.beneficiaries.fechaNacimiento
    },
    {
      etiqueta: LABELS.DOMICILE,
      valor: this.beneficiaries.domicilio?.descripcion ?? '-'
    },
    {
      etiqueta: LABELS.PHONE,
      valor: this.beneficiaries.telefono.toString() ?? '-',
    },
    {
      etiqueta: LABELS.EMAIL,
      valor: this.beneficiaries.email
    },
    {
      etiqueta: LABELS.RELATIONSHIP,
      valor: this.beneficiaries.parentesco
    },
    {
      etiqueta: LABELS.PORCENTAGE,
      valor: `${this.beneficiaries.porcentaje}%`
    }
    ];

    this.summary.push(...blocks)

  }

  createCoveragesBlock() {
    this.coverages = this.formHandler.getFormControl(EmissionValidStepsEnum.coverages)?.value;
    if (!this.coverages || this.coverages.length < 1)
      return;

    const blocks: CardData[] = []
    this.coverages.forEach(c => {
      blocks.push({
        etiqueta: c.descripcion,
        valor: c.montoFormateado
      })
    })


    this.summary.push(...blocks)
  }

  createRiskLocationBlock() {
    this.riskLocation = this.formHandler.getFormControl(EmissionValidStepsEnum.risk_location)?.value;
    if (!this.riskLocation || this.riskLocation?.codigoPostal=="")
      return;

    const blocks: CardData[] = [{
      etiqueta: LABELS.RISK_LOCATION,
      valor: `${this.riskLocation.calle} ${this.riskLocation.numero} - ${this.riskLocation.ciudad?.descripcion ?? ''}, ${this.riskLocation.region?.descripcion ?? ''}`
    }]


    this.summary.push(...blocks)
  }

  next(){
    this.showButton=false
    this.consultantProposal.notifyNextStep()
  }

  return(){
    this.consultantProposal.notifyPreviousStep()
  }

  createFreeDataBlock() {
    
/*
    this.issueState.checkState().subscribe({
      next: (state) => {
        if (state.state !== States.success)
          return;

        let form = this.formHandler.getFormControl(EmissionValidStepsEnum.free_data) as FormGroup;
        if (!form.value || (state.quotingInfo?.datosLibres?.length ?? 0) === 0)
          return;

        let blocks: CardData[] = [];
        // array de formControls
        state.quotingInfo?.datosLibres?.forEach(data => {

          let value =  Object.keys(form.controls).find(control => +control === data.id) ?? '-';
          blocks.push({
            etiqueta : data.descripcion ?? LABELS.FREE_DATA,
            valor :value
          })
        })
        
        this.summary.push(...blocks)
      }
    })*/


  }

  capitalizeWords(value: string): string {
    if (!value) return value;
    return value
      .split(' ')
      .map(word => word.charAt(0).toUpperCase() + word.slice(1).toLowerCase())
      .join(' ');
  }
  
  formatRiskLocation(): string {
    const { calle, numeroExterno, numeroInterno } = this.personalData;
    const form = this.formHandler.getForm();

    // Obtenemos los valores de municipio y estado
    const municipio = form?.controls[EmissionValidStepsEnum.personal_data].get('municipio')?.value?.descripcion ?? '';
    const estado = form?.controls[EmissionValidStepsEnum.personal_data].get('estado')?.value?.descripcion ?? '';
  
    // Capitalizamos calle, municipio, y estado
    const formattedCalle = this.capitalizeWords(calle);
    const formattedMunicipio = this.capitalizeWords(municipio);
    const formattedEstado = this.capitalizeWords(estado);
  
    // Construimos la dirección sin capitalizar numeroExterno ni numeroInterno
    const formattedLocation = [
      formattedCalle, numeroExterno, numeroInterno ? numeroInterno : '', formattedMunicipio, formattedEstado ];
  
    // Unimos las partes con ', ' entre municipio y estado, y ' - ' para los demás
    return `${formattedCalle} - ${numeroExterno} ${numeroInterno ? '- ' + numeroInterno : ''} - ${formattedMunicipio}, ${formattedEstado}`;
  }

}
