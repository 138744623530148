import { Component, Input, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Subscription } from 'rxjs';
import { ERRORS } from 'src/app/main/constants/errors.enum';
import { LABELS } from 'src/app/main/constants/labels.enum';
import { IProposalModel } from 'src/app/main/models/proposal.model';
import { AuthenticatorService } from 'src/app/main/services/authenticator.service';
import { ConsultantService } from 'src/app/main/services/consultant.service';
import { RedirectorService } from 'src/app/main/services/redirector.service';
import { LabelModel } from 'src/app/shared/models/label.model';
import { ModelCreatorService } from 'src/app/shared/services/model-creator.service';
import { IssueState } from 'src/app/state/issue/issue.state';
import { LoginState } from 'src/app/state/login/login.state';
import { States } from 'src/app/state/models/states.enum';
import { UserInfoComponent } from '../../user/user-info/user-info.component';
import { PopupOpenerService } from 'src/app/shared/services/popup-opener.service';
import { PopupMessageComponent } from 'src/app/shared/popup-message/popup-message.component';
import { PopUpTypesEnum } from 'src/app/shared/enums/popup-types.enum';
import { PopupDownloadsComponent } from 'src/app/shared/popup-downloads/popup-downloads.component';
import { PopupInsuranceComponent } from 'src/app/shared/popup-insurance-data/popup-insurance-data.component';
import { ICONS } from 'src/app/main/constants/icons.enum';
import { IOrdenItem } from 'src/app/main/models/select-item.model';

@Component({
  selector: 'renewal-list',
  templateUrl: './renewal-list.component.html',
  styleUrls: ['./renewal-list.component.scss']
})
export class RenewalListComponent implements OnInit{
  consultantError:string|undefined;
  showconsultantError=false;
  readonly my_proposals= LABELS.MY_PROPOSALS;
  readonly message:string=ERRORS.NO_PROPOSALS
  readonly proposal_list_error1=LABELS.PROPOSALS_LIST_ERROR1
  readonly proposal_list_error2=LABELS.PROPOSALS_LIST_ERROR2
  readonly go_quote = LABELS.GO_RENEWAL
  showMessage  = false;  
  hasFetchedQuotingInfo=false;
  proposals : IProposalModel[]=[]
  nameFilter="";
  zipCodeFilter="";
  vinFilter="";
  listOrden:IOrdenItem[]=[];
  currentPage=1
  recordsPerPage=10
  totalRecords=0
  totalPages=0
  quotingState1$ : Subscription = new Subscription();


  needsToUpdateClient  = false;
  updateClientComponent : any ={
    component:UserInfoComponent,
    config : {
      closeOnEscape : false,
      closable : false,
      width: '80%',
    },
    data :{ 
      showFullInfo : false,
      onSave : () => {        
        this.needsToUpdateClient = false;
        this.showMessagePopUp(LABELS.SAVE_SUCCESS, [LABELS.CLIENT_UPDATE_SUCCESS])}   
      } 
  };
  columns = [
    { title: 'Nombre', field: 'Nombre', styleClass : 'col-2' },
    { title: 'Email', field: 'Mail', styleClass : 'col-2' },
    { title: 'Póliza', field: 'PolizaProspecto', styleClass : 'col-1' },
    { title: 'Vin', field: 'Vin', styleClass : 'col-2' },
    { title: 'Código Postal', field: 'CodPostal', styleClass : 'col-1' },
    { title: 'Compañía', field: 'Compania', styleClass : 'col-1' },
    { title: 'Marca/Modelo', field: 'Modelo', styleClass : 'col-1' },
    { title: 'Fecha Vto', field: 'FechaVto', styleClass : 'col-1'},
    { title: 'Acciones', field: '', styleClass : 'col-1' } 
  ];
  proposalsList:any[]=[]

  componentData:any;
  showDownloads=false;
  popupSize=false;
  titleDownload:string=LABELS.TITLE_DOWNLOADS
  @Input() model : IProposalModel | undefined;

  private subscription: Subscription = new Subscription();
  constructor(private fb: FormBuilder,private modelCreator : ModelCreatorService,private consultantProposal:ConsultantService,private redirectorService: RedirectorService,    private issue_state : IssueState,private loginState : LoginState,    private dialog : PopupOpenerService) {
    this.checkLoginState();
  }

  ngOnInit(): void {
    this.subscription.add(
      this.consultantProposal.refreshList$.subscribe(() => {
        this.proposals=[]
        this.listProspectuses();
      })
    );
    this.listProspectuses();
  }
  subscribeToQuotingState(){
    this.quotingState1$ = this.issue_state.checkState().subscribe({
      next : ({prospectusQuoteState}) => {
        if(prospectusQuoteState.state === States.loading){
          return;
        }
        if(prospectusQuoteState.state === States.success && !this.hasFetchedQuotingInfo){
          this.hasFetchedQuotingInfo = true;
            this.issue_state.getQuotingInfo(prospectusQuoteState.quoteInfo!.TprId,prospectusQuoteState!.quoteInfo?.CotId)
          if(prospectusQuoteState.quoteInfo?.TprId==8){
            this.redirectorService.redirectToProposalVehicle();
          }            
        
        }
        this.quotingState1$.unsubscribe();
      }
    })      
  }

  checkLoginState(){
    if(!this.loginState.isLogged)
      return;
    
    if(!this.loginState.user?.esCliente){
      this.needsToUpdateClient = true;
      this.dialog.show(
        this.updateClientComponent
      );
    }
    else{
      this.loginState.getUserInfo();
    }
  }
  showMessagePopUp(title : string, messages : string[], type : PopUpTypesEnum = PopUpTypesEnum.success){
    this.updateClientComponent ={
      component:PopupMessageComponent,
      config : {
        closable : true
      },
      data : {
        type : type,
        title : title,
        messages : messages
      }      
    };
    this.needsToUpdateClient=true;
    this.dialog.hide();
    this.dialog.show(this.updateClientComponent)
  }
  goDasboard(){
    this.nameFilter="";
    this.zipCodeFilter="";
    this.vinFilter="";
    this.consultantProposal.triggerRefresh();
  }
  showError(msg  = ""){
    this.consultantError = ERRORS.COULD_NOT_REGISTER;
    if(msg)
      this.consultantError = msg;   
    this.showconsultantError = true;
  }
  listProspectuses(){
    this.showMessage=false
    const request={
      PaginaActual:this.currentPage,
      RegistrosPorPagina:this.recordsPerPage,
      Nombre:this.nameFilter,
      CodPostal:this.zipCodeFilter,
      Vin:this.vinFilter,
      Orden:this.listOrden
    }
    this.consultantProposal.consultProspectuses(request).subscribe({
      next  : (data) => {
        if(data.IsSuccess){
          this.proposalsList=data.Result.Prospectos;
          this.currentPage=data.Result.PaginaActual
          this.recordsPerPage=data.Result.RegistrosPorPagina
          this.totalPages=data.Result.TotalPaginas
          this.totalRecords=data.Result.TotalRegistros
          if(this.proposalsList.length==0){
            this.showMessage=true
          }
          return;
        }  
      },
      error : (err) => {
        this.showError();

      }
    })
  }
  detail(value:any){
    const prospect=value[1]
    if(value[0]==0){
    this.issue_state.getProspectusQuoteInfo(prospect.Id)
    this.subscribeToQuotingState();
    this.issue_state.fullState.prospectusQuoteState.goToPlans=false
    }
  }
  pageChange(value:any){
    this.currentPage=value[0];
    this.recordsPerPage=value[1];
    this.nameFilter=value[2];
    this.zipCodeFilter=value[3];
    this.vinFilter=value[4];
    this.listOrden=value[5];
    this.consultantProposal.triggerRefresh();
  }

  ngOnDestroy() {
    this.quotingState1$.unsubscribe();
    this.subscription.unsubscribe(); 
  }
  onCancel(){
    this.showDownloads=false;
  }
}
