import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SharedModule } from 'src/app/shared/shared.module';
import { CreateRenewalComponent } from './create-renewal.component';
import { CreateRenewalRoutingModule } from './create-renewal-routing.module';
import { EmissionPeriodicityInfoComponent } from 'src/app/features/issue/emission-periodicity/emission-periodicity-info.component';

@NgModule({
  declarations: [
    CreateRenewalComponent,
    EmissionPeriodicityInfoComponent

  ],
  exports:[
    CreateRenewalComponent,
    EmissionPeriodicityInfoComponent
  ],
  imports: [
    CommonModule,
    SharedModule,
    CreateRenewalRoutingModule
  ]
})
export class CreateRenewalModule { }
