import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { User } from '../../models/user.model';
import { environment } from 'src/environments/environment';
import { Observable } from 'rxjs';
import { IGenericResponseModel } from '../../models/generic-response.model';

@Injectable({
  providedIn: 'root'
})
export class UserService {

  private user_url : string = environment.parameters_url;

  constructor(private http : HttpClient) {   
  }

  addUser(user : User)  : Observable<IGenericResponseModel>{
    return this.http.post<IGenericResponseModel>(`${this.user_url}/AgregarCliente`, user);
  }

  updateUser(user : User) : Observable<IGenericResponseModel>{
    return this.http.post<IGenericResponseModel>(`${this.user_url}/EditarCliente`, user);
  }
  
  getUserInfo(){
    return this.http.post<IGenericResponseModel>(`${this.user_url}/BuscarInfoCliente`, undefined);
  }

  deleteUser(){
    return this.http.post<IGenericResponseModel>(`${this.user_url}/BorrarCliente`, undefined);
  }

}
