
    <div class="col-12">
      <div class="faq-container p-2">
        <h4 style="font-size: 1.3rem;" class="pt-2 ms-3">{{ faq_title }}</h4>
    
        <div *ngFor="let category of faqs; let cIndex = index">
          <!-- Si la categoría es vacía, mostramos las preguntas directamente -->
          <ng-container *ngIf="category.Categoria === ''; else categoryAccordion">
            <div class="faq-item" *ngFor="let faq of category.Preguntas; let i = index">
              <div class="faq-question" 
                tabindex="0"
                (keypress)="toggleAnswer(cIndex, i)"
                (click)="toggleAnswer(cIndex, i)">
                <span>{{ faq.Id }}</span>
                <i class="fas" [ngClass]="{'fa-chevron-down': !faq.Open, 'fa-chevron-up': faq.Open}"></i>
              </div>
              <div class="faq-answer" *ngIf="faq.Open" [innerHtml]="faq.Descripcion"></div>
            </div>
          </ng-container>
    
          <ng-template #categoryAccordion>
            <div class="faq-category">
              <div class="faq-question"
                tabindex="0"
                (keypress)="toggleCategory(cIndex)" (click)="toggleCategory(cIndex)">
                <h6 class="mt-2">{{ category.Categoria }}</h6>
                <i class="fas" [ngClass]="{'fa-chevron-down': !category.Open, 'fa-chevron-up': category.Open}"></i>
              </div>
            </div>
            <div *ngIf="category.Open" style="margin-left: 15px;">
              <div class="faq-item" *ngFor="let faq of category.Preguntas; let i = index">
                <div class="faq-question" 
                  tabindex="0"
                  (keypress)="toggleAnswer(cIndex, i)" (click)="toggleAnswer(cIndex, i)">
                  <span style="font-size: 0.85rem;">{{ faq.Id }}</span>
                  <i class="fas" [ngClass]="{'fa-chevron-down': !faq.Open, 'fa-chevron-up': faq.Open}"></i>
                </div>
                <div class="faq-answer" *ngIf="faq.Open" [innerHtml]="faq.Descripcion"></div>
              </div>
            </div>
          </ng-template>
        </div>
      </div>        
    </div>
  