<div>
    <app-navbar (menuClicked)="toggleMenu()"></app-navbar>
  </div>
  <div class="d-flex col-12 home-container background-home">
    <div class="col-{{smSizeMenu}} col-sm-{{smSizeMenu}} h-100 bg-white smooth-transition"
         [ngClass]="{'width-6': !menuOpen, 'd-none': isSmall && !menuOpen}">
      <app-sidebar [menuOpen]="menuOpen" (itemClicked)="toggleMenu()"></app-sidebar>
    </div>
  
    <div class="col-11 col-sm-{{smSizeBody}} flex-grow-1 scroll-y smooth-transition non-scroll-x">
      <div id="home-container" class="px-3 py-2 routing-container">
        <router-outlet></router-outlet>
      </div>
      <div class="px-3" *ngIf="showQuestions">
        <app-insurance-question></app-insurance-question>
      </div>
  
      <div>
        <app-footer></app-footer>
      </div>
    </div>
  </div>
  
