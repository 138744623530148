import { Component, EventEmitter, Input, OnChanges, Output, SimpleChanges } from '@angular/core';
import { FormControl, FormGroup, ValidatorFn } from '@angular/forms';
import { PasswordConfirmationModel } from '../models/password-confirmation.model';
import { InputModel } from '../models/input.model';
import { ERRORS } from 'src/app/main/constants/errors.enum';
import { ModelCreatorService } from '../services/model-creator.service';
import { LABELS } from 'src/app/main/constants/labels.enum';
import { InputTypesEnum } from 'src/app/main/constants/inputs-types.enum';

@Component({
  selector: 'app-password-confirmation',
  templateUrl: './password-confirmation.component.html',
  styleUrls: ['./password-confirmation.component.scss']
})
export class PasswordConfirmationComponent implements OnChanges {

  @Input() model : PasswordConfirmationModel = new PasswordConfirmationModel();
  @Output() outOfFocus = new EventEmitter<string>();

  passwordConfirmModel : InputModel = new InputModel();

  capsAreValid       = false;
  lengthIsValid      = false;
  lettersAreValid    = false;
  numbersAreValid    = false;  

  passwordError : string = ERRORS.PASSWORD_UNFILLED;
  
  must_contain = LABELS.MUST_PSW_CONTAIN;
  must_length = LABELS.MUST_PSW_LENGTH;
  must_letters = LABELS.MUST_PSW_LETTER;
  must_numbers = LABELS.MUST_PSW_NUMBER;
  must_mayus = LABELS.MUST_PSW_MAYUS

  numbersRegex  = /\d+/g;
  lettersRegex  = /[a-z]+/g;
  capsRegex  = /[A-Z]+/g;  

  constructor(private modelCreator : ModelCreatorService) {
    
  }

  ngOnChanges(changes: SimpleChanges): void {
    if(changes && changes['model']?.currentValue && this.model.parentGroup){
      this.model.parentGroup.addControl('passwordConfirm', new FormControl())
      this.passwordConfirmModel = this.modelCreator.createInputModel(LABELS.PASSWORD_CONFIRM, InputTypesEnum.password, 'passwordConfirm', this.model.parentGroup, ERRORS.PASSWORD_UNCONFIRMED,undefined,true);
    }
      
  }

  focusOut(control : string){
    this.outOfFocus.emit(control);   
    this.checkPasswordConstraints(true) ;
  }


  checkPasswordConstraints(showError : boolean){    
    if(!this.model.parentGroup || !this.model.formControlName)
      return;

    const password = this.model.parentGroup.controls[this.model.formControlName!].value;
    if(!password)
      return;

    this.lengthIsValid = password.length > 8;
    this.lettersAreValid = this.testRegex(this.lettersRegex, password);
    this.numbersAreValid = this.testRegex(this.numbersRegex, password);
    this.capsAreValid = this.testRegex(this.capsRegex, password);

    if(!showError)
      return;


    if(! (this.lengthIsValid && this.lettersAreValid && this.numbersAreValid && this.capsAreValid)){
      this.model.parentGroup.controls[this.model.formControlName!].setErrors({invalid : true});
    }
    else{
      this.model.parentGroup.controls[this.model.formControlName!].setErrors(null);
    }
  }

  testRegex(regex : RegExp, pattern : string){
    
    let result = regex.test(pattern);
    if(!result) // Si dio false puede ser un error de la regex, lo hacemos de nuevo por las dudas
      result = regex.test(pattern);
    
    return result;
  }

}

