import { Injectable } from "@angular/core";
import { ButtonModel } from "../models/button.model";
import { ButtonTypesEnum } from "../../main/constants/buttons.enum";
import { InputModel } from "../models/input.model";
import { FormGroup } from "@angular/forms";
import { ICONS } from "src/app/main/constants/icons.enum";
import { DividedInputModel } from "../models/divided-input.model";
import { PasswordConfirmationModel } from "../models/password-confirmation.model";
import { LabelModel } from "../models/label.model";
import { DropdownCustomModel } from "../models/dropdown.model";

@Injectable({
    providedIn: 'root'
  })
export class ModelCreatorService{

    createButtonModel(text : string, type : ButtonTypesEnum, classes? : string,disable?:boolean) : ButtonModel{
        return {
            text : text,
            type : type,
            classes : classes,
            disabled:disable
        }
    }

    createIconButtonModel(text : string, icon? : string, useSvgIcon? : boolean,  classes? : string) : ButtonModel{
        return {
            text : text,
            type : ButtonTypesEnum.Icon,
            icon: icon,
            classes : classes,
            useSvgIcon : useSvgIcon
        }
    }


    createInputModel(placeholder : string, type : string, formControlName : string, parentGroup : FormGroup, customError? : string, icon? : ICONS,
        required? : boolean,maxLength?:number,onlyNumber?:boolean, disabled? : boolean,mask?:string, subtitle?:string) : InputModel{
        return {
            placeholder : placeholder,
            type : type,
            formControlName : formControlName ,
            parentGroup : parentGroup,
            customError : customError,
            icon : icon,
            required : required,
            maxLength:maxLength,
            onlyNumber:onlyNumber,
            disabled : disabled,
            mask:mask,
            subtitle:subtitle,
        }        
    }

    createCalendarModel(name : string, parentGroup : FormGroup, required  = false, placeholder: string, disabled  = false, label  = '',showTime=false, format  = '', styleClass  = ''){
        return {          
          formGroup: parentGroup ,
          formControlName : name,
          disabled: disabled,
          label: label,
          styleClass: styleClass,
          required: required,
          placeholder: placeholder,
          format : format,
          showTime:showTime
        }
      }

    createSelectModel(name: string,placeholder: string, options: any[], 
        form : FormGroup, label  = '', styleClass  = "", required  = false,notSelectAutomatic=true,disabled=false,customError?:string): DropdownCustomModel {
        return {
          options: options,
          formGroup: form,
          formControlName: name,
          placeholder: placeholder,
          justify: 'justify-content-start',
          fullWidth : true,
          label : label,
          styleClass : styleClass,
          required : required,
          notSelectAutomatic:notSelectAutomatic,
          disabled:disabled,
          customError:customError
        }
    }

    createLabelModel(text:string,label:string) : LabelModel{
        return {
            label : label,
            text  :text  
        }        
    }

    createDividedInputModel(placeholder : string, type: string, formControlName : string, parentGroup : FormGroup, customError? : string, numberOfDivisions? : number, maxLength? : number, inputClasses? : string[]) : DividedInputModel{
        const model : DividedInputModel = this.createInputModel(placeholder, type, formControlName, parentGroup, customError);
        model.numberOfDivisions = numberOfDivisions ? numberOfDivisions : 1;
        model.maxLength = maxLength ? maxLength : 1;
        model.inputClasses = inputClasses;
        return model;
    }

    createPasswordModel(inputModel : InputModel, formControlName : string, parentGroup : FormGroup) : PasswordConfirmationModel{
        const model : PasswordConfirmationModel = {
            parentGroup : parentGroup,
            inputModel: inputModel,
            formControlName : formControlName,
        }
        return model;
    }

}
