import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { DashboardComponent } from '../dashboard/dashboard.component';
import { HomeComponent } from './home.component';
import { DashboardModule } from '../dashboard/dashboard.module';
import { HomeRoutingModule } from './home-routing.module';
import { NavbarComponent } from 'src/app/features/navbar/navbar.component';
import { SharedModule } from 'src/app/shared/shared.module';
import { SidebarComponent } from 'src/app/features/sidebar/sidebar.component';
 import { IssueInsuranceModule } from '../issue/issue-insurance/issue-insurance.module';
import { CreateInsuranceModule } from '../issue/create-insurance/create-insurance.module';
import { UserModule } from '../user/user.module';
import { PartnershipComponent } from '../partnership/partnership.component';
import { IssueInsuranceVehicleModule } from '../issue/issue-insurance-vehicle/issue-insurance-vehicle.module';
import { TipsComponent } from '../dicas/tips.component';
import { TipComponent } from '../dicas/tip.component';
import { IssueResidentialModule } from '../issue/issue-residential/issue-residential.module';
import { CreateRenewalModule } from '../issue/create-renewal/create-renewal.module';




@NgModule({
  declarations: [
    HomeComponent,
    NavbarComponent,
    SidebarComponent,
    PartnershipComponent,
    TipsComponent,
    TipComponent
  ],
  imports: [
    CommonModule,
    DashboardModule,
    IssueInsuranceModule,
    IssueInsuranceVehicleModule,
    IssueResidentialModule,
    CreateInsuranceModule,
    CreateRenewalModule,
    UserModule,
    HomeRoutingModule,
    SharedModule,
  ]
})
export class HomeModule { }
