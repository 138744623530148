<div *ngIf="model && model.inputModel">
    <div class="justify-content-center col-12 col-md-8 m-auto"><custom-input [model]="model.inputModel"
           (keyIsUp)="checkPasswordConstraints(false)" 
            (keyPressed)="checkPasswordConstraints(false)"
            (keyIsDown)="checkPasswordConstraints(false)"
            (inputFocusedOut)="focusOut('password')" />

    </div>
    
    <div class="justify-content-center col-12 col-md-8 m-auto"><custom-input [model]="passwordConfirmModel"
            (inputFocusedOut)="focusOut('confirmation')" /></div>

    <div class="justify-content-center col-12 col-md-8 m-auto px-3">

        <p class="fw-bold"> {{must_contain}}</p>
        <div class="row text-start">
            <div class="col-2"> <mat-icon *ngIf="lengthIsValid">done</mat-icon> </div>
            <div class="col-10">
                <p [ngClass]="{'fw-bold': lengthIsValid }">{{must_length}}</p>
            </div>
        </div>
        <div class="row text-start">
            <div class="col-2"> <mat-icon *ngIf="lettersAreValid">done</mat-icon> </div>
            <div class="col-10">
                <p [ngClass]="{'fw-bold': lettersAreValid  }">{{must_letters}}</p>
            </div>
        </div>
        <div class="row text-start">
            <div class="col-2"> <mat-icon *ngIf="numbersAreValid">done</mat-icon> </div>
            <div class="col-10">
                <p [ngClass]="{'fw-bold': numbersAreValid}">{{must_numbers}}</p>
            </div>
        </div>
        <div class="row text-start">
            <div class="col-2"> <mat-icon *ngIf="capsAreValid">done</mat-icon> </div>
            <div class="col-10">
                <p [ngClass]="{'fw-bold': capsAreValid }">{{must_mayus}}</p>
            </div>
        </div>


        <script src="https://www.google.com/recaptcha/api.js" async defer></script>

        <p *ngIf="lengthIsValid && lettersAreValid && numbersAreValid && capsAreValid && this.model.parentGroup && this.model.parentGroup.value.password !== this.passwordConfirmModel.parentGroup?.value?.passwordConfirm"
            class="text-danger">
            {{passwordError}}
        </p>
    </div>
</div>