<div class="d-flex flex-wrap gap-2 justify-content-center">
  <div class="comparison-table-wrapper">
    <div class="col-12">
      <p class="ps-2 text-white pointer back f-weight-500" (keypress)="handleDismiss()" (click)="handleDismiss()" tabindex="0"><i class="fa-solid fa-angle-left"></i> {{back}}</p>
    </div>  

    <div class="comparison-table" *ngIf="showCoverage">
      <h6 class="title">{{comparer_coverages_title}}</h6>
      <p class="subtitle">{{comparer_coverages_subtitled}}</p>
      
      <div class="table-body">
        <div class="header-cell"></div>
        <div *ngFor="let plan of data" class="header-cell">
          <h5>{{ plan.Plan }}</h5>
          <img alt="company-logo" *ngIf="plan.LogoCompania" [src]="'/assets/companies/cia' + plan.IdCompania + '.svg'">
          <p class="plan-price">{{ plan.Cuota }} <span class="monthly-text">{{price_per_month}}</span></p>
        </div>
        <div *ngFor="let cobertura of allCoberturas" class="body-row">
          <div class="body-cell">{{ cobertura }}</div>
          <div *ngFor="let plan of data" class="body-cell">
            <span>
              {{ getCoberturaPrice(plan, cobertura) }}
              <span *ngIf="!hasCobertura(plan, cobertura)" class="crossmark">✖</span>
            </span>
          </div>
        </div>
        <div class="body-row">
          <div class="body-cell"></div>
          <div *ngFor="let plan of data" class="body-cell">
            <button class="select-plan-button" (keypress)="beginEmission(plan)" (click)="beginEmission(plan)" tabindex="0">{{select_plan}}</button>
          </div>
        </div>
      </div>
    </div>

  </div>
</div>
