import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, Subject } from 'rxjs';
import { environment } from 'src/environments/environment';
import { IGenericResponseModel } from '../models/generic-response.model';
import { IRegisterModel } from '../models/auth/register.model';
import { CodeValidationModel } from '../models/code-validation.model';
import { AuthenticatorService } from './authenticator.service';
import { FormGroup } from '@angular/forms';
import { Vehicle } from '../models/vehicle.model';
import { ClienteProducto } from '../models/issue/issue-data.model';


@Injectable({
  providedIn: 'root'
})
export class ConsultantService {

  private readonly base_url = environment.emission_base_url;
  private readonly base_url_quoting = environment.quoting_url;
  private readonly base_url_emission = environment.emission_url;
  private base_url_quoting_parametrer = environment.parameters_url;
  tips:any;
  pricesSelect:any;
  private refreshListProposal = new Subject<void>();
  private nextStep= new Subject<string>();

  refreshList$ = this.refreshListProposal.asObservable();
  nextStepCompleted$ = this.nextStep.asObservable();
  triggerRefresh() {
    this.refreshListProposal.next();
  }

  
  notifyNextStep(){
    this.nextStep.next('1');
  }

  notifyPreviousStep(){
    this.nextStep.next('0');
  }

  constructor(private http : HttpClient,private auth:AuthenticatorService) { }

  cancelProposal(data:any) : Observable<IGenericResponseModel>{    
    return this.http.post<IGenericResponseModel>(this.base_url_emission + '/BajaPoliza',data);    
  }  

  consultProposal(data:any) : Observable<IGenericResponseModel>{    
    return this.http.post<IGenericResponseModel>(this.base_url + '/policy/ListarPolizasCliente',data);    
  }  

  consultProspects(id:number) : Observable<IGenericResponseModel>{    
    return this.http.post<IGenericResponseModel>(this.base_url + '/policy/Obtener',id);    
  }  

  consultProspectuses(data:any) : Observable<IGenericResponseModel>{    
    return this.http.post<IGenericResponseModel>(this.base_url_quoting + '/ObtenerProspectos',data);    
  }  

  
  listBoardProducts(){
    return this.http.post<IGenericResponseModel>(this.base_url_quoting + '/ListarTablero',null);    
  }
  listYearsVehicle(){
    return this.http.post<IGenericResponseModel>(this.base_url_quoting + '/ListarAnios',null);    
  }
  listMakeVehicle(vehicle:Vehicle){
    return this.http.post<IGenericResponseModel>(this.base_url_quoting + '/ListarMarcas',vehicle);    
  }
  listModelsVehicle(vehicle:Vehicle){
    return this.http.post<IGenericResponseModel>(this.base_url_quoting + '/ListarGrupos',vehicle);    
  }

  listVersionsVehicle(vehicle:Vehicle){
    return this.http.post<IGenericResponseModel>(this.base_url_quoting + '/ListarVersiones',vehicle);    
  }

  quote(data:ClienteProducto){
    return this.http.post<IGenericResponseModel>(this.base_url_quoting + '/Cotizar',data);    
  }

  saveQuote(data:ClienteProducto){
    return this.http.post<IGenericResponseModel>(this.base_url_quoting + '/GuardarCotizar',data);
  }



  listFaqs(data:any){
    return this.http.post<IGenericResponseModel>(this.base_url_quoting_parametrer + '/Faqs',data);    
  }

  listTips(){
    return this.http.post<IGenericResponseModel>(this.base_url_quoting_parametrer + '/Tips','');    
  }

  getPdf(data:any){
    return this.http.post<IGenericResponseModel>(this.base_url_quoting + '/ObtenerPdfPlanes',data);
  }
  getInsuranceData(data:any){
    return this.http.post<IGenericResponseModel>(this.base_url_quoting + '/ObtenerDatosAseguradora',data);
  }
  downloadDocuments(data:any): Observable<IGenericResponseModel>{
    return this.http.post<IGenericResponseModel>(this.base_url_quoting + '/DescargarDocumentacion', data);
  }
  
  updatePolicy(data:any): Observable<IGenericResponseModel>{
    return this.http.post<IGenericResponseModel>(this.base_url + '/policy/ActualizarPoliza',data);
  }
  

  getTips(){
    return this.tips
  }

  openPdf(pdf:any,name:string){
    if(pdf){
      const byteCharacters = atob(pdf);
      const byteNumbers = new Array(byteCharacters.length);
      for (let i = 0; i < byteCharacters.length; i++) {
        byteNumbers[i] = byteCharacters.charCodeAt(i);
      }
      const byteArray = new Uint8Array(byteNumbers);
      // Crear un Blob a partir del array de bytes
      const blob = new Blob([byteArray], { type: 'application/pdf' });
      if (blob.size === 0) {
        console.error('El blob recibido está vacío.');
        return;
      }
      const url = window.URL.createObjectURL(blob);
      // Crear un enlace para descargar el archivo
      const a = document.createElement('a');
      a.href = url;
      a.download = `${name}`;
      document.body.appendChild(a);
    
      a.click();
      
      window.open(url);
    
      window.URL.revokeObjectURL(url);
    }
  }

}
