import { HttpClient } from '@angular/common/http';
import { ElementRef, HostBinding, Injectable } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { environment } from 'src/environments/environment';
import { HSLModel, RGBModel } from '../models/theme/colors.model';
import { Router } from 'express';
import { IGenericResponseModel } from '../models/generic-response.model';
import { Observable } from 'rxjs';
import { ThemeResponse } from '../models/theme/theme-response.model';

@Injectable({
  providedIn: 'root'
})
export class ThemeService {

  private readonly theme_url = environment.authentication_url;

  constructor(private http : HttpClient, 
    private sanitizer : DomSanitizer,
  ) { }
  
  getOrganizationTheme(link: string) : Observable<ThemeResponse>{
    let url = `${this.theme_url}/ValidarLink`;
    if(link !== "")
      url+= `?link=${link}`;

    return this.http.get<ThemeResponse>(url);
  }

  getHueColor(hex : string) : HSLModel{
    let hsl : HSLModel = {degrees : 0, lightness: 0, saturation: 0 };
    const rgb = this.transformHEXtoRGB(hex);    
    if(rgb)
      hsl = this.transformRGBToHSL(rgb?.r, rgb?.g, rgb.b);

    return hsl;
  }  

  transformRGBToHSL(r : number, g : number, b : number) : HSLModel{
    r /= 255;
    g /= 255;
    b /= 255;
      
    const max = Math.max(r,g,b);
    const min = Math.min(r,g,b);

    let h = (max + min) /2;
    let s  =(max + min) /2;
    let l = (max + min) /2;

    if(max-min === 0 ){
      h = s = 0;
    }
    else {
      const d = max-min;
      s = l > 0.5 ? d / (2-max-min) : d/(max+min)
      switch(max){
        case r: h = (g - b) / d + (g < b ? 6 : 0); break;
        case g: h = (b - r) / d + 2; break;
        case b: h = (r - g) / d + 4; break;
      }
      h = (h ?? 0) / 6;
    }
    s*=100
    s = Math.round(s);
    l = Math.round(l*100);

    return {degrees: (h * 360), saturation: s, lightness: l };
  }  

  transformHEXtoRGB(hex : string) : RGBModel | null{
    const result = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(hex);
    return result ? {
      r: parseInt(result[1], 16),
      g: parseInt(result[2], 16),
      b: parseInt(result[3], 16)
    } : null;
  }

}
