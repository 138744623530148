<div class="pb-4 ">
    <div class="d-flex flex-wrap"></div>
    <div class="col-12">
      <p class="ps-2 text-white pointer back f-weight-500" tabindex="0" (keypress)="back()" (click)="back()"><i class="fa-solid fa-angle-left"></i> {{back_dashboard}}</p>
    </div>
    <!-- <div>
      <custom-select
            [model]="quotes"
      >
      </custom-select>
      </div> -->
    <div class="col-12 gap-2 d-flex  flex-wrap-reverse justify-content-center " style="margin-top: 5px;font-size: 1rem !important;">
        <div class="col-12 col-md-4 card scroll-y px-3" *ngIf="isQuoteSteps" >
          <div class="insurance-benefits">
            <h4 class="section-title" style="margin-top: 20px !important;"><span>{{title_banner_vehicle1}} {{title_banner_vehicle2}}</span></h4>
            <!-- <h4 class="section-title"><span class="bold-text">{{title_banner_vehicle2}}</span></h4> -->
            <h4 class="section-title">{{title_banner_vehicle3}} <span class="bold-text">{{title_banner_vehicle4}}</span></h4>
            <div class="benefits-grid">
                <div class="benefit-item">
                  <img alt="'Vantagem Financiera'" src="../../../../assets/banners/Financiera.svg"/>
                    <h5 class="bold-text" style="color:#5a5959;  font-size: 0.8rem  !important">{{subtitle_banner_vehicle1}}</h5>
                    <p><span class="bold-text">{{body1_banner_vehicle1}}</span> {{body2_banner_vehicle1}}</p>
                </div>
                <div class="benefit-item">
                  <img alt="Vantagem Asistencia" src="../../../../assets/banners/Asistencia.svg"/>
                    <h5 class="bold-text" style="color:#5a5959;font-size: 0.8rem  !important">{{subtitle_banner_vehicle2}}</h5>
                    <p><span class="bold-text">{{body1_banner_vehicle2}}</span> {{body2_banner_vehicle2}}</p>
                </div>
                <div class="benefit-item">
                  <img alt="Vantagem Legal" src="../../../../assets/banners/legal.svg"/>
                    <h5 class="bold-text" style="color:#5a5959; font-size: 0.8rem  !important">{{subtitle_banner_vehicle3}}</h5>
                    <p>La cobertura de <span class="bold-text">{{body1_banner_vehicle3}}</span> {{body2_banner_vehicle3}}</p>
                </div>
                <div class="benefit-item">
                  <img alt="Vantagem Soporte" src="../../../../assets/banners/soporte.svg"/>
                    <h5 class="bold-text" style="color:#5a5959; font-size: 0.8rem  !important">{{subtitle_banner_vehicle4}}</h5>
                    <p><span class="bold-text">{{body1_banner_vehicle4}}</span> {{body2_banner_vehicle4}}</p>
                </div>
            </div>
          </div>
        </div>        
        <div class="col-12 col-md-5 card px-3" *ngIf="isQuoteSteps && steps[step]!==undefined">
          <issue-vehicle-data  *ngIf="steps[step].id === 0" (backClicked)="backStep()" (completedClicked)="next()"></issue-vehicle-data>
          <issue-insured-data  *ngIf="steps[step].id === 1" (backClicked)="backStep()" (completedClicked)="next()"></issue-insured-data>
          <issue-payment-data  *ngIf="steps[step].id === 2" (backClicked)="backStep()" (completedClicked)="next()"></issue-payment-data>
        </div>

        <div class="col-12 col-md-2 card px-3 d-none d-md-block" *ngIf="isQuoteSteps">
          <app-issue-steps-aside [steps]="steps" [currentStep]="step" (stepClicked)="selectMenuItem($event.id)"></app-issue-steps-aside>
        </div>
          
    </div>

  </div>
  
  
  
  