<div class="h-100 w-100 text-center">
    <p class="fs-3 ">{{title}}</p>
    <img [alt]="'Popup Icon'"
         [src]="'/assets/icons/' + findIcon()" 
        class="d-block m-auto"    
        width="25%"         
        height="auto"
        style="max-width: 5em;" >    

    <div *ngFor="let msg of messages">
        <p class="fs-5" [innerHTML]="msg"></p>
    </div>

    <custom-button [model]="btnContinue!" (customClick)="onClick()" class="col-sm-6 offset-sm-2 mt-2" *ngIf="button"></custom-button>
</div>
