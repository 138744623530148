<custom-popup *ngIf="showAll"
    [show]="true"
    [hiddenHeader]="true"
    [hiddenButtons]="true"
    [maxSize]="true"
    [title]="title" 
    [componentData]="componentData"
    (dismissed)="onCancel()">
</custom-popup>

<div class="h-100 mx-2 ">
<div class="common-card h-100 scroll-y align-content-center d-flex flex-column justify-content-center">
    <div class="text-center fs-4 pt-2">
        <p class="fw-medium">{{title}}</p>
    </div>
    <div class="w-100 d-flex flex-wrap justify-content-center gap-4 justify-self-center align-self-center align-items-stretch align-content-center" *ngIf="products">

        <div class="col-4 d-flex justify-content-center  " *ngFor="let product of filterProducts(); let i = index">

            <div class="col-12 product-boxes d-flex flex-column justify-content-center p-2 pointer" 
            (keypress)="selectProduct(product, i)"
            (click)="selectProduct(product,i)"
            tabindex="0">
                <div class="col-12 d-flex justify-content-center p-0">
                    <img [alt]="'Tipo de Serviço'" [src]="'../../../../assets/product-types/' + product.Logo + '.svg'" class="product-image ">
                </div>
                <div class="col-12 p-0">
                    <p class="product-name fw-bold m-0 text-center ">{{product.Nombre}}</p>
                    <p class="product-description fs-6 m-0  text-secondary text-center">{{product.Descripcion}}</p>
                </div>

            </div>
        </div>

    </div>
</div>

</div>