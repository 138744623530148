import { IEnvironment } from "./environment.model";
/* eslint-disable*/
const appVersion =  require('package.json').version;


export const environment : IEnvironment= {
    production: true,
    appVersion: appVersion,
    authentication_url : "",
    notifications_url  : '',
    emission_url       : '',
    emission_base_url  : '',
    quoting_url        : '',
    parameters_url     : '',
};
