<div class="m-auto d-flex flex-row flex-wrap justify-items-center align-items-start align-content-start">
    <div class="w-100 px-2" *ngIf="prices && priceConfig ">
        <app-pricing-list 
            [prices]="prices"
            [config]="priceConfig"
            [pricingId]="pricingId" 
            (optionClicked)="onOptionClick($event)" 
            (planChecked)="onCheckPlan($event)"
            (continueClicked)="continue()">
        </app-pricing-list>
    </div>
</div>

    
<div class="container-spinner" [ngClass]="{'align-items-baseline pt-5': !load}" *ngIf="prices.length === 0">
    <div class="col-12 col-sm-12 card card-spinner  w-90 h-75" *ngIf="load">
        <div class="col-12 d-flex justify-content-center align-items-center">
            <span class="loader"></span>
        </div>
        <div class="col-12">
            <p class="fs-5 text-secondary text-center mb-4">{{load_plans}}</p>
        </div>
    </div>
    <div class="col-10 col-sm-10 card card-spinner-error h-75 w-90" *ngIf="!load">
        <div class="col-12 text-center error-content">
            <img [src]="'/assets/icons/' + 'download.svg'" alt="Icono de nube" class="error-icon mb-3">
            <p class="fs-3 error-title text-secondary">{{quote_error1}}</p>
            <p class="fs-5 text-secondary mb-4">{{quote_error2}}</p>

            <div class="content-show">
                <p class="fs-6 text-secondary mt-2">{{quote_help1}}</p>
                <a href="#" class="help-link d-block mb-3">{{quote_help2}}</a>
                <button class="retry-button">{{retry_quote}}</button>
            </div>
        </div>
    </div>
</div>

