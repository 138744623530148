import { Component, OnDestroy, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { Subscription } from 'rxjs';
import { ButtonStylesEnum, ButtonTypesEnum } from 'src/app/main/constants/buttons.enum';
import { LABELS } from 'src/app/main/constants/labels.enum';
import { PricedPlanModel } from 'src/app/main/models/issue/priced-plan.enum';
import { ISelectItem } from 'src/app/main/models/select-item.model';
import { RedirectorService } from 'src/app/main/services/redirector.service';
import { PopUpTypesEnum } from 'src/app/shared/enums/popup-types.enum';
import { ButtonModel } from 'src/app/shared/models/button.model';
import { PricesFilterModel, PricingListConfigModel } from 'src/app/shared/models/pricing-list-config.model';
import { PopupMessageComponent } from 'src/app/shared/popup-message/popup-message.component';
import { ModelCreatorService } from 'src/app/shared/services/model-creator.service';
import { PopupOpenerService } from 'src/app/shared/services/popup-opener.service';
import { IssueState } from 'src/app/state/issue/issue.state';
import { States } from 'src/app/state/models/states.enum';

@Component({
  selector: 'price-insurance',
  templateUrl: './price-insurance.component.html',
  styleUrls: ['./price-insurance.component.scss']
})

export class PriceInsuranceComponent implements OnInit, OnDestroy {
  priceConfig? : PricingListConfigModel = undefined;
  pricingId? : number;
  prices: any[] = [];
  selectedPrices: any[] = []
  btnContinue: ButtonModel | undefined;
  btnComparePlans: ButtonModel | undefined;
  load=true;
  popupData : any;

  quotingInfoSub: Subscription = new Subscription();
  isComparer = false;

  quotingState1$ : Subscription = new Subscription();
  readonly title_issue_plans = LABELS.TITLE_ISSUE_PLANS;
  readonly filter_plans = LABELS.FILTERS_PLANS;
  readonly filter_company = LABELS.FILTERS_COMPANY;
  readonly filter_types_plans = LABELS.FILTERS_TYPES_PLANS;
  readonly back_dashboard = LABELS.BACK_DASHBOARD;
  readonly load_plans=LABELS.LOAD_PLANS;
  readonly message_error=LABELS.NO_PLANS

  readonly quote_error1=LABELS.QUOTE_ERROR1
  readonly quote_error2=LABELS.QUOTE_ERROR2

  readonly quote_help1=LABELS.QUOTE_ERROR_HELP1
  readonly quote_help2=LABELS.QUOTE_ERROR_HELP2
  readonly retry_quote = LABELS.RETRY_QUOTE
  isVehicle=false;
  constructor(private modelCreator: ModelCreatorService,
    private popupSvc : PopupOpenerService,
    private redirector: RedirectorService,
    private router: Router,
    private issue_state: IssueState,
  ) { this.isComparer = false; }
  ngOnInit(): void {
    this.isVehicle=this.issue_state.fullState.quotingState.quotingInfo?.EsVehiculo ?? false;
    this.quotingInfoSub =
      this.issue_state.checkState().subscribe({
        next: ({ pricingState }) => {
          
          if (pricingState.state !== States.success ) {
            this.prices = [];
            // mostrar error o mensajito
            return;

          }
          else {
            this.prices = pricingState.pricing?.Result?.Planes ?? [];
            this.pricingId = pricingState.pricing?.Result.Id;
            if(this.prices.length==0 ){
              this.load=false
            }
            this.isComparer = false;
            this.btnComparePlans = this.modelCreator.createButtonModel(LABELS.COMPARE_PLANS_ISSUE, ButtonTypesEnum.Raised, ButtonStylesEnum.grey_white);
            this.btnContinue = this.modelCreator.createButtonModel(LABELS.CONTINUE_ISSUE, ButtonTypesEnum.Raised, ButtonStylesEnum.black);
            this.setPriceConfig()
          }

        }
      })
  }
  setPriceConfig(){
    const config = new PricingListConfigModel();
    config.allowMultiPlanSelection = false;

    if(this.isVehicle)
      config.showFranquicia=false;
    config.title = this.title_issue_plans;
    //config.multiOptions[0].selected = true;
    config.filters = this.setFilterOptions(this.prices);
    this.priceConfig = config;
  }

  checkPricesSelected(index: number, value: boolean) {

    this.prices[index].isSelected = !this.prices[index].isSelected;

    this.selectedPrices = this.prices.filter(p => p.isSelected);
  }

  comparePlans() {
    this.selectedPrices = this.prices.filter(p => p.isSelected === true);
    if (this.selectedPrices.length > 1)
      this.isComparer = true;
  }

  back() {
    this.isComparer = false;
  }
  backDasboard() {
    this.redirector.redirectToDashboard()
  }
  ngOnDestroy(): void {
    this.quotingInfoSub.unsubscribe();
  }


  backQuote(){
    const typeProduct=this.issue_state.fullState.quotingState.quotingInfo?.idTipoProducto;
    this.subscribeToQuotingState(typeProduct!);
  }

  onCheckPlan(planAndValue : any){
    // no se emite nada jeje
   return; 
  }

  subscribeToQuotingState(quoteType:number){
    this.quotingState1$ = this.issue_state.checkState().subscribe({
      next : ({quotingState}) => {
        if(quotingState.state === States.loading)
          return;

        // if(quotingState.state === States.empty || (quotingState.state === States.success && quotingState.quotingInfo?.idTipoProducto !== quoteType && quoteType !== -1)){
          this.issue_state.getQuotingInfo(quoteType);
        //   return;
        // }

        if(quotingState.state === States.success){
          if(quotingState.quotingInfo?.EsMascota){
            //this.showMessagePopUp(LABELS.COMING_SOON, [LABELS.WORKING_FOR_YOUR_EXPERIENCE]);
          }            
          else if(quotingState.quotingInfo?.EsVehiculo)
            this.redirector.redirectToProposalVehicle();
          else{
            //this.showMessagePopUp(LABELS.COMING_SOON, [LABELS.WORKING_FOR_YOUR_EXPERIENCE]);
          }          
        }
        this.quotingState1$.unsubscribe();
      }
    })      
  }

  continue(){
    const prices = this.prices.filter(p => p.isSelected === true);
    if(prices.length==1){
        const zipCode = this.issue_state.fullState.pricingState.initialRequest.Asegurado.CodigoPostal
        this.redirector.redirectToEmission(prices[0].IdCotizacionPlan);
        this.issue_state.getEmissionInfo(prices[0].IdPlan,zipCode)
        return
    }
    else{
      const error = prices.length == 0 ? LABELS.SELECT_AT_LEAST_PLAN : LABELS.SELECT_SINGLE_PLAN;
      this.showMessagePopUp('', [LABELS.ATTENTION, error], PopUpTypesEnum.warning)
    }
  }

  onOptionClick(option : any){
    if(!option.selected){
      this.priceConfig?.multiOptions?.forEach(m => {
        m.selected = false;
      })

      option.selected = true;

      this.prices.forEach(p => {p.isSelected = false});
    }
  }


  setFilterOptions(prices : PricedPlanModel[]){
    // Esto es ineficiente, pero vamos a tener 20 planes como MUCHISIMO
    const companies : ISelectItem[] = [];
    const plans : ISelectItem[] = [];
    prices.forEach(p => {
      if(!companies.find(c => c.id === p.IdCompania.toString()))
        companies.push({id: p.IdCompania.toString(), descripcion: p.Compania ?? ""});

      if(!plans.find(c => c.id === p.IdPlan.toString()))
        plans.push({id: p.IdPlan.toString(), descripcion : p.Plan});
    })
    
    const filter : PricesFilterModel[] = [
      { id:'COMPANY', descripcion : LABELS.FILTERS_COMPANY, opciones : companies, field: 'IdCompania'},
      { id:'PLAN', descripcion : LABELS.PLAN, opciones : plans, field: 'IdPlan'},
    ]

    return filter;
  }

  showMessagePopUp(title : string, messages : string[], type : PopUpTypesEnum = PopUpTypesEnum.success){
    this.popupData={
      component:PopupMessageComponent,
      config : {
        title : title,
        hideHeader : true
      },
      
      data : {
        type : type,
        messages : messages
      }
    };
    this.popupSvc.show(this.popupData)
  }
}
