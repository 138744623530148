import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { Subscription } from 'rxjs';
import { LABELS } from 'src/app/main/constants/labels.enum';
import { User } from 'src/app/main/models/user.model';
import { AppState } from 'src/app/state/app/app.state';
import { LoginState } from 'src/app/state/login/login.state';
import { StateHandlerService } from 'src/app/state/state-handler.service';

@Component({
  selector: 'app-navbar',
  templateUrl: './navbar.component.html',
  styleUrls: ['./navbar.component.scss']
})
export class NavbarComponent {
  
  @Output() menuClicked = new EventEmitter<any>();
  readonly welcome : string = LABELS.WELCOME;

  user? : User;
  enterpriseId? : string;
  userNameObs : Subscription | undefined;
  constructor(private stateHandler : StateHandlerService,
    private appState : AppState,
    private loginState: LoginState,
  ) {    
    this.user = appState.getLoginState().user;
    this.enterpriseId = this.stateHandler.getAppState().getLogo();
    
    this.userNameObs = loginState.getUserObs().subscribe({
      next : (user : any) => {
        if(user)
          this.user = user;
      }
    }); 
  }

  toggleMenu(){
    this.menuClicked.emit();
  }


}
