import { Component, OnDestroy, OnInit } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { NavigationEnd, Router } from '@angular/router';
import { Subscription, filter } from 'rxjs';
import { LABELS } from 'src/app/main/constants/labels.enum';
import { FaqCategory } from 'src/app/main/models/issue/tips.model';
import { ConsultantService } from 'src/app/main/services/consultant.service';


@Component({
  selector: 'app-insurance-question',
  templateUrl: './insurance-question.component.html',
  styleUrls: ['./insurance-question.component.scss']
})
export class InsuranceQuestionComponent implements OnInit, OnDestroy{
  private routerSubscription?: Subscription;
  readonly faq_title=LABELS.INSURANCE_QUESTIONS_FREQUENT

  readonly click_here = LABELS.CLICK_HERE;

  companies_section = [
    LABELS.P_PROVIDERS_FOOTER11,
    LABELS.P_PROVIDERS_FOOTER12,
    LABELS.P_PROVIDERS_FOOTER13,
    LABELS.P_PROVIDERS_FOOTER14,
    LABELS.P_PROVIDERS_FOOTER15,
    LABELS.P_PROVIDERS_FOOTER16,
    LABELS.P_PROVIDERS_FOOTER21,
    LABELS.P_PROVIDERS_FOOTER22,
    LABELS.P_PROVIDERS_FOOTER23,
    LABELS.P_PROVIDERS_FOOTER24,
    LABELS.P_PROVIDERS_FOOTER25,
    LABELS.P_PROVIDERS_FOOTER31,
    LABELS.P_PROVIDERS_FOOTER32,
    LABELS.P_PROVIDERS_FOOTER33,
    LABELS.P_PROVIDERS_FOOTER34,

    LABELS.P_PROVIDERS_FOOTER41,
    LABELS.P_PROVIDERS_FOOTER42,
    LABELS.P_PROVIDERS_FOOTER43,
    LABELS.P_PROVIDERS_FOOTER44,
  ]

  faqs:FaqCategory[]=[];
  constructor(private consultantProposal:ConsultantService,private router: Router,
    private domSanitizer : DomSanitizer
  ){
  }
  ngOnInit(): void {
    
    this.fetchFaqs();
    this.routerSubscription = this.router.events.pipe(
      filter(event => event instanceof NavigationEnd)
    ).subscribe(() => {
      this.fetchFaqs();
    });
  }

  toggleCategory(categoryIndex: number) {
    const category = this.faqs[categoryIndex];
  
    // Cierra todas las demás categorías
    this.faqs.forEach((cat, index) => {
      if (index !== categoryIndex) {
        cat.Open = false;
      }
    });
  
    // Alterna la categoría seleccionada
    category.Open = !category.Open;
  
    // Opcional: Cierra todas las preguntas si se cierra la categoría
    if (!category.Open) {
      category.Preguntas.forEach(faq => faq.Open = false);
    }
  }
  
  toggleAnswer(categoryIndex: number, questionIndex: number) {
    const category = this.faqs[categoryIndex];
  
    // Cierra todas las preguntas de la categoría, excepto la seleccionada
    category.Preguntas.forEach((faq, index) => {
      if (index !== questionIndex) {
        faq.Open = false;
      }
    });
  
    // Alterna la pregunta seleccionada
    category.Preguntas[questionIndex].Open = !category.Preguntas[questionIndex].Open;
  }

  fetchFaqs(): void {
    const urlSegments = this.router.url.split('/');
    const lastSegment = urlSegments[urlSegments.length - 1];


    const request = { Id: "1", Descripcion: "", Tipo: 1 };

    switch (lastSegment) {
      case 'quoting-vehicle':
      case 'quoting-vehicle-plans':
        request.Descripcion = "JRN_002";
        break;
      case 'insurance':
      case 'user':
      case 'inicio':
        request.Descripcion = "JRN_001";
        break;
      case 'partnership':
        request.Descripcion = "JRN_001";
        return; 
      case 'pet':
        request.Descripcion = "JRN_012";
        break;
      case 'dental':
        request.Descripcion = "JRN_013";
        break;
      case 'payment':
      case 'summary':
      case 'risk_location':
      case 'driver':
      case 'vehicle':
      case 'personal_data':
      case 'confirmation':
        request.Descripcion = "JRN_003";
        break;
      default:
        request.Descripcion = "JRN_001"; 
        break;
    }
    this.consultantProposal.listFaqs(request).subscribe({
      next: (data) => {
        if (data.IsSuccess) {
          this.faqs = data.Result.map((response: any) => ({
            Open: false,
            Categoria: response.Categoria,
            Preguntas: response.Preguntas.map((faq: any) => ({
              Id: faq.Pregunta,
              Descripcion: this.domSanitizer.bypassSecurityTrustHtml(faq.Respuesta),
              Open: false,
              Orden:faq.Orden
            }))
          }));
          
        }
      },
      error: (err) => {
        // Manejo de errores
      }
    });
  }

  ngOnDestroy(): void {
    this.routerSubscription?.unsubscribe();
  }
}
