<div class="col-12">
  <p tabindex="0" class="ps-2 text-white pointer back" (click)="back()" (keypress)="back()"> < {{back_dicas}}</p>
</div>
<div class="header detail-header" style="background-color: rgb(7, 12, 37);">
  <div class="text-content">
    <h1>{{ detailTip!.Titulo }}</h1>
    <p>{{ detailTip!.Parrafo }}</p>
  </div>
  <div class="image-content" style="background-image: url('/assets/tips/content {{idTip}}.jpg');"></div>
</div>
<div class="description-section">
  <div [innerHTML]="detailTip!.Descripcion"></div>
</div>
<div class="footer">
  <p>{{ detailTip!.Texto }}</p>
</div>
