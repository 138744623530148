import { ElementRef, NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { MaterialModule } from './shared/material/material.module';
import { HeaderComponent } from './main/header/header.component';
import { FormsModule } from '@angular/forms';
import { SharedModule } from './shared/shared.module';
import { LandingComponent } from './pages/landing/landing.component';
import { LoginComponent } from './pages/login/login.component';
import { HTTP_INTERCEPTORS, HttpClient, HttpClientModule } from '@angular/common/http';
import { FullCoverCardComponent } from './shared/full-cover-card/full-cover-card.component';
import { RegisterComponent } from './pages/register/register.component';
import { ResetPasswordComponent } from './pages/reset-password/reset-password.component';
import { InterceptorService } from './main/services/interceptor.service';
import { ValidateComponent } from './pages/validate/validate.component';
import { SideMenuComponent } from './features/dashboard/side-menu/side-menu.component';
import { ProductsListComponent } from './features/dashboard/products-list/products-list.component';
import { RedirectComponent } from './pages/redirect/redirect.component';
import { SpinnerComponent } from './main/spinner/spinner.component';
import { ProposalListComponent } from './features/dashboard/proposal-list/proposal-list.component';
import { ProposalCardComponent } from './features/dashboard/proposal-card/proposal-card.component';
import { RecoverComponent } from './pages/recover/recover.component';
import { HomeModule } from './pages/home/home.module';
import { AppState } from './state/app/app.state';
import { IssueState } from './state/issue/issue.state';
import { LoginState } from './state/login/login.state';
import { IssueInsuranceState } from './state/issue/issue-insurance.state';
import { CallCenterLoginComponent } from './pages/call-center-login/call-center-login.component';
import { DatePipe } from '@angular/common';

@NgModule({
  declarations: [
    AppComponent,
    HeaderComponent,
    LandingComponent,
    LoginComponent,
    FullCoverCardComponent,
    RegisterComponent,
    ResetPasswordComponent,
    ValidateComponent,    
    SideMenuComponent,
    RedirectComponent,
    SpinnerComponent,
    RecoverComponent,
    CallCenterLoginComponent
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    BrowserAnimationsModule,
    MaterialModule,
    SharedModule,
    FormsModule,    
    HttpClientModule,
    HomeModule
  ],
  providers: [
    {
      provide: HTTP_INTERCEPTORS,
      useClass: InterceptorService,
      multi: true
    },
    AppState, IssueState, LoginState ,IssueInsuranceState,   
    HttpClient ,
    DatePipe
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
