import { Component } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { ButtonStylesEnum, ButtonTypesEnum } from 'src/app/main/constants/buttons.enum';
import { ERRORS } from 'src/app/main/constants/errors.enum';
import { InputTypesEnum } from 'src/app/main/constants/inputs-types.enum';
import { LABELS } from 'src/app/main/constants/labels.enum';
import { IActionsModel } from 'src/app/main/models/actions.model';
import { CoverCardModel } from 'src/app/main/models/cover-card.model';
import { IRegisterModel } from 'src/app/main/models/auth/register.model';
import { AuthenticatorService } from 'src/app/main/services/authenticator.service';

import { RedirectorService } from 'src/app/main/services/redirector.service';
import { TranslationsService } from 'src/app/main/services/translations.service';
import { ButtonModel } from 'src/app/shared/models/button.model';
import { InputModel } from 'src/app/shared/models/input.model';
import { PasswordConfirmationModel } from 'src/app/shared/models/password-confirmation.model';
import { ModelCreatorService } from 'src/app/shared/services/model-creator.service';
import { AppState } from 'src/app/state/app/app.state';
import { IEmailValidation } from 'src/app/main/models/auth/email-validation.model';


@Component({
  selector: 'app-register',
  templateUrl: './register.component.html',
  styleUrls: ['./register.component.scss']
})
export class RegisterComponent {

  registerForm : FormGroup;

  emailModel : InputModel;
  employeeIdModel : InputModel;
  passwordModel : InputModel;
  passwordConfirmModel : InputModel;

  passwordConfirmationModel : PasswordConfirmationModel
  
  currentStep  = 0;  

  numbersRegex  = /\d+/g;
  lettersRegex  = /[a-z]+/g;
  capsRegex  = /[A-Z]+/g;  
  
  capsAreValid       = false;
  lengthIsValid      = false;
  lettersAreValid    = false;
  numbersAreValid    = false;  
  showRegisterError  = false;

  confirmButtonModel : ButtonModel;

  privacyText = LABELS.PRIVACY_WARN;
  useTerms = LABELS.USE_TERMS;
  privacyPolicies = LABELS.PRIVACY_POLCIES;
  title = LABELS.REGISTER_MYSELF
  passwordError = ERRORS.PASSWORD_DOES_NOT_MATCH;
  registerError : string = ERRORS.COULD_NOT_REGISTER;
  email = LABELS.EMAIL;
  pass = LABELS.PASSWORD;
  conf_pass = LABELS.PASSWORD_CONFIRM;

  coverCardModel : CoverCardModel ={showBackButton : true, useDefaultBack : false };  

  constructor(private modelCreator : ModelCreatorService,
    private authenticator : AuthenticatorService,
    private fb : FormBuilder,
    private router : Router,
    private redirector : RedirectorService,
    private appState : AppState,
    private translations: TranslationsService) {
      this.registerForm = this.fb.group({
        email    : ['', [Validators.required]],
        password : ['', [Validators.required]],
        passwordConfirm : ['', [Validators.required]],
        employeeId : ['']
      });
    
      this.loadTranslations();
      this.emailModel = this.modelCreator.createInputModel(this.email, InputTypesEnum.email, 'email', this.registerForm, ERRORS.EMAIL_INVALID,undefined,true);
      this.passwordModel = this.modelCreator.createInputModel(this.pass, 'password', 'password', this.registerForm, ERRORS.PASSWORD_UNFILLED,undefined,true);
      this.employeeIdModel = this.modelCreator.createInputModel(LABELS.ASOCIATE_NUMBER, InputTypesEnum.common, 'employeeId', this.registerForm, ERRORS.CONTROL_UNFILLED,undefined,true);
      this.passwordConfirmModel = this.modelCreator.createInputModel(this.conf_pass, 'password', 'passwordConfirm', this.registerForm, ERRORS.PASSWORD_UNCONFIRMED);
      this.confirmButtonModel = this.modelCreator.createButtonModel(LABELS.CONTINUE, ButtonTypesEnum.Raised, ButtonStylesEnum.black);
      this.passwordConfirmationModel = this.modelCreator.createPasswordModel(this.passwordModel, 'password', this.registerForm);
    }


  loadTranslations() {
    this.translations.getTranslationsObservable()?.subscribe(translations => {
      if (translations.LABELS) {
        this.privacyText = LABELS.PRIVACY_WARN;
        this.useTerms = LABELS.USE_TERMS;
        this.privacyPolicies = LABELS.PRIVACY_POLCIES;
        this.title = LABELS.REGISTER_MYSELF;
        this.registerError = ERRORS.COULD_NOT_REGISTER;
        this.email = LABELS.EMAIL;
        this.pass = LABELS.PASSWORD;
        this.conf_pass = LABELS.PASSWORD_CONFIRM;
        this.emailModel.placeholder = LABELS.EMAIL;
        this.passwordModel.placeholder = LABELS.PASSWORD;
        this.passwordConfirmModel.placeholder = LABELS.PASSWORD;
      }

      if (translations.ERRORS) {
        this.employeeIdModel.customError = ERRORS.CONTROL_UNFILLED;
        this.emailModel.customError = ERRORS.EMAIL_INVALID;
        this.passwordModel.customError = ERRORS.PASSWORD_UNFILLED;
        this.passwordError = ERRORS.PASSWORD_DOES_NOT_MATCH;
        this.registerError = ERRORS.COULD_NOT_REGISTER;
      }
    });
  }

  nextStep(step : number){    
    this.cleanError();
    if(step === 0){
      
      if(this.registerForm.controls['email'].invalid || !this.registerForm.value.email){  
       return this.registerForm.get('email')?.markAllAsTouched();              
      }

      if(this.registerForm.controls['employeeId'].invalid || !this.registerForm.value.employeeId){  
        return this.registerForm.get('employeeId')?.markAllAsTouched();
      }        
    
      const currentSignUp : IEmailValidation = {
        Username : this.registerForm.value.email.toLowerCase(),
        Referencia : this.registerForm.value.employeeId 
      }
      this.authenticator.isEmailRegistrable(currentSignUp).subscribe({
        next  : (data) => {
          if(data.IsSuccess){

            this.confirmButtonModel.text = LABELS.REGISTER_MYSELF;
            this.currentStep++;
            return;
          }

          this.selectErrorAction(data.Code)          
        },
        error : (err) => {
          this.showError();

        }
      })          
      return;
    }

    else if(step === 1){
      this.checkPasswordConstraints()
      if(this.registerForm.controls['password'].invalid || this.registerForm.controls['passwordConfirm'].invalid )
      {
        this.registerForm.get('password')?.markAllAsTouched();
        this.registerForm.get('passwordConfirm')?.markAllAsTouched(); 
        return 
      }
      if(!this.lengthIsValid || !this.capsAreValid || !this.numbersAreValid || !this.lettersAreValid)
        return;
          

      this.currentStep++;

      this.submit();
    }

  }

  checkPasswordConstraints(){    
    const password = this.registerForm.controls['password'].value;
    
    this.lengthIsValid = password.length > 8;
    this.lettersAreValid = this.testRegex(this.lettersRegex, password);
    this.numbersAreValid = this.testRegex(this.numbersRegex, password);
    this.capsAreValid = this.testRegex(this.capsRegex, password);
  }

  showError(msg  = ""){
    this.registerError = ERRORS.COULD_NOT_REGISTER;
    if(msg)
      this.registerError = msg;
      
    
    this.showRegisterError = true;
  }

  cleanError(){
    this.showRegisterError = false;
    this.registerError = ERRORS.COULD_NOT_REGISTER;
  }


  testRegex(regex : RegExp, pattern : string){
    
    let result = regex.test(pattern);
    if(!result) // Si dio false puede ser un error de la regex, lo hacemos de nuevo por las dudas
      result = regex.test(pattern);
    
    return result;
  }

  submit(){
    
    this.cleanError();
    this.checkPasswordConstraints();

    if(this.registerForm.invalid || !this.lengthIsValid || !this.lettersAreValid || !this.numbersAreValid)
      return;

    const user : IRegisterModel = {
      Username : this.registerForm.value.email,
      Password : this.registerForm.value.password,
      IdEmpresa : this.appState.getEnterpriseId(),
      NroDocumento : this.registerForm.value.document,
      Referencia: this.registerForm.value.employeeId
    }

    this.authenticator.registerUser(user).subscribe({
      next  : (data) => {
        if(data && data.IsSuccess){
          this.redirector.redirectToValidation(user, true, 'register');
        }
        else{
          this.showRegisterError = true;
        }
      },
      error : (err) => {        
        this.showRegisterError = true;
      }
    }      
    );
  }


  selectErrorAction(code : number){

    const actions : IActionsModel[] = [
      {code : 100,
        func : () => {
          this.showError(ERRORS.EMAIL_ALREADY_REGISTERED);
          return;
        }
      },
      {
        code : 102,
        func : () => {
          this.showError(ERRORS.EMAIL_NEEDS_VALIDATION);
          setTimeout(() => {
            this.redirector.redirectToValidation({Username : this.registerForm.value.email, Password : "", IdEmpresa: this.appState.getEnterpriseId(), Referencia : this.registerForm.value.employeeId }, false, 'register')  
          }, 2500);
          return;
        }
      }

    ];

    const currentAction = actions.find(a => a.code === code) 
    if(currentAction){
      currentAction.func.call(this);
      return;
    }

    this.showError();
  }


  navigateBack(){
    if(this.currentStep === 0 ){
      this.redirector.redirectToLanding();
    }
    else if(this.currentStep === 1){
      this.cleanError();
      this.registerForm.reset();
      this.currentStep = 0;
    }
    else{
      this.currentStep = 0;
    }
  }
}
