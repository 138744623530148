import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, Subject } from 'rxjs';
import { environment } from 'src/environments/environment';
import { IGenericResponseModel } from '../models/generic-response.model';
import { IRegisterModel } from '../models/auth/register.model';
import { CodeValidationModel } from '../models/code-validation.model';
import { RedirectorService } from './redirector.service';
import { LoginState } from 'src/app/state/login/login.state';
import { AppState } from 'src/app/state/app/app.state';
import { IEmailValidation } from '../models/auth/email-validation.model';


@Injectable({
  providedIn: 'root'
})
export class AuthenticatorService {

  private readonly base_url = environment.authentication_url;

  public token  = "";
  
  constructor(private http : HttpClient,
    private redirector : RedirectorService,
    private loginState : LoginState,
    private app_state : AppState
  ) { }

  setToken(token : string){
    this.token = token;
  }

  login(user : string, password: string, enterpriseId : number, campaignId : number) : Observable<IGenericResponseModel>{      
    return this.http.post<IGenericResponseModel>(this.base_url + '/Login', {Username: user, Password : password, IdEmpresa : enterpriseId, IdCampania : campaignId});
  }  

  loginMicrosoft(enterpriseId : number){
    window.location.href = this.base_url + '/LoginMicrosoft?idEmpresa=' + enterpriseId.toString();
  }

  loginGoogle(enterpriseId : number){
    window.location.href = this.base_url + '/LoginGoogle?idEmpresa=' + enterpriseId.toString();
  }

  changePassword( model :CodeValidationModel){
    return this.http.post<IGenericResponseModel>(this.base_url + '/Change', model);    
  }

  isLogged(){
    return this.token != "" && this.token.length > 1;
  }

  logout(redirect  = true){    
    this.setToken('');
    
    this.app_state.setIsCallCenter(this.loginState.user?.isCallCenter || false) 
    
    this.app_state.clearAppState();    

    if(redirect)
      this.redirector.redirectToLogin();
  }

  isEmailValid(email : string) : Observable<any>{
    return this.http.post(this.base_url + '/validarEmail', email);
  }

  isEmailRegistrable(validation : IEmailValidation){ 
    return this.http.post<IGenericResponseModel>(this.base_url + '/ValidarEmailRegistro', validation);
  }

  registerUser(form : IRegisterModel){
    return this.http.post<IGenericResponseModel>(this.base_url + '/Register', form);
  }

  isCodeValid(user : IRegisterModel){
    return this.http.post<IGenericResponseModel>(this.base_url + '/validarCodigoRegister', user);
  }

  resendCode(email : string){
    return this.http.post<IGenericResponseModel>(this.base_url + '/ReenviarCodigoRegister', email);
  }

  getUserCall(email : string){
    return this.http.post<IGenericResponseModel>(this.base_url + '/CallCenter', email);
  }

}
