import { Component, Input, OnChanges, SimpleChanges } from '@angular/core';
import { PopupOpenerService } from '../services/popup-opener.service';
import { FormGroup } from '@angular/forms';
import { ModelCreatorService } from '../services/model-creator.service';
import { InputModel } from '../models/input.model';
import { LABELS } from 'src/app/main/constants/labels.enum';
import { InputTypesEnum } from 'src/app/main/constants/inputs-types.enum';
import { ButtonStylesEnum, ButtonTypesEnum } from 'src/app/main/constants/buttons.enum';
import { ButtonModel } from '../models/button.model';
import { LocatorService } from 'src/app/main/services/issue/locator.service';
import { ERRORS } from 'src/app/main/constants/errors.enum';
import { DropdownCustomModel } from '../models/dropdown.model';
import { Masks } from '../enums/masks.enum';
import { IssueState } from 'src/app/state/issue/issue.state';

@Component({
  selector: 'app-new-home',
  templateUrl: './new-home.component.html',
  styleUrls: ['./new-home.component.scss']
})
export class NewHomeComponent implements OnChanges {
    
  @Input() form? : FormGroup;
  @Input() onSave? : () => void;
  @Input() regions : any[] = [];
  @Input() isNumberRequired  = true;
  @Input() disabled  = true;
  districts : any[] = [];
  
  streetModel? : InputModel;
  internalNumberModel? : InputModel;
  externalNumberModel? : InputModel;
  floorModel? : InputModel;
  zipCodeModel? : InputModel;
  cityModel? : DropdownCustomModel;
  regionModel? : DropdownCustomModel;
  districtsModel? : DropdownCustomModel;

  btnSave : ButtonModel;
  showInputs  = false;
  showError  = false;
  msgError  = "";
  showCombos  = false;

  constructor(private popupOpener : PopupOpenerService,
    private modelCreator : ModelCreatorService,
    private locator : LocatorService,
    private issueState : IssueState
  ) {

      this.btnSave = this.modelCreator.createButtonModel(LABELS.SAVE, ButtonTypesEnum.Common, ButtonStylesEnum.black, false);
        
  }
  ngOnChanges(changes: SimpleChanges): void {
    if(this.form && this.regions.length > 0){
      const regions = this.regions.map((r : any) => {
        if(r.Id)
          return {id : r.Id, descripcion : r.Descripcion}
        
        return r;
      })

      const districts = this.districts?.map((r : any) => {
        if(r.Id)
          return {id : r.Id, descripcion : r.Descripcion}
        
        return r;
      })
      
      this.createInputModels(regions,districts);
      if(this.form.controls['municipio'].valid){
          this.form.controls['municipio'].patchValue(this.form.controls['municipio'])
      }
      if(this.form.controls['codigoPostal'].value && (this.form.controls['codigoPostal'].valid || this.form.controls['codigoPostal'].disabled))
        this.checkPostalCode();
    }
  }

  createInputModels(regions : any,districts : any){
    this.zipCodeModel = this.modelCreator.createInputModel(LABELS.ZIPCODE, InputTypesEnum.mask, 'codigoPostal', this.form!, undefined, undefined, true, 9, false);    
    this.zipCodeModel!.disabled=!this.isNumberRequired
    this.zipCodeModel.mask = Masks.zip_code;
    this.zipCodeModel!.disabled=this.disabled
    this.streetModel = this.modelCreator.createInputModel(LABELS.STREET, InputTypesEnum.common, 'calle', this.form!, ERRORS.CONTROL_UNFILLED, undefined, this.isNumberRequired, 64, false, false);
    this.internalNumberModel = this.modelCreator.createInputModel(LABELS.NUMBER+" Interno", InputTypesEnum.common, 'numeroInterno', this.form!,ERRORS.CONTROL_UNFILLED, undefined,false, 24, false, false); 
    this.externalNumberModel = this.modelCreator.createInputModel(LABELS.NUMBER+" Externo", InputTypesEnum.common, 'numeroExterno', this.form!, ERRORS.CONTROL_UNFILLED, undefined, this.isNumberRequired, 24, false, false);    
    
    // this.internalNumberModel.disabled=this.disabled;
    // this.externalNumberModel.disabled=this.disabled;
    this.floorModel = this.modelCreator.createInputModel(LABELS.COMPLEMENTARY, InputTypesEnum.common, 'pisodepto', this.form!, undefined, undefined, false, 50);    
    
    this.cityModel = this.modelCreator.createSelectModel('municipio', LABELS.CITY, [], this.form!, undefined, undefined, true , undefined, true);
    this.cityModel.outsideLabel = LABELS.CITY;    
    
    this.regionModel = this.modelCreator.createSelectModel('estado', LABELS.REGION, regions ?? [], this.form!, undefined, undefined, true, false, true);
    this.regionModel.outsideLabel = LABELS.REGION;

    this.enableOrDisableControls(true);
    this.showInputs = true;
  }

  getCities(){
    if(!this.form?.value?.region?.id || this.regionModel?.disabled)
      return;

    this.locator.getCities(this.form?.value.region.id).subscribe({
      next : (data : any) => {
        if(data.IsSuccess)
          this.cityModel!.options = data.Result.map((c : any) => { return {id : c.Id, descripcion : c.Descripcion}});
      },
      error : () => {
        // mensajito? 
      }

    })
  }

  checkPostalCode(){
    if(!this.form?.controls['codigoPostal'].value || (this.form.controls['codigoPostal'].invalid && !this.form.controls['codigoPostal'].disabled))
      return;
    const cp = this.form?.controls['codigoPostal'].value.replaceAll("-", "")
    this.showError = false;
    this.enableOrDisableControls(true);

    const idCia       = this.issueState.fullState.emissionDataState.EmissionInfo?.IdCompania; 
    const idProducto  = this.issueState.fullState.emissionDataState.EmissionInfo?.IdProducto;
    if(!idCia || !idProducto){
      console.log("Home error")
      return;
    }
      
    this.locator.getDomicileByPostalCode(cp, idCia.toString(), idProducto.toString()).subscribe({
      next : (data : any) => {
        
        
        if (!data.IsSuccess || !data?.Result?.IdCiudad) {
          this.showCombos = false;
          this.enableOrDisableControls(false);
          return;
        }

        const address = {
          calle: data.Result.Calle,
          colonia:this.districts.length== 0 ? data.Result.Distrito:'',
          idCiudad: data.Result.IdCiudad,
          estado: this.regionModel?.options?.find((r: any) => +r.id == data.Result.IdRegion),
          municipio: this.cityModel?.options?.find(c => +c.id == data.Result.IdCiudad)
        }

        if (!address.estado) {
          const region = { id: data.Result.IdRegion?.toString(), descripcion: data.Result.Region }
          this.regionModel?.options?.push(region);
          address.estado = region;
        }

        if (!address.municipio) {
          this.cityModel!.options = [];
          const city = { id: data.Result.IdCiudad?.toString(), descripcion: data.Result.Ciudad }
          this.cityModel?.options?.push(city)
          address.municipio = city;
        }

        if(data.Result.Colonias){
          const districts = data.Result.Colonias?.map((r : any) => {
            if(r.Id)
              return {id : r.Id, descripcion : r.Descripcion}
           
            return r;
          })
          this.districtsModel = this.modelCreator.createSelectModel('colonia', LABELS.DISTRICT, districts ?? [], this.form!, undefined, undefined, true, false, false,LABELS.SELECT_ERROR);
          this.districtsModel.outsideLabel = LABELS.DISTRICT;
        }

        this.form?.patchValue(address);
      },
      error : () => {
        // mensajito? 
        this.enableOrDisableControls(false)
      }

    })
  }

  enableOrDisableControls(disabled : boolean){
    this.cityModel!.disabled = disabled;
    this.regionModel!.disabled = disabled;    
  }
}
