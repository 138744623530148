import { Component, Input } from '@angular/core';
import { ButtonTypesEnum } from 'src/app/main/constants/buttons.enum';
import { LABELS } from 'src/app/main/constants/labels.enum';
import { ButtonModel } from 'src/app/shared/models/button.model';

@Component({
  selector: 'app-insurance-ad',
  templateUrl: './insurance-ad.component.html',
  styleUrls: ['./insurance-ad.component.scss']
})
export class InsuranceAdComponent {
  
  readonly title:string=LABELS.INSURANCE_AD_TITLE;
  body:string=LABELS.INSURANCE_AD_BODY;

  btnBegin : ButtonModel = new ButtonModel();
  icon="fa-solid fa-chevron-right";

  constructor(){
    this.btnBegin = {
      text: LABELS.INSURANCE_AD_BUTTON,
      classes : 'btn btn-primary bg-btn btn-primary text-white bg-dark',
      type:ButtonTypesEnum.Common
    }
  }

}
