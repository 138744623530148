import { Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import { LABELS } from 'src/app/main/constants/labels.enum';

@Component({
  selector: 'detail-button',
  templateUrl: './detail-button.component.html',
  styleUrls: ['./detail-button.component.scss'],
})
export class DetailButtonComponent {
  @Input() title: string | undefined;
  @Input() showDetailBox: boolean | undefined;
  @Input() type: string | undefined;
  @Input() isRenewal: boolean | undefined = false;

  readonly download_documentation = LABELS.DOWNLOAD_DOCUMENTACION;
  readonly carrier_data = LABELS.CARRIER_DATA;
  readonly quote_other_companies = LABELS.QUOTE_OTHER_COMPANIES;
  readonly accept_renewal = LABELS.ACCEPT_RENEWAL;
  readonly download_proposal = LABELS.DOWNLOAD_PROPOSAL;
  readonly titleText = LABELS.SEE_PROPOSAL_DETAIL; 

  @Output() customClick = new EventEmitter<number>();

  emitClick(detail: number) {
    this.customClick.emit(detail);
  }
}
