import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SharedModule } from 'src/app/shared/shared.module';
import { DashboardModule } from '../../dashboard/dashboard.module';
import { CalendarModule } from 'primeng/calendar';
import { IssueResidentialComponent } from './issue-residential.component';
import { IssueRiskComponent } from 'src/app/features/issue/issue-risk/issue-risk.component';
import { IssueProtectionSystemComponent } from 'src/app/features/issue/issue-protections-system/issue-protections-system.component';
import { IssueRenewalResidentialComponent } from 'src/app/features/issue/issue-renewal-residential/issue-renewal-residential.component';







@NgModule({
  declarations: [
    IssueResidentialComponent,
    IssueRiskComponent,
    IssueProtectionSystemComponent,
    IssueRenewalResidentialComponent
  ],
  imports: [
    CommonModule,
    SharedModule,
    DashboardModule,
    CalendarModule,
  ]
})
export class IssueResidentialModule { }
