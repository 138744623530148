<div class="col-12">
    <div class="col-12 pt-3 d-flex align-items-center">
        <p class="fs-4 text-left title-font-white">
            <span class="underline-wide title-font-white">{{ my_proposals.slice(0, 5) }}</span>{{ my_proposals.slice(5) }}
        </p>
    </div>
    <div *ngIf="proposals.length > 0">
        <div class="mb-4">
            <proposal-card class="h-100"></proposal-card>
        </div>
        <div *ngIf="proposals.length > 0">
            <div *ngFor="let proposal of proposals" class="col-12 overflow-x-scroll h-100">
                <proposal-card class="h-100" [model]="proposal"></proposal-card>
            </div>
        </div> 
    </div>   
    <div class="container-spinner" *ngIf="proposals.length === 0">
        <div class="col-12 d-flex justify-content-center align-items-center  w-90 h-75" *ngIf="!showMessage">
            <span class="loader"></span>
        </div>
        <div class="col-10 col-sm-10 card card-spinner-error" *ngIf="showMessage">
          <div class="col-12 text-center error-content">
            <img [src]="'/assets/icons/' + 'search.svg'" class="header-icon" alt="icon">
            <p class="fs-6 error-title text-secondary">{{proposal_list_error1}}</p>
            <p class="fs-7 text-secondary mb-4">{{proposal_list_error2}}</p>   
            <button class="retry-button" (click)="goDasboard()">{{go_quote}}</button>
          </div>
        </div>
      </div> 
</div>