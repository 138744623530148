<div class="h-100 w-100">
    <img alt="partnership-1" src="/assets/partnership/first-section.png" height="auto" width="100%">
    
    <div class="second-section fs-5">
        <div class="ps-5 margin-aon text-black">
            <p>{{second_section}}</p>
        </div>
    </div>

    <div class="third-section bg-white">
        <p class="fs-2 fw-bold text-black"> {{third_section.title}}</p>
        
        <p class="fw-light py-2" *ngFor="let p of third_section.paragraphs">
            <span class="fw-bold text-black">{{p.question}} </span>
            <br>
            <span class="">{{p.content}}</span>
        </p>

    </div>

    <div class="third-plus-section bg-white">
        <div class="fw-bold text-black">
            <p>{{companies_section[0]}}</p>
        </div>
    
        <div class="">
            <p>{{companies_section[1]}}</p>
        </div>
    
        <div class="fw-bold text-black pt-5">
            <p>{{companies_section[2]}}</p>
        </div>
    
        <div class="mb-5">
            <p>{{companies_section[3]}}</p>
        </div>
    
        <div class="fw-bold text-black">
            <p>{{companies_section[4]}}</p>
        </div>
    
        <div class="mb-5">
            <p>{{companies_section[5]}}</p>
        </div>
    
        <div class="fw-bold text-black">
            <p>{{companies_section[6]}}</p>
        </div>
    
        <div class="mb-5">
            <p>{{companies_section[7]}}</p>
        </div>
    
        <div class="fw-bold text-black">
            <p>{{companies_section[8]}}</p>
        </div>
    
        <div class="">
            <p>{{companies_section[9]}}</p>
        </div>
    
        <div class="">
            <p>{{companies_section[10]}}</p>
        </div>

        <div class="fw-bold text-black">
            <p>{{companies_section[11]}}</p>
        </div>
    
        <div class="">
            <p>{{companies_section[12]}}</p>
        </div>
    
        <div class="">
            <p>{{companies_section[13]}}</p>
        </div>

        <div class="fw-bold text-black">
            <p>{{companies_section[14]}}</p>
        </div>
    
        <div class="">
            <p>{{companies_section[15]}}</p>
        </div>
    
    </div>


    <div class="fourth-section bg-white px-5">
        <p class="fs-2 text-black fw-bold fs-5 ms-5 ps-4">{{fourth_section.title}}</p>

        <div class="d-flex col-12 gap-2 justify-content-evenly">
            <div *ngFor="let subsection of fourth_section.advantages; index as i" class="col-5">
                <img alt="partnership-2" [src]="'/assets/partnership/advantage-'+ (i + 1).toString() +'.jpg'"
                    class="advantage-image">
    
                <p class="advantage-title pt-2 pb-1 fw-bold">{{subsection.title}}</p>
                <p class="advantage-content">{{subsection.content}}</p>
            </div>
        </div> 
    </div>


    <div class="fifth-section bg-white p-5">
        <div class="d-flex col-12 gap-2 justify-content-evenly">
            <div *ngFor="let subsection of fifth_section.advantages; index as i" class="col-5">
                <img alt="partnership-3" [src]="'/assets/partnership/advantage-'+ (i + 3).toString() +'.jpg'"
                    class="advantage-image">
    
                <p class="advantage-title pt-2 pb-1 fw-bold">{{subsection.title}}</p>
                <p class="advantage-content">{{subsection.content}}</p>
            </div>
        </div> 
    </div>

</div>
