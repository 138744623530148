import { Component, OnInit } from '@angular/core';
import { AbstractControl, FormBuilder, FormGroup } from '@angular/forms';
import { EmissionValidStepsEnum } from 'src/app/main/models/emission/emission-valid-steps.enum';
import { FreeDataModel } from 'src/app/main/models/emission/free-data.model';
import { EmissionFormHandlerService } from 'src/app/main/services/issue/emission-form-handler.service';
import { DropdownCustomModel } from 'src/app/shared/models/dropdown.model';
import { InputModel } from 'src/app/shared/models/input.model';
import { IssueState } from 'src/app/state/issue/issue.state';

@Component({
  selector: 'app-emission-free-data',
  templateUrl: './emission-free-data.component.html',
  styleUrls: ['./emission-free-data.component.scss']
})
export class EmissionFreeDataComponent {

  freeData : FreeDataModel[] | undefined | null;
  freeDataForm : AbstractControl | null;
  parentGroup : FormGroup;
  
  inputModels : any[] = [];


  constructor(private formHandler : EmissionFormHandlerService,
    private fb : FormBuilder,
    private issueState : IssueState
  ) {
    this.parentGroup = formHandler.getForm();
    this.freeDataForm = formHandler.getFormControl(EmissionValidStepsEnum.free_data);
  }

  createDynamicForm(fields : FreeDataModel[]){
    const formGroup = this.fb.group({});
    
    fields.forEach(f => {
      if(f.id){
        formGroup.addControl(f.id.toString(), this.fb.control(''))
        
        formGroup.get(f.id.toString())?.setValue(f.default);      
        let model : any = null;
        if(f.control === "TXT"){
          model = this.createTextModel(f, formGroup);
        }
        else if(f.control === "CMB"){
          model = this.createDropdownModel(f, formGroup);
          model.descripcion = f.descripcion;
        }
        model.control = f.control;

        if(model)
          this.inputModels.push(model);
      }
    })
    this.freeDataForm = formGroup;
  }

  createTextModel(f : FreeDataModel, form : FormGroup) : InputModel{
    return {
      parentGroup : form,
      formControlName : f.id?.toString(),
      required : !f.nullable,
      disabled : false,
      max : Number(f.maximo) ?? 0,
      min : Number(f.minimo) ?? 0,
      maxLength : f.longitudMaxima ?? 0,
      minLength : 0,
      type : "text",
      placeholder : f.descripcion ?? ""      
    }
  }

  createDropdownModel(f : FreeDataModel, form : FormGroup) : DropdownCustomModel {

    f.valoresPosibles?.map((v :any) => {v.name = v.descripcion})
    return {
        options : f.valoresPosibles as any ,
        //selected : f.default,
        placeholder : 'Seleccione la opción',
        formGroup : form,
        formControlName : f.id?.toString(),
    }
  }

}
