<custom-popup *ngIf="showCoverages"
[show]="true" 
[hiddenHeader]="true"
[hiddenButtons]="true"
[maxSize]="true"
[title]="titleCoverages" 
[componentData]="componentData"
(dismissed)="onCancel()">
</custom-popup>

<div class="col-12">
  <div class="card h-100">
    <div [class.card-header-selected]="data?.isSelected" class="card-header d-flex justify-content-between align-items-center pointer" (keypress)="toggleSelection()"  (click)="toggleSelection()" tabindex="0">
      <div class="w-100 text-center">
        <p class="mb-2 mt-2">{{data?.Plan}}</p>
      </div>
      <input type="checkbox"  class="form-check-input rounded-circle ml-2" [checked]="data?.isSelected" >
    </div>
    <div class="img-container">
      <img alt="Company Image" [src]="'/assets/companies/cia' + data?.IdCompania + '.svg'" class="img-fluidd">
    </div>
    <div class="card-body text-center">
      <p>{{quota_plan}}</p>
      <p><strong>{{data?.Cuota}}</strong></p>
      <p *ngIf="showFranquicia">{{sum_insured_plan}}</p>
      <!-- <p *ngIf="showFranquicia"><strong>{{data?.SumaAsegurada}}</strong></p> -->
      <p *ngIf="showFranquicia"><strong>{{ showFranquicia ? (data?.SumaAsegurada || '&nbsp;') : '&nbsp;' }}</strong></p> <!--ESTOS CARACTERES &nbsp; SON PARA QUE QUEDE UN ESPACIO EN BLANCO EN EL HTML-->
      <div class="text-center mb-4 mt-2 col-8">
        <custom-button [model]="btnDetail!" (customClick)="showDetail()"></custom-button>
      </div>
    </div>

  </div>
</div>

