import { AfterViewInit, Component, ComponentFactoryResolver, ComponentRef, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges, ViewChild, ViewContainerRef } from '@angular/core';
import { LABELS } from 'src/app/main/constants/labels.enum';

@Component({
  selector: 'custom-popup',
  templateUrl: './custom-popup.component.html',
  styleUrls: ['./custom-popup.component.scss']
})
export class CustomPopUpComponent implements AfterViewInit  {
  @Output() accepted = new EventEmitter<any>();
  @Output() dismissed = new EventEmitter<any>();

  @Input() componentData: any;
  @Input() show: boolean|undefined;
  @Input() title:string |undefined;
  @Input() hiddenButtons:boolean|undefined;
  @Input() hiddenHeader:boolean|undefined;
  @Input() maxSize:boolean|undefined;
  @Input() buttonText : string | undefined = LABELS.SAVE;

  @ViewChild('componentContainer', { read: ViewContainerRef }) componentContainer!: ViewContainerRef;

  constructor(private resolver: ComponentFactoryResolver) { }

  ngAfterViewInit() {
    if (this.show) {
      this.loadComponent();
    }
  }

  ngOnChanges() {
    if (this.show) {
      this.loadComponent();
    }
  }

  private loadComponent() {
    if (this.componentData && this.componentData.component && this.componentContainer) {
      const componentFactory = this.resolver.resolveComponentFactory(this.componentData.component);
      this.componentContainer.clear();
      const componentRef = this.componentContainer.createComponent(componentFactory);
      this.setComponentVariables(componentRef, this.componentData);      
    }
  }

  private setComponentVariables(componentRef : ComponentRef<unknown>, componentData : any){
    Object.keys(componentData).forEach(data => {
      if(data === 'component')
        return;
      
      if(componentRef.instance)
        (componentRef.instance as any)[data] = this.componentData[data];
    });

    if ((componentRef.instance as any).closePopup) {
      (componentRef.instance as any).closePopup.subscribe(() => {
        this.handleDismiss();
      });
    }
  }

  handleDismiss(){
    this.dismissed.emit();
  }
  
  handleAcept(){
    this.accepted.emit();
}

}