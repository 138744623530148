import { DatePipe } from '@angular/common';
import { Component, OnDestroy, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { Subscription } from 'rxjs';
import { LABELS } from 'src/app/main/constants/labels.enum';
import { QuotingInfoModel } from 'src/app/main/models/emission/quoting-info.model';
import { ClienteProducto, DatosLiberty, IDataDriver, IDataOwner, IInsured, IRenewal, IVehicles, Vehiculo } from 'src/app/main/models/issue/issue-data.model';
import { QuoteInfoModel } from 'src/app/main/models/issue/quote-info.model';
import { ISelectItem, ISelectQuoteItem } from 'src/app/main/models/select-item.model';

import { ConsultantService } from 'src/app/main/services/consultant.service';
import { QuotingFormHandlerService } from 'src/app/main/services/issue/quoting-form-handler.service';
import { RedirectorService } from 'src/app/main/services/redirector.service';
import { PopUpTypesEnum } from 'src/app/shared/enums/popup-types.enum';
import { ButtonModel } from 'src/app/shared/models/button.model';
import { DropdownCustomModel } from 'src/app/shared/models/dropdown.model';
import { PopupMessageComponent } from 'src/app/shared/popup-message/popup-message.component';
import { ModelCreatorService } from 'src/app/shared/services/model-creator.service';
import { PopupOpenerService } from 'src/app/shared/services/popup-opener.service';
import { IssueInsuranceState } from 'src/app/state/issue/issue-insurance.state';
import { IssueState } from 'src/app/state/issue/issue.state';
import { LoginState } from 'src/app/state/login/login.state';
import { States } from 'src/app/state/models/states.enum';

@Component({
  selector: 'quotes-info',
  templateUrl: './quotes-info.component.html',
  styleUrls: ['./quotes-info.component.scss']
})

export class QuotesInfoComponent implements OnInit,OnDestroy{
    readonly back_dashboard=LABELS.BACK_DASHBOARD
    readonly my_quotes= LABELS.MY_QUOTES_LIST;
    readonly my_re_quotes=LABELS.MY_RE_QUOTES
    readonly go_to_plans=LABELS.GO_TO_PLANS
    readonly label_quote = LABELS.LABEL_MY_QUOTE;
    selectedDate: Date | null = null;
  parentGroup: FormGroup |undefined;

  quotingState1$ : Subscription = new Subscription();

  quotingInfo? : QuotingInfoModel;
  quoteInfo? : QuoteInfoModel[];
  quotingInfoSub : Subscription = new Subscription();

  step = 0;
  steps: any[] = [];

  quotes?: DropdownCustomModel;
  selectQuote: ISelectQuoteItem[] |undefined;
  popupData : any;
  
  constructor(
    private popupSvc : PopupOpenerService, 
    private formHandler : QuotingFormHandlerService,    
    private redirector : RedirectorService,
    private fb: FormBuilder,
    private issue_state : IssueState,
    private login_state : LoginState,
    private modelCreator : ModelCreatorService,
    private datePipe: DatePipe){

      this.issue_state.getQuoteInfo();
  }  


  loadQuotingInfo() {    
    this.checkState();
  }

  checkState(){
    this.quotingInfoSub = this.issue_state.checkState().subscribe({
      next :(state) => {      
        if(state.quoteState.state === States.success){
          this.quoteInfo = state.quoteState.quoteInfo;
            //this.setValidSteps(this.quotingInfo!);
          this.selectQuote = this.selectQuote ?? [];

          this.quoteInfo?.forEach((quote: any) => {
            const parsedObject = JSON.parse(quote.CotDatosMinimos);
            const tipoProducto = quote.TprId === 8 ? 'AUTO' : quote.TprId === 9 ? 'ANIMAIS DE ESTIMAÇÃO' :quote.TprId === 10 ? 'DENTAL' : `Tipo produto:${quote.TprId}`;
            const formattedDate = this.datePipe.transform(quote.CotFecha, 'dd/MM/yyyy HH:mm:ss');
            
            this.selectQuote!.push({
              descripcion: `Id: ${quote.CotId} - Cliente: ${quote.NumeroDocumento}  -  Tipo de produto: ${tipoProducto} -  Data: ${formattedDate}`,
              id: quote.CotId,
              tipo: quote.TprId,
              originalDate: quote.CotFecha
            });
          });
          
          this.selectQuote.sort((a, b) => new Date(b.originalDate).getTime() - new Date(a.originalDate).getTime());
          
          this.quotes = this.modelCreator.createSelectModel('quote', LABELS.SELECT_QUOTES, this.selectQuote!, this.parentGroup!, LABELS.VERSION,  '', true);
        }
        else{
          // mensajito... 
          // En teoría siempre deberíamos tener los datos de cotización
          // peeero... 
        }
      }
    })
    
  }


  subscribeToQuotingState(quoteType:number){
    this.quotingState1$ = this.issue_state.checkState().subscribe({
      next : ({quotingState}) => {
        if(quotingState.state === States.loading)
          return;

        // if(quotingState.state === States.empty || (quotingState.state === States.success && quotingState.quotingInfo?.idTipoProducto !== quoteType && quoteType !== -1)){
          this.issue_state.getQuotingInfo(quoteType);
        //   return;
        // }

        if(quotingState.state === States.success){
          if(quotingState.quotingInfo?.EsMascota){
            //this.showMessagePopUp(LABELS.COMING_SOON, [LABELS.WORKING_FOR_YOUR_EXPERIENCE]);
          }            
          else if(quotingState.quotingInfo?.EsVehiculo)
            this.redirector.redirectToProposalVehicle();
          else{
            //this.showMessagePopUp(LABELS.COMING_SOON, [LABELS.WORKING_FOR_YOUR_EXPERIENCE]);
          }          
        }
        this.quotingState1$.unsubscribe();
      }
    })      
  }
  showMessagePopUp(title : string, messages : string[], type : PopUpTypesEnum = PopUpTypesEnum.success){
    this.popupData={
      component:PopupMessageComponent,
      config : {
        title : title,
        hideHeader : true
      },
      
      data : {
        type : type,
        messages : messages,
        button:true
      }
    };
    this.popupSvc.show(this.popupData)
  }
  validationQuote(value:number){
    const quote = this.parentGroup!.get('quote');
    const quoteId =quote!.value;
    if(quoteId==""){
      this.showMessagePopUp('', [LABELS.ATTENTION, LABELS.SELECT_SINGLE_QUOTE], PopUpTypesEnum.warning )
      return
    }
    this.subscribeToQuotingState(quoteId.tipo)
    if(value==1)
      this.issue_state.setQuote(quoteId.id,quoteId.tipo,false);
    else
      this.issue_state.setQuote(quoteId.id,quoteId.tipo,true);
    if(quoteId.tipo==8){
      this.redirector.redirectToProposalVehicle();
    }


  }
  ngOnInit(): void {
    this.parentGroup = this.fb.group({
        quote: ['', Validators.required] 
      });
    this.loadQuotingInfo();
    //this.setValidSteps();

  }



  
  back(){
    this.redirector.redirectToDashboard();
  }

  ngOnDestroy(): void {
    this.quotingInfoSub.unsubscribe();
  }
}
