<div class="col-12">
    <div style="max-width: 100%; margin: auto;font-size: 0.9rem;">
        <renewal-table
        [columns]="columns"
        [data]="proposalsList"
        [totalPaginas]="totalPages"
        [registrosPorPagina]="recordsPerPage"
        [paginaActual]="currentPage"
        [totalRegistros]="totalRecords"
        [isRenewal]="false"
        (detailClicked)="detail($event)"
        (pageChange)="pageChange($event)">
        </renewal-table>
    </div>   
</div>

<custom-popup *ngIf="showPopUp"
[show]="true" 
[hiddenHeader]="true"
[hiddenButtons]="true"
[maxSize]="popupSize"
[title]="titleDownload" 
[componentData]="componentData"
(dismissed)="onCancel()">
</custom-popup>