import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { SharedModule } from 'src/app/shared/shared.module';
import { IssueVehicleDataComponent } from 'src/app/features/issue/issue-vehicle-data/issue-vehicle-data.component';
import { DashboardModule } from '../../dashboard/dashboard.module';
import { PriceInsuranceComponent } from '../price-insurance/price-insurance.component';
import { CalendarModule } from 'primeng/calendar';
import { IssueOwnerDataComponent } from 'src/app/features/issue/issue-owner-data/issue-owner-data.component';
import { IssueDriverDataComponent } from 'src/app/features/issue/issue-driver-data/issue-driver-data.component';
import { IssueInsuredDataComponent } from 'src/app/features/issue/issue-insured/issue-insured-data.component';
import { IssueInsuranceVehicleRoutingModule } from '../issue-insurance-vehicle/issue-insurance-vehicle-routing.module';
import { IssueInsuranceVehicleComponent } from './issue-insurance-vehicle.component';
import { IssueRenewalComponent } from 'src/app/features/issue/issue-renewal/issue-renewal.component';
import { QuotesInfoComponent } from 'src/app/features/issue/quotes-info/quotes-info.component';
import { IssuePaymentDataComponent } from 'src/app/features/issue/issue-payment/issue-payment-data.component';






@NgModule({
  declarations: [
    IssueInsuranceVehicleComponent,
    IssueVehicleDataComponent,
    PriceInsuranceComponent,
    IssueOwnerDataComponent,
    IssueDriverDataComponent,
    IssueInsuredDataComponent,
    IssueRenewalComponent,
    QuotesInfoComponent,
    IssuePaymentDataComponent
  ],
  imports: [
    CommonModule,
    SharedModule,
    DashboardModule,
    CalendarModule,
    IssueInsuranceVehicleRoutingModule
  ]
})
export class IssueInsuranceVehicleModule { }
