import { ButtonTypesEnum } from "../../main/constants/buttons.enum";

export class ButtonModel{
    type : ButtonTypesEnum  = ButtonTypesEnum.Common;
    text  = "";
    disabled? : boolean = false;
    classes? : string = ""; // Estas clases sobreescriben las de material
    color? : 'primary' | 'accent' | 'warn' = "primary";  // Material    
    
    icon? : string;
    useSvgIcon? : boolean = false;        
}