import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { HomeComponent } from './home.component';
import { DashboardComponent } from '../dashboard/dashboard.component';
import { ProposalListComponent } from 'src/app/features/dashboard/proposal-list/proposal-list.component';
import { CreateInsuranceComponent } from '../issue/create-insurance/create-insurance.component';
import { PriceInsuranceComponent } from '../issue/price-insurance/price-insurance.component';
import { PartnershipComponent } from '../partnership/partnership.component';
import { TipsComponent } from '../dicas/tips.component';
import { TipComponent } from '../dicas/tip.component';
import { QuotesInfoComponent } from 'src/app/features/issue/quotes-info/quotes-info.component';
import { RenewalListComponent } from 'src/app/features/dashboard/renewal-list/renewal-list.component';
import { EmissionListComponent } from 'src/app/features/dashboard/emission-list/emission-list.component';



const routes: Routes = [
  {
    path: 'user',
    loadChildren: () => import('../user/user.module').then(module => module.UserModule),
    canLoad: [],
  },
  {
    path: 'emission',
    loadChildren: () => import('../issue/create-insurance/create-insurance.module').then(module => module.CreateInsuranceModule),
    canLoad: [],
  },
  {
    path: 'renewals',
    loadChildren: () => import('../issue/create-renewal/create-renewal.module').then(module => module.CreateRenewalModule),
    canLoad: [],
  },
  {
    path: 'cotizacion',
    loadChildren: () => import('../issue/issue-insurance/issue-insurance.module').then(module => module.IssueInsuranceModule),
    canLoad: [],
  },
  {
    path: 'quoting-vehicle',
    loadChildren: () => import('../issue/issue-insurance-vehicle/issue-insurance-vehicle.module').then(module => module.IssueInsuranceVehicleModule),
    canLoad: [],
  },
  {
    path: 'partnership',
    component: PartnershipComponent
  },
  { path: 'quoting-vehicle-plans', component: PriceInsuranceComponent },
  { path: 'insurance-client', component: ProposalListComponent },
  { path: 'insurance', component: EmissionListComponent },
  { path: 'renewal', component: RenewalListComponent },
  { path: 'tips', component: TipsComponent },
  { path: 'tip', component: TipComponent },
  { path: 'quotes', component: QuotesInfoComponent },
  { path: 'inicio', component: DashboardComponent },
  { path: '**', component: ProposalListComponent },

];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class HomeRoutingModule { }
