<custom-popup
    [show]="isPopupShowing"
    [hiddenHeader]="true"
    [hiddenButtons]="true"
    [title]="''" 
    [componentData]="componentData"
    (dismissed)="closePopup()">
    
</custom-popup>

<div class="col-12">
    <p class="ps-5 pt-1 text-white pointer" (keypress)="backDasboard()" (click)="backDasboard()" tabindex="0">
      < {{back_dashboard}}</p>
  </div>
<div class="h-100 w-100 p-3 p-md-6 d-flex flex-md-wrap flex-wrap-reverse gap-5 justify-content-center">
    <div class="col-12 col-md-4 card scroll-y p-3">
        <side-advices [model]="advices"></side-advices> 
    </div>  

    <div class="col-12 col-md-5">
        <div class="m-auto p-2 common-card h-100 d-flex col-12 flex-column align-items-center scroll-y justify-content-center pt-4">

            <div class="col-12 col-md-8">
                <p class="fs-5 fw-medium">{{title}}</p>
            </div>
            
            <div class="col-12 d-flex justify-content-center">
                <div class="d-flex col-12 col-md-9 justify-content-center flex-wrap">
                    <div class="col-12">
                        <p>{{regions_title}}</p>
                        <custom-select [model]="regionSelect"></custom-select>
                    </div>
    
                    <div class="col-12 p-1 d-flex py-4 align-content-center align-items-center" >
                            <custom-input [model]="petInput!"></custom-input>            
                        <div class="bg-secondary-subtle add-icon d-flex justify-content-center align-items-center p-1 mx-2 mt-4 responsive-icon"
                            (keydown)="addPet()"
                            (click)="addPet()"
                            tabindex="0">
                            <mat-icon aria-hidden="false" aria-label="Add Pet" fontIcon="add"></mat-icon>
                        </div>
                    </div>
    
                    <div class="p-4" *ngIf="!form.value.mascotas" style="height: 5rem;"></div>
                    <div class="p-1 text-center col-4 d-flex flex-column" *ngFor="let pet of form.value?.mascotas ?? []">
                        <div class="pet-icon d-flex justify-content-center align-content-center align-self-center">
                            <img alt="Pet logo" src="/assets/product-types/pro9-white.svg" class="icon-logo">
                          </div>
                        <p class="text-sm text-ellipsis text-center ">{{pet.nombre}}</p>    
                    </div>
                </div>
            </div>
    
            <div class="col-12 col-md-10 flex-wrap text-end py-2 mb-4 ">
                <custom-button [model]="btnNext" (customClick)="price()"/>
            </div>
    
        </div>

</div>

