import { Component } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { LABELS } from 'src/app/main/constants/labels.enum';
import { ConsultantService } from 'src/app/main/services/consultant.service';
import { RedirectorService } from 'src/app/main/services/redirector.service';

@Component({
  selector: 'app-tip',
  templateUrl: './tip.component.html',
  styleUrls: ['./tip.component.scss']
})
export class TipComponent {
  idTip=0
  detailTip:any;
  readonly back_dicas=LABELS.BACK_TIPS
constructor(private consultantProposal: ConsultantService,private route : ActivatedRoute,private redirector : RedirectorService){
  this.route.queryParams.subscribe({
    next : (params) => {
        if(params){
            const a = params['idTip'];
            if(!this.idTip){
              this.idTip = +a;
              const tips=this.consultantProposal.getTips();
              this.detailTip=tips.find((t: { Id: any; })=>t.Id==this.idTip)
            }
        }                
    }
  })
}

  back(){
    this.redirector.redirectToTips();
  }

}
