import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { CreateInsuranceComponent } from './create-insurance.component';
import { SharedModule } from 'src/app/shared/shared.module';
import { EmissionPlanDescriptionComponent } from 'src/app/features/issue/issue-plan-description/issue-plan-description.component';
import { EmissionPersonalInfoComponent } from 'src/app/features/issue/emission-personal-info/emission-personal-info.component';
import { CreateInsuranceRoutingModule } from './create-insurance-routing.module';
import { EmissionVehicleInfoComponent } from 'src/app/features/issue/emission-vehicle-info/emission-vehicle-info.component';
import { EmissionBeneficiariesComponent } from 'src/app/features/issue/emission-insured/emission-insured.component';
import { EmissionRiskLocationComponent } from 'src/app/features/issue/emission-risk-location/emission-risk-location.component';
import { EmissionCoveragesComponent } from 'src/app/features/issue/emission-coverages/emission-coverages.component';
import { EmissionSummaryComponent } from 'src/app/features/issue/emission-summary/emission-summary.component';
import { EmissionPaymentComponent } from 'src/app/features/issue/emission-payment/emission-payment.component';
import { EmissionFreeDataComponent } from 'src/app/features/issue/emission-free-data/emission-free-data.component';
import { EmissionManagerInfoComponent } from 'src/app/features/issue/emission-manager/emission-manager.component';
import { EmissionConfirmationComponent } from 'src/app/features/issue/emission-confirmation/emission-confirmation.component';
import { EmissionDriverInfoComponent } from 'src/app/features/issue/emission-driver/emission-driver.component';

@NgModule({
  declarations: [
    CreateInsuranceComponent,
    EmissionPersonalInfoComponent,
    EmissionVehicleInfoComponent,
    EmissionBeneficiariesComponent,
    EmissionRiskLocationComponent,
    EmissionCoveragesComponent,
    EmissionSummaryComponent,
    EmissionPaymentComponent,
    EmissionFreeDataComponent,
    EmissionConfirmationComponent,
    EmissionManagerInfoComponent,
    EmissionDriverInfoComponent
  ],
  exports:[
    CreateInsuranceComponent,
    EmissionPersonalInfoComponent,
    EmissionVehicleInfoComponent,
    EmissionBeneficiariesComponent,
    EmissionRiskLocationComponent,
    EmissionCoveragesComponent,
    EmissionSummaryComponent,
    EmissionPaymentComponent,
    EmissionFreeDataComponent,
    EmissionDriverInfoComponent
  ],
  imports: [
    CommonModule,
    SharedModule,
    CreateInsuranceRoutingModule
  ]
})
export class CreateInsuranceModule { }
