<div class="col-12 pt-5 mt-1 ms-4">
    <div class="col-12 py-2">
        <p class="fw-medium fs-5">{{title}}</p>
    </div>
    <div class="row">
        <div *ngFor="let block of summary" class="p-0 col-12 col-md-5 col-lg-5">
            <div class="">
                <p class="summary-almost-normal xd mb-2">{{block.etiqueta}}</p>
            </div>
            <div class="">
                <p class="summary-bold">{{block.valor}}</p>
            </div>
        </div>
    </div>
</div>
<div class="row mb-4 mt-2" *ngIf="showButton">
    <custom-button [model]="btnReturn!" (customClick)="return()" class="col-sm-6 mt-2"></custom-button>
    <custom-button [model]="continueBtn!" (customClick)="next()" class="col-sm-6 mt-2"></custom-button>
</div>