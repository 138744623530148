import { IssueState } from "../issue/issue.state";
import { Injectable } from "@angular/core";
import { LoginState } from "../login/login.state";
import { Subject } from "rxjs";
import { ISelectItem } from "src/app/main/models/select-item.model";

@Injectable()
export class AppState {    
    private enterpriseId? : number = -1;
    private enterpriseLogo? : string;
    private campaignId? : number;
    private isCallCenter  = false;
        

    private enterprise$ = new Subject<ISelectItem>();

    constructor(
        private issue_state : IssueState,
        private login_state : LoginState
    ) {}

    getEnterpriseSub(){
        return this.enterprise$.asObservable();
    }

    getEnterpriseId(){
        return this.enterpriseId ?? -1;
    }

    getCampaignId(){
        return this.campaignId ?? -1;
    }

    setCampaignId(id : number){
        this.campaignId = id;
    }

    setIsCallCenter(value : boolean){
        this.isCallCenter = value;   
        if(this.isCallCenter)
            this.enterpriseId = 0;     
    }
    
    
    setEnterpriseId(id : number){        
        if(id === null)
            return;

        this.enterpriseId = id;
        this.enterpriseLogo = id + ".png";
        this.enterprise$.next({
            id : this.enterpriseId.toString(),
            descripcion : this.enterpriseLogo.toString(),
        })
    }

    getLogo(){
        return this.enterpriseLogo;
    }

    setLogo(logo : string){
        this.enterpriseLogo = logo;
    }
    
    getLoginState(){
        return this.login_state
    }

    getIssueState(){
        return this.issue_state;
    }

    clearAppState(){
        this.issue_state.clearState();
        this.login_state.clearState();
    }
}