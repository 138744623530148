<modal-popup class="modal-content" #modal1
    [display]="show!"
    [hideHeader]="hiddenHeader"
    [hideButtons]="hiddenButtons"
    [maxSize]="maxSize"
    (cancel)="handleDismiss()">
        <div class="app-modal-header" *ngIf="!hiddenHeader">    
            <h2 class="modal-title">{{title}}</h2>
        </div>
        <div class="app-modal-body" [ngClass]="{'my-5': hiddenHeader}">
            <ng-template #componentContainer></ng-template>
        </div>
        <div class="app-modal-footer" *ngIf="!hiddenButtons">
            <button type="button" mat-raised-button  class="base-background text-white" (click)="handleAcept()">{{buttonText}}</button>
        </div>
</modal-popup>