<full-cover-card [model]="coverCardModel" (backPressed)="navigateBack()">
    <div class="container justify-content-start justify-content-md-center flex-column align-items-center h-100">

        <div class="pt-md-1"><p class="fs-3 text-center">{{title}}</p></div>

        
        <div class=" col-12 pt-3 pb-1">
        <div *ngIf="currentStep === 0" class="justify-content-center col-12 col-md-6 m-auto ">
            <custom-input [model]="emailModel"/>

            <custom-input [model]="employeeIdModel"/>
        </div>   

        <div *ngIf="currentStep === 1">
            <app-password-confirmation [model]="passwordConfirmationModel"></app-password-confirmation>
        </div>  
        </div>
        
        <div class="col-12 col-md-6 m-auto">
            <div class="text-danger text-center pb-3" *ngIf="showRegisterError">{{registerError}}</div>
            <div class="m-auto">
                <custom-button [model]="confirmButtonModel" class="" (customClick)="nextStep(currentStep)"/>
            </div>
            
            <div class="col-12 text-center py-3 ">
                <div class="row">
                    <p>{{privacyText}}</p>
                </div>
                <div class="row pt-0 d-flex justify-content-between">
                    <p>
                        <a href="https://www.aon.com/mexico/about-aon/politica-de-privacidad.jsp" target="_blank" class="text-decoration-underline text-dark">{{privacyPolicies}}</a>
                    </p>
                    
                </div>
                
            </div>
            
        </div>
    </div>


</full-cover-card> 
