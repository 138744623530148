
<div class="fs-6 h-100 d-flex flex-column gap-4 pt-4 scroll-y container sidebar-wrapper"
     [ngClass]="{'menu-open': menuOpen}">
  <div *ngFor="let menu of menus; index as i" class="d-flex align-items-center justify-content-start w-100" style="cursor: pointer;">
    <ng-template #tooltipSide>{{menu.description | titlecase}}</ng-template>
    <div tabindex="0" class="text-center" [ngClass]="{'col-2 col-sm-4': menuOpen, 'col-12': !menuOpen}" (keypress)="navigateTo(menu)" (click)="navigateTo(menu)"
         [pTooltip]="!menuOpen ? tooltipSide : undefined" [tooltipStyleClass]="'tooltipSide'">
      <i [ngClass]="menu.icon" class="icon-class"></i>
    </div>
    <div *ngIf="menuOpen" class="col-10 pl-2" (keypress)="navigateTo(menu)" (click)="navigateTo(menu)" tabindex="0">
      <p class="m-0">{{menu.description}}</p>
    </div>
  </div>
</div>
