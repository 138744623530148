import { Component, OnDestroy, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { HeaderService } from 'src/app/main/services/header.service';
import { ButtonTypesEnum } from 'src/app/main/constants/buttons.enum';
import { ButtonModel } from 'src/app/shared/models/button.model';
import { DomSanitizer } from '@angular/platform-browser';

@Component({
  selector: 'app-landing',
  templateUrl: './landing.component.html',
  styleUrls: ['./landing.component.scss']
})
export class LandingComponent implements OnInit, OnDestroy{

  btnBegin : ButtonModel = new ButtonModel();
  pdfSrc:any="";

  constructor(private headerService : HeaderService,
              private router : Router,
              private sanitizer: DomSanitizer) {
    headerService.showHeader();    
    
    this.btnBegin = {
      text: 'Empezar',
      classes : 'bg-secondary bg-opacity-25 title-font begin-button px-5',
      type:ButtonTypesEnum.Common
    }

  }
  ngOnInit(): void {
    this.sendMessageToIframe();
  }

  sendMessageToIframe() {
    window.parent.postMessage(
      { eventName: 'EmissionOk', eventValue: 'EmissionOk' },
      '*'
    );
  }
  ngOnDestroy(): void {
    this.headerService.hideHeader();
  }

  login(){
    this.router.navigateByUrl('login');
  }
}
