<div class="stepper-container row">
    <app-custom-stepper [currentStep]="currentStep" [steps]="steps" class="custom-stepper"></app-custom-stepper>
  </div>
  
<div class="col-12 py-2">
    <p class="fw-medium fs-5">{{title}}</p>
</div>

<div *ngIf="currentStep===0">
    <div class="col-12">
        <custom-input [model]="placa!"></custom-input>
    </div>
    
    <div class="col-12">
        <custom-input [model]="motor!"></custom-input>
    </div>
    
    <div class="col-12">
        <custom-input [model]="numeroSerie!"></custom-input>
    </div>
</div>

<div *ngIf="currentStep===1">
    <div class="col-12">
        <custom-select [model]="condicion!"></custom-select>
    </div>
    
    <div class="col-12 pt-3">
        <app-input-datetime-custom [model]="vigencyModel!"></app-input-datetime-custom>
    </div>

    <!-- <div class="col-12">
        <custom-input [model]="precio!"></custom-input>
    </div> -->
</div>


<!-- <div *ngIf="form?.value.ceroKm && notaFiscal && valorNota"
 class="">
    <div class="col-12">
        <custom-input [model]="notaFiscal!"></custom-input>
    </div>

    <div class="col-12">
        <custom-input [model]="valorNota!"></custom-input>
    </div>    
    
</div> -->
<div class="row mb-4 mt-4">
    <custom-button [model]="btnReturn!" (customClick)="return()" class="col-6 col-sm-6 mt-2"></custom-button>
    <custom-button [model]="continueBtn!" (customClick)="next()" class="col-6 col-sm-6 mt-2"></custom-button>
</div>   