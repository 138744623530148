import { Component, Input, OnChanges, SimpleChanges } from '@angular/core';
import { LABELS } from 'src/app/main/constants/labels.enum';
import { NewProposalModel } from 'src/app/main/models/issue/new-proposal.model';

@Component({
  selector: 'app-popup-pet-confirm',
  templateUrl: './popup-pet-confirm.component.html',
  styleUrls: ['./popup-pet-confirm.component.scss']
})
export class PopupPetConfirmComponent implements OnChanges {

  proposal : NewProposalModel | undefined = undefined;
  pet_number = LABELS.PET_NUMBER;

  plan_title    = LABELS.PLAN;
  name_title    = LABELS.PET_NAME;
  general_title = LABELS.CONFIRM_PROPOSAL_INFO;

  ngOnChanges(changes: SimpleChanges): void {
    
    this.proposal;
  }

}
