import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { LABELS } from 'src/app/main/constants/labels.enum';
import { RedirectorService } from 'src/app/main/services/redirector.service';


@Component({
  selector: 'app-plan-comparer',
  templateUrl: './plan-comparer.component.html',
  styleUrls: ['./plan-comparer.component.scss']
})
export class PlanComparerComponent implements OnInit{

  @Input() data:any;
  @Output() backed = new EventEmitter<boolean>();
  @Output() planSelected = new EventEmitter<number>();

  readonly quota_plan= LABELS.QUOTA_PLAN
  readonly sum_insured_plan= LABELS.SUM_INSURED_PLAN
  readonly back=LABELS.BACK
  readonly comparer_coverages_title=LABELS.COMPARER_COVERAGES_TITLE
  readonly comparer_coverages_subtitled=LABELS.COMPARER_COVERAGES_SUBTITLED
  readonly select_plan=LABELS.SELECT_PLAN
  readonly price_per_month=LABELS.PRICE_PER_MONTH
  readonly money_symbol=LABELS.MONEY_SYMBOL
  readonly limit= LABELS.LIMIT_COMPARER
  readonly coverages= LABELS.COVERAGES_COMPARER
  allCoberturas: string[] = [];
  showCoverage=true;
constructor(private redirector : RedirectorService,){
}
  ngOnInit() {
    this.mergeCoberturas();
  }

  mergeCoberturas() {
    const dasd= this.data;
    const coberturaSet = new Set<string>();
    this.data.forEach((plan: { Coberturas: any[]; }) => {
      plan.Coberturas.forEach(cobertura => coberturaSet.add(cobertura.Descripcion));
    });
    this.allCoberturas = Array.from(coberturaSet);
  }
  hasCobertura(plan: any, cobertura: string): boolean {
    return plan.Coberturas.some((c: { Descripcion: string; }) => c.Descripcion === cobertura);
  }

  getCoberturaPrice(plan: any, cobertura: string): string | null {
    const coberturaObj = plan.Coberturas.find((c: { Descripcion: string; }) => c.Descripcion === cobertura);
    return coberturaObj ? coberturaObj.Tipo : null; // Asumiendo que el precio está en coberturaObj.Precio
  }

  beginEmission(plan:any){
    this.planSelected.emit(plan);
    //this.redirector.redirectToEmission(1)
  }

  handleDismiss() {
    this.backed.emit();
  }

  showPrices(){
    this.showCoverage=false
  }
  showCoverages(){
    this.showCoverage=true
  }
}

