<div class="w-100 h-100 d-flex justify-content-center align-content-center" *ngIf="!isLoading">
    <div class="row"  *ngIf="isSuccess">
        <div class="col-12 text-center mb-4">
            <img alt="Emission succeded" src="/assets/icons/emission-completed.svg" width="20%" height="auto">
        </div>

        <div class="col-12 text-center">
            <p class="text-center fw-bold fs-5">{{success_lbl}}</p>
            <p class="text-center fs-6 mb-0">
                {{success_msg}}
            </p>
            <p class="text-center fs-6" [innerHTML]="success_msg_2"></p>
        </div>

        <div class=" text-center">
            <custom-button [model]="succesBtn" (customClick)="redirectToProposalList()"></custom-button>
        </div>
        <div class="text-center mt-4">
            <div class="text-secondary fontMd">
                {{check_spam}}
            </div>
        </div>

        <div class="text-center mt-4 mb-4">
            <div class="text-secondary text-small align-self-end fontSm">
                {{legal_text}}
            </div>
        </div>
    </div>

    <div class="row" *ngIf="!isSuccess">
        <div class="col-12 text-center mb-5" >
            <img alt="Emission Failed" src="/assets/icons/emission-error.svg" width="70%" height="auto">
            <div class="mt-3 text-center">
                <p class="text-center fw-bold" *ngIf="!isSuccess">{{error_lbl}}</p>
            </div>
        </div>
    </div>

</div>

<div *ngIf="isLoading" class="w-100 h-100 d-flex justify-content-center align-content-center text-center">
    <p class="text-center text-secondary">{{loading_message}}</p>

</div>