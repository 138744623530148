
<div
    (click)="onContainerClicked($event)"
    (keypress)="keyPressed()"
     class="modal fade modal-popup modalBgOp" tabindex="-1"
    [ngClass]="{'in': true}"
    [ngStyle]="{'display': visible ? 'block' : 'none', 'opacity': true ? 1 : 0}">
    <div class="modal-dialog size modalSize" [ngClass]="{'modal-xl': maxSize}">
        <div class="modal-content popupcontrollers_content card" style="margin-top: 150px;">
            <i class="fa fa-times cancel-circle close-icon" aria-hidden="false" tabindex="0" (keypress)="handleDismiss()" (click)="handleDismiss()"></i>  
            <div class="modal-header popupcontrollers_header" *ngIf="!hideHeader">
                <ng-content select=".app-modal-header"></ng-content>
            </div>
            <div class="modal-body popupcontrollers_body row">
                <ng-content select=".app-modal-body"></ng-content>
            </div>
            <div class="modal-footer popupcontrollers_footer" *ngIf="!hideButtons">
                <ng-content select=".app-modal-footer"></ng-content>
            </div>
        </div>
    </div>
</div>